.leaves-fw-600{
  font-weight: 600 !important;
}

.leaves-flex-start{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.leaves-flex-end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.leaves-display-flex{
  display: flex;
}

.leaves-apply-btn{
  margin-top:32px !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
  height: 62px !important;
}

.leaves-apply-btn-text{
  text-transform: none;
  border-radius: 18px !important;
}


@media only screen and (max-width: 767px) {
  .leaves-apply-btn{
    margin-top:24px !important;
    border-radius: 18px !important;
  }
}

@media only screen and (max-width: 1050px) {
}

@media only screen and (max-width: 1200px) {}
