.shareModalPopupContent .boxStyle {
    width: 100%;
    height: 58px;
    padding: 0 15px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    background: #f9f8fd;
}

.shareModalPopupContent .addTaskPopup {
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 8px;
    width: 70%;
    height: auto;
    max-height: 80%;
    max-width: 650px;
}

.shareModalPopupContent .addTaskPopupGrid {
    padding: 20px;
}

.shareModalPopupContent .iconButton {
    position: absolute;
    right: 5px;
    top: 5px;
}

.shareModalPopupContent .m4 {
    margin: 4px 0;
}

.shareModalPopupContent .addStudentGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 8px 0;
    padding-top: 0;
}

.shareModalPopupContent .taskTitle {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    padding-top: 0;
}

.shareModalPopupContent .shareSearchIcon {
    height: 25px;
    width: 25px;
}

.shareModalPopupContent .searchParticipantInput {
    border: none;
    outline: none;
    opacity: 0.5;
    font-size: 18px;
    width: 100%;
    background: transparent;
}

.shareModalPopupContent .studentListGrid {
    margin: 4px 0;
    display: block;
}

.shareModalPopupContent .studentInnerGrid {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
}

.shareModalPopupContent .studentListTitle {
    font-size: 18px;
    font-weight: 600;
}

.shareModalPopupContent .searchItemGrid {
    margin: 8px 0;
    max-height: 210px;
    overflow-y: auto;
    padding: 12px;
}

.shareModalPopupContent .accordianGrid {
    margin: 8px 0;
}

.shareModalPopupContent .accordianSummary {
    flex-direction: row-reverse;
}

.shareModalPopupContent .accordianDetails {
    display: flex;
    flex-direction: column;
}

.shareModalPopupContent .noDataTextBox {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.shareModalPopupContent .noDataFound {
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
}

.shareModalPopupContent .selectedUserGrid {
    border-bottom: 1px solid #000;
    margin: 4px 0;
    cursor: pointer;
}

.shareModalPopupContent .avtarGrid {
    align-self: center;
    justify-content: center;
    display: flex;
}

.shareModalPopupContent .userAvtar {
    height: 50px;
    width: 50px;
}

.shareModalPopupContent .userFLname {
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
}

.shareModalPopupContent .studentEmail {
    text-transform: none;
    font-size: 14px;
    font-weight: bold;
    word-break: break-all;
}