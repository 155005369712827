



.search_icon_css{
    width:22px !important;
    height: 22px !important;
}
.profileOuterBox{
    padding:20px !important;
}
.image_box{
    width: 96px !important;
    height: 96px !important;
}
.up_downArrow{
  width: 25px !important;
  height: 25px !important;
  margin-top: 7px !important;
}
.up_downArrow_outer{
  width: 30px !important;
  height: 33px !important;
}
.filter_box{
  width:315px !important;
  padding: 15px !important;
}
.filter_value_box{
  font-size: 16px !important;
  padding: 10px 10px 10px 15px !important;
}
.option_menu{
  padding: 20px 25px 30px 25px !important;
  border-radius: 4px !important;
    width: 285px !important;
}
.button_height{
  height: 30px !important;
  align-items: center;
}
.menu_css{
  margin-top: 130px !important;
}
@media screen and (max-width: 767px) {
  .menu_css{
    margin-top: 110px !important;
  }
  .filter_box{
    width:185px !important;
    padding: 7px !important;
  }
  .filter_value_box{
    font-size: 12px !important;
    padding: 5px 5px 5px 7px !important;
  }
  .option_menu{
    padding: 10px 12px 15px 12px !important;
    border-radius: 4px !important;
    width: 170px !important;
  }
  .button_height{
    height: 24px !important;
    align-items: center;
  }
  .up_downArrow{
    width: 19px !important;
    height: 19px !important;
    margin-top: 1px !important;
  }
  .up_downArrow_outer{
    width: 25px !important;
    height: 27px !important;
  }
    .search_icon_css{
        width:16px !important;
        height: 16px !important;
    }
    .profileOuterBox{
        padding:15px !important;
    }
    .image_box{
        width: 56px !important;
        height: 56px !important;
    }

    .filtermob {
      padding-left: 10px !important;
      padding-right: 10px !important;
      margin-top: 10px !important;
    }
  }
  @media screen and (max-width: 959px) and (min-width: 768px) {
    .menu_css{
      margin-top: 116px !important;
    }
    .button_height{
      height: 26px !important;
      align-items: center;
    }
    .filter_box{
      width:215px !important;
      padding: 10px !important;
    }
    .filter_value_box{
      font-size: 13px !important;
      padding: 7px 7px 7px 9px !important;
    }
    .option_menu{
      padding: 13px 15px 17px  !important;
      border-radius: 6px !important;
      width: 195px !important;
    }
    
    .up_downArrow{
      width: 21px !important;
      height: 21px !important;
      margin-top: 5px !important;
    }
    .up_downArrow_outer{
      width: 27px !important;
      height: 29px !important;
    }
    .search_icon_css{
      width:17px !important;
      height: 17px !important;
  }
  .profileOuterBox{
      padding:15px !important;
  }
  .image_box{
      width: 64px !important;
      height: 64px !important;
  }
  }
  @media screen and (max-width: 1279px) and (min-width: 959px) {
    .menu_css{
      margin-top: 125px !important;
    }
    .button_height{
      height: 28px !important;
      align-items: center;
    }
    .filter_box{
      width:265px !important;
      padding: 12.5px !important;
    }
    .filter_value_box{
      font-size: 14.5px !important;
      padding: 8px 8px 8px 12.5px !important;
    }
    .option_menu{
      padding: 16px 21px 25px  !important;
      border-radius: 8px !important;
      width: 240px !important;
    }
    .up_downArrow{
      width: 23px !important;
      height: 23px !important;
      margin-top: 5px !important;
    }
    .up_downArrow_outer{
      width: 29px !important;
      height: 31px !important;
    }
    .search_icon_css{
      width:18.5px !important;
      height: 18.5px !important;
  }
  .profileOuterBox{
      padding:17px !important;
  }
  .image_box{
      width: 74px !important;
      height: 74px !important;
  }

  .col12{
    width: 100% !important;
    height: 46px !important;
  }
  
  .paginationDisabled a {
    padding: 5px 5px 7px 5px !important;
  }
  }