/* .PhoneInputInput{
    padding: 17px 10px;
    font-family: "Open sans";
    font-size: 15.8px;
    border:none;
}
.PhoneInputInput:focus{
    outline: none !important;
}
.PhoneInputCountry{
    padding: 10px;
}
.PhoneInputCountryIcon{
    margin:5px;
} */

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

.input_pass_icon {
  border-right: none !important;
}

.input_focus_pass {
  border-left: none !important;
  -webkit-box-shadow: 0 0 0 1000px #f1f1f1 inset !important;
}

.input_focus_pass:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 1000px #f1f1f1 inset !important;
}

@media (max-width: 599px) {
  .input_pass_icon {
    padding: 15px 15px !important;
  }

  .input_focus_pass {
    height: 52px !important;
  }
}
