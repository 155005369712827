.memberBoxContainer {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1) !important;
    background-color: #fff !important;
    border-radius: 6px !important;
    padding: 20px !important;
    position: relative !important;
    cursor: pointer !important;
}
.memberBoxContainer .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.memberBoxContainer .imageBox {
    border-radius: 50%;
    padding: 3px;
}
.memberBoxContainer .avatar {
    width: 100%;
    height: 100%;
    padding: 6px;
    border-radius: 50%;
}
.memberBoxContainer .flexColumn {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.memberBoxContainer .title {
    font-weight: 600;
    width: 100%;
    word-break: break-all;
}
.memberBoxContainer .fw600 {
    font-weight: 600px;
}
.memberBoxContainer .fs16 {
    font-size: 16px;
}
.memberBoxContainer .ml20 {
    margin-left: 20px;
}
.maxWidth_420 {
    max-width: 420px !important;
}
.memberBoxContainer .textLeft {
    text-align: left;
}