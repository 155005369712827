.MesDialogContainer {
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 29px;
    padding: 32px 18px;
    width: 600px;
    max-width: 90%;
}

.MesDialogContainer .emptyDiv {
    height: 1px;
    margin: 14px 0 31px 0;
    border: solid 0.3px #979797;
}

.MesDialogContainer .flexCenter {
    display: flex;
    justify-content: center;
}

.MesDialogContainer .titleDiv {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3f526d;
    margin-bottom: 14px;
}

.MesDialogContainer .confirmationBox {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3f526d;
    margin: 31px 2px 6px 3px;
}

.MesDialogContainer .descriptionTypo {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #959393;
    margin: 6px 53px 40px 54px;
}

.MesDialogContainer .btnTypo {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
}

.MesDialogContainer .whiteText {
    color: #fff;
}

.MesDialogContainer .dltBtn {
    margin: 0 0 0 20px;
    padding: 9px 20px 12px 22px;
    border-radius: 3px;
    background: #f6f6f6;
    text-transform: initial;
}

.MesDialogContainer .cancelBtn {
    margin: 0 20px 0 0;
    padding: 9px 20px 12px 22px;
    border-radius: 3px;
    background: #7f6cfc;
    color: white;
    text-transform: initial;
}