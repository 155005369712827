.QuestionStudentViewMainDiv {
    padding: 22px 20px 21px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px
}

.QuestionStudentViewQuestionDiv {
    padding: 0px 20px 8px 20px;
    display: flex;
    overflow-x: auto
}

.QuestionStudentViewQuestionTxt {
    font-size: 16px;
    font-weight: 600;
}

.QuestionStudentViewQuestionTxtMr {
    margin-left: 1%
}

.QuestionStudentViewHintDiv {
    display: flex;
    flex-direction: row;
}

.QuestionStudentViewHintImg {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    cursor: pointer
}

.QuestionStudentViewPopoverHintDiv {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-family: Open sans;
    padding: 10px 20px;
    width: 200px;
    max-height: 200px;
    overflow-y: scroll;
    text-align: center;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px 0px
}

.QuestionStudentViewPopoverHintTitle {
    font-size: 16px;
    border-bottom: 1px solid #f1f2f4;
    padding-bottom: 10px
}

.QuestionStudentViewHintTxtDiv {
    background-color: #f1f2f4;
    margin-top: 5px;
    width: 100%
}


.QuestionStudentViewHintTxtImg {
    width: 100px;
    max-height: 200px
}

.QuestionStudentViewHintTxt {
    margin-top: 5%;
}

.QuestionStudentViewMarksTxt {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-right: 20px !important
}

.QuestionStudentViewOptionQuestionDiv {
    align-items: center;
    margin-bottom: 16px !important;
}

.QuestionStudentViewOptionLayoutRadioDiv {
    justify-content: flex-end;
    display: flex
}

.QuestionStudentViewOptionLayoutImage {
    max-height: 60px;
    width: 20%;
    cursor: pointer;
}

.QuestionStudentViewOptionLayoutImageDiv {
    font-family: 'Open sans';
    font-size: 20px;
    font-weight: 600;
    color: #3f526d;
    padding-left: 31px !important;
    display: flex;
}

.QuestionStudentViewOptionLayoutTitleTxt {
    font-family: "Open sans" !important;
    font-size: 48px !important;
    font-weight: 600;
    text-align: center;
}

.QuestionStudentViewOptionLayoutAnswerTxt {
    font-family: 'Open sans';
    font-size: 20px;
    font-weight: 600;
    color: #3f526d;
    padding-left: 31px !important;
    word-break: break-all
}

.QuestionStudentViewOptionLayoutBorderMainGrid {
    padding: 13px 40px 13px 12px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #fff;
    background-color: #fff;
    align-items: center
}

.QuestionStudentViewOptionLayoutCircleMainGrid {
    padding: 13px 40px 13px 12px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    align-items: center
}

.QuestionStudentViewOptionLayoutBlockMainGrid {
    padding: 13px 40px 13px 12px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    align-items: center
}

.QuestionStudentViewOptionLayoutDTTitleDiv {
    padding: 6px 21.5px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
}

.QuestionStudentViewOptionLayoutRRLTitleDiv {
    padding: 6px 21.5px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
}

.QuestionStudentViewOptionLayoutORRLTitleDiv {
    padding: 6px 21.5px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    background-color: #fff
}

.QuestionStudentViewOptionLayoutCLTitleDiv {
    padding: 6px 21.5px;
    display: inline-block;
    justify-content: center;
    border-radius: 50px;
    background-color: #fff
}

.QuestionStudentViewOptionLayoutOCLTitleDiv {
    padding: 6px 21.5px;
    display: inline-block;
    justify-content: center;
    border-radius: 50px;
}

.QuestionStudentViewOptionLayoutRBLTitleDiv {
    padding: 6px 21.5px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
}

.QuestionStudentViewOptionLayoutORBLTitleDiv {
    padding: 6px 21.5px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
}

.QuestionStudentViewTextFieldQuestion {
    width: 100%;
    background-color: #fff;
    border-radius: 8.8px;
    margin: 0px 35px 0px 1px !important;
    border: none !important;
}

.QuestionStudentViewAnswerTitleTxt {
    font-family: "Open sans" !important;
    font-size: 20px !important;
    color: #3f526d;
    margin: 30px 0px 40px 0px !important;
    font-weight: 600 !important;
}

.QuestionStudentViewMatchQuestionMainGrid {
    display: flex;
}

.QuestionStudentViewMatchQuestionSubMainGrid {
    width: 100%;
    justify-content: center
}

.QuestionStudentViewMatchQuestionMainDiv {
    display: flex;
    flex-direction: column
}

.QuestionStudentViewMatchQuestionMainGridContainer {
    margin-bottom: 20px !important;
    align-items: center
}

.QuestionStudentViewMatchQuestionAttachmentDiv {
    border: 1px solid #3f526d;
    padding: 10px 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.QuestionStudentViewMatchQuestionAttachmentImg {
    height: 50px;
    width: 60px;
    cursor: pointer;
    object-fit: contain;
}

.QuestionStudentViewMatchQuestionAttachmentTxt {
    font-family: "Open sans" !important;
    font-size: 20px !important;
    color: #3f526d;
    font-weight: 600 !important
}

.QuestionStudentViewMatchQuestionLineDiv {
    display: flex;
    align-items: center
}

.QuestionStudentViewMatchQuestionCircle {
    border-radius: 50%;
    display: inline-block;
    height: 25px;
    width: 25px;
}

.QuestionStudentViewMatchQuestionLine {
    height: 4px;
    width: 100px;
}

.QuestionStudentViewMatchQuestionCorrectAnswerDiv {
    font-family: "Open sans" !important;
    font-size: 20px !important;
    color: #3f526d;
    font-weight: 600 !important;
    border-radius: 6px;
    background-color: #fff;
    padding: 10px 0px;
    text-align: center
}

.QuestionStudentViewMatchQuestionCorrectNoAnswerImg {
    height: 40px;
    width: 60px;
    cursor: pointer;
    object-fit: contain;
}

.QuestionStudentViewMatchQuestionCorrectNoAnswerDiv {
    background-color: #fff;
    padding: 20px 0px;
    border-radius: 6px,
}

.QuestionStudentViewMatchQuestionCorrectDragTxt {
    font-family: "Open sans" !important;
    font-size: 20px !important;
    color: #3f526d !important;
    font-weight: 600 !important;
    margin-bottom: 30px !important;
}

.QuestionStudentViewMatchQuestionPossibleResponseDiv {
    padding: 12px 11px;
    border-radius: 4px;
    border: solid 0.5px #3f526d;
    background-color: #f9f8fd;
    font-size: 20px;
    font-weight: 600;
    color: #3f526d;
    font-family: 'Open sans';
    text-align: center;
}

.QuestionStudentViewMatchQuestionPossibleResponseImg {
    height: 40px;
    width: 100%;
    cursor: pointer;
    object-fit: contain;
}

.QuestionStudentViewMatchQuestionPossibleResponseTxt {
    font-family: "Open sans" !important;
    font-size: 20px !important;
    color: #3f526d;
    font-weight: 600 !important
}

.QuestionStudentViewChartQuestionMainDiv {
    margin-top: 53px;
}

.QuestionStudentViewChartQuestionSubDiv {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 52px
}

.QuestionStudentViewChartQuestionAnswerTitleTxt {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.QuestionStudentViewChartQuestionMainTableCell {
    border: 0px !important;
    text-align: center !important
}

.QuestionStudentViewChartQuestionMainTableCellDiv {
    margin-bottom: 40px !important;
}

.QuestionStudentViewChartQuestionMainTableCellTxt {
    font-size: 16px !important;
    font-weight: 600 !important;
    margin-bottom: 20px !important
}

.QuestionStudentViewChartQuestionTextField {
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: 180px;
    height: 52px;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    padding: 0 10px !important;
}

.QuestionStudentViewSortingQuestionMainDiv {
    width: 100%;
    justify-content: center
}

.QuestionStudentViewSortingQuestionAnswerTxt {
    font-size: 20px !important;
    margin: 30px 0px 40px 0px !important;
    font-weight: 600;
}

.QuestionStudentViewSortingQuestionSubDiv {
    margin-bottom: 20px;
}

.QuestionStudentViewSortingQuestionDragDiv {
    font-family: "Open sans" !important;
    font-size: 20px;
    color: #3f526d;
    font-weight: 600;
    border-radius: 6px;
    background-color: #fff;
    padding: 10px 0px;
    text-align: center;
    position: relative;
}

.QuestionStudentViewSortingQuestionDahzeIcon {
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
    object-fit: contain !important;
    margin-right: 10px !important;
    position: absolute;
    left: 5%
}

.QuestionStudentViewLabelTypeQuestionMainDiv {
    width: 100%;
    justify-content: center !important;
}

.QuestionStudentViewLabelTypeQuestionAnswetTitleTxt {
    font-size: 20px !important;
    margin: 30px 0px 40px 0px !important;
    font-weight: 600 !important;
}

.QuestionStudentViewLabelTypeQuestionAttachmentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    min-height: 300px;
    justify-content: space-evenly;
    position: relative;
}

.QuestionStudentViewLabelTypeQuestionAttachmentSubDiv {
    position: relative;
}

.QuestionStudentViewLabelTypeQuestionAttachmentImg {
    height: 500px;
    width: 500px;
    object-fit: contain
}

.QuestionStudentViewLabelTypeQuestionAnswerMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.QuestionStudentViewLabelTypeQuestionAnswerDropDown {
    width: 100%;
}

.QuestionStudentViewLabelTypeQuestionTooltipSpanDiv {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden
}

.QuestionStudentViewLabelTypeQuestionAnswerTitleDiv {
    font-size: 20px !important;
    margin: 30px 0px 40px 0px !important;
    font-weight: 600 !important;
}

.QuestionStudentViewLabelTypeQuestionPossibleDragAnswerDiv {
    display: flex;
    flex-direction: row !important;
    margin-top: 25px;
    flex-flow: wrap;
}

.QuestionStudentViewLabelTypeQuestionPossibleDragAnswerSubDiv {
    border: 1px solid #3f526d;
    padding: 10px 20px;
    border-radius: 6px;
    margin-right: 20px !important;
    display: flex;
    align-items: center;
}

.QuestionStudentViewLabelTypeQuestionDragAnswerSubDivTxt {
    font-family: "Open sans" !important;
    font-size: 20px !important;
    color: #3f526d;
    font-weight: 600 !important
}

.QuestionStudentViewHotspotTypeQuestionMainDiv {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 100%
}

.QuestionStudentViewHotspotTypeQuestionAttachmentDiv {
    margin-bottom: 15px !important;
    width: 100%;
}

.QuestionStudentViewHotspotTypeQuestionDrawBtn {
    margin-bottom: 32px !important;
    padding: 10px 25px !important;
    border-radius: 6px !important
}

.QuestionStudentViewHotspotTypeQuestionDrawIcon {
    width: 30px;
    height: 30px;
    margin-right: 32px
}

.QuestionStudentViewHotspotTypeQuestionImgDiv {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px
}

.QuestionStudentViewHotspotTypeQuestionImg {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 80vh;
    text-align: center;
}

.QuestionStudentViewFlashCardTypeQuestionMainDiv {
    padding: 32px 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px
}

.QuestionStudentViewFlashCardTypeQuestionTextField {
    width: 100%;
    border-radius: 6px;
    text-align: start;
    font-size: 15.8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 0px
}

.QuestionStudentViewAudioVideoTypeQuestionMainDiv {
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px 0px;
    margin-bottom: 20px !important;
    padding: 20px
}

.QuestionStudentViewAudioVideoTypeQuestionAnsweerTxtDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.QuestionStudentViewAudioTypeQuestionListDiv {
    border-bottom: 1px solid #f1f2f4
}

.QuestionStudentViewVideoTypeQuestionListDiv {
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    position: relative
}

.QuestionStudentViewVideoTypeQuestionListSubDiv {
    display: flex;
    flex-direction: column;
    justify-content: center
}