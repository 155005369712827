.parent_notification_box {
  border-radius: 10px;
  height: 187px;
  padding: 22px 33px 22px 32px;
  border-radius: 20px;
  background: linear-gradient(115deg, #eec54c -42%, #ea8888 100%);
  overflow: auto;
}
.notification_view_all_text {
  font-weight: 600 !important;
}

.notification_text {
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-left: 22px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff !important;
}

.no_notification_text {
  opacity: 0.5 !important;
  font-size: 16px !important;
  color: #fff !important;
}

.no_notification_img_block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.no_notification_text_block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px !important;
}

.no_notification_img_block_span {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background-color: #cccccc33;
  padding: 17px;
}

.no_notification_img_block img {
  height: 28px;
  object-fit: contain;
}

.notification_msg_icon {
  height: 37px;
  width: 37px;
}

.notification_list {
  margin: 20px 0;
  padding: 22px 42px 12px 42px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.notification_list_header {
  font-size: 20px;
  font-weight: bold;
  margin: 30px 0 10px 25px;
}

.notification_title {
  font-size: 26px;
  font-weight: bold;
  margin: 10px 0;
}

.notification_desc {
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}

.notification_date {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0;
}

.notification_list_divider {
  margin: 30px 0 !important;
}

.view_all_text {
  white-space: nowrap !important;
}

.announcement_title_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.announcement_desc {
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0 20px 0;
}

.display_flex {
  display: flex;
}

.announcement_indicator {
  color: lightgray;
  margin: 15px 10px 0 0;
}

@media only screen and (max-width: 767px) {
  .announcement_title_div {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .notification_list {
    margin: 10px 0;
    padding: 12px 22px 12px 22px;
  }

  .notification_list_header {
    margin: 20px 0 10px 25px;
  }
}

@media only screen and (max-width: 1023px) {
}

@media only screen and (max-width: 1200px) {
  .parent_notification_box {
    margin-top: 31px;
  }
}
