.attendance-main-grid {
    border: 1px sold #4d4d4d !important;
    border-radius: 8px !important;    
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 4px;
}

.attendance-title-text {
    font-size: 22px !important;
    font-weight: 600 !important;
}

.attendance-option-grid {
    margin-top: 16px !important;
}

.attendance-flex-start {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.attendance-option-item {
    margin-top: 4px !important;
    padding-left: 8px !important;
}

.attendance-fw-600 {
    font-weight: 600 !important;
}

.attendance-flex-end {
    display: flex;
    justify-content: flex-end;
}

.attendance-linear-progress-grid {
    padding: 24px 0px;
}

.attendance-circular-progress-grid {
    margin-top: 16px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 32px !important;
}


.attendance-circular-progress-div {
    position: relative;
}

.attendance-ratio{
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
}

@media only screen and (max-width: 598px) {
    .attendance-student-marks {
        flex-direction: column;
    }
    .attendance-main-grid{
        padding-right: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .attendance-main-grid{
        padding-right: 24px;
    }
    .attendance-circular-progress-grid {
        justify-content: center;
        padding-right: 0px !important;
    }
}

@media only screen and (max-width: 1023px) {}

@media only screen and (max-width: 1200px) {}