.uploadfile-popup{
    padding: 30px;
    width: 50%;
}


@media only screen and (max-width: 767px) {
    .uploadfile-popup {
     padding: 15px;
     width: 95%;
    }
  }