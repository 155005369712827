.canvas {
    width: 600px;
    height: 200px;
}


@media (max-width: 767px) {
  .canvas{
    width: 300px; 
  }  
}