.numberoutline {
  width: 31px;
  height: 31px;
  margin: 9px 11px 20px 10px;
  padding: 5px 8px;
  border-radius: 10px;
  border: solid 1px #a96fb5;
  background-color: #fff;
  cursor: pointer;
}

.selectNumberoutline {
  width: 31px;
  height: 31px;
  margin: 9px 11px 20px 10px;
  padding: 5px 8px;
  border-radius: 10px;
  border: solid 1px #a96fb5;
  background-color: #f1f2f4;
  cursor: pointer;
}

.bg-yellow {
  background-color: yellow;
}

.bg_grey {
  background-color: #f1f2f4;
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: 600;
  height: 36px;
  padding-top: 10px;
}

.box {
  float: left;
  height: 26px;
  width: 26px;
  margin-right: 5px;
  border: 1px solid black;
  clear: both;
  border-radius: 6px;
}

.yellow {
  background-color: #f8db3b;
}

.green {
  background-color: green;
  color: white;
}

.grey {
  background-color: #f1f2f4;
}

.white {
  background-color: white;
}

.view_questionbox {
  margin: 19px 20px 0 20px;
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10%;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.view_rectangle {
  width: 252px;
  height: 36px;
  margin: 12px 0 13px;
  padding: 8px 162px 9px 20px;
  opacity: 0.08;
  background-color: #3f526d;
}

.textlabel_section1 {
  width: 70px;
  height: 19px;
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000 !important;
}
.attach_file_text {
  font-weight: 600 !important;
}
.file_text_500 {
  font-weight: 500 !important;
}

.-Assessment-Students--Question-1 {
  width: 1440px;
  height: 1024px;
  padding: 26px 19px 45px 20px;
}

.pad_ing {
  padding-left: 20px;
  font-family: 'Open sans';
}

.mar-gin {
  margin-left: 20px;
}

.Rectangle {
  /* width: 252px; */
  width: auto;
  height: 1px;
  margin: 30.9px 0 24.1px;
  background-color: #d8d8d8;
}

.view_crtqts {
  width: 20px;
  height: 20px;
  margin: 20px 10px 16px 20px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px #fae7fe;
  background-color: #fff;
}

.margin_bottom {
  margin-bottom: 25px;
}

.student_question_box_view {
  margin: 20px 0px;
}

/* Customize Area Start */
.flashCardQuestion_div {
  padding: 16px 16px 12px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e1e1e1;
  background-color: var(--calcite);
}

.flashCardQuestion_img_div {
  width: 100%;
  height: 240px;
  margin-bottom: 18px;
}

.flashCardQuestion_hint_div {
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 600;
  color: #3f526d;
  font-family: 'Open sans';
  text-align: center;
}

.flashCardQuestion_textField_div {
  margin-bottom: 40px;
}

.flashCardQuestion_option_number_div {
  font-size: 18px;
  font-weight: 600;
  color: #3f526d;
  font-family: 'Open sans';
  text-align: center;
}

.flashCardCenter {
  width: 100%;
}

.activeFlashCard_div {
  opacity: 1;
}

.inActiveFlashCard_div {
  opacity: 0.32;
}

.activeFlashCard_Center_div {
  opacity: 1;
}

.inActiveFlashCard_Center_div {
  opacity: 0.32;
}

._2x2_lineGraphDiv {
  padding: 0 0 28px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

._2x2_graphLinesAttrDiv {
  margin: 12px 0px 0px 0;
  padding: 32px 0 32px 32px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

._2x2_graphLinesAttrDiv_tableCell {
  text-align: start;
  vertical-align: baseline;
  border: 0px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

._2x2_graphLinesAttrDiv_tableCell_Error {
  color: red !important;
  text-align: center;
}

._2x2_graphLinesAttrDiv_tableCell_Textfield {
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 10px !important;
}
._2x2_graphLinesAttrDiv_tableCell_Div {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 50px;
}
.writeAnswerTextTypography {
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-right: 20px !important;
  margin-top: 20px !important;
}
/* Customize Area End */

@media (max-width: 1279px) {
}

@media (max-width: 959px) {
  .student_question_box_view {
    margin: 30px 0px 20px 0px !important;
  }
}

@media (max-width: 767px) {
  .student_question_box_view {
    margin: 93px 0px 20px 0px !important;
  }

  .activeFlashCard_div {
    display: none;
  }

  .inActiveFlashCard_div {
    display: none;
  }
}
