.fontSizeHeader {
  font-size: 20px !important;
  padding: 12px !important;
}

.fontSizeBody {
  font-size: 18px !important;
  padding: 12px !important;
}

.SingleLineDiv {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-width-90-image {
  width: 60% !important;
  height: 66%;
}

.Date_of_due_date_Icon_doc:focus {
  outline: none;
}
.Date_of_due_date_Icon_doc {
  background-repeat: no-repeat;
  background-image: url('../../assets/dateCalendar.png');
  background-size: 27px 27px;
  background-position: top 8px right 10.3px;
}

.input_today_date_css_format_doc > input {
  width: 190px;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  color: #3f526d;
  font-family: 'Open sans';
  cursor: pointer;
}

.leftsidebar-col {
  width: 65.5%;
  display: flex;
  flex-direction: column;
  padding-right: 35px;
}

.student-top-sec {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding: 16px 0px 30px 0px;
  flex-wrap: wrap;
}

.progress-block {
  width: 69.5%;
  margin-top: 33px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background: rgb(182, 165, 254);
  padding: 13px 10px 13px 30px;
}
.studentcup-img {
  z-index: 1000;
  width: 100%;
  margin-top: -34%;
  margin-left: -14%;
  object-fit: contain;
  transform: rotate(-1deg);
}
.progress-block .progress-title {
  margin-left: 20px;
  font-family: 'Open sans';
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-bottom: 10px;
}

.table-list .attendance-text {
  width: 70%;
  margin: 4px 7px 0px 7px;
  font-family: 'Open sans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(255, 255, 255);
}

.table-list {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-top: 18px;
  margin-left: -10%;
  position: relative;
  left: -25px;
}

.my-subjects {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.wrap-div {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}

.right-sidebar {
  display: flex;
  flex-direction: column;
  width: 34.5%;
  padding-left: 15px;
}

.new-student-pro {
  border-radius: 15px;
  box-shadow: rgb(30 28 46 / 8%) 0px 24px 48px 0px;
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 28%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  align-items: center;
}

.studentcup-outer {
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

.work-progress {
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  background-color: rgb(255, 255, 255);
  width: 37%;
}

.student-dashboard-right {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  padding: 18px 35px 43px 35px;
  border-radius: 20px;
  box-shadow: rgb(224 224 224 / 50%) 0px 8px 27px 0px;
  background-color: rgb(255, 255, 255);
}

.services-boxs {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
  overflow-x: auto;
}

.learning-curve {
  display: flex;
  justify-content: space-between;
}

.goals {
  display: block;
  justify-content: space-between;
  height: 100%;
  width: 25%;
}

.learning-data {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  padding: 20px 0px 30px 35px;
  border-radius: 20px;
  box-shadow: rgb(30 28 46 / 8%) 0px 24px 48px 0px;
  background-color: rgb(255, 255, 255);
  width: 73%;
}
.reminder-quote {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  padding: 26px 25px 60px 30px;
  border-radius: 20px;
  box-shadow: rgb(30 28 46 / 8%) 0px 24px 48px 0px;
  background-color: rgb(255, 255, 255);
}

.upcoming-classes {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  padding: 32px 35px 30px 35px;
  border-radius: 20px;
  box-shadow: rgb(30 28 46 / 8%) 0px 24px 48px 0px;
  background-color: rgb(255, 255, 255);
}

.dates-tab {
  display: flex;
  margin-top: 21px;
  overflow-x: auto;
  min-height: 60px;
  /* margin-right: -35px; */
  padding-right: 34px;
}

.table-classes {
  display: flex;
  flex-direction: column;
  margin-top: 33px;
  padding: 24px 34px;
  height: 100%;
  border-radius: 13px;
  background-color: rgb(249, 248, 253);
}

.scroll-w {
  width: 100%;
}

.attendance-row {
  justify-content: space-between;
}

.table-list .attendance-row .attendance-text:last-child {
  width: auto;
  margin-right: 0;
}

.card-subject {
  padding: 7px 13px 4px 14px;
  border-radius: 10px;
  width: 170px;
  background-size: cover;
  margin-right: 24px;
}

.cal-pd {
  padding: 35px 30px;
}
.notes-box {
  margin-top: 26px;
}
.heading {
  margin: 26px 0 0;
  padding: 24px 0px 0px 0;
}
.service-block {
  padding: 70px 15px 15px 9px;
}
.set-goal {
  padding: 70px 10px 15px 10px;
  min-height: 109px;
  min-height: 148px;
  width: 100%;
}
.set-goal-text {
  padding-top: 11px;
}

.set-goal .new-goal {
  font-size: 11px;
  font-family: 'Open sans';
  font-weight: normal;
  color: #fff;
}
.goal-col {
  padding: 8px 10px 0;
}

.dates-tab button {
  width: 141px;
  height: 40px;
}

/* pascal case css */
.MuiTypography-root.UserDocumentText {
  text-transform: none;
  font-size: 20px;
  font-weight: 600;
}

.MuiButton-text.UserDocumentBtnTab {
  height: 39px;
  margin: 0px 10px 0px 0px;
  padding: 0;
  min-width: 133px;
}

.MuiTypography-root.UserDocumentTabText {
  text-transform: none;
  font-size: 15px;
  font-weight: 600;
}

.UserDocFullWidth {
  width: 100%;
}

.ImagePreviewGrid {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ImagePreviewBox {
  width: 100%;
  height: 100%; 
  position: relative;
}

.ImagePreviewCloseIcon {
  width: 32px;
  height: 32px; 
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.UserPreviewImage {
  width: 100%;
  height: 100%; 
}

.UserDocMesDialog {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 29px;
  padding: 32px 18px;
  width: 600px;
}

.UserDocMesDivider {
  height: 1px;
  margin: 14px 0 31px 0;
  border: solid 0.3px #979797;
}

.UserDocMesActions {
  display: flex;
  justify-content: center;
}

.UserDocBox {
  background-color: white;
  border-radius: 8px;
  margin: 19px 0 0;
}

.UserDocSearchBox {
  border-radius: 8px;
  border: solid 1px #7f6cfc;
  padding: 1% 1.2% 1% 1.7%;
}

.UserDocSearchInput {
  border: none;
  outline: none;
  color: #484848;
  opacity: 0.55;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  padding-right: 1%;
  padding-left: 1%;
}

.UserDocSearchImg {
  color: #7f6cfc;
  margin-right: 1%;
  cursor: pointer;
}

.UserDocContainer {
  margin-top: 2%;
  padding: 2% 2% 3%; 
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.UserDocAlignCenter {
  align-items: center;
}

.UserDocFlex {
  display: flex;
}

.MuiTypography-root.ListOfPermissionTitle {
  font-size: 18px;
  font-weight: 600;
}

.MuiGrid-root.UserDocFilterGrid {
  flex-basis: 0;
  margin-right: 20px;
}

.UserDocDataGrid {
  margin-top: 3% !important;
}

.MuiTable-root.UserDocTable {
  width: 100%;
}

.UserDocTableHead {
  border-bottom: none;
}

.MuiTableCell-root.UserDocHeaderUserName {
  font-weight: 600;
  line-height: normal;
  background-color: #ded9ff;
  border-bottom: none;
}

.MuiTableRow-root th:first-child.UserDocHeaderUserName {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.MuiTableCell-root.UserDocHeaderTitle {
  font-weight: 600;
  line-height: normal;
  background-color: #ded9ff;
  border-bottom: none;
}

.MuiTableCell-root.UserDocHeaderAction {
  font-weight: 600;
  line-height: normal;
  background-color: #ded9ff;
  border-bottom: none;
}

.MuiTableRow-root th:last-child.UserDocHeaderAction {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.UserDocRowSpace {
  height: 10px;
}

.UserDocTableRowBg {
  background: #f9f8fd;
}

.MuiTableCell-root.UserDocTableDataUserName {
  border-bottom: none;
  padding: 0;
}

.MuiTableRow-root td:first-child.UserDocTableDataUserName {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.MuiTypography-root.UserDocDataUserNameText {
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.MuiTableCell-root.UserDocTableDataCell {
  border-bottom: none;
  padding: 0;
}

.MuiTypography-root.UserDocDataOtherText {
  font-weight: 600;
  line-height: normal;
}

.MuiTypography-root.UserDocDataViewText {
  font-weight: 600;
  line-height: normal;
  text-decoration: underline;
  cursor: pointer;
}

.MuiTableCell-root.UserDocTableDataView {
  border-bottom: none;
  padding: 0;
}

.MuiTableRow-root td:first-child.UserDocTableDataView {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.NoUserDataFoundText {
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.UserDocPaginationBox {
  text-align: end;
  margin-top: 25px;
}

.MuiTypography-root.UserViewDocBack {
  font-size: 1.25rem;
  color: #3f526d;
  font-weight: 600;
  cursor: pointer;
}

.UserViewDocBackArrow {
  width: 18px;
  height: 16px;
  margin-right: 6px;
}

.UserViewDocDetails {
  display: flex;
  align-items: center;
}

.MuiAvatar-root.UserViewDocAvatar {
  height: 80px;
  width: 80px;
}

.MuiTypography-root.UserViewDocName {
  font-size: 22px;
  font-weight: 600;
  margin-left: 20px;
}

.MuiTypography-root.UserViewDocClassDetails {
  font-size: 18px;
  font-weight: 600;
  opacity: 0.52;
  margin-left: 20px;
  display: flex;
  text-transform: capitalize;
}

.UserViewDocClassName {
  max-width: 210px;
  word-break: break-all;
}

.MuiTypography-root.UserViewDocSchool {
  font-size: 18px;
  font-weight: 600;
  opacity: 0.52;
  margin-left: 20px;
}

.UserViewDocDividerGrid {
  margin: 25px 0 20px !important;
}

.UserViewDocDivider {
  width: 100%;
}

.UserViewDocBtnItems {
  display: flex;
  justify-content: end;
}

.MuiButton-root.UserViewDocCancelBtn {
  max-width: 180px;
  padding: 13px 0;
  border-radius: 6px;
  border: solid 1px #7f6cfc;
  margin-right: 26px;
}

.MuiTypography-root.UserViewDocCancelBtnText {
  text-transform: none;
  font-size: 18px;
  font-weight: 600;
  color: #7f6cfc;  
}

.MuiButton-root.UserViewDocEditBtn {
  max-width: 180px;
  padding: 13px 0;
  border-radius: 6px;
  background-color: #7f6cfc;
}

.MuiButton-root:hover.UserViewDocEditBtn {
  background-color: #7f6cfc;
}

.MuiTypography-root.UserViewDocEditBtnText {
  text-transform: none;
  font-size: 18px;
  font-weight: 600;
  color: white;  
}

.UserDocumentItem {
  height: 300px;
  padding: 20px 35px;
  background: #f9f8fd;
  border-radius: 6px;
  margin-bottom: 6px !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.UserDocumentZoomIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 20px;
  right: 20px;
  position: absolute;
  cursor: pointer;
}

.UserDocumentZoomImg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; 
}

.MuiTypography-root.UserViewDocFileText {
  font-size: 22px;
  font-weight: 600;
  margin-left: 16px;
}

.RejectDocumentDialog {
  border-radius: 8px;
}

.RejectDocumentDialogGrid {
  padding: 26px 32px 40px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  min-width: 500px;
}

.RejectDocumentHeader {
  margin-bottom: 15px !important;
  display: flex;
  justify-content: center;
}

.MuiFormLabel-root.RejectDocumentLabel {
  font-size: 24px;
  color: #3f526d;
  font-weight: 600;
}

.RejectDocumentDivider {
  width: 100%;
}

.RejectFileNameGrid {
  margin-bottom: 35px !important;
  margin-top: 33px !important;
}

.MuiTypography-root.RejectDocumentFileName {
  font-family: 'Open sans';
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3f526d;
  margin: 31px 2px 6px 3px;
  word-break: break-all;
}

.RejectDocReasonGrid {
  margin-bottom: 14px !important;
}

.MuiFormLabel-root.RejectDocReasonText {
  font-size: 1.25rem;
  color: #3f526d;
  font-weight: 600;
}

.RejectDocBtnGrid {
  display: flex;
  margin-top: 30px !important;
}

.MuiButton-root.RejectDocBtn {
  padding: 10px 47px;
  border-radius: 8px;
  background-color: #7f6cfc;
}

.MuiTypography-root.RejectDocBtnText {
  text-transform: none;
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
}

.MuiButton-root.CancelRejectDocBtn {
  padding: 11px 24px 11px 25px;
  border-radius: 6px;
  background-color: #fff;
  margin-left: 20px;
}

.MuiTypography-root.RejectDocCancelBtnText {
  text-transform: none;
  font-size: 1.125rem;
  font-weight: 600;
  color: #3f526d;
}

.DocApprovedImg {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.MuiTypography-root.DocApprovedText {
  font-size: 18px;
  color: #0c5c10;
  font-weight: 600;
  margin-left: 6px;
}

.MuiTypography-root.DocRejectedText {
  font-size: 18px;
  color: #a81440;
  font-weight: 600;
  margin-left: 6px;
}

.DocMoreOptionImg {
  width: 24px;
  height: 24px;
  background-color: #fff;
  position: absolute; 
  right: 20px;
  cursor: pointer;
}

.UnderReviewRejectIcon {
  width: 32px;
  height: 32px;
  margin-right: 60px; 
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.UnderReviewApproveIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.DocApproveRejectGrid {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255 , 0.72);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChangeStatusModal {
  margin-top: 50px;
}

.ChangeStatusModalBox {
  max-width: 324px;
  padding: .625rem 1rem;
}

.ChangeStatusDiv {
  display: flex;
  align-items: center;
  cursor: pointer; 
}

.ChangeStatusResetIcon {
  width: 32px;
  height: 32px; 
  margin-right: 12px;
}

.ChangeStatusText {
  text-transform: none;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.17px;
}

@media screen and (min-width: 1921px) {
  .notification-block {
    margin-top: 59px !important;
    padding: 16px 20px 15px 30px !important;
  }
}

@media screen and (max-width: 1450px) {
  .fontSizeHeader {
    font-size: 20px !important;
    padding: 12px !important;
  }

  .fontSizeBody {
    font-size: 18px !important;
    padding: 12px !important;
  }

  .modal-width-90-image {
    width: 55% !important;
    height: 65%;
  }

  .my-subjects {
    width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .fontSizeHeader {
    font-size: 14px !important;
    padding: 9px !important;
  }

  .fontSizeBody {
    font-size: 12px !important;
    padding: 9px !important;
  }
  .modal-width-90-image {
    width: 50% !important;
    height: 60%;
  }
  .wrap-div {
    flex-wrap: wrap;
  }

  .leftsidebar-col {
    width: 100%;
    padding-right: 0;
  }

  .right-sidebar {
    width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .fontSizeHeader {
    font-size: 12px !important;
    padding: 7px !important;
  }

  .fontSizeBody {
    font-size: 10px !important;
    padding: 7px !important;
  }
  .modal-width-90-image {
    width: 55% !important;
    height: 60% !important;
  }

  .student-top-sec {
    padding-right: 0;
  }
  .new-student-pro {
    width: 100%;
  }
  .progress-block {
    width: 100%;
    padding: 0;
  }
  .flexwrap {
    flex-wrap: wrap;
    padding: 15px;
  }

  .studentcup-outer {
    width: auto;
    text-align: center;
    margin: 0 auto;
  }
  .studentcup-img {
    width: 70%;
    transform: rotate(0deg);
    margin: 0 auto;
  }
  .table-list {
    width: 100%;
  }
  .table-list {
    margin-left: 0;
    margin-bottom: 15px;
    left: 0;
  }
  .progress-block .progress-title {
    margin-left: 0;
  }
  .table-list .attendance-text {
    margin: 4px 0px 2px 0px;
  }
  .work-progress {
    width: 100%;
  }
  .right-sidebar {
    padding-left: 0;
  }
  .new-student-pro {
    height: auto;
    padding: 15px;
  }
  .attendance-row {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .fontSizeHeader {
    font-size: 10px !important;
    padding: 6px !important;
  }

  .fontSizeBody {
    font-size: 8px !important;
    padding: 6px !important;
  }

  .modal-width-90-image {
    width: 50% !important;
    height: 50%;
  }

  .studentcup-img {
    width: 65%;
  }

  .student-dashboard-right {
    margin-top: 0;
    padding: 20px 15px;
  }
  .my-subjects {
    width: 100%;
  }

  .learning-curve {
    flex-wrap: wrap;
    height: auto;
  }

  .goals {
    display: flex;
    height: auto;
    width: 100%;
    padding-top: 12px;
  }

  .learning-data {
    width: 100%;
    padding: 15px;
  }
  .set-goal {
    width: 100% !important;
    margin: 33.3px 7px 5px 7px !important;
  }

  .reminder-quote {
    margin-top: 20px;
    padding: 26px 15px 20px 15px;
  }
  .dataimg {
    width: 100%;
  }
  .upcoming-classes {
    padding: 15px;
  }
  .dates-tab {
    justify-content: space-between;
    margin-right: 0;
  }
  .dates-tab button {
    margin-bottom: 10px;
  }
  .notification-block {
    margin-top: 35px !important;
  }
  .table-classes {
    padding: 15px;
    overflow-x: scroll;
  }

  .scroll-w {
    width: 450px;
  }
  .card-subject:last-child {
    margin-right: 0;
  }

  .cal-pd {
    padding: 15px;
  }
}
.embedImg {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}