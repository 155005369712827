.MonthEventMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.MonthEventSubDiv {
    font-family: "Open sans";
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    color: #3f526d;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.MonthEventLunchTimeImg {
    height: 14px;
    width: 14px
}

.MonthEventLunchTimeDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-left: 8px;
    position: relative;
    bottom: 5px
}

.MonthEventLunchTimeTxt {
    font-family: "Open sans" !important;
    font-size: 12px !important;
    line-height: 1.67;
}