.notificationDropDown .MuiSelect-root.MuiSelect-select {
  padding: 10px 14px;
  padding-right: 68px;
  min-width: 130px;
}

#recorderVideoPreview {
  width: auto;
  height: 55px;
  font-family: OpenSans;
  font-size: 46px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #959393;
  margin-left: 4px;
}

#fileNameTextDoc {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create-video-btn {
  width: 100%;
  height: 50px;
  /* border: none !important; */
  /* border-radius: 8px !important; */
  padding: 10px 24px !important;
  /* background-color: #af38cb !important; */
  /* background: linear-gradient(to top, #af38cb, #471c51) !important; */
  /* background: linear-gradient(to top, rgb(175, 56, 203), rgb(71, 28, 81)); */
}

.Teacher_Profile.Date_Icon:focus {
  outline: none;
}

.Teacher_Profile.Date_Icon {
  background-repeat: no-repeat;
  background-image: url('../assets/dateCalendar.png');
  background-size: 30px 30px;
  background-position: top 9px right 12px;
}

.MuiTabs-centered {
  justify-content: space-around !important;
}

/* [type="date"] {
  background: url("../assets/image-calendar_2021-10-20/image-calendar.png") 97%
    50% no-repeat;
}*/

[type='date']::-webkit-calendar-picker-indicator {
  opacity: 1;
  cursor: pointer;
  bottom: 0;
  color: transparent;

  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  background: url('../assets/image-calendar_2021-10-20/image-calendar.png') 97%
    50% no-repeat;
}

.profile_main_div {
  padding-top: 45px;
}

.profile_detail_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  border-width: 1px;
  border-color: #e5ebf4;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.profile_image {
  height: 140px;
  width: 140px;
  border-width: 5px;
  border-style: solid;
  border-color: #471c51;
  padding: 3px;
  border-radius: 50%;
}

.profile_avatar {
  height: 140px !important;
  width: 140px !important;
  border-width: 5px;
  border-style: solid;
  border-color: #471c51;
  padding: 3px;
}

.add_photo_icon {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 0;
  right: 5px;
  cursor: pointer;
}

.personal_info_main_div {
  padding: 35px 30px;
  border-color: #e5ebf4;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 5%;
}

.personal_details_name {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #3f526d;
  margin-top: 20px !important;
  text-transform: capitalize;
  text-align: center;
}

.other_details_block {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
}

.class_list {
  color: #3f526d;
  font-size: 16px !important;
  font-family: 'Open sans' !important;
}

.subject_list {
  color: #3f526d;
  font-size: 14px !important;
  font-family: 'Open sans' !important;
  text-align: center;
  text-transform: capitalize;
}

.locked_div {
  background-color: #fef8ff;
  padding: 11px 0;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  flex-wrap: wrap;
}

.profile_info_label_top {
  font-size: 16px !important;
  /* color: #3f526d !important; */
  font-weight: 500 !important;
  margin-bottom: 20px;
}

.profile_info_label {
  font-size: 16px !important;
  /* color: #3f526d !important; */
  font-weight: 500 !important;
  margin-bottom: 20px;
  margin-top: 23px;
}

.create_video_btn_text {
  color: #ffffff;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: capitalize;
  font-family: 'Open sans' !important;
}

.teacher_update_btn {
  /* background-color: #af38cb !important; */
  /* background: linear-gradient(to top, #af38cb, #471c51) !important; */
  /* border-radius: 8px !important; */
  /* color: #fff !important; */
  width: 22%;
  height: 50px;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.teacher_tab {
  height: 72px;
  width: 25%;
  /* font-family: "Open sans" !important; */
  font-size: 18px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  /* color: #3f526d !important; */
}

.teacher_tab.MuiTab-root {
  max-width: 100% !important;
  min-width: 100px !important;
}

.badge_img {
  position: relative;
  width: 200px;
  height: 151px;
  margin-top: -135px !important;
  /* margin-left: 10px !important;   */
}

.recent_activity_main_div {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 7px 22px 0 rgba(227, 218, 218, 0.5);
}

.recent_activity {
  font-family: 'Open Sans' !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center;
}

.login_session_text {
  margin: 6px 0px 18px 10px !important;
  font-family: 'Open sans' !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #3f526d;
}

.recent_activity_header_text {
  font-size: 16px !important;
  font-family: 'Open Sans' !important;
  font-weight: 500 !important;
  color: #000;
  padding: 0 15px;
}

.recent_activity_detail_text {
  font-family: 'Open sans' !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #3f526d;
}

.ellipsis_text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.spacing_15 {
  padding: 15px;
}

.recent_activity_header_div {
  padding: 15px 0px;
  background-color: #f9f8fd;
}

.MuiTypography-root.add_upload_text {
  font-size: 20px;
  margin-top: 20px;
}

.textfield {
  width: 342px !important;
  border-color: '#ebebeb';
}

.documentButton {
  width: 342px !important;
  height: 50px !important;
  margin: 14px 0 0 !important;
  padding: 10px 24px !important;
  border-radius: 8px !important;
  background: linear-gradient(to top, #af38cb, #471c51) !important;
  /* color: #fff !important; */
  font-size: 18px !important;
}

.txt {
  width: 342px;
  height: 40px !important;
  margin: 12px 0px 14px !important;
  /* font-family: Open Sans !important; */
  font-size: 12px !important;
  /* font-weight: normal ;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal; */
  text-align: center !important;
  /* color: rgb(149, 147, 147) !important; */
}

.addFileTxt {
  color: #af38cb;
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
}

.viewModal {
  justify-content: center;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 4px 4px 2%;
  height: 80%;
  width: 60%;
}

.alignCenterGrid {
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  width: 100%;
  justify-content: center;
}

.viewModalImg {
  height: 295px;
  width: inherit;
  object-fit: contain;
}

.uploadingBlock {
  border-width: 1px;
  border-color: #cadaf1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 20px 20px 20px;
  padding: 40px;
  justify-content: center;
  height: 193px;
  width: 80%;
  box-shadow: 0 8px 27px 0 rgba(224, 224, 224, 0.5);
}

.record_video_bottom_section {
  position: relative;
  text-align: left;
  padding: 15px 0;
}

/* pascal case css */
.BadgesModal {
  justify-content: center;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border-radius: 10px;
  padding: 8px;
  width: 50%;
  background-image: radial-gradient(circle at 50% 2%, #efdc71, #edb261 86%);
}

.BadgeModalClose {
  position: absolute;
  top: 10px;
  right: 10px;
}

.BadgeModalCloseImg {
  cursor: pointer;
}

.BadgesGrid {
  max-height: 368px;
  overflow: auto;
  margin: 5px 0 !important;
}

.BadgesItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 !important;
  text-align: center;
  min-height: 160px;
}

.ProfileNotifications {
  padding: 15px;
  background-color: #f9f8fd;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 20px;
}

.StudentProfileNotifications {
  padding: 15px;
  background-color: #fef8ff;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 20px;
}

.ProfileNotificationHead {
  display: flex;
  width: 34%;
  justify-content: space-around;
  align-items: center;
}

.NotificationsDynamic {
  padding: 2% 0 4% 0;
}

.NotificationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.NotificationIcon {
  position: relative;
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.NotificationTextBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.MuiTypography-root.NotificationName {
  font-size: 16px;
}

.MuiTypography-root.NotificationDesc {
  font-size: 12px;
}

.NotificationCheckGrid {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.MuiSvgIcon-root.NotificationManual {
  font-size: 30px;
  color: rgba(249,251,252,255); 
}

.MuiSvgIcon-root.NotificationChecked {
  font-size: 30px;
}

.StudentNotificationsDynamic {
  border-color: #e5ebf4;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
}

.ProfileEduInfo {
  padding: 35px 30px;
  border-color: #e5ebf4;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  margin-top: 20px;
}

.MuiTypography-root.EndAdornmentPer {
  width: 15px;
  height: 24px;
  font-family: 'Open sans';
  font-size: 18px;
  color: #959393;
}

.MuiFormHelperText-root.SpecializationError {
  color: red;
  margin: 3px 14px 0;
}

.ProfileImgDiv {
  margin-top: -80px;
  position: relative;
}

.MuiTypography-root.TeacherClassText {
  font-size: 16px;
}

.MuiTypography-root.StudentClassText {
  font-size: 14px;
}

.MuiTypography-root.BatchYearText {
  margin-left: 4px;
}

.LockedImg {
  position: relative;
  height: 30px;
  width: 22px;
  margin-right: 10px;
}

.UnlockedImg {
  position: relative;
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.MuiTypography-root.LockedProfileText {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.ProfileLinkedinDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 25px;
  width: 100%;
}

.ProfileLinkedinImg {
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.ProfileBadgesBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: radial-gradient(circle at 50% 2%, #efdc71, #edb261 92%);
  border-radius: 8px;
  margin-top: 125px;
  padding: 30px 20px;
}

.BadgesShare {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  align-items: start;
  position: absolute;
  top: -15px;
  right: -7px;
}

.ProfileBadgesHead {
  position: relative;
  display: flex;
  justify-content: center;
}

.MuiTypography-root.ProfileMyBadgesText {
  color: #3f526d;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

.BadgeDetailsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px !important;
  text-align: center;
}

.BadgeDetailsImg {
  width: 62px;
  height: 84px;
  object-fit: contain;
}

.MuiTypography-root.BadgeDetailName {
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.MuiTypography-root.BadgeNoRecordText {
  font-size: 18px;
  margin-top: 20px;
}

.BadgesDetailDivider {
  width: 100%;
}

.MuiButton-root.BadgeViewAllBtn {
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.MuiTypography-root.BadgeViewAllBtnText {
  font-size: 20px;
  margin-top: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.RecentLogActivityMainBox {
  margin-top: 50px;
  border-color: #e5ebf4;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  margin-bottom: 10px;
}

.RecentLogActivityHead {
  background-color: #fef8ff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
}

.RecentActivityNoRecord {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
}

.MuiTypography-root.RecentActivityNoRecordText {
  font-size: 18px;
}

.MuiBox-root.ProfileTabsBox {
  height: 72px;
  margin: 0px 2px 30px 0px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
}

.ProfileTabs {
  width: 100%;
}

.ProfilePersonalInfoDiv {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
}

.ProfileTeacherDocumentDiv {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 30px;
}

.ProfileTeacherNotificationDiv {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 5%;
}

.ProfilePhotoBox {
  width: 258px;
  height: 183px;
  margin: 0 16px;
}

.MuiMenuItem-root.MenuItemProfilePhoto {
  display: flex;
  justify-content: center;
  height: 61px;
}

.MuiGrid-root.MenuItemProfileImg {
  flex-basis: 0;
  align-self: center;
}

.MuiGrid-root.MenuItemProfileGrid {
  flex-basis: 0;
}

.MuiTypography-root.MenuItemProfileText {
  text-transform: none;
  font-size: 20px;
  font-weight: 500;
}

.ProfileHiddenInput {
  display: none !important;
}

.RecentActivitiesBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
}

.ActivityIcon {
  position: relative;
  width: 52px;
  height: 52px;
  margin-right: 10px
}

.ActivityDetailsDiv {
  display: flex;
  flex-direction: column;
}

.MuiTypography-root.ActivityOSName {
  font-size: 16px;
  font-weight: 600;
}

.MuiTypography-root.ActivityDate {
  font-size: 16px;
  margin-top: 5px;
}

.MuiButton-root.ViewRecentActivitiesBtn {
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.MuiTypography-root.ViewAllRecentActivityText {
  font-size: 18px;
  text-decoration-style: solid;
  text-decoration-line: underline;
  text-decoration-color: #3a60d7;
  text-transform: none;
  cursor: pointer;
  font-weight: 600;
}

.PrivateTabIndicator-colorSecondary-2669.TeacherProfileTabIndicator {
  background: #3a5dbdff;
}

.PersonalInfoContainer {
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
}

.PersonalInfoDetail {
  justify-content: space-between;
  flex-direction: row;
}

.PersonalInfoMandatory {
  color: red;
}

.MuiFormHelperText-root.PersonalInfoErrorText {
  color: red;
  margin: 3px 14px 0;
}

.UpdatePersonalInfoDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 40px !important;
}

.DeleteDocumentActions {
  display: flex;
  justify-content: center;
}

.PreviewPhotoModal {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 29px;
  padding: 32px 18px;
  width: 800px;
  padding-bottom: 2%;
}

.PreviewPhotoModalHeader {
  display: flex;
  justify-content: center;
  position: relative;
}

.MuiSvgIcon-root.PreviewPhotoModalClose {
  color: #3f526d;
  position: absolute;
  right: 0;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.PreviewPhotoDiv {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
}

.PreviewPhotoLoader {
  position: absolute;
  top: 50%;
}

.PreviewImg {
  height: 320px;
  width: 500px;
  object-fit: contain;
}

.MuiTypography-root.PreviewPhotoName {
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  font-family: 'Open sans';
  color: #3f526d;
}

.CreateVideoModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CreateVideoModalClose {
  cursor: pointer;
}

.VideoDropDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.VideoHiddenInput {
  display: none !important;
}

.CreateVideoDividerGrid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.MuiTypography-root.CreateVideoOrText {
  font-family: 'Open sans';
  font-size: 12px;
  font-weight: bold;
  color: #959393;
}

.RecordVideoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.MuiButton-root.PreviewVideoBackBtn {
  background-color: transparent;
  padding: 0;
}

.PreviewVideoBack {
  display: flex;
  align-items: center;
}

.PreviewVideoBackIcon {
  margin-top: 5px;
}

.MuiTypography-root.PreviewVideoBackText {
  margin-left: 5px;
}

.PreviewVideoDiv {
  position: relative;
  margin: 10px;
  object-fit: contain;
  border-radius: 8px;
  height: 90%;
}

.MuiButton-root.PreviewSubmitBtn {
  width: 88px;
  height: 40px;
  cursor: pointer;
  margin: 0 20px 0 0;
  padding: 9px 19px 12px 20px;
  border-radius: 3px;
  position: relative;
  background: linear-gradient(to top, rgb(175, 56, 203), rgb(71, 28, 81));
}

.PreviewSubmitBtnText {
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: center;
  color: #fff;
}

.MuiButton-root.PreviewCancelBtn {
  width: 88px;
  height: 40px;
  cursor: pointer;
  margin: 0 20px 0 0;
  padding: 9px 19px 12px 20px;
  border-radius: 3px;
  background: #f6f6f6;
}

.MuiTypography-root.PreviewCancelBtnText {
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: center;
  color: #3f526d;
}

.VideoSuccessModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.VideoSuccessGrid {
  position: relative;
  text-align: center;
  color: #fff;
}

.VideoSuccessOval {
  height: 100%;
  width: 100%;
}

.ProfileDocumentMainBox {
  box-shadow: 0 8px 27px 0 rgba(224, 224, 224, 0.5);
  border-radius: 8px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 240px;
  margin: 30px 32px 5px 32px;
  position: relative;
}

.ProfileDocumentImg {
  position: relative;
  margin-top: -20px;
}

.ProfileDocumentName {
  font-size: 18px;
  font-weight: 600;
  width: 170px;
  text-align: center;
  overflow-wrap: break-word;
}

.MuiButton-root.DocumentUploadBtn {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.MuiButton-root.ViewDocumentBtn {
  font-size: 18px;
  margin-bottom: 18px;
  text-transform: capitalize;
  color: #af38cb;
}

.MuiButton-root.DocumentRejectedBtn {
  color: #a81440;
  border-color: #a81440;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.MuiButton-root.DocUnderReviewBtn {
  color: #ffb84d;
  border-color: #ffb84d;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.ApprovedCheckImg {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 7px;
  right: 7px;
}

.StudentProfileDocumentName {
  font-size: 18px;
  font-weight: 600;
  width: 170px;
  text-align: center;
  overflow-wrap: break-word;
  color: #3f526d;
  font-family: 'Open sans';
}

.MuiButton-root.StudentDocumentUploadBtn {
  color: blue;
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.UploadDocumentDiv {
  border-width: 1px;
  border-style: dashed;
  border-color: #cadaf1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 20px 20px 20px;
  padding: 40px;
  background: #f9f8fd;
}

.UploadDocumentImg {
  position: relative;
  width: 62px;
  height: 62px;
  cursor: pointer;
}

.UploadDocumentInput {
  display: none !important;
  height: 50px;
}

.MuiTypography-root.UploadDocumentText {
  font-size: 14px;
  width: 80%;
  text-align: center;
  margin-top: 20px;
}

.MuiTypography-root.MandatoryDocumentsText {
  padding: 0px 30px;
  height: 27px;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.MandatoryDocumentContainer {
  padding: 32px 15px;
}

.MuiTypography-root.AdditionalDocumentsText {
  padding: 0px 30px;
  height: 27px;
  margin-top: 5px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.AdditionalDocumentContainer {
  padding: 32px 30px;
}

.NoFilePreviewText {
  line-height: normal;
  letter-spacing: normal;
}

.MuiTypography-root.UpdateBtnText {
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: capitalize;
}

.MuiTypography-root.FileNameHeader {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.MuiTextField-root.FileNameInput {
  width: 342px;
}

.FileNameInputProps {
  height: 50px;
}

.MuiButton-root.AddMoreFileBtn {
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 30px;
  z-index: 1;
}

.AddFileBtnImg {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.MuiTypography-root.AddFileText {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}

.AddDescriptionDiv {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  border: solid 1px #ebebeb;
  padding: 26px;
  box-shadow: 0 8px 27px 0 rgba(224, 224, 224, 0.5);
}

.MuiTypography-root.AddDescriptionText {
  padding-bottom: 15px;
  width: 170px;
  height: 22px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.MuiTextField-root.AddDescriptionTextField {
  margin-top: 2%;
  width: 340px;
  margin-bottom: 20px;
  font-family: 'Open sans';
  border-color: #ebebeb;
}

.MuiButton-root.UpdateAllBtn {
  margin-top: 30px !important;
}

.UploadMandatoryDocumentGrid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UploadMandatoryDocImg {
  position: relative;
  width: 62px;
  height: 62px;
  cursor: pointer;
}

.FileUploadedImg {
  position: relative;
  width: 62px;
  height: 62px;
}

.FileUploadedCheckImg {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: 40px;
}

.MuiIconButton-root.DeleteUploadedFileIcon {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  top: 50px;
  right: 50px;
  z-index: 1;
}

.DeleteUploadedImg {
  object-fit: contain;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.MuiTypography-root.FileUploadedText {
  font-size: 20px;
  margin-top: 20px;
}

.MuiTypography-root.BackArrowIconDocument {
  font-size: 13px;
  font-weight: 600;
  margin: 0px 5px -5px;
}

.MuiTypography-root.TextBackArrowDocument {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'Open sans';
  color: #3f526d;
  text-transform: none;
}

.UploadingBlockDiv {
  height: 80px;
  width: 80px;
}

.UploadingFileImg {
  position: relative;
  width: 38px;
  height: 50px;
}

.MuiTypography-root.UploadingFileText {
  font-size: 20px;
  width: 80%;
  text-align: center;
  margin-top: 20px;
}

.MuiTypography-root.TakeWhileText {
  font-size: 14px;
  width: 80%;
  text-align: center;
  margin-top: 14px;
}

.UploadingMainDiv {
  border-width: 1px;
  border-style: dashed;
  border-color: #cadaf1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  position: relative;
}

.UploadingDivItem {
  margin-right: auto !important;
  display: flex;
}

.MuiButton-root.UploadingBackBtn {
  background-color: transparent;
}

.FullWidthDiv {
  width: 100%;
}

.MuiTypography-root.AdditionalFileUploadText {
  height: 22px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  align-self: flex-start;
  margin: 20px 0px;
}

.MuiIconButton-root.PreviewVideoOptionIcon {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
}

.PreviewVideoOptions {
  object-fit: contain;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.PreviewVideoGrid {
  display: flex;
  justify-content: center;
  position: relative;
}

.MuiDivider-root.PreviewVideoModalDivider {
  margin: 0px 0px 32px 0px;
}

.PreviewVideoOptionBox {
  width: 203px;
}

.PreviewVideoOptionItem {
  display: flex;
  justify-content: center;
}

.MuiGrid-root.PreviewVideoFlexBasis {
  flex-basis: 0;
}

.MuiTypography-root.PreviewVideoActionName {
  text-transform: none;
  font-size: 16px;
  font-family: 'Open Sans';
  font-weight: 600;
  color: #3f526d;
}

.PreviewVideoClose {
  color: #3f526d;
  cursor: pointer;
}

.BulkDocumentUploadDiv {
  margin: 10px 0px;
  display: flex;
  border: solid 1px #ebebeb;
  padding: 26px;
  align-items: center;
  box-shadow: 0 8px 27px 0 rgba(224, 224, 224, 0.5);
  position: relative;
  overflow-x: auto;
}

.MuiGrid-root.BulkDocumentContainer {
  flex-wrap: nowrap;
}

.MuiGrid-root.BulkDocumentDiv {
  display: flex;
  align-items: center;
  justify-content: start;
  max-width: 84px;
}

.DocumentUploadedIconBox {
  position: relative;
  width: 62px; 
}

.DocumentUploadedCheckIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
}

.DocumentInputDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DocumentInputItem {
  display: flex;
  flex-direction: column;
}

.MuiTextField-root.DocumentInput {
  height: 50px;
  padding-left: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  background-color: #fff;
}

.MuiTypography-root.DocumentSizeText {
  margin-top: 2%;
  padding-left: 10px;
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #959393;
}

.MuiTypography-root.StudentDocumentSizeText {
  margin-top: 2%;
  padding-left: 20px;
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #959393;
}

.UploadedDocActions {
  display: flex;
  position: absolute;
  left: 85%;
  top: 40%;
}

.UploadedDocOpenImg {
  width: 24px;
  height: 24px;
  margin-right: 15%;
  cursor: pointer;
}

.UploadedDocDeleteImg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.BulkUploadingBox {
  position: relative;
  width: 62px;
  height: 62px;
}

.UploadingFileIcon {
  position: relative;
  height: 42px;
}

.BulkUploadingFileText {
  padding-left: 20px;
  padding-bottom: 5px;
  border-radius: 8px;
  background-color: #fff;
}

.MuiTypography-root.ThisMayTakeWhileText {
  padding-left: 20px;
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #959393;
}

.DocumentRelative {
  position: relative;
}

.MuiButton-root.UploadVideoBackBtn {
  background-color: transparent;
  padding: 0;
}

.MuiTypography-root.UploadVideoBackArrow {
  font-size: 13px;
  font-family: 'Open sans';
  font-weight: 600;
  margin: 0px 5px -5px;
}

.UploadingVideoIcon {
  position: relative;
  width: 42px;
  height: 42px;
}

.UploadVideoProgressDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px 0px 0px;
  justify-content: center;
  height: 220px;
  border-radius: 8px;
  border: dashed 1px #cadaf1;
}

.UploadVideoProgress {
  height: 60px;
  width: 60px;
}

.MuiTypography-root.UploadingPerText {
  color: #202020;
  font-size: 20px;
  width: 80%;
  text-align: center;
  margin-top: 20px;
  font-family: 'Open sans';
  font-weight: 500;
}

.MuiTypography-root.UploadingVideoTakeTimeText {
  color: #959393;
  font-size: 14px;
  width: 80%;
  text-align: center;
  margin-top: 14px;
}

.ParentNotificationDiv {
  margin-top: 11px !important;
  display: flex;
  justify-content: start;
  align-items: center; 
}

.ParentNotificationLabel {
  margin-left: 5px;
}

.CreateVideoIcon {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.CapturePhotoGrid {
  display: flex;
  justify-content: center;
  position: relative;
}

.MuiSvgIcon-root.CapturePhotoClose {
  color: #3f526d;
  position: absolute;
  right: 0;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.CapturePhotoNotAllowedText {
  font-weight: 600;
  color: red;
  font-size: 16px;
  text-align: center;
  margin-top: 2%;
}

.CapturePhotoLoader {
  text-align: center;
  margin-top: 2%;
}

.CapturePhotoAllowedGrid {
  display: flex;
  justify-content: center;
  margin: 30px 0 !important;
  width: 100%;
}

.CapturedImage {
  height: 400px;
  width: 100%;
  object-fit: contain;
}

.WebCamCapture {
  object-fit: cover;
  margin: 0px 14px;
}

.CapturePhotoActions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.MuiButton-root.CaptureCameraIcon {
  width: 10%;
  cursor: pointer;
  background-color: #fff;
}

.MuiButton-root.PhotoSubmitBtn {
  width: 88px;
  height: 40px;
  cursor: pointer;
  margin: 0 20px 0 0;
  padding: 9px 19px 12px 20px;
  border-radius: 3px;
  background: linear-gradient(262deg, #3a60d7 111%, #253873 -31%);
}

.MuiTypography-root.PhotoSubmitBtnText {
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: center;
  color: #fff;
}

.MuiButton-root.PhotoCancelBtn {
  width: 88px;
  height: 40px;
  cursor: pointer;
  margin: 0 20px 0 0;
  padding: 9px 19px 12px 20px;
  border-radius: 3px;
  background: #f6f6f6;
}

.MuiTypography-root.PhotoCancelBtnText {
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: center;
  color: #3f526d;
}

.MuiButton-root.VideoSubmitBtn {
  width: 88px;
  height: 40px;
  cursor: pointer;
  margin: 0 20px 0 0;
  padding: 9px 19px 12px 20px;
  border-radius: 3px;
  background: linear-gradient(262deg, #3a60d7 111%, #253873 -31%);
  position: relative;
}

.VideoSubmitBtnText {
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: center;
  color: #fff;
}

.MuiButton-root.RecordVideoBtn {
  width: 10%;
  height: 100%;
  cursor: pointer;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}

.VideoAllowedDiv {
  position: relative;
  margin: 10px;
  object-fit: contain;
  border-radius: 8px;
}

.AllowedVideoPreviewDiv {
  width: 100%;
  height: 15%;
}

.MuiTypography-root.VideoWebCamBack {
  font-size: 13px;
  font-family: 'Open Sans';
  font-weight: 600;
  margin: 0px 5px -5px;
}

.VideoWebcamClose {
  cursor: pointer;
}

.DocumentDetailsDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.MuiTypography-root.AddNewUploadFileText {
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
}

.DocumentsDetailsContainer {
  padding: 35px 30px;
}

.MuiTypography-root.StudentFileUploadedText {
  color: #202020;
  font-size: 20px;
  margin-top: 20px;
}

.MuiTypography-root.StudentFileNameText {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 5px;
}

.MuiTypography-root.StudentUploadVideoBackText {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: none;
}

.StudentUploadVideoBackBox {
  margin-right: auto;
}

.ViewDocHeadImgDiv {
  height: 20%;
  margin-top: -63px;
  margin-bottom: 22px;
  align-self: center;
  width: fit-content;
}

.ViewDocHeadImg {
  width: 200px;
  height: 174px;
  object-fit: contain;
}

.ViewDocOptionsDiv {
  background-color: #fff;
  width: fit-content;
  align-self: end;
  margin-top: -54px;
}

.ViewDocOptions {
  object-fit: contain;
  height: 100%;
  cursor: pointer;
}

.ViewDocStatusDiv {
  width: 172px;
  height: 38px;
  padding: 8px 26px;
  border-radius: 25px;
  align-self: center;
  position: relative;
  z-index: 30;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.MuiTypography-root.ViewDocStatusText {
  height: 24px;
  font-family: 'Open sans';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #fff;
  text-align: center;
}

.ViewDocImageDiv {
  background-color: #f6f6f6;
  padding: 50px 0 20px 0;
  position: relative;
  top: -20px;
}

.ViewDocLoader {
  position: absolute;
  top: 50%;
}

.ViewDocOptionBox {
  width: 203px;
}

.MuiMenuItem-root.ViewDocOptionMenu {
  display: flex;
  justify-content: center;
}

.MuiGrid-root.ViewDocOptionFlexBasis {
  flex-basis: 0;
}

.MuiTypography-root.ViewDocOptionText {
  text-transform: none;
  font-size: 16px;
  font-family: 'Open sans';
  font-weight: 600;
  color: #3f526d;
}

.ViewDocCloseOption {
  color: #3f526d;
  cursor: pointer;
}

.RecentActivityMainBox {
  flex-direction: row;
  min-width: 500px;
}

.MuiTypography-root.RecentBackText {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Open sans';
  padding: 4px;
  text-transform: none;
}

.RecentActivityHeaderBox {
  height: 100%;
  border-radius: 13px;
}

.RecentActivityBrowser {
  width: 17%;
}

.RecentActivityOS {
  width: 18%;
}

.RecentActivityDateTime {
  width: 15%;
}

.RecentActivityLocation {
  width: 35%;
}

@media (max-width: 959px) {
  /* .create-video-btn {
    width: 95%;
  } */
  .recent_activity_header_text {
    padding: 0 5px;
  }

  .recent_activity_detail_text {
    font-size: 14px !important;
  }

  .spacing_15 {
    padding: 5px;
  }

  #recorderVideoPreview {
    font-size: 40px;
  }

  .video_recording_icon {
    height: 70px;
    width: 70px;
  }

  .record_video_bottom_section {
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .txt {
    height: auto !important;
  }
}

@media (max-width: 599px) {
  .MuiTypography-root.add_upload_text {
    font-size: 14px !important;
  }

  .textfield {
    width: auto !important;
  }

  .txt {
    width: auto !important;
    height: auto !important;
  }

  .addFileTxt {
    font-size: 14px;
  }

  .documentButton {
    width: auto !important;
  }

  .viewModal {
    width: 90% !important;
  }

  .viewModalImg {
    width: inherit;
  }

  .uploadingBlock {
    width: 100% !important;
    height: fit-content;
    padding: 4px !important;
    margin: 5px !important;
  }

  .personal_info_main_div {
    padding: 20px;
  }

  .teacher_update_btn {
    width: 60%;
  }

  .spacing_0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .spacing_pt_0 {
    padding-top: 0 !important;
  }

  .spacing_pb_0 {
    padding-bottom: 0 !important;
  }

  .teacher_tab {
    font-size: 16px !important;
  }

  .profile_image {
    height: 120px;
    width: 120px;
  }

  .profile_avatar {
    height: 120px !important;
    width: 120px !important;
  }

  .badge_img {
    width: 150px;
    height: 115px;
  }

  .recent_activity {
    font-size: 18px !important;
  }

  .login_session_text {
    margin-top: 3px !important;
  }

  .recent_activity_main_div {
    margin-right: 20px;
  }

  .xs_pl_10 {
    padding-left: 10px;
  }

  .recent_activity_header_div {
    padding: 15px 10px;
  }

  #recorderVideoPreview {
    height: 40px;
    font-size: 30px;
  }

  .video_recording_icon {
    height: 60px;
    width: 60px;
  }
}

div:focus,
input:focus {
  outline: none !important;
}

.type-date-input {
  color: red;
}
