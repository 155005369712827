
  #fileNameText21 {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    .slick-dots li button{
      background-color: #fff;
      
    }
    .slick-dots li button:before{
      color:transparent !important;
      background-color: #af38cb;
      color: #af38cb !important;
      border-radius: 50%;
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before{
       background-color: #fdfdfd !important;
      color: #fdfcfc !important;
      border:2px solid #af38cb;
      border-radius: 50%;
    }
 .sliderTask{
      margin:20px 0px;
    }
  
     .paragrapWrapClass{
       text-overflow: ellipsis;
       overflow: hidden;
       -webkit-line-clamp: 3;
       -webkit-box-orient: vertical;
       display: -webkit-box;
     }
  .tabsButton{
    min-width: 133px !important;
    height: 50px !important;
    margin-right: 10px !important;
  }
  .tabsButtonText{
    font-size: 18px !important;
  }
  .selected_tab_container {
    display: flex !important;
    flex-direction: column !important;
    padding-right: 10px !important;
  }
  .selected_tab_button {
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-style: solid !important;
    border-radius: 6px !important;
    margin: 0px 10px 0px 0px !important;
    padding: 0px !important;
  }
  .selected_tab_label {
    text-transform: none !important;
    font-family: "Open Sans"!important;
    font-weight: 600 !important;
  }
  .classesButtonText{
    font-size: 20px !important;
  }
  .moreInfoButton{
    min-width: 135px !important;
    height: 42px !important;
    border-radius: 6px !important;
  }
  .moreInfoIcon{
    font-size: 19px !important;
    margin-right: 10px;
  }
  .moreInfoText{
    font-size: 18px !important;
  }
  .listGridView{
    height: 40px !important;
    width: 40px !important;
  }
  .listGridViewInner{
    font-size: 40px !important;
  }
  .font_18{
    font-size: 18px !important; 
}
.font_16{
    font-size: 16px !important;
}
.font_15{
  font-size: 15px !important;
}
.font_20{
  font-size: 20px !important;
}
.font_14{
  font-size: 14px !important;
}
.font_12{
  font-size: 12px !important;
}
.font_22{
  font-size: 22px !important;
}

.filterbtn {
  font-size: 18px !important;
  padding: 5px 19px 5px 20px !important;
}
.username{
  font-size: 18px !important;
  color: #3f526d !important;
  margin-bottom: 10px !important;
}

.selectBox select{
  padding: 13px 19px 13px 20px !important;
  border-radius: 8px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
}
.selectBox .MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    top: 4px;
}
.input_date_of_event_css_format>input {
  padding: 14px;
  outline: none;
  border: 1px solid #ebebeb;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  position: relative;
  width: 100%;
  font-family: "Open sans";
}


.input_date_of_event_css_error_format>input {
  padding: 14px;
  outline: none;
  border: 1px solid red;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  position: relative;
  width: 100%;
  font-family: "Open sans";
}

/* remove inline css */
.outer-liveclass .schedule-title{
  font-size: 24px;
  font-weight: bold;
}

.time-table-row .subHeading, .board-outer .schedule-title{
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;

}



@media screen and (max-width: 1023px) {

    .Headerhight{
      margin-top: 62px !important;
      border-radius: 0 !important;
    }
    .main-wrapper{
      border-radius: 0 !important;
    }
    .event-btn {
      padding: 6px 15px !important;
    }
    .event-btn p{
      margin-left: 10px !important;
      font-size: 15px !important;
    }
}

  @media screen and (max-width: 767px) {
    .Headerhight{
      padding: 10px 15px !important;
      margin-top: 56px !important;
    }

    .font_14{
      font-size: 12px !important;
    }
    .font_12{
      font-size: 10px !important;
    }
    
    .font_18{
      font-size: 14px !important; 
  }
  .font_16{
      font-size: 12px !important;
  }
  .font_15{
    font-size: 11px !important;
  }
  .font_20{
    font-size: 16px !important;
  }
  .font_22{
    font-size: 17px !important;
  }
    .tabsButton{
      min-width: 80px !important;
      height: 33px !important;
      margin-left: 0 !important;
      margin-right: 10px !important;
    }
    .tabsButtonText{
      font-size: 12px !important;
    }
    .classesButtonText{
      font-size: 16px !important;
    }
    .moreInfoButton{
      min-width: 100px !important;
      height: 33px !important;
      border-radius:5px !important;
    }
    .moreInfoIcon{
      font-size: 15px !important;
      margin-right: 6px;
    }
    .moreInfoText{
      font-size: 14px !important;
    }
    .listGridView{
      height: 30px !important;
      width: 30px !important;
    }
    .sliderTask{
      margin:20px 0px;
    }
    .listGridViewInner{
      font-size: 30px !important;
    }
    .mob-col6 {
      max-width: 50% !important;
      flex-basis: 50% !important;
    }

    .username{
      font-size: 16px !important;
    
    }
    .Category-btn p {
      font-size: 13px !important;
    }
    .Category-btn button {
      padding: 8px 13px 8px !important;
      width: 174px !important;
      display: flex !important;
      align-items: center !important;
      margin: -18px 0 8px;
    }
    .search-outer {
      flex-wrap: wrap;
    }
    .co4Mob{
      max-width: 100% !important;
      flex-basis: 100% !important;
      margin: 0 !important;
     }
     .paragrapWrapClass{
       text-overflow: ellipsis;
       overflow: hidden;
       -webkit-line-clamp: 3;
       -webkit-box-orient: vertical;
       display: -webkit-box;
     }
     .TeacherClasses.selectBox {
      max-width: 100% !important;
      flex-basis: 100% !important;
      margin-left: 0 !important;
      padding: 10px 0 15px!important;
     }
     .TeacherClasses.selectBox select {
       width: 100% !important;
       margin: 15px 0 !important;
     }

     .TeacherClasses.selectBox .MuiSvgIcon-root {
      top: 21px;
     }
     
     .co4Mob div {
       width: 100% !important;
       text-align: left !important;
       font-size: 14px !important;
       margin: 0 !important;
     }
     
     .event-btn-outer{
      max-width: 100% !important;
      flex-basis: 100% !important;
     }
     .event-btn {
       width: 100% !important;
       padding: 10px 15px !important;
     }
     .event-btn img{
      width: 20px !important;
     }
     .co4Mob div.Teacher-Slect {
       margin-top: 15px !important;
     }

     .TeacherClasses.selectBox .Teacher-Slec .MuiSvgIcon-root {
      top: 0px;
     }

     .Create-Event-Popup {
      padding: 20px 15px !important;
      width: 94% !important;
      height: auto;
      border-radius: 8px !important;
     }
     .Toastify__toast-container {
      z-index: 99999999 !important;
     }

     .TeacherClasses.selectBox .Teacher-Slect .MuiSvgIcon-root{
      top: 0px ;
     }

     .Create-Event-Popup .TeacherCreateEvent-HeadText-52 {
      margin-bottom: 8px !important;
      font-size: 18px !important;
     }

     .Create-Event-Popup .input_due_date_css_format > input {
      background-size: 24px 24px !important;
      background-position: top 9px right 12px !important;
      width: 97% !important;
      padding: 10px 7px !important;
      font-size: 15px;
     }

     .Create-Event-Popup  .Teacher_Profile.Date_Icon{
      background-size: 24px 24px !important;
      background-position: top 13px right 12px !important;
      width: 97% !important;
     }

     .Create-Event-Popup .TeacherCreateEvent-selectDropDown-54 {
      width: 97% !important;
     }
     .Create-Event-Popup  label {
      margin-bottom: 10px !important;
      font-size: 14px !important;
     }
     .Create-Event-Popup  select {
      padding: 12px 14px !important;
      font-size: 15px !important;
     }

     .Create-Event-Popup .file-drop-target div {
       width: 96% !important;
       flex-direction: inherit !important;
       flex-wrap: wrap !important;
       justify-content: center !important;
       margin: 0 5px !important;
       padding: 20px 0px !important;
     }
     .Create-Event-Popup .file-drop-target p{
      font-size: 16px  !important;
     }

     .Create-Event-Popup .mt0{
       margin-top: 0 !important;
     }
     .AddEvent-Btn {
      height: 46px !important;
      font-size: 15px !important;
      
     }
     .Eventtitle{
      margin-bottom: 15px !important;
     }
     
     .Eventtitle p {
      font-size: 18px !important;
      margin-bottom: 13px !important;
     }
     .Sl-menu div {
       width: 100% !important;
     }

     .Create-Event-Popup .MuiOutlinedInput-input {
      padding: 14px 14px !important;
     }

     .outer-liveclass .schedule-title, .board-outer .schedule-title{
      font-size: 18px;
     
    }

    .time-table-row .subHeading {
      font-size: 16px;
    }
    .board-outer .MuiTypography-root{
      font-size: 16px;
    }
   .Headerhight .mob-spacing {
    margin: 0;
    }

    .sliderTask-slider {
      padding: 15px 15px 30px !important;
      width: 94% !important;
    }
  

  }
  @media screen and (max-width: 959px) and (min-width: 768px) {
    .font_14{
      font-size: 12.5px !important;
    }
    .font_12{
      font-size: 10.5px !important;
    }
    .font_18{
      font-size: 15px !important; 
  }
  .font_16{
      font-size: 13px !important;
  }
  .font_15{
    font-size: 12px !important;
  }
  .font_20{
    font-size: 17px !important;
  }
  .font_22{
    font-size: 18.5px !important;
  }
    .tabsButton{
      min-width: 100px !important;
      height: 36px !important;
      margin-right: 10px !important;
    }
    .tabsButtonText{
      font-size: 13px !important;
    }
    .classesButtonText{
      font-size: 17.5px !important;
    }
    .moreInfoButton{
      min-width: 120px !important;
      height: 38px !important;
      border-radius: 4px !important;
    }
    .moreInfoIcon{
      font-size: 17px !important;
      margin-right: 7px;
    }
    .moreInfoText{
      font-size: 16px !important;
    }
    .listGridView{
      height: 33px !important;
      width: 33px !important;
    }
    .listGridViewInner{
      font-size: 33px !important;
    }
  }
  @media screen and (max-width: 1279px) and (min-width: 959px) {
    .font_14{
      font-size: 13px !important;
    }
    .font_12{
      font-size: 11px !important;
    }
    .font_18{
      font-size: 16.5px !important; 
  }
  .font_16{
      font-size: 14.5px !important;
  }
  .font_15{
    font-size: 13.5px !important;
  }
  .font_20{
    font-size: 18.5px !important;
  }
  .font_22{
    font-size: 20px !important;
  }
    .tabsButton{
      min-width: 120px !important;
      height: 37px !important;
      margin-right: 10px !important;
    }
    .tabsButtonText{
      font-size: 14px !important;
    }
    .classesButtonText{
      font-size: 18.5px !important;
    }
    .moreInfoButton{
      min-width: 128px !important;
      height: 35px !important;
      border-radius: 5px !important;
    }
    .moreInfoIcon{
      font-size: 18px !important;
      margin-right: 8px;
    }
    .moreInfoText{
      font-size: 17px !important;
    }
    .listGridView{
      height: 36px !important;
      width: 36px !important;
    }
    .listGridViewInner{
      font-size: 36px !important;
    }
   
    
  }

