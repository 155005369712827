.edit_online_lecture_parent_modal {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.edit_online_lecture_parent_modal .main_container {
    background-color: white;
    border: 1px solid #979797;
    outline: none;
    border-radius: 29px;
    width: 80%;
    height: 85%;
    padding: 30px 40px;
    box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.2);
}
.h_100 {
    height: 100% !important;
}
.w_100 {
    width: 100% !important;
}
.edit_online_lecture_parent_modal .top_heading_container {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    border-bottom: 0.3px solid #979797 !important;
}
.edit_online_lecture_parent_modal .head_container {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    margin: 0 0px 26px 1px;
    width: 95%;
}
.edit_online_lecture_parent_modal .head_typo {
    text-transform: none !important;
    font-size: 24px !important;
    font-family: 'Open Sans' !important;
    font-weight: bold !important;
    color: #3f526d !important;
    margin: 0 0px 26px 0px !important;
}
.edit_online_lecture_parent_modal .modal_close_icon {
    cursor: pointer !important;
    font-size: 38px !important;
    color: lightgrey !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
}
.edit_online_lecture_parent_modal .form_inner_box {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - 190px);
    margin-top: 10px;
    padding-right: 10px;
}
.edit_online_lecture_parent_modal .form_group {
    align-items: center;
    justify-content: center;
    text-align: start;
}
.edit_online_lecture_parent_modal .form_group_label {
    text-transform: none;
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #3f526d;
    margin: 0px 0px 3px 0;
}
.edit_online_lecture_parent_modal .input_container {
    margin: 0px 0px 16px 0px;
}
.edit_online_lecture_parent_modal .input_box {
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 8.8px;
    border: none;
}
.edit_online_lecture_parent_modal .error_msg {
    color: red; 
    margin: 3px 14px 0;
}
.edit_online_lecture_parent_modal .disabled_input_box {
    width: 100%;
    border-radius: 8.8px;
    border: none;
}
.edit_online_lecture_parent_modal .radio_group {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.edit_online_lecture_parent_modal .radio_group_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
}
.edit_online_lecture_parent_modal .radio_group_label {
    text-transform: none;
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #3f526d;
}
.flex_box_row {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}
.edit_online_lecture_parent_modal .description_margin {
    margin: 26px 12px 0px 0px;
}
.edit_online_lecture_parent_modal .description_field {
    width: 100%;
    margin: 5px 35px 16px 1px;
    background-color: #fff;
    border-radius: 8.8px;
    border: none;
}
.edit_online_lecture_parent_modal .btn_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    border-radius: 12px;
    padding: 17px 0px 0 0px;
}
.edit_online_lecture_parent_modal .update_button {
    background-color: #b620e0;
    border-radius: 6px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    box-shadow: none;
    padding: 13px 100px;
}
