.MainGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 6px;
    padding: 30px 8px;
    max-width: 600px !important;
    position: relative;
}

.ParentGrid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
}

.CloseIcon .MuiSvgIcon-root{
    height: 48px;
    width: 48px;
    opacity: 0.1;
}

.SelectAll{
    padding: 0px 28px 0px 0px;
}


.MainModal{
    display: flex;
    align-items: center;
    justify-content: center;
}

.MainGrid .CloseIconDiv{
    position: absolute; 
    right: 5px; 
    top: 5px;
}

.ParentGrid .MV4{
    margin: 4px 0px;
}
.ParentGrid .MV8{
    margin: 8px 0px;
}
.ParentGrid .MV20{
    margin: 20px 0px;
}


.ParentGrid .MinHeight64{
    min-height: 64px;
}

.MainGrid .ParentGrid .SearchGrid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 0px;
}

.MainGrid .ParentGrid .TitleText{
    font-size: 24px;
    font-weight: bold;
    margin-top: 0px;
    padding-top: 0px;
}

.MainGrid .ParentGrid .BoxStyle{
    width: 100%;
    height: 58px;
    padding: 0px 15px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    background: #f9f8fd;
}

.MainGrid .ParentGrid .SearchIcon{
    height: 25px;
    width: 25px;
}

.MainGrid .ParentGrid .SearchInput{
    border: none;
    outline: none;
    font-size: 18px;
    width: 100%;
}

.MainGrid .ParentGrid .ListContainer{
    margin: 4px 0px;
    height: 400px;
    overflow-y: auto;
    display: block;
}
.MainGrid .ParentGrid .ListContainer .AccordianHeader{
    box-shadow: none;
}
.MainGrid .ParentGrid .ListContainer .AccordianHeader .MuiIconButton-edgeEnd{
    margin: 0px;
    padding: 4px;
}
.ListContainer .AccordionDetail {
    display: flex;
    flex-direction: column;
}
.ListContainer .AccordionDetail .ListGrid{
    border-bottom: 1px solid black; 
    margin-top: 4px; 
    margin-bottom: 4px;
}
.MainGrid .ParentGrid .PersonImage{
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.MainGrid .ParentGrid .ML0{
    margin-left: 0px;
}
.MainGrid .ParentGrid .PL0{
    padding-left: 0px;
}
.MainGrid .ParentGrid .SectionHeader{
    width: 100%;
    height: 58px;
    padding: 0px 15px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f8fd;
}


.MainGrid .ParentGrid .RowReverse{
    flex-direction: row-reverse;
}

.MainGrid .ParentGrid .SectionHeader .MuiAccordionSummary-content{
    justify-content: space-between;
    align-items: center;
}
.MainGrid .ParentGrid .SearchListBox{
    display: flex;
    flex-direction: column;
    padding: 8px 16px 16px 16px;
}
.MainGrid .ParentGrid .SearchListBox .ListGridContainer{
    border-bottom: 1px solid black;
    margin-top: 4px; 
    margin-bottom: 4px;
}
.MainGrid .ParentGrid .ActionButton{
    padding: 12px 32px 12px 32px;
    height: 48px;
    margin: 0px 4px;
}

@media screen and (max-width: 1279px) {
    .MainGrid{
        max-width: 60% !important;
    }
    .ParentGrid{
        width: 90%;
    }
    .SelectAll{
        padding: 0px 28px 0px 0px !important;
    }

    .CloseIcon .MuiSvgIcon-root{
        height: 48px;
        width: 48px;
    }
}

@media screen and (max-width: 959px) {
    .MainGrid{
        max-width: 70% !important;
    }
    .ParentGrid{
        width: 90%;
    }
    .SelectAll{
        padding: 0px 24px 0px 0px !important;
    }

    .CloseIcon .MuiSvgIcon-root{
        height: 42px;
        width: 42px;
    }
}

@media screen and (max-width: 767px) {
    .MainGrid{
        max-width: 90% !important;
    }
    .ParentGrid{
        width: 90%;
    }
    .SelectAll{
        padding: 0px !important;
    }


    .CloseIcon .MuiSvgIcon-root{
        height: 32px;
        width: 32px;
    }
}

@media screen and (min-width: 540px) and (max-width:767px) {
    
    .SelectAll{
        padding: 0px 18px 0px 0px !important;
    }
}
