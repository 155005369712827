.paginationBttns {
  width: 100%;
  height: auto;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

.paginationBttns a {
  padding: 13px 20px;
  margin: 5px;
  border-radius: 8px;
  border: 1px solid #af38cb;
  color: #af38cb;
  font-family: 'Open sans';
  cursor: pointer;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.previousBttn {
  /* color: #ffffff !important; */
  /* background-color: #af38cb !important; */
  padding: 13px 10px 13px 10px !important;
}
.previousBttn :hover {
  /* color: #fff !important; */
  /* background-color: #af38cb !important; */
}

.nextBttn {
  color: #ffffff !important;
  border-radius: 6px !important;
  background-image: linear-gradient(
    to top,
    #af38cb 101%,
    #471c51 33%
  ) !important;
  padding: 13px 10px 13px 10px !important;
}
.nextBttn :hover {
  color: #fff !important;
  background-image: linear-gradient(
    to top,
    #af38cb 101%,
    #471c51 33%
  ) !important;
}
.paginationBttns a:hover {
  /* color: #fff !important; */
  border-radius: 6px;
  /* background-image: linear-gradient(to top, #af38cb 101%, #471c51 33%); */
}
.paginationActive a {
  color: #fff;
  background-image: linear-gradient(
    to top,
    #af38cb 101%,
    #471c51 33%
  ) !important;
  border-radius: 6px;
}

.paginationDisabled a {
  /* color: #471c51 !important; */
  /* background-color: #ffffff !important; */
  font-size: 18 !important;
  font-family: 'Open sans';
  font-weight: 600;
  opacity: 0.4;
  border-radius: 8px;
  padding: 13px 5px 13px 5px !important;
  /* border: 1px solid #af38cb; */
  align-items: center;
  cursor: not-allowed !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-1s2u09g-control {
  border: solid 0.3px #471c51;
  border-radius: 6px;
}

.css-1pahdxg-control {
  border: solid 0.3px #471c51 !important;
  box-shadow: none !important;
  border-radius: 6px;
}
.css-1x56lie-control {
  border: solid 0.3px #471c51 !important;
  border-color: red !important;
  border-radius: 6px !important;
  cursor: pointer !important;
}
.css-bjblvx-control {
  cursor: pointer !important;
  border: solid 0.3px #471c51 !important;
  box-shadow: none !important;
  border-radius: 6px;
}
.css-bjblvx-control:hover {
  border: solid 0.3px #471c51 !important;
  cursor: pointer;
}
.css-2613gy-menu {
  background-color: red !important;
}

.input_Text_Emoji:focus-visible {
  outline: none !important;
}
.css-tlfecz-indicatorContainer {
  padding: 10px;
}
.input_today_date_css_format > input {
  width: 237px;
  padding: 16.5px 23px;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  color: #3f526d;
  font-family: 'Open sans';
  cursor: pointer;
}

input.input-search-box::placeholder {
  opacity: 0.45;
  color: #484848 !important;
}

.gray-placeholder::placeholder {
  opacity: 0.45;
  color: #484848 !important;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-image: linear-gradient(to top, #af38cb 101%, #471c51 33%);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -140px;
  margin-top: 3px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #471c51 transparent;
  margin-left: 60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.css-1n92z2t-InputComponent {
  font-size: 16 !important;
  font-weight: 600 !important;
  color: #3f526d !important;
  font-family: 'Open sans' !important;
}
.css-97psnk-ReactDropdownSelect {
  background-color: #f9f8fd !important;
  padding: 0px 19px !important;
  border-radius: 6px !important;
  border: solid 0.3px #471c51 !important;
}
.input_date_css_format > input {
  width: 100%;
  padding: 18.5px 14px;
  font-size: 15.8px;
  border-radius: 8px;
  border: 1px solid lightgray;
  position: relative;
  cursor: pointer;
}
.rdtPicker {
  position: absolute !important;
}
.rdtDays > tr {
  padding: 10px !important;
}

.dropDown_Value {
  border: solid 0.3px linear-gradient(to top, #af38cb 101%, #471c51 33%) !important;
  cursor: pointer !important;
  border-radius: 6 !important;
}
.Date_of_birth_calendar_Icon {
  background-repeat: no-repeat;
  background-image: url('../assets/calendar.png');
  background-size: 19px 19px;
  background-position: top 17px right 28.3px;
}
