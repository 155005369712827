.teacherSubjectpart .subGrid {
    display: flex ;
    flex-direction: row;
    width: 100%;
  }

.teacherSubjectpart .gridSubjects {
    margin-top: 20px;
    align-items: center;    
  }

.teacherSubjectpart .avatarBox {
    width: 70px;
    height: 70px;
    background-color: rgb(222, 240, 255);
    border-radius: 6px;    
  }

.teacherSubjectpart .avatarBox .avtarIcon {
    width: 40px;
    height: 40px;
    margin: 15px 14px 15px 15px;
    display: inline-block;
  }

.teacherSubjectpart .classesButtonText {
    text-transform: none;
    font-weight: 600;
  }
  
.teacherSubjectpart .mrleft {
    margin-left: 25px;
  }

.teacherSubjectpart .alignStart {
    text-align: start;
  }

.teacherSubjectpart .unitBox {
  margin-left: 24px;
}

.teacherSubjectpart .avatar-square {
  height: 38px;
  width: 38px;
  margin: 0px 24px 0 0;
}

.teacherSubjectpart .selectbox-outer {
  display: flex;
  justify-content: flex-start;
}

.teacherSubjectpart .mt20 {
  margin-top: 20px;
}

.teacherSubjectpart .searchBoxouter {
  display: flex;
  width: 100%;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
}

.teacherSubjectpart .searchBox {
  border-radius: 8px;
  width: 40%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.teacherSubjectpart .searchIcon {
  width: 25px;
  height: 25px;
}

.teacherSubjectpart .searchBoxinner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.teacherSubjectpart .bulletBox {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.teacherSubjectpart .bulletListIcon {
  cursor: pointer;
  padding: 5px;
  font-size: 40px;
  border-radius: 6px;
}

.teacherSubjectpart .subsubjectname {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.teacherSubjectpart .teacherSubjectname {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 8px;
  text-transform: none;
}

.teacherSubjectpart .borderListIcon {
  cursor: pointer;
  font-size: 40px;
  padding: 5px;
  border-radius: 6px;
  margin-left: 12px;
}

.teacherSubjectpart .mtop20 {
  margin-top: 20px;
}


.teacherSubjectpart .searchSubject {
  border: 0;
  outline: 0;
  color: #484848;
  opacity: 0.45;
  font-size: 1.125rem;
  font-weight: 400;
  width: 100%;
}

.teacherSubjectpart .searchSubjectbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.teacherSubjectpart .selectbox-outer .MuiNativeSelect-icon {
  font-size: 35px;
  top: 8px;
  margin: 0;
}

.teacherSubjectpart .teacherSub {
  width: 54%;
  display: flex;
}

.teacherSubjectpart .teachersubject-title {
  padding-left: 20px;
}

.teacherSubjectpart .percentValue {
  font-size: 19px;
  font-weight: 600;
}

.teacherSubjectpart .teacherUnits{
  width: 52%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;  
}

.teacherSubjectpart .searchSubjectouter {
  display: flex;
  border-radius: 8px;
  background-color: #f9f8fd;
  padding: 28px 58px 28px 40px;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;
}

.teacherSubjectpart .teachersearchSubjectbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f8fd;
  padding: 20px 27.1px 25px 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.teacherSubjectpart .nodataText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2%;
}

.teacherSubjectpart .borderLineprogress {
  width: 100%;
  margin: 30px 0;
}

.teacherSubjectpart .nodataIcon {
  height: 182px;
  object-fit: contain;
}

.teacherSubjectpart .noRecordfound {
  width: 100%;
  text-align: center;
  margin-top: 2%;
}

.teacherSubjectpart .noRecordfound span {
  font-size: 22px;
  opacity: 0.5;
  font-weight: 600;
  color: #3f526d;
}

.teacherSubjectpart .teacherunitsTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
} 

.teacherSubjectpart .searchSubjectouter .teacherunitsTitle {
  width: 50%;
}

.teacherSubjectpart .icPresentation {
  height: 38px;
  width: 38px;
  margin: 0px 14px 0 0;
}

.teacherSubjectpart .subjectListing {
  padding: 28px 58px 28px 40px;
  display: flex;
  border-radius: 8px;
  background-color: #f9f8fd;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;
}

.teacherSubjectpart .subjectboxView {
  display: block;
  padding: 20px 27.1px 25px 20px;
}

.teacherSubjectpart .subjectboxView .subGrid {
  justify-content: space-between;
}

.teacherSubjectpart .subjectboxView .teacherSubjectname {
  font-size: 18px;
}
.teacherSubjectpart .subjectboxView .subsubjectname {
  font-size: 14px;
}

.teacherSubjectpart .subjectBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.teacherSubjectpart .grig-spc {
  margin-top: 50px;
  margin-bottom: 50px;
}

.teacherSubjectpart .block {
  display: block;
}

.teacherSubjectpart .none {
  display: none;
}

.teacherSubjectpart .tsOuter {
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
}

.teacherSubjectpart .widthFull {
  width: 100%;
}

.teacherSubjectpart .teacherSub {
  display: flex;
}

@media screen and (max-width: 1199px) {
  .teacherSubjectpart .teacherGridView .MuiGrid-grid-xs-4{
    max-width: 50%;
    flex-basis: 50%;
  }

  .teacherSubjectpart .teacherSub {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .teacherSubjectpart .teacherSub .avatarBox {
    width: 70px;
    height: 70px;
    background-color: #def0ff;
    border-radius: 6px;
  }

  .teacherSubjectpart .teacherSub .avatarBox .avtarIcon {
    height: 40px;
    width: 40px;
    margin: 15px 14px 15px 15px;
  }

  .teacherSubjectpart .subjectListing{
    padding: 28px 20px 28px 20px;
  }
}


@media screen and (max-width: 991px) {
    .teacherSubjectpart .teacherSub{
      width: 36%;
    }
    .teacherSubjectpart .teacherUnits{
      width: 65%;
    }
}

@media screen and (max-width: 767px) {
    .teacherSubjectpart .subjectListing {
      padding: 15px;
      flex-wrap: wrap;
    }

    .teacherSubjectpart .teacherSub {
      width: 100%;
      margin-bottom: 20px;
    }

    .teacherSubjectpart .teacherUnits {
      width: 100%;
      flex-wrap: wrap;
    }

    .teacherSubjectpart .avatarBox{
      width: 50px;
      height: 50px;
    }

    .teacherSubjectpart .avatarBox .MuiAvatar-rounded {
      height: 30px;
      width: 30px;
      margin: 10px;
    }

    .teacherSubjectpart .teacherUnits .MuiCircularProgress-root {
      width: 38px;
      height: 38px;
    }

    .teacherSubjectpart .teacherUnits .MuiTypography-caption {
      font-size: 12px;
    }

    .teacherSubjectpart .teacherUnits p {
      font-size: 16px;
    }

    .teacherSubjectpart .selectBox select {
      padding: 13px 5px 13px 14px;
      font-size: 14px;
    }

    .teacherSubjectpart .selectBox {
      margin-left: 15%;
    }

    .teacherSubjectpart .selectBox .MuiSvgIcon-root {
      width: 30px;
      height: 31px;
      top: 5px;
    }

    .teacherSubjectpart .ListIcon {
      font-size: 30px;
    }

    .teacherSubjectpart .searchBlock {
      padding: 10px 13px 8px;
      width: 68%;
    }

    .teacherSubjectpart .searchBlock input {
      font-size: 15px;
    }

    .teacherSubjectpart .teacherGridView .MuiGrid-grid-xs-4{
      max-width: 100%;
      flex-basis: 100%;
    }

    .teacherSubjectpart .subGrid .MuiAvatar-square{
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }

    .teacherSubjectpart .subGrid p {
      font-size: 16px;
    }

    .teacherSubjectpart .grig-spc {
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .teacherSubjectpart .avatar-square {
      margin-right: 8px;
    }

    .teacherSubjectpart .avatar-square .MuiAvatar-img {
      width: 85%;
      height: 85%;
    }
}