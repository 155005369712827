.announcement_notification_box {
  height: 187px;
  padding: 22px 34px 20px 31px;
  border-radius: 20px;
  box-shadow: 0 24px 48px 0 rgba(30, 28, 46, 0.08);
  background-image: linear-gradient(to bottom, #dc819c 0%, #e12c59);
  overflow: auto;
}

.view_all_text {
  white-space: nowrap !important;
  font-weight: 600 !important;
}

.announcement_text {
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-left: 22px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff !important;
}

.no_announcement_img_block {
  opacity: 0.5 !important;
  font-size: 16px !important;
  color: #fff !important;
}

.no_announcement_img_block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 7% !important;
}

.no_announcement_img_block_span {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background-color: #cccccc33;
  padding: 17px;
}

.no_announcement_img_block img {
  height: 28px;
  object-fit: contain;
}

.announcement_msg_icon {
  height: 37px;
  width: 37px;
}

@media only screen and (max-width: 1200px) {
  .announcement_notification_box {
    margin-top: 31px;
  }
}
