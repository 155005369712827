.iconBox {
  float: right;
  display: flex;
}

.mainTab button {
  min-width: auto;
}

.iconQuestion {
  width: 35px;
  height: 35px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  display: block;
  border: 1px solid #d3d3d3;
  margin-left: 10px;
  cursor: pointer;
}

.footerBtn button {
  box-shadow: none;
}

.footerBtn button:first-child {
  background: #3f526d;
  color: #fff;
}

.questionList {
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0;
  float: left;
  width: 100%;
  background: #f1f4ff;
  margin-bottom: 15px;
}

.iconQuestion img {
  max-width: 25px;
  max-height: 25px;
}

.questionTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  color: #3f526d;
  float: left;
}

.fileNameText1 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.commonPopup {
  position: absolute;
  top: 50%;
  border-radius: 6px;
  padding: 20px;
  left: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.34);
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 500px;
  background: #fff;
}

.viewBody {
  margin: 0;
}

.sharepeople .viewBody {
  position: relative;
}

.commonPopup h5 {
  font-size: 15px;
  margin: 0;
  font-weight: 500;
}

.commonPopup ul {
  margin: 15px 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
}

.commonPopup ul li {
  padding: 0 15px 0 0;
  margin: 0;
  font-size: 16px;
}

.commonPopup .listdata {
  display: block;
}

.commonPopup .listdata li {
  padding: 0;
  margin: 0 0 18px;
  display: flex;
  color: #3f526d;
  font-size: 16px;
}

.commonPopup .listdata li span {
  min-width: 95px;
  padding-right: 30px;
}

.commonPopup .listdata li:first-child {
  border: 0;
}

.commonPopup ul li:first-child {
  border-right: 1px solid #c7c7c7;
  margin-right: 15px;
}

.commonPopup ul li img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
}

.titleMain {
  border-bottom: 1px solid #c7c7c7;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  padding-bottom: 40px;
}

.titleMain h4 {
  display: flex;
  line-height: 34px;
}

.AdminFeedbackSelectClassDropDown {
  width: 180px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.AdminFeedbackReviewDropDown {
  width: 180px;
  margin-bottom: 20px;
}