.AccountSuccessBox {
    flex-grow: 1 !important;
    overflow-x: hidden !important;
    height: 100% !important;
}

.AccountSuccessBox .accountRegistration_div {
    justify-content: space-between;
    display: flex;
}

.AccountSuccessBox .accountRegistration_helpIcon {
    color: #f57b42;
    padding: 0px;
}

.AccountSuccessBox .logoImage {
    width: 151px;
    position: relative;
    object-fit: cover;
}

.AccountSuccessBox .typesofAccount_div {
    width: 60%;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    text-align: center;
}

.AccountSuccessBox .typesofAccount_textLabel {
    font-size: 28px;
    font-weight: bold;
    color: #347503;
}

.AccountSuccessBox .requestTest {
    margin-top: 20px;
    font-size: 34px;
    font-weight: bold;
    color: "#000";
}

.AccountSuccessBox .accountRegistration_footer {
    justify-content: space-between;
    display: flex;
}

.AccountSuccessBox .Follow_the_instructions {
    margin: auto;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8c8aad;
    margin-bottom: 30px;
}

.AccountSuccessBox .textfield_div {
    margin: auto;
    justify-content: center;
    margin-bottom: 20px;
}