.EmailVerificationParentBox {
    flex-grow: 1;
    height: 100%;
}

.EmailVerificationParentBox .loginImageDiv {
    position: relative;
}

.EmailVerificationParentBox .halfcircleImage {
    width: 100%;
    height: 100%;
    background-color: rgba(142, 123, 253, 1);
    position: absolute;
    z-index: 0;
    object-fit: cover;
}

.EmailVerificationParentBox .logoImage {
    width: auto;
    height: 68px;
    position: relative;
    margin: 33px 29px 29px 30px;
    object-fit: cover;
}

.EmailVerificationParentBox .textlabel_quoteText {
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    position: relative;
    font-size: 4.5rem;
    margin: 25px 34px;
}

.EmailVerificationParentBox .image_studentillustratorImage {
    width: 56%;
    height: auto;
    bottom: 0%;
    position: absolute;
    transform: translateX(105%);
}

.EmailVerificationParentBox .image_cloudsmallImage {
    width: 65px;
    height: 51px;
    position: absolute;
    top: 61%;
    left: 30%;
}

.EmailVerificationParentBox .image_cloudbigImage {
    width: 151px;
    height: 117px;
    bottom: 10%;
    left: 8%;
    position: absolute;
}

.EmailVerificationParentBox .loginDiv {
    align-content: center;
    width: 100%;
    justify-content: center;
    margin: auto;
}

.EmailVerificationParentBox .sucessLogoDiv {
    width: 74px;
    height: 74px;
    object-fit: contain;
}

.EmailVerificationParentBox .errorLogoDiv {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.EmailVerificationParentBox .textlabel_welcomeText {
    font-size: 35px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 40px;
    color: #253873;
    text-align: center;
}

.EmailVerificationParentBox .textlabel_followinstructionText {
    color: #3f526d;
    font-size: 22px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.FullVhHeight {
    height: 100vh !important;
}

.FullHeight {
    height: 100% !important;
}

.ErrorBox {
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 25px !important;
}