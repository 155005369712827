.studentPart .mainBox {
  background-color: white;
  border-radius: 8px;
  margin: 19px 0 25px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  padding: 25px 20px 25px 20px;
}

.studentPart .searchBox {
  border-radius: 8px;
  padding: 10px 12px;
  width: 40%;
}

.studentPart .moreInfoText {
  color: #471c51;
  font-weight: 600;
  text-transform: capitalize;
}

.studentPart .subjectBox {
  display: flex;
  padding: 15px 50px 15px 50px;
  color: #fff;
  margin: 20px 0 10px;
  border-radius: 6px;
}

.studentPart .subjectBoxTitle {
  width: 49.5%;
  color: #fff;
  font-family: 'Open sans';
  font-size: 20px;
  font-weight: 600;
}

.studentPart .studentSubjouter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;  
}

.studentPart .iconsOuter {
  display: flex;
  justify-content: flex-start;
}

.studentPart .ListIcon {
  cursor: pointer;
  padding: 5px;
  font-size: 40px;
  border-radius: 6px;
}

.studentPart .borderIcon {
  cursor: pointer;
  font-size: 40px;
  padding: 5px;
  border-radius: 6px;
  margin: 0 0 0 12px;
}

.studentPart .iconsInner {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.studentPart .moreInfoButton {
    cursor: pointer;
    padding: 0;
    margin-left: 21px;
    background-origin: border-box;
    background-clip: content-box border-box;
}

.studentPart .studentSubjinner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.studentPart .classesButtonText {
  text-transform: none;
  font-weight: 600;
  font-size: 1.25rem;
  font-family: "Open sans";
  margin: 18px 0px 11px 0; 
  color: #3f526d;
}

.studentPart .subjectContentBox {
  border-radius: 8px;
  width: 100%;
  display: flex;
  margin: 20px 0 0 0;
}

.studentPart .borderRadius8 {
  border-radius: 8px;
}

.studentPart .subjectDataBox {
  display: flex;
  background-color: #f9f8fd;
  border-radius: 6px;
  margin-top: 10px;
  height: 60px;
}

.studentPart .subjectIcon {
  margin: 0 0 0 50px;
  width: 40px;
  height: 40px;
}

.studentPart .Search-Subject {
  border: none;
  outline: none;
  color: #484848;
  opacity: 0.45;
  font-size: 1.125rem;
  width: 100%;
}

.studentPart .subjectSubjectimg {
  display: flex;
  width: 50%;
  align-items: center;
}

.studentPart .subjectImg {
  height: 48px;
  width: 48px;
  margin: 0 13px 0 0;
}

.studentPart .studentAvatar {
  display: flex;
  justify-content: center;
  margin: 0 0 0 12px;
}

.studentPart .noRecordfound {
  margin: 0 0 27px 10px;
  font-size: 1.125rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f526d;
}

.studentPart .subjectGridouterbox {
  display: flex;
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
}

.studentPart .subjectGridouter {
  display: flex;
  flex-direction: column;
}

.studentPart .searchIcon {
  height: 25px;
  width: 25px;
}

.studentPart .subjecBox {
  background-color: #fff;
  border-radius: 6px;
  padding: 25px 27px 32px 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.studentPart .subjectImgBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  padding-bottom: 12px;
}

.studentPart .subjectTitle {
  font-size: 1.125rem;
  font-weight: 600;
  color: #3f526d;
  text-transform: capitalize;
}

.studentPart .questionList {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
  align-items: center;
}

.studentPart .questionList .questionListIcon {
  margin: 0px 12px 0px 0px;
  width: 32px;
  height: 32px;
} 

.studentPart .questionListName {
  font-size: 1rem;
  font-family: "open sans";
  font-weight: normal;
  color: #3f526d; 
}

.studentPart .subjTilte {
  font-size: 1rem;
  font-family: "Open sans";
  font-weight: 600;
  color: #3f526d;
  margin: 22px 0px 0px 0px;
}

.studentPart .subjectName {
  font-size: 20px;
  font-family: 'Open sans';
  font-weight: 500;
  color: #3f526d;
  margin: 7px 0px 6px 30px;
  text-transform: 'capitalize';
}

.studentPart .subjectDataBox .teacherIcon {
  margin: 12px 0px 0px 0px;
  width: 34px;
  height: 34px;
}

.studentPart .teacherName {
  width: 80%;
  font-size: 16px;
  font-family: 'Open sans';
  font-weight: 600;
  color: #3f526d;
  margin: 12px 0px 6px 22px;
  padding: 5px 0px 10px 0px;
  white-space: nowrap;
}

@media screen and (max-width: 1279px) {
  .studentPart .subjectBoxTitle {
    font-size: 18px;
  }

  .studentPart .subjectName {
    font-size: 18px;
    margin: 7px 0px 5px 20px;
  }
}

@media screen and (max-width: 959px) {
  .studentPart .subjectBoxTitle {
    font-size: 17px;
  }

  .studentPart .subjectIcon {
    margin: 0 0 0 12px;
    width: 24px;
    height: 24px;
  }

  .studentPart .subjectName {
    font-size: 17px;
    margin: 6px 0px 4px 16px;
  }

  .studentPart .teacherIcon {
    margin: 16px 0 0 18px;
    width: 28px;
    height: 28px;
  }

  .studentPart .teacherName {
    font-size: 17px;
    margin: 12px 0px 6px 16px;
  }

  .studentPart .searchBox {
    width: 60%;
  }

  .studentPart .subjectBox {
    padding: 15px 20px 15px 15px;
  }
}

@media screen and (max-width: 767px) {
  .studentPart .subjectBoxTitle {
    white-space: nowrap;
    margin-right: 20px;
    font-size: 15px;
  }

  .studentPart .subjectIcon {
    margin: 0 0 0 12px;
    width: 18px;
    height: 18px;
  }

  .studentPart .subjectName {
    font-size: 15px;
    margin: 5px 0px 3px 12px;
  }

  .studentPart .teacherIcon {
    margin: 16px 0 0 16px;
    width: 24px;
    height: 24px;
  }

  .studentPart .teacherName {
    font-size: 14px;
    margin: 14px 0px 2px 9px;
    padding: 0;
  }

  .studentPart .subjectBoxTitle {
    width: 100%;
  }

  .studentPart .studentSubjects-button_color-47 {
    color: white;
    min-width: 100%;
    background-color: #a439cb;
  }

  .studentPart .subjectDataBox {
    height: 50px;
  }

  .studentPart .subjectBox {
    padding: 10px 2px 10px 15px;
  }

  .studentPart .mainBox {
    padding: 12px 10px 12px 11px;
  }

  .studentPart .searchBox {
    width: 100%;
  }

  .studentPart .Search-Subject {
    padding-left: 15px;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
  }
  
  .studentPart .subjectTitle {
    font-size: 16px;
  }
  .studentPart .subjectImg {
    width: 32px;
    height: auto;
  }
  .studentPart .subjecBox {
    padding: 15px;
  }
  .studentPart .subjecBox p {
    font-size: 15px;
  }
  .studentPart .subjTilte p {
    margin-top: 10px;
  }
}
