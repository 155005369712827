/* input:-internal-autofill-selected {
    background-color: transparent !important;
} */

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.input_props input:-webkit-autofill,
.input_props input:-webkit-autofill:hover,
.input_props input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #f1f1f1 inset !important;
}

.learn_together_text {
  font-size: 122px !important;
  line-height: 139px;
}

.h2-title {
  margin: 40px auto;
  font-size: 14px;
  max-width: 135px;
}

.textlabel_loginText {
  background: -webkit-linear-gradient(left, #776be8 0%, #aca2ff 99%);
  height: 76px;
  border-radius: 8.8px !important;
  font-size: 19.8px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  width: 100%;
  text-transform: none !important;
}

.login_forrmInput_textfield {
  height: 76px !important;
  border-radius: 8.8px !important;
  background-color: rgb(241, 241, 241) !important;

}

.input_props .MuiInputBase-input {
  font-size: 15.8px !important;
  color: #03014c !important;
  font-family: 'Open sans' !important;
  padding-left: 15px !important;
  background-color: rgb(241, 241, 241) !important;
}

.input_start_adornment_icon {
  color: #7c7ba0;
  padding: 15px;
  margin-right: 0px;
  font-size: 22px !important;
}

.social_media_div {
  width: 95%;
  border: 1px solid lightgray;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Open sans';
  color: rgb(0, 0, 0);
  padding: 10px 0px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.social_media_img {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 15%;
  font-size: 12px;
}

.main_right_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 50px 0 16%;
}

.EmailLoginRoot {
  flex-grow: 1;
  height: 100%;
}

.EmailLoginH100 {
  height: 100%;
}

.EmailLoginLoginImageDiv {
  position: relative;
}

.EmailLoginHalfCircleImage {
  width: 100%;
  height: 100%;
  background-color: rgba(142, 123, 253, 1);
  position: absolute;
  z-index: 0;
  object-fit: cover
}

.EmailLoginImageStudenIllustratorImage {
  width: 56%;
  height: auto;
  bottom: 0%;
  position: absolute;
  transform: translateX(105%)
}

.EmailLoginLogoImage {
  width: auto;
  height: 68px;
  position: relative;
  margin: 33px 29px 29px 30px;
}

.EmailLoginTextlabelQuoteText {
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  position: relative;
  font-family: 'Open sans';
  font-size: 4.5rem;
  margin: 25px 34px;
}

.EmailLoginImageCloudSmallImage {
  width: 65px;
  height: 51px;
  position: absolute;
  top: 61%;
  left: 30%;
}

.EmailLoginImageCloudBigImage {
  width: 151px;
  height: 117px;
  bottom: 10%;
  left: 8%;
  position: absolute;
}

.EmailLoginLoginDiv {
  justify-content: center;
  width: 60%;
  margin: 5% 0px 4% 0px !important;
}

.EmailLoginAttrbutedTextlabelDiv {
  text-align: right;
  font-family: 'Open sans';
  font-size: 16px;
  margin: 5% 0px 4% 0px !important;
}

.EmailLoginAttrbutedTextlabelSigninText {
  color: #03014c
}

.EmailLoginAttrbutedTextlabelSigninText2 {
  font-weight: bold ;
  margin-left: 10px !important;
  font-size: 16px;
  color: #000000 !important;
}

.EmailLoginTextlabelWelcomeText {
  font-size: 35.1px;
  font-weight: bold;
  margin: 4% 0px 3% 0px;
  font-family: 'Open sans';
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 25px;
}

.EmailLoginTextlabelFollowInstructionText {
  color: #03014c;
  font-size: 14px;
  font-family: 'Open sans';
  line-height: 1.5;
  opacity: 0.5;
  width: 90%;
  padding: 0 41.5px 0 0px;
  margin: 19.3px 0px 29.1px 0px;
}

.EmailLoginTextField {
  width: 100%
}

.EmailLoginForgotPasswordText {
  text-align: right;
  font-weight: bold;
  font-family: 'Open sans';
  cursor: pointer;
  margin: 20px 0 30px 0;
}

.EmailLoginOrTextLine {
  text-align: center;
  font-family: 'Open sans';
  border-bottom: 1px solid rgba(183, 183, 204, 1);
  line-height: 0.1em;
  font-size: 14px;
}

.EmailLoginOrTextLabel {
  background: white;
  padding: 0 10px;
  color: rgba(183, 183, 204, 1);
}

.EmailLoginSocialMediaDiv {
  margin-bottom: 14px;
  margin-top: 20px;
  justify-content: space-around;
}

.EmailLoginMainDiv {
  height: 100vh;
}

.EmailLoginMT20 {
  margin-top: 20px;
}

.EmailLoginSocialMedia {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1366px) {
  .learn_together_text {
    font-size: 108px !important;
    line-height: 130px;
  }
}

@media (max-width: 1279px) {
  .main_right_div {
    padding: 0 50px 0 50px;
  }

  .learn_together_text {
    font-size: 50px !important;
    margin: 2px 34px 140px !important;
  }

  .student_illustrator_img {
    width: 27% !important;
    right: 0% !important;
    transform: translateX(0%) !important;
  }

  .attributed_textlabel {
    margin-top: 0 !important;
  }

  .welcome_text {
    margin-top: 0 !important;
  }

  .login_div_otp {
    margin: 5% 0px 4% 0px !important;
  }
}

@media (max-width: 959px) {
  .learn_together_text {
    font-size: 42px !important;
    margin-top: 0 !important;
    margin-bottom: 85px !important;
    line-height: initial;
  }

  .student_illustrator_img {
    width: 32% !important;
  }

  .cloud_big_img {
    width: 100px !important;
    height: 70px !important;
    bottom: 2% !important;
  }

  .cloud_small_img {
    width: 45px !important;
    height: 35px !important;
    bottom: 10% !important;
    top: auto !important;
  }
}

@media (max-width: 767px) {
  .learn_together_text {
    font-size: 37px !important;
    max-width: 215px !important;
    margin-bottom: 75px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    line-height: 40px;
  }

  .main_right_div {
    padding: 0 15px;
  }

  .login_div {
    width: 90% !important;
  }

  .h2-title {
    margin: 30px auto;
  }

  .login_div_otp {
    width: 90% !important;
  }

  .welcome_text {
    font-size: 25px !important;
    padding-top: 0px !important;
  }

  .cloud_big_img {
    width: 70px !important;
    height: 50px !important;
    bottom: 1% !important;
  }

  .cloud_small_img {
    left: 40% !important;
  }

  .student_illustrator_img {
    width: 35% !important;
    bottom: 5% !important;
  }

  .social_media_img {
    height: 22px !important;
    width: 22px !important;
  }

  .social_media_div {
    width: 95% !important;
    font-size: 12px !important;
    height: 30px;
  }

  .textlabel_loginText {
    height: 55px;
    font-size: 16px !important;
  }

  .login_forrmInput_textfield {
    height: 55px;
  }

  .input_start_adornment_icon {
    padding-left: 0px;
  }

  .SchholLogo {
    width: 180px !important;
    height: auto !important;
    margin: 20px !important;
  }

  .attributed_textlabel {
    font-size: 14px !important;
  }

  .attributed_textlabel .MuiTypography-colorPrimary {
    font-size: 14px !important;
  }

  .Sub-h {
    font-size: 13px !important;
    margin: 10px 0px 20px !important;
  }

  .input_props .MuiInputBase-input {
    padding-left: 0 !important;
    font-size: 14px !important;
    background-color: rgb(241, 241, 241) !important;
  }
}