.deleteTaskModal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .deleteTaskModal .deleteMes1 {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 31px 32px 6px 33px;
  }
  
  .deleteTaskModal .deleteDiv {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 14px;
  }
  
  .deleteTaskModal .deleteModalBox {
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 29px;
    padding: 32px 18px;
    width: 576px;
  }
  
  .deleteTaskModal .borderNew {
    height: 1px;
    margin: 14px 0 31px 0;
  }
  
  .deleteTaskModal .deleteGrid {
    display: flex;
    justify-content: center;
  }
  
  .deleteTaskModal .deleteMes2 {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 6px 53px 40px 54px;
  }
  
  .deleteTaskModal .cancelButton {
    margin: 0 20px 0 0;
    padding: 9px 20px 12px 22px;
    border-radius: 3px;
  }
  
  .deleteTaskModal .deleteButton {
    margin: 0 0px 0 20px;
    padding: 9px 20px 12px 22px;
    border-radius: 3px;
  }
  
  .deleteTaskModal .cancelText, .deleteTaskModal .deleteText {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
  }