.inputDueDateError>input {
    width: 100%;
    padding: 16px 7px;
    position: relative;
    font-size: 18px;
    opacity: 1;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid red;
    color: #000;
    font-family: 'Open sans';
}

.inputDueDate>input {
    width: 100%;
    padding: 16px 7px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    position: relative;
    color: #000;
    opacity: 1;
    font-family: 'Open sans';
}

.online_lecture_modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.online_lecture_modal .create_event_popup {
    background: #fff;
    border: 1px solid #979797;
    outline: none;
    border-radius: 29px;
    width: 85%;
    height: 85%;
    padding: 30px 40px;
    box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.2);
}

.h_100 {
    height: 100% !important;
}

.w_100 {
    width: 100% !important;
}

.online_lecture_modal .online_lecture_modal_container {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    border-bottom: 0.3px solid #979797 !important;
}

.online_lecture_modal .online_lecture_title_box {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    margin: 0 0px 26px 1px !important;
    width: 95% !important;
}

.online_lecture_modal .online_lecture_title_typo {
    text-transform: none !important;
    font-size: 24px !important;
    font-weight: bold !important;
}

.online_lecture_modal .modal_close_icon {
    cursor: pointer !important;
    font-size: 38px !important;
    color: lightGray !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
}

.online_lecture_modal .form_container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - 190px);
    margin-top: 10px;
    padding-right: 10px;
}

.online_lecture_modal .center_box_items {
    align-items: center;
    justify-content: center;
    text-align: start;
}

.online_lecture_modal .form_group_label {
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0px 3px 0;
}

.online_lecture_modal .form_group {
    margin: 0px 0px 16px 0px;
}

.online_lecture_modal .textbox {
    width: 100%;
    background-color: #fff;
    border-radius: 8.8px;
    border: none;
}

.online_lecture_modal .error_msg {
    color: red;
    margin: 3px 14px 0;
}

.online_lecture_modal .native_select {
    width: 100%;
    text-transform: capitalize;
}

.online_lecture_modal .options {
    text-transform: capitalize;
}

.online_lecture_modal .textbox_disabled {
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 8.8px;
    border: none;
}

.online_lecture_modal .radiogroup {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.online_lecture_modal .radio_group_label {
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
}

.mr_10 {
    margin-right: 10px;
}

.online_lecture_modal .margin_box {
    margin: 26px 12px 0px 0px;
}

.online_lecture_modal .description_field {
    width: 100%;
    margin: 5px 35px 16px 1px;
    background-color: #fff;
    border-radius: 8.8px;
    border: none;
}

.online_lecture_modal .button_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    border-radius: 12px;
    padding: 17px 0px 0 0px;
}

.online_lecture_modal .add_btn {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    box-shadow: none;
    padding: 13px 100px;
    text-transform: capitalize;
}