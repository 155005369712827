.testHeader_mainContainer {
    background-color: white;
    position: fixed;
    z-index: 999;
    padding: 26px 20px 0 20px !important
}

.testHeader_mainContainer_SubDiv {
    padding: 0px 0px 17px 0px !important
}

.testHeader_examTitle_Div {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.testHeader_examTitle_img {
    width: 52px !important;
    height: 52px !important
}

.testHeader_examTitle_titleName {
    text-transform: none;
    font-size: 22px !important;
    font-weight: 600 !important;
    margin-left: 20px
}

.testHeader_exam_Div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.testHeader_examClock_img {
    width: 28px;
    height: 28px;
}

.testHeader_examClock_title {
    text-transform: none;
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-left: 16px;
    margin-right: 4px;
}

.testHeader_examClock_subTitle {
    text-transform: none;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.testHeader_divider {
    width: 100%
}