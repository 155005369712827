.StudentTimeTableMainTab {
    margin-top: 20px;
}

.StudentTimeTableStartMeetingMainGrid {
    width: 100%;
    background-color: white;
    border-radius: 8;
    margin: 19px 2px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    padding: 25px 20px 60px;
    min-height: 55vh;
    position: relative;
    flex-direction: row;
}

.StudentTimeTableRowDirection {
    flex-direction: row;
}

.StudentTimeTableFlexContainer {
    display: flex;
}

.StudentTimeTableCalendarDiv {
    align-items: center;
}

.StudentTimeTableCalendarBtn {
    width: 113px;
    margin-right: 10px;
    border-radius: 6;
    margin-left: 50x;
    text-transform: capitalize;
    font-size: 18;
    font-weight: 600;
    text-align: center;
}

.StudentTimeTableDailyBtn {
    margin-left: 50x;
}

.StudentTimeTableCalendarDiv {
    margin-top: 0.6% !important;
    width: 100% !important;
    overflow: auto !important
}


.StudentTimeTableAttandanceToday {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.StudentTimeTableBtn {
    font-size: 20px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.StudentTimeTableDailyBtnDiv {
    width: 113px;
    margin-right: 10px !important;
    border-radius: 6px !important;
    margin-left: 50px !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-align: center;
}

.StudentTimeTableWeeklyBtnDiv {
    width: 113px;
    margin-right: 10px !important;
    border-radius: 6px !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-align: center;
}