.BackBtnDiv{
    margin: 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.BackBtn{
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}


.BackBtnDiv .BackBtnText{
    margin-left: 5px;
    font-size: 16px;
    font-weight: 600;
}

.BackBtnDiv .BackBtnHeadingText{
    margin-left: 20px;
    font-size: 20px;
    font-weight: 600;
}

.TabBox{
    height: 72px;
    margin: 0px 0px 30px 0px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
}

.Width100{
    width: 100%;
}


.TabBox .LibraryTab {
    height: 72px;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
}

.LibraryTabDetail {
    padding: 10px 30px 30px 30px;
    height: 100%;
    min-height: 420px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
}