.BackButtonDiv {
    margin: 20px 10px;
    display: flex;
    align-items: center;
}

.BackButtonDiv .BackIcon {
    height: 14px;
    width: 18px;
    object-fit: contain;
    margin-right: 10px;
    cursor: pointer;
}

.BackButtonDiv .BackText {
    font-size: 20px;
    font-weight: 600;
}

.ContentDiv {
    padding: 20px 24px 30px 24px;
    height: 100%;
    margin: 20px 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
}

.ContentDiv .SummaryText {
    font-size: 24px;
    font-weight: 600;
}

.ContentDiv .MV10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.ContentDiv .MV7 {
    margin-top: 7px;
    margin-bottom: 7px;
}

.ContentDiv .MT7 {
    margin-top: 7px;
}

.ContentDiv .MV4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.ContentDiv .MS20 {
    margin-left: 20px;
}
.ContentDiv .MR20 {
    margin-right: 20px;
}

.ContentDiv .AddIcon {
    margin: 0px 0px 0px 20px;
}

.ContentDiv .ColorRed {
    color: red;
}

.ContentDiv .YesText {
    font-size: 15px;
    margin-left: 30px;
}

.ContentDiv .QuestionDescriptionText {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 600;
}

.ContentDiv .FieldTitleBox {
    display: flex;
    margin: 10px 0px 20px;
}

.ContentDiv .Size22 {
    height: 22px;
    width: 22px;
}


.ContentDiv .Error {
    border: solid 0.5px #ff0000;
}

.ContentDiv .ActionButtonBox {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}


.ContentDiv .ActionButton {
    width: 200px;
    height: 60px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-right: 20px;
    text-transform: none;
  }