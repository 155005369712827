.today_button_outer {
  padding: 16px 40px 15px 41px !important;
  /* margin-right:20px !important ; */
  border-radius: 6px;
  max-width: 140px !important;
  width: 100% !important;
}

.input_today_date_css_format2>input {
  width: 237px;
  padding: 16.5px 23px;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

input.input-search-box::placeholder {
  opacity: 0.45;
  color: #484848;
}

.Date_of_due_date_Icon2.input_today_date_css_format {
  outline: none;
}

.Date_of_due_date_Icon2 {
  background-repeat: no-repeat;
  background-image: url("../../assets/duedateCalendar.png");
  background-size: 33px 33px !important;
  background-position: top 11px right 10.3px !important;
}

.outer-liveclass {
  padding: 25px 20px 60px !important;
  height: 100% !important;
}

@media screen and (max-width: 1279px) and (min-width: 960px) {
  .small-calendar1 {
    min-width: 800px !important;
  }

  .input_today_date_css_format2>input {
    width: 175px !important;
    padding: 17px 16px !important;
    font-size: 16px !important;


    border-radius: 7px !important;

  }

  .Date_of_due_date_Icon2 {

    background-size: 29px 29px !important;
    background-position: top 11px right 8.3px !important;
  }


}

@media screen and (max-width: 959px) and (min-width: 768px) {
  .small-calendar1 {
    min-width: 700px !important;
  }

  .today_button_outer {
    padding: 10px 35px 9px 35px !important;
    /* margin-right:10px !important ; */
    border-radius: 3px !important;

  }

  .input_today_date_css_format2>input {
    width: 160px !important;
    padding: 15.5px 14px !important;
    font-size: 15px !important;


    border-radius: 6px !important;

  }

  .Date_of_due_date_Icon2 {

    background-size: 26px 26px !important;
    background-position: top 12px right 6.7px !important;
  }

}

@media screen and (max-width: 767px) {
  .today_button_outer {
    padding: 7px 30px 7px 30px !important;
    /* margin-right:10px !important ; */
    border-radius: 3px !important;
    height: 45px !important;

  }

  .today_button_outer span {
    line-height: 28px !important;
    font-size: 16px !important;
  }

  .small-calendar1 {
    min-width: 500px !important;
  }

  .outer-liveclass {
    padding: 15px 8px 50px !important;
    height: 100% !important;
  }

  .input_today_date_css_format2>input {
    width: 140px !important;
    padding: 13px 12px !important;
    font-size: 13px !important;
    border-radius: 4px !important;

  }

  .Date_of_due_date_Icon2 {

    background-size: 24px 24px !important;
    background-position: top 10px right 5.3px !important;
  }

  .rdtPicker {
    margin-left: -15px !important;
    min-width: 225px !important;
  }
}