.uploadclass-popup{
    background-color: #fff !important;
    border:1px solid #979797 !important;
    outline: none !important;
    border-radius: 8px !important;
    width: 50% !important;
    height: auto !important;
    padding: 36px 32px 31px 30px !important;
    box-shadow:0 0 24px 4px rgba(0, 0, 0, 0.2) !important;
}
.upload_class_flex_center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.upload_class_flex_center_row {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: row !important;
}
.upload_class_flex_between {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.upload_class_head_text {
    font-size: 20px !important;
    font-weight: 600 !important;
}
.upload_class_close_icon {
    width: 24px !important;
    height: 24px !important;
    color: #3f526d !important;
    opacity: 0.12 !important;
    cursor: pointer !important;
}
.upload_class_file_container {
    padding: 29px 0px 24px 0px !important;
    border-radius: 8px !important;
    background-color: #f9f8fd !important;
    margin-top: 35px !important;
    align-items: center !important;
    justify-content: center !important;
}
.upload_class_d_none {
    display: none !important;
}
.upload_class_add_file_btn {
    box-shadow: none !important;
    text-transform: none !important;
    font-size: 16px !important;
    padding: 11px 31px !important;
}
.upload_class_heading{
    text-transform: none !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}
.upload_class_typo {
    margin: 21px 0 0 !important;
    font-size: 16px !important;
    text-align: center !important;
}
.upload_class_file_upload_img {
    width: 61px !important;
    height: 63px !important;
}
.upload_class_uploader_box {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    background-color: #fff !important;
    border-radius: 12px !important;
    margin-top: 35px !important;
    padding: 34px 216px 34px 201px !important;
}
.upload_class_text {
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    margin: 10px 24px 0 3px !important;
}
.upload_class_align {
    text-align: start !important;
}
.upload_class_text_field {
    width: 100% !important;
    margin: 5px 35px 16px 1px !important;
    border-radius: 8px !important;
    border: 1px solid #ebebeb !important;
}
.upload_class_update_btn {
    margin: 0 20px 0 0 !important;
    border-radius: 3px !important;
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0.1px !important;
    box-shadow: none !important;
    cursor: pointer !important;
}
.upload_class_btn_container {
    display: flex !important;
    flex-direction: row !important;
    margin-top: 30px !important;
}
.upload_class_upload_btn {
    margin: 0 20px 0 0 !important;
    padding: 12px 30px 12px 31px !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    text-transform: capitalize !important;
}
.upload_class_cancel_btn {
    background-color: #fff !important;
    margin: 0 20px 0 0 !important;
    padding: 12px 33px !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
}