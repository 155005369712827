.MuiButton-root.CreateReportCardTab {
    cursor: pointer;
    padding: 11px 13px;
    min-width: 133px;
    border-radius: 6px;
}

.MuiTypography-root.CreateReportCardSelectedLabel {
    text-transform: none;
    font-size: 15px;
    font-weight: 600;
    color: white;
}

.MuiTypography-root.CreateReportCardLabel {
    text-transform: none;
    font-size: 15px;
    font-weight: 600;
    color: #8F99A5;
}

.CreateReportCardFlexColumn {
    display: flex;
    flex-direction: column;
}

.CreateReportCardSelectClass {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.GradingScaleTableContainer {
    border-radius: 6px;
    border: 0;
}

.MuiTable-root.GradingScaleTable {
    width: calc(100% - 1px);
}

.GradingScaleTableHead {
    background-color: #ded9ff;
}

.MuiTableCell-root.GradingScaleTableTitle {
    padding: 17px 20px;
    font-size: 20px;
    font-weight: bold;
}

.GradingScaleActionHead {
    display: flex;
    justify-content: center;
}

.GradingScaleTableBody {
    margin-top: 10px;
    background-color: #f9f8fd;
}

.MuiTableCell-root.GradingScaleTableData {
    padding: 17px 20px;
    border: 1px solid lightgray;
    font-size: 18px;
    font-weight: 600;
}

.MuiTableCell-root.GradingScaleTableScore {
    padding: 17px 20px;
    border: 1px solid lightgray;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.GradingScaleActionData {
    display: flex;
    justify-content: space-evenly;
}

.MuiIconButton-root.GradingScaleEditDelete {
    padding-top: 0;
    padding-bottom: 0;
}

.GradingScaleDeleteImg {
    height: 28px;
    width: 28px;
    object-fit: contain;
}

.GradingScaleEditImg {
    height: 24px;
    width: 24px;
    object-fit: contain;
}

.MuiTableCell-root.AddGradingScaleCell {
    padding: 15px 20px;
    border: 1px solid lightgray;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.DeleteGradeModal {
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 29px;
    padding: 32px 18px;
    width: 600px;
}

.DeleteGradeModalDivider {
    height: 1px;
    margin: 14px 0 31px 0;
    border: solid 0.3px #979797;
}

.DeleteGradeActionDiv {
    display: flex;
    justify-content: center;
}

.MuiDialog-paper.CreateReportCardModal {
    border-radius: 8px;
}

.MuiSvgIcon-root.ReportGradeCloseIcon {
    cursor: pointer;
    font-size: 38px;
    color: lightGray;
}

.ReportGradeHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ReportGradeContainer {
    padding: 15px;
}

.ReportGradeNameField {
    display: flex;
    flex-direction: column;
}

.ReportGradeLeftField {
    display: flex;
    flex-direction: column;
    margin-top: 2% !important;
}

.ReportGradeRightField {
    display: flex;
    flex-direction: column;
    margin-top: 2% !important;
    align-items: flex-end;
}

.AddAttrModal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.AddAttrModalGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 6px;
    max-width: 480px;
    position: relative;
    padding: 4px;
}

.AddAttrCloseDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.MuiIconButton-root.AddAttrCloseIcon {
    padding: 4px;
}

.MuiGrid-root.AddAttrMainGrid {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.MuiFormHelperText-root.AddAttrError {
    color: red;
}

.MuiButton-root.AddAttributeBtn {
    margin-bottom: 26px;
}

.MuiSelect-root.MuiInputBase-input.CategoryErrorInputProps {
    border-color: 1px solid red;
    text-transform: capitalize;
}

.MuiSelect-root.MuiInputBase-input.CategoryInputProps {
    border-color: #ebebeb;
    text-transform: capitalize;
}

.ModuleBorderBottom {
    border-bottom: 1px solid gray;
}

.ModulesFlexColumn {
    display: flex;
    flex-direction: column;
}

.ModulesCoScholasticDiv {
    margin-top: 48px !important;
}

.ModulesFlex {
    display: flex;
}

.MuiGrid-root.ModulesCoScholasticReport {
    border-radius: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.ModulesCoScholasticField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ModulesSelectAttributes {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.MuiCheckbox-root.ModuleSelectCheckBox {
    color: #3f526d;
}

.ModulesCoScholasticClear {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    align-content: space-around;
}

.ModulesCoScholasticClearIcon {
    height: 48px;
    width: 48px;
}

.ModulesBtnDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.MuiButton-root.ModulesBtnAdd {
    width: auto;
    margin: 0px 16px 0px 0px;
}

.MuiButton-root.ModulesBtnEdit {
    width: auto;
}

.ManageAttributesModal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiGrid-root.ManageAttributesModalGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 6px;
    max-width: 835px;
    position: relative;
    padding: 4px;
    width: auto;
}

.ManageAttributesHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.EditAttributesText {
    padding: 16px 15px 16px 20px;
}

.MuiIconButton-root.EditAttributesClose {
    padding: 16px 15px 16px 20px;
}

.MuiGrid-root.ManageAttributesTableGrid {
    display: flex;
    flex-direction: column;
    margin: 0 32px;
}

.ManageAttributesTableContainer {
    border-radius: 6px;
    border: 0;
    height: 480px;
}

.MuiTable-root.ManageAttributesTable {
    width: 100%;
}

.ManageAttributesTableHead {
    background-color: #ded9ff;
}

.MuiTableCell-root.ManageAttributesLabel {
    padding: 17px 20px;
    font-size: 20px;
    font-weight: bold;
}

.MuiTableCell-root.ManageAttributesAction {
    padding: 17px 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.ManageAttributesTableBody {
    margin-top: 10px;
    background-color: #f9f8fd;
}

.MuiTableCell-root.ManageAttributesCellLabel {
    padding: 12px 19px;
    border: 1px solid lightgray;
    font-size: 18px;
    font-weight: 600;
}

.MuiTableCell-root.ManageAttributesCellAction {
    padding: 12px 19px;
    border: 1px solid lightgray;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.MuiIconButton-root.ManageAttributesActiveDeleteIcon {
    padding: 4px;
    opacity: 1;
}

.MuiIconButton-root.ManageAttributesDisabledDeleteIcon {
    padding: 4px;
    opacity: 0.4;
}

.MuiIconButton-root.ManageAttributesActionEditIcon {
    padding: 4px;
}

.CustomReportCardTitle {
    display: flex;
    flex-direction: column;
}

.FlexRowSpaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CustomReportCardSelect{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ReportCardMargin {
    margin-top: 10px !important;
}

.ReportCardMargin20 {
    margin-top: 20px !important;
}

.MuiGrid-root.ReportCardSelectGradeScale {
    padding-bottom: 20px !important;
}

.ReportCardDisplayFlex {
    display: flex;
}

.MuiTypography-root.FillAssessmentText {
    font-size: 16px;
    font-weight: 600;
}

.ReportAssessmentGrid {
    padding: 8px !important;
}

.MuiButton-root.CustomReportBtn {
    text-transform: capitalize;
}

.ReportAddAssessmentDiv {
    margin-bottom: 10px !important;
}

.MuiButton-root.ReportAddAssessmentBtn {
    width: auto;
}

.CustomReportGradeTableContainer {
    border-radius: 6px;
    border: 0;
}

.MuiTable-root.CustomReportGradeTable {
    width: calc(100% - 1px);
}

.CustomReportGradeTableHead {
    background-color: #ded9ff;
}

.MuiTableCell-root.CustomReportGradeTableHeadCell {
    padding: 17px 20px;
    font-size: 20px;
    font-weight: bold;
}

.CustomReportGradeTableBody {
    margin-top: 10px;
    background-color: #f9f8fd;
}

.MuiTableCell-root.CustomReportGradeTableDataCell {
    padding: 17px 20px;
    border: 1px solid lightgray;
    font-size: 18px;
    font-weight: 600;
}

.MuiTableCell-root.CustomReportGradeTableScoreCell {
    padding: 17px 20px;
    border: 1px solid lightgray;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.MuiSelect-root.MuiInputBase-input.CustomReportCardSelectInput {
    border-color: #ebebeb;
    text-transform: capitalize;
}

@media (max-width: 599px) {
    .DeleteGradeModal {
        width: 95% !important;
    }
}