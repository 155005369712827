.MobileHeader {
  display: flex;
  width: 100%;
  padding: 24px 0 23px 0;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  background: #fff;
  border-radius: 20px 0 0 0;
  margin: 0 14px;
}

.MobileHeader .ProfileBox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: end;
}

.MobileHeader .OnClickHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MobileHeader .DataAvtar {
  cursor: pointer;
  height: 55px;
  width: 55px;
  margin-right: 5px;
  border-radius: 50%;
}

.MobileHeader .AvatarImg {
  cursor: pointer;
  height: 55px;
  width: 55px;
  margin-right: 5px;
  border-radius: 50%;
}

.MobileHeader .LeftBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
}

.MobileHeader .KeyBoardBox {
  width: 22px;
  height: 22px;
  display: flex;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
}

.MobileHeader .WhiteKey {
  height: 22px;
  width: 22px;
  color: #fff;
}

.MobileHeader .TitleUsed {
  color: #000;
  font-family: "Open sans";
  font-size: 20px;
  font-weight: 600;
}

.MobileHeader .HeaderRight {
  display: flex;
  width: 28%;
  align-items: flex-start;
  transition: none;
}

.MobileHeader .ContentSpace {
  justify-content: space-between;
}

.MobileHeader .ContentEnd {
  justify-content: end;
}

.MobileHeader .AvtarIcon {
  width: 25px;
  height: 25px;
  border-radius: 0;
}

.MobileHeader .IconBg {
  background: #fff;
  border-radius: 50%;
}

@media only screen and (max-width: 1200px){
  .MobileHeader .HeaderRight {
    width: 60%;    
  }
}

@media only screen and (max-width: 767px){
  .MobileHeader .HeaderRight {
    width: 85%;    
  }
}
