/* Customizable Area Start */
.card {
  display: flex !important;
  flex-direction: column !important;
  margin: 24px 27px 27px 5px !important;
  padding: 20px !important;
  border-radius: 8px !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.14) !important;
  background-color: #fff !important;
}

.card_title {
  height: 24px;
  margin: 0 40px 12px 0;
  font-size: 18px;
  font-weight: 600;
  color: #f85b62;
}

.card_sub_title {
  height: 40px;
  margin: 12px 13px 26px 0;
  opacity: 0.5;
  font-size: 14px;
  line-height: 1.43;
  color: #3f526d;
}

.card_in_image {
  width: 100% !important;
  height: auto !important;
  margin: 10px 0 0 !important;
}

.pointer_cursor {
  cursor: pointer !important;
}

.icon_button {
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}
.setting_box {
  height: 40px;
  width: 40px;
}

.green_card {
  padding: 30px;
  height: 200px;
  border-radius: 15px;
  margin: 0px 10px;
  background-color: #b0f9e6;
}
@media (max-width: 1200px) {
  .spacing_top_mobile {
    margin: 0px 10px 10px 10px !important;
  }
}

.purple_card {
  padding: 30px;
  height: 200px;
  border-radius: 15px;
  margin: 0px 10px;
  background-color: #cdc3ff;
}

.pink_card {
  padding: 30px;
  height: 200px;
  border-radius: 15px;
  margin: 0px 10px;
  background-color: #ffd5d7;
}

.yellow_card {
  padding: 30px;
  height: 200px;
  border-radius: 15px;
  margin: 0px 10px;
  background-color: #fff3db;
}

.pink_outlined_assignment {
  padding: 5px;
  background-color: #feeeef;
  border-radius: 30%;
  width: 20% !important;
  height: auto !important;
  color: #c0777f;
}

.green_outlined_assignment {
  padding: 5px;
  background-color: #e6fff9;
  border-radius: 30%;
  width: 20% !important;
  height: auto !important;
  color: #6f9d90;
}

.purple_outlined_assignment {
  padding: 5px;
  background-color: #ebe7fe;
  border-radius: 30%;
  width: 20% !important;
  height: auto !important;
  color: #9088c0;
}
.margined_pink_text {
  color: #e79099;
  font-size: 22px !important;
  margin-top: 10px !important;
}
.margined_purple_text {
  color: #89aaf2;
  font-size: 22px !important;
  margin-top: 10px !important;
}

.purple_text {
  color: #89aaf2;
  font-size: 54px !important;
}

.pink_text {
  color: #e79099;
  font-size: 54px !important;
}
.margined_green_text {
  color: #39a78c;
  font-size: 22px !important;
  margin-top: 10px !important;
}
.green_text {
  color: #39a78c;
  font-size: 54px !important;
}

.bold_orange_text {
  color: #fda506;
  font-size: 28px !important;
  font-weight: bolder !important;
}

.orange_outlined_assignment {
  width: 100% !important;
  height: 70px !important;
  color: #fda506;
  border-radius: 15%;
  background-color: #fff8eb;
}

.blue_card {
  padding: 30px;
  height: 200px;
  border-radius: 15px;
  margin: 0px 10px;
  background-color: #cbe3ef;
}

.bold_blue_text {
  color: #46728d;
  font-size: 28px !important;
  font-weight: 500 !important;
}

.blue_outlined_assignment {
  width: 100% !important;
  height: 70px !important;
  color: #46728d;
  background-color: #e3ecf3;
  border-radius: 15%;
}

.green_box {
  background-color: #26c165;
}

.aqua_box {
  background-color: #4fdbb6;
}

.blue_box {
  background-color: #5887a3;
}

.yellow_box {
  background-color: #ffcc70;
}

.red_box {
  background-color: #f24a3d;
}
.margin_left_20 {
  margin-left: 20px !important;
}

.margin_left_10 {
  margin-left: 10px !important;
}

.padding_10 {
  padding: 10px !important;
}

.table_container {
  border-radius: 6px !important;
  border: 0px !important;
  padding: 5px !important;
}
.table_head {
  background-color: #f9f8fd !important;
}

.teacher_reports_dashboard_title {
  font-weight: 600 !important;
  font-size: 1.125rem !important;
  color: #475365 !important;
}

.margin_bottom_20 {
  margin-bottom: 20px !important;
}

.margin_15_0 {
  margin: 15px 0 !important;
}

.color_fff {
  color: #fff !important;
}

.color_f9f8fd {
  color: #f9f8fd !important;
}

.width_100 {
  width: 100% !important;
}
.question_type_grid {
  cursor: pointer !important;
  padding: 10px !important;
  border-radius: 5px !important;
  width: 315px !important;
}

.accordion_details {
  display: block !important;
  background-color: #fff !important;
}

.capitalized_white_text {
  color: #fff !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}

.question_grid {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
  padding: 10px !important;
  border-radius: 5px !important;
}

.table_cell {
  opacity: 0.58 !important;
  font-size: 14px !important;
  border-bottom: 0px !important;
  color: #3f526d !important;
}

.table_body_cell {
  /* padding: 17px 0px 16px 20px !important; */
  font-size: 18px !important;
  text-transform: capitalize !important;
}

.no_data_image {
  width: 500px !important;
  height: auto !important;
  margin-bottom: 25px !important;
}
.no_data_title_big {
  font-weight: 500 !important;
  font-size: 30px !important;
}

.no_data_box {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.performance_select_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.custom_select {
  width: 260px !important;
  height: 44px !important;
}
.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}
.css-13cymwt-control {
  height: 44px !important;
  background-color: #f9f8fd !important;
}
.css-t3ipsp-control:hover {
  border: 0 !important;
  border: 1px solid hsl(0, 0%, 70%) !important;
  border-color: hsl(0, 0%, 70%);
}
.css-t3ipsp-control {
  box-shadow: 0 0 0 0px !important;
  border: 1px solid hsl(0, 0%, 70%) !important;
  border-color: hsl(0, 0%, 70%);
}

.view_scorebox {
  width: 231px !important;
  height: 148px !important;
  margin: 10px 0 !important;
  padding: 13px 140px 13px 20px !important;
}
/* Customizable Area End */
