.questionListsidebar {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding-top: 95px;
}

.arrowBg {
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 5px 0 0 5px;
  display: block;
  padding-top: 3px;
  padding-left: 2px;
  position: absolute;
  left: -5px;
  top: 20px;
  cursor: pointer;
  transform: rotate(180deg);
}

.popupToggle .MuiBackdrop-root {
  display: none;
}

.popupToggle .MuiDrawer-paper {
  top: 92px;
}

.summary-sidebar {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  height: 100%;
  background: #fff;
  width: 250px;
}

.hiddenPanel {
  box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
    0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
  height: 100%;
  width: 50px;
  background: #fff;
  position: fixed;
  right: 0px;
  top: 93px;
}

.hiddenPanel .leftArrow {
  left: 0;
  top: 15px;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 0px 5px 5px 0px;
  display: block;
  padding-left: 3px;
  padding-top: 3px;
  position: absolute;
  cursor: pointer;
}

.summary-sidebar h5 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  padding-left: 35px;
  font-weight: 600;
  padding-top: 10px;
}

.section-list p {
  margin: 15px 0 15px;
  padding: 8px 15px 8px 15px;
  background-color: #eceff6;
  font-family: Helvetica;
  font-size: 14px;
}

.section-list ul {
  padding: 0px 15px 0px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
}

.section-list ul li {
  width: 20%;
  margin-bottom: 10px;
  margin-right: 5px;
}

.section-list ul li span.activeNumber {
  color: #fff;
}

.section-list ul li span {
  border: solid 1px #586799;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 14px;
  color: #2e2c34;
  font-family: Helvetica;
  cursor: pointer;
}
.section_name {
  font-weight: 600 !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
}

.section_number {
  font-weight: 500 !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
}

.all_questions {
  font-weight: 600 !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px !important;
  margin: 0 7px 25px 10px !important;
}
