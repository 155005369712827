.textlabel_evaluation_details {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  padding: 15px 0 !important;
}

.textlabel_students {
  font-size: 26px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  padding: 17px 0 !important;
}

.back_text_evaluation {
  font-size: 22px !important;
  font-weight: 600 !important;
  cursor: pointer;
  padding: 15px 0 10px 0 !important;
}

.back_arrow_img {
  height: 14px;
  width: 18px;
  object-fit: contain;
  margin-right: 10px
}

.evaluation_heading_section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assessment_name_textlabel {
  font-size: 24px !important;
  font-weight: bold !important;
  padding: 15px 10px 0px 0px;
  text-transform: capitalize;
}

.assessment_subject_textlabel {
  font-size: 20px !important;
  font-weight: 600 !important;
  padding: 15px 0 0 0 !important;
  text-transform: capitalize;
}

.student_search_grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.search_input_box {
  border: none;
  border-radius: 8px;
  padding: 13px 20px 11px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  height: 60px;
}

.student_search_icon {
  height: 34.5px !important;
  width: 34.5px !important;
  color: #3b5ab1;
}

.search_field {
  border: none;
  outline: none;
  color: #3f526d;
  opacity: 0.5;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  text-overflow: ellipsis;
}

.selected_list_bullet_icon {
  background-color: #3b5ab1;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  font-size: 55px !important;
  border-radius: 6px;
}

.not_selected_list_bullet_icon {
  background-color: white;
  border: 1px solid #3b5ab1;
  color: #3b5ab1;
  cursor: pointer;
  padding: 5px;
  font-size: 55px !important;
  border-radius: 6px;
}

.selected_all_round_icon {
  background-color: #3b5ab1;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  font-size: 55px !important;
  border-radius: 6px;
  margin: 0px 0px 0px 12px;
}

.not_selected_all_round_icon {
  background-color: white;
  border: 1px solid #3b5ab1;
  color: #3b5ab1;
  cursor: pointer;
  padding: 5px;
  font-size: 55px !important;
  border-radius: 6px;
  margin: 0px 0px 0px 12px;
}

.display_flex_column {
  display: flex;
  flex-direction: column;
}

.student_table_container {
  border-radius: 6px;
  border: 0;
}

.student_list_head {
  background-color: rgba(58, 96, 215, 0.14);
}

.list_student_name {
  padding: 17px 5px 17px 20px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  border-bottom: 0 !important;
}

.list_student_details {
  padding: 17px 5px 17px 5px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  border-bottom: 0 !important;
}

.student_list_table_body {
  margin-top: 10px;
}

.no_student_record {
  padding: 17px 0px 16px 20px !important;
  font-size: 18px !important;
}

.blank_cell_row {
  padding: 4px 0px 4px 0px !important;
  border-bottom: 0 !important;
}

.student_cell_row {
  background-color: rgb(249, 248, 253);
  border-radius: 15%;
}

.cell_student_name {
  padding: 17px 5px 17px 20px !important;
  border-bottom: 0 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.cell_student_details {
  padding: 17px 5px 17px 5px !important;
  border-bottom: 0 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.cell_student_view {
  text-decoration: underline;
  cursor: pointer;
  text-align: center !important;
}

.text_align_center {
  text-align: center !important;
}

.MuiTypography-root.grid_no_student_record {
  font-size: 18px;
  margin-top: 20px;
  margin-left: 20px;
}

.bg_white {
  background-color: white;
}

.overflow_x_auto {
  overflow-x: auto;
}

.opacity_1 {
  opacity: 1;
}

.opacity_light {
  opacity: 0.5;
}

.student_grid_view {
  border-radius: 12px;
  box-shadow: 0 0 6px 0 rgba(52, 73, 140, 0.2);
  background-color: #fff;
  padding: 20px;
  cursor: pointer;
}

.student_grid_view_disabled {
  border-radius: 12px;
  box-shadow: 0 0 6px 0 rgba(52, 73, 140, 0.2);
  background-color: #fff;
  padding: 20px;
  opacity: 0.6;
}

.student_img_section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.student_img {
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
}

.grid_student_name {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}

.grid_student_date {
  font-size: 14px !important;
  font-weight: normal !important;
}

.student_detail_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.student_detail_divider {
  margin: 15px 0 !important;
}

.status_and_marks_section {
  display: flex;
  justify-content: space-between;
}

.grid_student_status {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.status_color {
  font-weight: bold !important;
}

.grid_student_yellow_marks {
  color: #e69416;
  font-size: 14px !important;
  font-weight: bold !important;
}

.grid_student_green_marks {
  color: #0c5c10;
  font-size: 14px !important;
  font-weight: bold !important;
}

.evaluation_details_section {
  padding: 30px 40px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 32px 0 !important;
}

.total_title {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  margin-bottom: 10px !important;
}

.total_detail {
  font-size: 48px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.student_img_details {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50% !important;
}

.grid_student_name_details {
  font-size: 24px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}

.grid_student_date_details {
  font-size: 18px !important;
  font-weight: normal !important;
}

.flex_display {
  display: flex;
}

.evaluation_section_title {
  font-size: 24px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.section_total_marks {
  font-size: 18px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.evaluation_section_arrow {
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
}

.evaluation_section_arrow_rotate {
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
  transform: rotate(180deg);
}

.section_rectangle_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit_evaluation_btn {
  width: 123px;
  height: 48px;
  border-radius: 8px !important;
  /* background-color: #253873 !important; */
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center;
  /* color: #ffffff !important; */
  margin-top: 20px !important;
  text-transform: capitalize !important;
  color:#fff !important;
}

.save_evaluation_btn {
  width: 123px;
  height: 48px;
  border-radius: 8px !important;
  /* border: solid 1px #253873 !important;
  background-color: #fff !important; */
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center;
  /* color: #253873 !important; */
  margin-top: 20px !important;
  text-transform: capitalize !important;
  margin-right: 20px !important;
}

.submit_text {
  font-family: "Open sans";
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3f526d;
  margin: 31px 0px 6px 0px;
}

.submit_btn_section {
  display: flex;
  justify-content: center;
  margin-top: 55px;
  margin-bottom: 8px;
}

.evaluation_accordion.MuiAccordion-root:before {
  display: none;
}

.evaluation_accordion.MuiAccordion-root {
  margin-bottom: 32px;
  border-radius: 12px;
  box-shadow: 0 2px 12px 0 rgba(224, 224, 224, 0.2);
  border: solid 1px #e5ebf4;
  background-color: #fff;
}

.evaluation_accordion .MuiAccordionSummary-root {
  padding: 18px 40px !important;
}

.section_question_title {
  font-size: 18px !important;
  font-weight: normal !important;
  display: flex;
}

.correct_answer_section {
  margin: 20px 0 32px 0;
  padding: 15px 72px;
  border-radius: 6px;
  background-color: #eef0f8;
  width: 100%;
}

.correct_answer_left {
  display: flex;
  align-items: center;
}

.score_section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.score_text {
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center;
}

.not_attemped_text {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center;
  margin-right: 10px !important;
}

.correct_answer_text {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.correct_answer_rectangle {
  width: 50px;
  height: 50px;
  margin: 0 10px 0 16px;
  padding: 12px;
  border-radius: 6px;
  border: solid 1px #c1c8e4;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.total_marks_text {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right;
  color: #84818a;
}

.add_comment_div {
  width: 199px;
  height: 44px;
  margin: 20px 0px 28px 0;
  border-radius: 5px;
  background-color: #eef0f8;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.add_comment_div_disabled {
  width: 199px;
  height: 44px;
  margin: 20px 0px 28px 0;
  border-radius: 5px;
  background-color: #eef0f8;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0.5;
}

.add_comment_text {
  font-size: 18px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #2e499d;
}

.evaluation_question_detail_section {
  display: flex !important;
  flex-direction: column;
  padding: 0 40px !important;
}

.evaluation_question_divider {
  margin-bottom: 32px !important;
}

.mcq_option_rectangle {
  width: 60px;
  height: 60px;
  margin: 0 14px 0 0;
  /* opacity: 0.33; */
  border-radius: 6px;
  border: solid 1px #979797;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.obj_answer_div {
  display: flex;
  align-items: center;
}

.obj_answer_common {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.objective_attachment {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.attemped_answer_rectangle {
  width: 60px;
  height: 60px;
  margin: 0 14px 0 0;
  /* opacity: 0.33; */
  border-radius: 6px;
  border: solid 1px #3a60d7;
  background-color: #3a60d7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mcq_option_title {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #585858;
}

.attemped_answer_title {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #ffffff;
}

.mcq_option_text {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.one_word_main_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px !important;
  height: 60px;
  padding-left: 16px !important;
}

.correct_answer_div {
  padding: 0 22px 0 0;
  border-radius: 6px;
  background-color: #def4df;
}

.answer_div {
  padding: 0 22px 0 0;
}

.wrong_answer_div {
  padding: 0 22px 0 0;
  border-radius: 6px;
  background-color: #f7dee5;
}

.display_flex {
  display: flex;
}

.subjective_image {
  object-fit: contain;
  height: 40px;
}

.long_answer_rectangle {
  margin: 16px 0 0 0;
  padding: 16px;
  border-radius: 6px;
  border: solid 1px #c1c8e4;
  background-color: #fcfcfc;
  width: 100%;
}

.ans_text {
  font-size: 18px !important;
  font-weight: normal !important;
}

.long_answer_text {
  font-size: 16px !important;
  font-weight: normal !important;
  padding-bottom: 20px;
}

.view_attach_file_box {
  width: 240px;
  margin: 30px 10px 0 0;
  padding: 7.5px 15px;
  border-radius: 8px;
  border: solid 1px #c1c8e4;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.textlabel_attachfile {
  margin: 0 16px 0 0 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.long_ans_add_comment_div {
  margin: 24px 0 40px 0;
  padding: 26px 40px 32px;
  border-radius: 6px;
  background-color: #eef0f8;
  width: 100%;
}

.text_editor_div {
  margin: 16px 0 0px 0;
}

.question_image_section {
  padding: 20px 40px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background-color: #fff;
  height: 300px;
  position: relative;
  margin-bottom: 10px;
}

.zoom_icon_div {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background-color: #3f526d;
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.zoom_icon {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.done_comment_div {
  display: flex;
  justify-content: flex-end;
}

.done_comment_btn {
  width: 123px;
  height: 48px;
  border-radius: 8px !important;
  /* background-color: #253873 !important; */
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center;
  /* color: #ffffff !important; */
  text-transform: capitalize !important;
}

.option_img_data {
  height: 50px;
  width: 70px;
  cursor: pointer;
  object-fit: contain;
}

.publish_evaluation_btn {
  width: 123px;
  height: 48px;
  border-radius: 8px !important;
  /* background-color: #253873 !important; */
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center;
  /* color: #ffffff !important; */
  text-transform: capitalize !important;
  margin-top: -5.5% !important;
}

.publish_evaluation_disable_btn {
  width: 123px;
  height: 48px;
  border-radius: 8px !important;
  background-color: #ebeaed !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center;
  color: #b4b4b4 !important;
  text-transform: capitalize !important;
  margin-top: -5.5% !important;
}

.annotation_modal {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  padding: 60px 20px;
  width: 60%;
}

.custom_markerjs2_ {
  justify-content: center;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 70% !important;
  z-index: 1300 !important;
  max-height: 90%;
}

.custom_markerjs2_>div>div:nth-child(2)>div>div:last-child {
  display: none !important;
}

.custom_markerjs2_toolbar {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 1) 100%);
  height: 50px !important;
  align-items: center;
}

.custom_markerjs2_toolbox-button-row {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
}

.custom_markerjs2_toolbar_button_colors {
  fill: black !important;
  background-color: white;
}

.custom_markerjs2_toolbar_button {
  padding: 7px !important;
  margin: 5px !important;
}

.custom_markerjs2_toolbox-button_colors {
  fill: black !important;
  background-color: white;
}

.custom_markerjs2_toolbox_button {
  padding: 7px !important;
  margin: 5px !important;
}

.raise_query_btn {
  width: 174px;
  height: 48px;
  border-radius: 8px !important;
  /* border: solid 1px #af38cb !important;
  background-color: #fff !important; */
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center;
  /* color: #af38cb !important; */
  text-transform: capitalize !important;
  margin: 20px 0 !important;
}

.textlabel_teachercomment {
  margin-left: 5px;
}

.title_teachercomment {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: rgba(63, 82, 109, 0.5);
  margin-top: 10px !important;
  display: flex;
}

.textlabel_answersheet {
  font-size: 24px !important;
  font-weight: 600 !important;
  padding: 15px 0 0 15px;
}

.right-check-icon {
  margin-left: 10px;
  object-fit: contain;
  height: 16px;
}

.close-red-icon {
  margin-left: 10px;
  object-fit: contain;
  height: 25px;
}

.sorting-attempted-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0px 0px 20px 0px;
  justify-content: center;
}

.sorting-field {
  width: 40%;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sorting-border-field {
  width: 40%;
  border: 1px solid gray;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sorting-correct-block {
  border: 1px solid gray;
  border-radius: 4px;
  margin: 15px 0;
  background-color: white;
  padding: 30px 20px 10px 20px;
}

.absolute-icon {
  position: absolute;
  right: 15px;
}

.pt-1 {
  padding-top: 1px !important;
}

.graph-plotting-field {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 15px;
}

.graph-plotting-field-div {
  width: 60%;
}

.graph-field-label {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.graph-categories-section {
  margin-top: 20px !important;
}

.graph-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.graph-width {
  width: 80%;
  position: relative;
}

.graph-no-data-found-text {
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  bottom: 50%;
  left: 45%;
  background-color: white;
}

.graph-student-answer-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.graph-student-answer-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px 0px 20px 0px;
}

.correct-ans-bg {
  background-color: #def4df;
}

.in-correct-ans-bg {
  background-color: #f7dee5;
}

.video-list-grid {
  display: flex;
  flex-direction: row;
  margin: 30px 0 !important;
  position: relative;
}

.video-list-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video-download-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 32px;
}

.rating-scale-stepper {
  display: flex;
  align-items: center;
}

.label-image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  justify-content: space-evenly;
  position: relative;
  margin-bottom: 20px;
}

.label-bg-image {
  height: 500px;
  width: 500px;
  object-fit: contain;
}

.relative-position {
  position: relative;
}

.label-image-answer {
  position: absolute;
  width: 200px;
  height: 52px;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.label-image-answer-text {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.label-image-correct-ans {
  position: absolute;
  width: 200px;
  height: 52px;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 1px solid gray;
}

.match-question-text {
  font-size: 20px !important;
  font-weight: 600 !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.match-main-div {
  display: flex;
  flex-direction: column;
}

.match-question {
  border: 1px solid #3f526d;
  padding: 10px 20px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
}

.match-correct-answer {
  touch-action: none;
  padding: 10px 20px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #def4df;
}

.match-wrong-answer {
  touch-action: none;
  padding: 10px 20px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f7dee5;
}

.match-teacher-answer {
  touch-action: none;
  padding: 10px 20px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #3f526d;
}

.match-img-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.match-item-container {
  margin-bottom: 20px;
}

.match-answer-image {
  height: 50px;
  width: 60px;
  cursor: pointer;
  object-fit: contain;
  margin-right: 20px;
}

.evaluation-blob-img-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.evaluation-blob-img {
  height: 500px;
  width: 500px;
  object-fit: contain;
}

.flash-card {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e1e1e1;
  background-color: #fff;
  min-height: 470px;
  min-width: 385px;
  position: relative;
}

.flash-card-image-block {
  height: 240px;
  margin: 0 0 20px;
  border-radius: 20px;
  background-color: #ebeef8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.flash-card-image {
  height: 90%;
  width: 90%;
  object-fit: contain;
}

.flash-card-answer-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px 0px 20px 0px;
}

.flash-card-correct-field {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #def4df;
}

.flash-card-wrong-field {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f7dee5;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.flash-card-hint-text {
  margin: 0 0 30px 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.flash-card-attempted-text {
  font-size: 18px;
  font-weight: 600;
  padding-left: 16px;
}

.flash-card-count {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.view-two-by-two-box {
  margin: 30px 0;
  padding: 0 20px 28px 0;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
}

.view-two-by-two-box-teacher {
  margin: 30px 0;
  padding: 0 0 28px 0;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.two-by-two-head-cell {
  border: 0;
  border-bottom: 0 !important;
  padding: 16px 16px 0 28px !important;
}

.two-by-two-answer-cell {
  border: 0;
  border-bottom: 0 !important;
}

.two-by-two-text {
  font-size: 18px;
  font-weight: 600;
}

.two-by-two-answer {
  width: 180px;
  height: 52px;
  padding: 12px 16px;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.two-by-two-answer-green {
  width: 180px;
  height: 52px;
  padding: 12px 16px;
  border-radius: 6px;
  background-color: #def4df;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
}

.two-by-two-answer-red {
  width: 180px;
  height: 52px;
  padding: 12px 16px;
  border-radius: 6px;
  background-color: #f7dee5;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
}

.load-more-que-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 40px 0;
}

.MuiButton-root.load-more-que-btn {
  padding: 10px 20px;
  font-size: 16px;
  text-transform: capitalize;
  width: 150px;
  height: 48px;
  font-weight: 600;
}

@media (max-width: 1279px) {
  .evaluation_details_section {
    padding: 20px 20px;
    margin: 20px 0 !important;
  }

  .publish_evaluation_btn {
    margin-top: -13% !important;
  }

  .publish_evaluation_disable_btn {
    margin-top: -13% !important;
  }

  .sorting-field {
    width: 50%;
  }

  .sorting-border-field {
    width: 50%;
  }

  .graph-plotting-field-div {
    width: 70%;
  }

  .flash-card {
    min-height: 400px;
    min-width: 350px;
  }

  .flash-card-image-block {
    height: 220px;
  }

  .correct_answer_section {
    padding: 15px 30px;
  }

  .two-by-two-answer {
    width: 150px;
    height: 50px;
  }

  .two-by-two-answer-green {
    width: 150px;
    height: 50px;
  }

  .two-by-two-answer-red {
    width: 150px;
    height: 50px;
  }

  .two-by-two-answer-cell {
    padding: 12px !important;
  }

  .view-two-by-two-box {
    width: 800px;
    overflow: auto;
  }

  .view-two-by-two-box-teacher {
    width: 700px;
    overflow: auto;
  }
}

@media (max-width: 959px) {
  .total_detail {
    font-size: 38px !important;
  }

  .list_view_icon {
    font-size: 48px !important;
  }

  .publish_evaluation_btn {
    margin-top: 0 !important;
  }

  .publish_evaluation_disable_btn {
    margin-top: 0 !important;
  }

  .custom_markerjs2_ {
    width: 90% !important;
  }

  .sorting-field {
    width: 60%;
  }

  .sorting-border-field {
    width: 60%;
  }

  .correct_answer_section {
    margin: 20px 0 20px 0;
    padding: 15px 20px;
  }

  .evaluation-blob-img {
    height: 400px;
    width: 400px;
    object-fit: contain;
  }

  .flash-card {
    min-height: 380px;
    min-width: 300px;
  }

  .flash-card-image-block {
    height: 180px;
  }

  .two-by-two-answer {
    width: 130px;
  }

  .two-by-two-answer-green {
    width: 130px;
  }

  .two-by-two-answer-red {
    width: 130px;
  }

  .view-two-by-two-box {
    width: 500px;
    overflow: auto;
  }

  .view-two-by-two-box-teacher {
    width: 500px;
    overflow: auto;
  }

  .two-by-two-answer-cell {
    padding: 10px !important;
  }
}

@media (max-width: 599px) {
  .textlabel_evaluation_details {
    font-size: 18px !important;
    padding: 5px 0 !important;
  }

  .total_title {
    font-size: 15px !important;
  }

  .total_detail {
    font-size: 28px !important;
  }

  .xs_center_div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .submit_btn_section {
    margin-top: 30px;
  }

  .edit_evaluation_btn {
    width: 100px;
  }

  .save_evaluation_btn {
    width: 90px;
    margin-right: 8px !important;
  }

  .back_text_evaluation {
    font-size: 18px !important;
    display: flex;
    align-items: center;
  }

  .student_img_details {
    width: 60px !important;
    height: 60px !important;
  }

  .grid_student_name_details {
    font-size: 20px !important;
  }

  .grid_student_date_details {
    font-size: 16px !important;
  }

  .evaluation_accordion .MuiAccordionSummary-root {
    padding: 18px 20px !important;
  }

  .evaluation_question_detail_section {
    padding: 0 20px !important;
  }

  .correct_answer_section {
    margin: 20px 0 20px 0;
    padding: 15px 15px;
  }

  .long_ans_add_comment_div {
    margin: 24px 0 10px 0;
    padding: 16px;
  }

  .correct_answer_text {
    font-size: 18px !important;
  }

  .correct_answer_rectangle {
    width: 40px;
    height: 40px;
    margin: 0 5px 0 5px;
    padding: 8px;
  }

  .question_image_section {
    padding: 15px;
  }

  .xs_objective_spacing {
    padding: 0 8px !important;
  }

  .mcq_option_rectangle {
    width: 50px;
    height: 50px;
    margin: 0 10px 0 0;
  }

  .attemped_answer_rectangle {
    width: 50px;
    height: 50px;
    margin: 0 10px 0 0;
  }

  .custom_markerjs2_toolbar>div:nth-child(1) {
    white-space: pre-wrap !important;
    flex-flow: wrap;
  }

  .custom_markerjs2_toolbar>div:nth-child(2) {
    display: flex;
    align-self: flex-start;
  }

  .custom_markerjs2_toolbar>div:nth-child(4) {
    display: flex;
    align-self: flex-start;
  }

  .custom_markerjs2_toolbar {
    height: 90px !important;
  }

  .sorting-field {
    width: 80%;
  }

  .sorting-border-field {
    width: 80%;
  }

  .graph-plotting-field-div {
    width: 90%;
  }

  .graph-width {
    width: 100%;
  }

  .evaluation-blob-img {
    height: 250px;
    width: 230px;
    object-fit: contain;
  }

  .view-two-by-two-box {
    width: 300px;
    overflow: auto;
  }

  .view-two-by-two-box-teacher {
    width: 225px;
    overflow: auto;
  }

  .two-by-two-answer {
    width: 110px;
    height: 40px;
  }

  .two-by-two-answer-green {
    width: 110px;
    height: 40px;
  }

  .two-by-two-answer-red {
    width: 110px;
    height: 40px;
  }
}

@media (max-width: 359px) {
  .custom_markerjs2_toolbar {
    height: 130px !important;
  }
}