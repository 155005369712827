.Header {
  height: 75px;
  box-shadow: 0 2px 4px 0 rgba(216, 216, 216, 0.5);
  padding: 0 35px;
  align-items: center;
}

.input_date_cal_css_format > input {
  width: 93%;
  padding: 18.5px 10px;
  font-size: 15.8px;
  border-radius: 5px;
  border: 1px solid lightgray;
  position: relative;
}
.input_date_css_error_format > input {
  width: 93%;
  padding: 18.5px 10px;
  position: relative;
  font-size: 15.8px;
  border-radius: 5px;
  border: 1px solid red;
}
.Date_of_birth_calendar_Icon:focus {
  outline: none;
}
.react-tel-input .country-list .country {
  text-align: start !important;
  margin: 0px 0px 0px 10px !important;
}
.rdtPicker {
  position: absolute !important;
}
.Date_of_birth_calendar_Icon {
  background-repeat: no-repeat;
  background-image: url('../assets/calendar-1.png');
  background-size: 19px 19px;
  background-position: top 17px right 28.3px;
}
/* .PhoneInputInput{
    padding: 17px 10px;
    font-family: "Open sans";
    font-size: 15.8px;
    border:none;
}
.PhoneInputInput:focus{
    outline: none !important;
}
.PhoneInputCountry{
    padding: 10px;
}
.PhoneInputCountryIcon{
    margin:5px;
} */
.input_pass_icon {
  border-right: none !important;
}
.input_focus_pass {
  border-left: none !important;
}
.input_focus_pass:focus {
  outline: none;
}
.select .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none;
}

.react-tel-input .form-control::-webkit-input-placeholder,
.Date_of_birth_calendar_Icon::-webkit-input-placeholder {
  color: #a6a5c0;
}
.react-tel-input .form-control::-moz-placeholder,
.Date_of_birth_calendar_Icon::-moz-placeholder {
  color: #a6a5c0;
}
.react-tel-input .form-control:-ms-input-placeholder,
.Date_of_birth_calendar_Icon:-ms-input-placeholder {
  color: #a6a5c0;
}
.react-tel-input .form-control:-moz-placeholder,
.Date_of_birth_calendar_Icon:-moz-placeholder {
  color: #a6a5c0;
}

.Instructions-Info {
  margin: 20px auto !important;
}

.uploadimage_btn {
  width: 116px;
  height: 45px !important;
  box-shadow: none !important;
}

.Create-Account_block {
  margin: 25px 20px 25px 0;
  padding: 12px 0 3px 12px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(30, 28, 46, 0.08);
}
.grade_Level_responsiveClass {
  padding: 2.875rem 0 2.875rem 3.0625rem;
}
@media only screen and (max-width: 1199px) {
  .typesofAccount_div {
    width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .typesofAccount_div .Date_of_birth_calendar_Icon_register {
    width: 94.3% !important;
    left: -1px;
  }
}

@media only screen and (max-width: 767px) {
  .typesofAccount_div {
    margin: 65px -7% !important;
    width: auto !important;
  }

  .typesofAccount_div .MuiSvgIcon-fontSizeSmall {
    padding-left: 0 !important;
  }

  .typesofAccount_div .MuiInputBase-input {
    padding: 2.5px 14px !important;
    height: 46px !important;
    width: 100% !important;
  }

  .Mob-scroolbar {
    display: block !important;
  }
  .Mob-scroolbar .MuiGrid-item {
    max-width: 100% !important;
    flex-basis: 100% !important;
    justify-content: center !important;
  }
  .Create-Account_block {
    margin-bottom: 20px;
    margin-right: 0 !important;
  }

  .typesofAccount_div .MuiSelect-select {
    padding: 3.5px 14px;
    align-items: center;
    display: flex;
  }
  .Header {
    padding: 0 15px;
  }

  .typesofAccount_div .form-control {
    height: 55px !important;
  }

  .typesofAccount_div .TeacherAccountRegistration-selectDropDown-1 {
    height: 55px !important;
    padding: 7.5px 14px !important;
  }

  .typesofAccount_div .Date_of_birth_calendar_Icon_register {
    padding: 8.5px 18.5px !important;
    background-position: top 16px right 15.3px !important;
  }
  .selectitem .MuiInputBase-formControl {
    height: 51px !important;
    padding: 7.5px 14px !important;
  }
  .upload_btn-mob {
    margin-bottom: 30px !important;
    margin-top: 10px !important;
  }
  .Mob-scroolbar .upload-closeicon {
    max-width: 8.333333% !important;
    flex-basis: 8.333333% !important;
  }

  .selectitem .MuiSelect-select {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 93%;
    padding: 0 !important;
  }

  .selectitem {
    width: 95% !important;
    text-align: left;
  }
  .selectitem .classSubjectAddIcon {
    top: 5px;
    width: 20px;
    height: 25px;
    right: -9px;
  }

  .grade_Level_responsiveClass {
    padding: 2.875rem 0 0rem 0.625rem;
  }
}

@media only screen and (max-width: 414px) {
  .typesofAccount_div .Date_of_birth_calendar_Icon_register {
    left: -1px;
    width: 89% !important;
  }

  .grade_Level_responsiveClass {
    padding: 2.875rem 0 0rem 0.625rem;
  }

  .react-router-modal__modal-open {
    position: fixed !important;
  }
}
