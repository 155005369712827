.zmmtg-root{
    display: none;
}

.meeting-info-container__wrapper{
    display: none !important;
}

.participants-section-container__participants-footer-bottom > button:first-of-type{
    display: none !important;
}
