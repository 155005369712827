.rbc-label {
  font-size: 14px;
  color: black;
}
.rbc-event {
  /* margin-top: 3px; */
  /* padding-bottom: 5px; */
  /* max-height: 93px !important; */
  /* height: 9% !important; */
  /* width: 102% !important; */
}

.btn_joinnow {
  width: 110px;
  height: 40px;
  border-radius: 8px !important;
  background: #907dfc;
  /* background-image: linear-gradient(285deg, #b6a5fe 114%, #907dfc -5%); */
  box-shadow: 0 8px 16px 0 rgba(182, 165, 254, 0.32);
  text-transform: capitalize !important;
}
.btn_ended_meet {
  opacity: 0.6;
  width: 110px;
  height: 40px;
  border-radius: 8px !important;
  background-color: #999999;
  box-shadow: 0 8px 16px 0 rgba(182, 165, 254, 0.32);
  text-transform: capitalize !important;
  cursor: auto;
}

.label_joinnow {
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
}
.label_end_class {
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center;
  color: #999999;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.Date_Icon:focus {
  outline: none;
}

.Date_Icon {
  background-repeat: no-repeat;
  background-image: url('../../assets/image-down-arrow.png');
  background-size: 22px 13px;
  background-position: top 20px right 12px;
}
.date-input > input {
  width: 250px;
  padding: 13.75px 23px;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  border-radius: 8px;
  position: relative;
  font-family: 'Open sans';
  cursor: pointer;
}

.date-input-student > input {
  width: 250px;
  padding: 12.5px 23px;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  border-radius: 8px;
  position: relative;
  color: #3f526d;
  font-family: 'Open sans';
  cursor: pointer;
}
.daily-btn {
  height: 56px;
}
.ml0{
  margin-left: 0 !important;
  margin-bottom: 0px !important;
  margin-top: 2px !important;
}

@media (max-width: 1279px) {
  .small-datepicker > input {
    width: 100% !important;
    font-size: 14px !important;
  }
  .small-event-button {
    width: 90px !important;
    min-width: 50px !important;
  }
  .less-padding-small-screen {
    padding: 0 10px !important;
  }
  .font-14 {
    font-size: 14px !important;
  }
}

@media (max-width: 991px) {
  .small-event-button {
    width: 50% !important;
  }
  .faltuCalender .rdt {
    margin: 0px 0px 0px 10px !important;
  }
  .addclass-btn {
  }
}

@media (max-width: 767px) {
  .small-event-button {
    margin-right: 5px !important;
  }

  .faltuCalender .rdt {
    margin: 14px 0px 0px 0px !important;
  }
  .addclass-btn {
    min-width: 126px !important;
    width: 87% !important;
    padding-right: 7px !important;
    margin: 0 0 14px !important;
  }

  .less-padding-small-screen {
    width: 25px !important;
    padding: 0 !important;
    margin-right: 6px !important;
  }
  .daily-btn {
    height: 40px;
    font-size: 15px !important;
   
  }
}


.popover {
  /* position: absolute; */
  /* overflow-y: auto;
  overflow-x: hidden;

  min-width: 16;
  min-height: 16;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px); */
}
@media screen and (max-width: 450px) {
  .responsive-margin-top {
    margin-top: 10px !important;
  }
}
