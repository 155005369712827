.upload_text {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #3f526d;
    text-transform: none;
}

.sample_csv_btn {
    width: 169px;
    height: 42px;
    border-radius: 6px !important;
    background-color: rgba(255, 255, 255, 0.11) !important;
}

.sample_csv_text {
    font-family: "Open sans" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #3f526d;
    text-transform: none;
    margin-left: 15px !important;
}

.import_box {
    width: 771px;
    height: 331px;
    border-radius: 8px;
    border: dashed 1px #3f526d;
    background-color: #f9f8fd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 23px;
}

.upload_csv_img {
    width: 104px;
    height: 104px;
    object-fit: contain;
}

.drag_and_drop_file_text {
    font-size: 15px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal !important;
    text-align: center;
    color: #3f526d;
}

.assessment_csv_upload_btn {
    width: 140px;
    height: 50px;
    border-radius: 8px !important;
    background-color: #3a60d7 ;
    color: #fff !important;
    font-family: "Open sans" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}
.ErrorCSV_PaginationBtmDiv{
    margin-bottom: 20px !important;
}
.assessment_csv_cancel_btn {
    width: 140px;
    height: 50px;
    border-radius: 8px !important;
    border: solid 1px #3a60d7 ;
    background-color: #fff !important;
    color: #3f526d ;
    font-family: "Open sans" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    margin-left: 20px !important;
}

.upload_csv_div {
    height: 60px;
    width: 220px;
    padding: 10px 24px 10px 26px !important;
    border-radius: 8px !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff !important;
}

.upload_media_ml {
    margin-left: 26px !important;
}

.upload_csv_instruction_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;
}

.upload_csv_main_div {
    margin-top: 8%;
}

.uplaod_assessment_modal {
    width: 800px;
    height: 478px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}
.assesmentUpload_AssessmentTest_errorBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: "0 20px 50px 20px"
}
.assesmentUpload_AssessmentTest_closeBtnBox{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
}
.assesmentUpload_AssessmentTest_textAlignCenter{
    text-align: 'center'
}
.exam_yellow_bg {
    width: 130px;
    height: 130px;
    background-color: #ffbb52;
    border-radius: 50%;
}
.ErrorCSV_PaginationDiv{
    margin-top: 20px !important;
}
.exam_pencil_img {
    width: 114px;
    height: 121px;
    object-fit: contain;
}

.do_you_want_to_create_text {
    font-family: "Open sans" !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67 !important;
    letter-spacing: normal !important;
    text-align: center;
    color: #3f526d;
    margin-top: 40px !important;
    max-width: 60%;
}

.bottom_button_section {
    margin-top: 53px;
}

.yes_button {
    width: 140px;
    height: 50px;
    border-radius: 6px !important;
    background-color: #3a60d7 !important;
    font-family: "Open sans" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #fff !important;
    text-transform: capitalize !important;
    margin-left: 35px !important;
}

.no_button {
    width: 140px;
    height: 50px;
    border-radius: 6px !important;
    background-color: #fff !important;
    font-family: "Open sans" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #3f526d !important;
    border: solid 1px #3a60d7 !important;
    text-transform: capitalize !important;
}

.error_upload_modal {
    width: 800px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.error_in_uploading {
    font-size: 34px !important;
    font-weight: 600 !important;
    color: #3f526d;
    margin: 20px 0 40px 0 !important;
}

.upload_error_text {
    font-size: 24px !important;
    font-weight: normal !important;
    color: #3f526d;
    text-align: center;
}
.error_csv_section{
    padding: 28px 30px 42px 20px;
  border-radius: 8px;
  border: solid 0.5px #ff4c4d;
  margin-top: 35px;
}
.csv_reupload_button {
    width: 140px;
    height: 50px;
    border-radius: 6px !important;
    background-color: #3a60d7;
    font-family: "Open sans";
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #fff;
    text-transform: capitalize !important;
}

.csv_cancel_button {
    width: 140px;
    height: 50px;
    border-radius: 6px !important;
    background-color: #fff;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #3f526d;
    border: solid 1px #3a60d7;
    text-transform: capitalize !important;
    margin-left: 20px !important;
}

.position-relative{
    position: relative;
}

.back-btn-box{
    position: absolute;
    cursor: pointer;
    margin-top: 20px;
    top: 0px;
    left: 0px;
}

.back-btn-text{
    margin-left: 5px !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.assessment-upload-btn-box{
    display: flex;
    justify-content: flex-start;
}

.assessment-upload-border-error{
    border:1px solid #3a60d7 !important;
}

.sample-csv-link{
    text-decoration: none;
    color: none;
}

.text-align-center{
    text-align: center;
}
.selected-file-img{
    width: 61px;
    height: 63px;
}
.selected-file-name-text{
    text-transform: none;
    font-size: 20px !important;
    font-family: Open Sans !important;
    font-weight: 500 !important;
    color: rgb(63, 82, 109);
    margin: 10px 0px 0px !important;
}
.file-uploaded-text{
    text-transform: none;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #959393;
    margin: 10px 0 0 0 !important;
}

.d-none{
    display: none !important;
}

.mt-3-p{
    margin-top:3%;
}

.mt-1-p{
    margin-top:1%;
}
.mt-2-p{
    margin-top:2%;
}
.action-btn-box{
    margin-top: 8%;
    display: flex;
    justify-content: center;
}
.opacity-40{
    opacity: 0.4;
}
.opacity-100{
    opacity: 1;
}
.browse-text{
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 1279px) {
    .import_box {
        width: 700px;
    }

    .uplaod_assessment_modal {
        width: 700px;
    }
}

@media (max-width: 959px) {
    .import_box {
        width: 100%;
        height: 300px;
    }

    .upload_csv_div {
        height: 50px;
        width: 180px;
    }

    .sample_csv_btn {
        width: 150px;
        margin-left: 15px !important;
    }

    .upload_csv_main_div {
        margin-top: 10%;
    }

    .uplaod_assessment_modal {
        width: 550px;
    }

    .do_you_want_to_create_text {
        max-width: 90%;
    }

    .error_upload_modal {
        width: 550px;
    }

    .error_in_uploading {
        font-size: 30px !important;
        margin: 20px 0 30px 0 !important;
    }

    .upload_error_text {
        font-size: 22px !important;
    }
}

@media (max-width: 599px) {
    .upload_csv_div {
        width: auto;
        padding: 10px !important;
    }

    .upload_text {
        font-size: 16px !important;
    }

    .sample_csv_text {
        font-size: 14px !important;
    }

    .upload_media_ml {
        margin-left: 10px !important;
    }

    .import_box {
        width: 90%;
        margin-left: 5%;
        height: 250px;
    }

    .upload_csv_instruction_section {
        flex-direction: column;
        margin-top: 20px;
    }

    .sample_csv_btn {
        margin-left: 0px !important;
        margin-top: 15px !important;
    }

    .assessment_csv_upload_btn {
        width: 125px;
    }

    .upload_csv_main_div {
        margin-top: 65px;
    }

    .upload_csv_img {
        width: 80px;
        height: 80px;
    }

    .alert_modal_xs {
        width: 90% !important;
        padding: 15px !important;
    }

    .font_16_xs {
        font-size: 16px !important;
    }

    .uplaod_assessment_modal {
        width: 90%;
        height: 420px;
    }

    .exam_yellow_bg {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    .exam_pencil_img {
        width: 85px;
        height: 90px;
    }

    .do_you_want_to_create_text {
        font-size: 20px !important;
        margin-top: 30px !important;
    }

    .bottom_button_section {
        margin-top: 40px;
    }

    .yes_button {
        width: 100px;
        margin-left: 20px !important;
    }

    .no_button {
        width: 100px;
    }

    .error_upload_modal {
        width: 90%;
    }

    .error_in_uploading {
        font-size: 24px !important;
        margin: 10px 0 15px 0 !important;
    }

    .upload_error_text {
        font-size: 18px !important;
    }


    .csv_reupload_button {
        width: 120px;
    }

    .csv_cancel_button {
        width: 120px;
        margin-left: 15px !important;
    }
}