.shareTaskModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shareTaskModal .shareTaskPopup {
  background: #fff;
  border: 1px solid #979797;
  outline: none;
  border-radius: 29px;
  width: 75%;
  padding: 32px 34px 34px 34px;
  max-width: 600px;
}

.shareTaskModal .shareTaskForm {
  height: 100%;
}

.shareTaskModal .shareTaskBox {
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 0.3px solid #979797;
  margin: 0 10px 26px 10px;
}

.shareTaskModal .shareTaskTitle {
  text-transform: none;
  font-size: 24px;
  font-family: "Open sans";
  font-weight: bold;
  color: #3f526d;
  margin: 0 0 26px 0;
}

.shareTaskModal .shareWithBox {
  align-items: center;
  justify-content: center;
  text-align: start;
  margin: 0 33px 0 1px;
}

.shareTaskModal .shareWithTitle {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin: 0 24px 9px 3px;
}

.shareTaskModal .teacherStudentBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  border-radius: 12px;
}

.shareTaskModal .shareWithTeacherBtn, .shareTaskModal .shareWithStudentBtn {
  margin: 0 20px 0 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  box-shadow: none;
  text-transform: none;
}

.shareTaskModal .shareBtnBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  border-radius: 12px;
  padding: 34px 0 0 0;
}

.shareTaskModal .shareButton {
  margin: 0 20px 0 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  box-shadow: none;
}

.shareTaskModal .shareCancelBtn {
  background: #f6f6f6;
  border-radius: 3px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  box-shadow: none;
}