.class-period-datepicker.input_today_date_css_format > input {
  width: 100% !important;
  font-size: 1.125rem;
  box-shadow: none;
  opacity: 0.9;
  border: solid 0.3px rgba(63, 82, 109, 0.5);
  padding: 13.69px 19.2px !important;
  border-radius: 6 !important;
}

.class-period-datepicker.Date_of_due_date_Icon {
  background-position: top 9px right 10.3px !important;
}

.topFilterBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tableCell {
  min-width: 150px !important;
  border-bottom: none !important;
  padding: 12px !important;
  max-width: 150px;
}

.periodTableCell {
  min-width: 100px;
  border-bottom: none !important;
  padding: 12px !important;
}

.emptyCell {
  /* background-color: darksalmon; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:6px;
  padding: 2.5% 8%;
}

.fileNameText22 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input_today_date_css_class_period > input {
  padding: 16.5px 23px;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  color: #3f526d;
  cursor: pointer;
  width: 100%;
}

input.input-search-box::placeholder {
  opacity: 0.45;
  color: #484848;
}
.Date_of_due_date_Icon_class_period.input_today_date_css_format {
  outline: none;
}
.Date_of_due_date_Icon_class_period {
  background-repeat: no-repeat;
  background-image: url('../../assets/duedateCalendar.png');
  background-size: 33px 33px !important;
  background-position: top 11px right 10.3px !important;
}

.dropdown-image {
  background-size: 10px 11px !important;
  background-position: top 16px right 14px !important;
  margin-right: 10px;
  background-image: url('../../assets/caret-down.png');
  background-repeat: no-repeat;
}

.custom-date-time > input {
  padding: 10px 23px;
  width: 250px;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  border-radius: 8px;
  position: relative;
  color: #3f526d;
  cursor: pointer;
}

.date-input > input {
  width: 250px;
  padding: 13.75px 23px;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

/* Handle on hover */
.more_icon {
  display: flex !important;
  justify-content: flex-end !important;
}
.filter_icon {
  width: 26px !important;
  width: 26px !important;
  margin-right: 28px !important;
}

.font_18 {
  font-size: 18px !important;
}
.font_16 {
  font-size: 16px !important;
}
.font_15 {
  font-size: 15px !important;
}
.font_20 {
  font-size: 20px !important;
}
.font_14 {
  font-size: 14px !important;
}
.font_12 {
  font-size: 12px !important;
}
.font_22 {
  font-size: 22px !important;
}
.week_list {
  padding: 15px 20px !important;
  margin-right: 15px !important;
}

.dropDownSelect {
  width: 100% !important;
}
.dropDownSelect select {
  padding: 11px;
  font-size: 16px;
}

.tablecell-outer span {
  background-color: #fff;
  padding: 3px;
  border-radius: 100%;
}

@media screen and (max-width: 767px) {
  .class-period-datepicker.input_today_date_css_format > input {
    font-size: 13.5px !important;
    padding: 9.69px 15.2px !important;
    border-radius: 6px !important;
  }

  .class-period-datepicker.Date_of_due_date_Icon {
    background-size: 25px 25px !important;
    background-position: top 6px right 10.3px !important;
  }
  .dropDownSelect {
    max-width: 185px;
  }
  .filter_icon {
    width: 18px !important;
    width: 18px !important;
    margin-right: 18px !important;
  }
  .font_14 {
    font-size: 12px !important;
  }
  .font_12 {
    font-size: 10px !important;
  }

  .font_18 {
    font-size: 14px !important;
  }
  .font_16 {
    font-size: 12px !important;
  }
  .font_15 {
    font-size: 11px !important;
  }
  .font_20 {
    font-size: 16px !important;
  }
  .font_22 {
    font-size: 17px !important;
  }
  .more_icon {
    display: flex !important;
    justify-content: normal !important;
  }

  .input_today_date_css_class_period > input {
    padding: 10px 17px !important;
    font-size: 13px !important;
    max-width: 185px;
  }
  .Date_of_due_date_Icon_class_period {
    background-size: 24px 24px !important;
    background-position: top 5px right 5.3px !important;
  }
  .week_list {
    padding: 7px 9px !important;
    margin-right: 7px !important;
  }
  .tableCell {
    min-width: 120px !important;
    padding: 8px !important;
  }
}
@media screen and (max-width: 959px) and (min-width: 768px) {
  .class-period-datepicker.input_today_date_css_format > input {
    font-size: 14.5px !important;
    padding: 12.13px 16.5px !important;
    border-radius: 6px !important;
  }

  .class-period-datepicker.Date_of_due_date_Icon {
    background-size: 27px 27px !important;
    background-position: top 6px right 10.3px !important;
  }
  .filter_icon {
    width: 22px !important;
    width: 22px !important;
    margin-right: 22px !important;
  }
  .font_14 {
    font-size: 12.5px !important;
  }
  .font_12 {
    font-size: 10.5px !important;
  }
  .font_18 {
    font-size: 15px !important;
  }
  .font_16 {
    font-size: 13px !important;
  }
  .font_15 {
    font-size: 12px !important;
  }
  .font_20 {
    font-size: 17px !important;
  }
  .font_22 {
    font-size: 18.5px !important;
  }
  .input_today_date_css_class_period > input {
    padding: 13px 19px !important;
    font-size: 15px !important;

    border-radius: 6px !important;
  }

  .Date_of_due_date_Icon_class_period {
    background-size: 26px 26px !important;
    background-position: top 10px right 6.7px !important;
  }
  .more_icon {
    display: flex !important;
    justify-content: flex-end !important;
  }
  .week_list {
    padding: 10px 13px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 1279px) and (min-width: 959px) {
  .class-period-datepicker.input_today_date_css_format > input {
    font-size: 16.5px !important;
    padding: 12.31px 18.2px !important;
    border-radius: 6px !important;
  }

  .class-period-datepicker.Date_of_due_date_Icon {
    background-size: 30px 30px !important;
    background-position: top 8px right 10.3px !important;
  }
  .filter_icon {
    width: 24px !important;
    width: 24px !important;
    margin-right: 24px !important;
  }
  .font_14 {
    font-size: 13px !important;
  }
  .font_12 {
    font-size: 11px !important;
  }
  .font_18 {
    font-size: 16.5px !important;
  }
  .font_16 {
    font-size: 14.5px !important;
  }
  .font_15 {
    font-size: 13.5px !important;
  }
  .font_20 {
    font-size: 18.5px !important;
  }
  .font_22 {
    font-size: 20px !important;
  }
  .input_today_date_css_class_period > input {
    padding: 15.5px 21px !important;
    font-size: 16px !important;

    border-radius: 7px !important;
  }
  .Date_of_due_date_Icon_class_period {
    background-size: 29px 29px !important;
    background-position: top 11px right 8.3px !important;
  }
  .more_icon {
    display: flex !important;
    justify-content: flex-end !important;
  }
  .week_list {
    padding: 12.5px 16px;
    margin-right: 13px;
  }
}

.adminClassPeriodHeaderPart {
  margin: 20px 10px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.adminClassPeriodHeaderPart .mainHeading {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}
.adminClassPeriodHeaderPart .flexEnd {
  display: flex !important;
  justify-content: flex-end !important;
}
.adminClassPeriodMainContainer .flexEnd {
  display: flex !important;
  justify-content: flex-end !important;
}
.adminClassPeriodHeaderPart .moreIconImg {
  cursor: pointer;
  width: 60px;
  height: 60px;
}
.adminClassPeriodMainContainer {
  padding: 0 10px !important;
  margin-top: 1% !important;
  margin-bottom: 2% !important;
}
.adminClassPeriodMainContainer .innnerBox {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
  padding: 2% !important;
  border-radius: 8px !important;
}
.adminClassPeriodMainContainer .weekListing {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 12px !important;
}
.adminClassPeriodMainContainer .weekTypo {
  margin-right: 15px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 6px;
}
.adminClassPeriodMainContainer .mb12 {
  margin-bottom: 12px !important;
}
.adminClassPeriodMainContainer .filterButton {
  text-transform: capitalize;
  font-weight: 600;
  padding: 5px 15px 5px;
  border-radius: 6px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  color: #3f526d;
}
.adminClassPeriodMainContainer .tableContainerBox {
  padding-top: 1.5%;
}
.adminClassPeriodMainContainer .dayDateBox {
  padding: 5% 3%;
  border-radius: 8px;
  text-align: center;
}
.adminClassPeriodMainContainer .dateTypo {
  color: darkgray;
  margin-left: 3%;
}
.adminClassPeriodMainContainer .fw600 {
  font-weight: 600;
}
.adminClassPeriodMainContainer .fw500 {
  font-weight: 500;
}
.adminClassPeriodMainContainer .darkColor {
  color: #ccc;
}
.adminClassPeriodMainContainer .w100 {
  width: 100%;
}
.adminClassPeriodMainContainer .empty {
  justify-content: left;
  cursor: pointer;
}
.adminClassPeriodMainContainer .dataTypo {
  font-size: 14px;
  margin: 2px 0;
}
.adminClassPeriodMainContainer .flexBetween {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
}
.adminClassPeriodMainContainer .avatarImg {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.adminClassPeriodMainContainer .editImg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.adminClassPeriodMainContainer .h48 {
  height: 48px;
}
.adminClassPeriodMainContainer .addImg {
  height: 24px;
  width: 24px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.adminClassPeriodMainContainer .paginationBox {
  text-align: end;
  margin-top: 25px;
}
.menuBoxContainer {
  margin-top: 5% !important;
}
.menuBoxContainer .mainBox {
  width: 290px !important;
  border-radius: 8px !important;
  padding: 16px 13px 13px !important;
  margin: 0 !important;
}
.menuBoxContainer .justifyC {
  justify-content: center !important;
}
.menuBoxContainer .flexCenter {
  display: flex !important;
  align-items: center !important;
}
.menuBoxContainer .img {
  width: 24px;
  height: 24px;
}
.menuBoxContainer .uploadTypo {
  text-transform: none;
  font-size: 20px;
  font-weight: 500;
  margin-left: 18px;
  color: #3f526d;
}
.menuBoxContainer .divider {
  width: 100%;
  background: #e5ebf4;
  margin: 5px 0;
}
.menuBoxContainer .imgAutoGenerated {
  width: 20px;
  height: 20px;
  margin: 0 2px;
}