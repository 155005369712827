.classSection {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 20px;
  color: #3f526d;
  margin-bottom: 30px;
}

.classSection li {
  list-style: none;
  text-align: center;
}

.classSection li .paper {
  background: #fff;
  border-radius: 25px;
  border: 1px solid #eaeaea;
  padding: 40px;
}

.classSection li .reportIcon {
  width: 110px;
  margin: 0 auto;
}


.classSection li .reportIcon img {
  width: 100%;
}

Button {
  box-shadow: 0 0 0 0;
  font-size: 16px;
  line-height: 20px;
  padding: 13px 20px;
  border-radius: 6px;
  background: #8b78fc;
}

.classSection li h4 {
  font-size: 24px;
  color: #253873;
  margin: 15px 0;
}

.classSection li p { 
  color: #999;
  font-size: 16px;
  margin-bottom: 35px;
}

.classSection li Button {
  box-shadow: 0 0 0 0;
  font-size: 16px;
  line-height: 20px;
  width: 150px;
  padding: 13px 20px;
  border-radius: 6px;
  background: #8b78fc;
}

.classSection li Button:hover {
  box-shadow: 0 0 0 0;
}

.reportDetails li {
  text-align: left;
}

.reportDetails li h4 {
  font-size: 20px;
  min-height: 90px;
  margin-bottom: 50px;
  border-bottom: 1px solid #eaeaea;
}

.reportDetails li .centerButton { 
  display: flex;
  column-gap: 20px;
}

.reportDetails li .centerButton Button {
  background: #fff;
  border: 1px solid #999;
  box-shadow: 0 0 0;
  color: #666;
  text-align: left;
}

.profileContent {
  box-shadow: 0px 1px 9px 6px #eaeaea;
  background: #fff;
  border-radius: 5px;
  padding: 60px;
  text-align: center;
  margin-top: 40px;
}

.schoolLogo {
  width: 120px;
  height: auto;
  position: absolute;
  left: 0;
}

.schoolLogo img {
  max-width: 100%;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.tableContent {
  margin-top: 50px;
  border: 1px solid #d9d9d9;
  margin-bottom: 50px;
  border-bottom: 0;
}

.topHeader {
  display: inline-flex;
  align-items: center;
  text-align: center;
  column-gap: 60px;
  margin-bottom: 60px;
}

.schoolTitle {
  margin: 0;
  padding: 0;
  text-align: center;
}

.schoolTitle h3 {
  font-size: 36px;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  line-height: 80px;
}

.studentProfile {
  display: inline-flex;
  width: 100%;
  text-align: left;
}

.studentProfile .studentPhoto {
  width: 200px;
  height: auto;
  border-radius: 5px;
  margin-top: 46px;
  border: 1px solid #eaeaea;
}

.studentProfile .studentPhoto img {
  max-width: 100%;
  border-radius: 5px;
}

.MuiTableRow-root th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 0;
}

.MuiTableRow-root th:last-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.formGroup {
  margin-bottom: 20px;
}

.profileContent h5 {
  font-size: 28px;
  margin: 50px 0;
}

.profileContent h4 {
  font-size: 22px;
}

.profileContent p {
  font-size: 16px;
  max-width: 800px;
  margin: 0 auto;
}

.profileTitle {
  position: relative;
  display: flex;
  margin: 20px 0;
}

.profileTitle .backBtn {
  margin-right: 20px;
  background: none;
  text-transform: none;
  font-size: 20px;
  padding-left: 0;
}

.profileTitle .editBtn {
  position: absolute;
  right: 0;
  margin-right: 10px;
  text-transform: none;
  font-size: 20px;
  padding-right: 0;
}

.editBtn .MuiSvgIcon-root {
  transform: rotate(180deg);
} 

.tableContent .accordianDetails {
  display: block;
}

.tableContent .accordianTitle {
  box-shadow: none;
}

.tableContent .tableSectionpart {
  margin-bottom: 30px;
}

.tableSectionpart tr td:last-child {
  border-right:0;
}

.tableSection {
  padding-top: 10px;
}

.tableSection td {
  border-right: 1px solid #D9D9D9;
  border-top: 1px solid #D9D9D9;
  border-radius: 0;
  padding: 10px;
  text-align: center;
}

.blanktableSection td {
  border-right: 1px solid #D9D9D9;
  border-radius: 0;
  text-align: center;
}

.tableSection td:last-child, .tableSection td:first-child {
  border-radius: 0;
}

.tableSection td:last-child {
  border-right: 0;
}

.tableContent .MuiAccordion-root {
  box-shadow: 0 !important;
}


Button.commonBtnPrimary , .commonBtnPrimary {
  background: #7f6cfc;
  padding: 11px 20px;
  border-radius: 5px;
  color: #fff;
  text-transform: none;
  font-size: 16px;
  line-height: 19px;
}

.tableRow td {
  padding: 20px;
  border-bottom:1px solid #d1d1d1;
  border-right:1px solid #d1d1d1;
  border-radius: 0;
}

.tableRow td:first-child {
  border-radius: 0;
  border-left:1px solid #d1d1d1;
}

.tableRow td:last-child {
  border-radius: 0;
}

.accordianPart .tableContentarea {
  padding: 0;
}

.noTableRow tr:last-child td , .tableRow tr:last-child td {
  border-bottom: 0;
}

.noTableRow td:first-child, .noTableRow td:last-child {
  border-left:0;
  border-radius: 0;
}

.noTableRow:last-child td {
  border-bottom: 0;
  border-radius: 0;
}

.noTableRow td {
  border-right: 0;
  border-bottom:1px solid #d1d1d1;
  border-radius: 0;
}


/* .inline-Block {
  display: inline-block;
}

.hwidth {
  width: 200px;
  display: inline-block;
} */

Button.saveChangebtn {
  right: 190px;
  position: absolute;
  margin-right: 20px;
}

Button.commonBtnSecondary {
  background: #fff;
  padding: 11px 20px;
  border-radius: 5px;
  color: #7f6cfc;
  text-transform: none;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid #7f6cfc;
}

Button.commonBtnSecondary:hover {
  background: #7f6cfc;
  color: #fff;
}

Button.commonBtnPrimary:hover {
  background: #999;
}

Button.commonBtnPrimary svg, Button.commonBtnSecondary svg {
  margin-left: 10px;
}

.profileTitle h2{
  margin: 10px 0 0 0;
  line-height: 26px;
  font-weight: 600;
}

.studentProfile .profileForm {
  width: 100%;
  text-align: left;
}

.profileForm label{
  margin-bottom: 15px;
  margin-top: 12px;
}

.blockVision {
 background: #f9f8fd;
 padding: 20px;
 max-width: 800px;
 margin: 0 auto;
}

.blockVision h4 {
  font-size: 22px;
  margin-bottom: 20px;
}

.full {
  width: 100%;
}

.threeCol, .twoCol {
  width: 100%;
  display: flex;
  column-gap: 20px;
}

.gap {
  column-gap: 20px;
  display: flex;
}

.twoCol > div {
  width: 50%;
}

.threeCol > div {
  width: 33.33%;
}

.formGroup input, .formGroup .MuiOutlinedInput-root {
  width: 100%;
}

.formGroup .MuiFormControl-root {
  width: 100%;
}

.datePicker .MuiInput-underline:before, .datePicker .MuiInput-underline:after {
    display: none;
}

.datePicker {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 11px 14px;
}

.datePicker > .MuiFormControl-root {
  width: 100%;
}

.tableContent .MuiAccordion-rounded {
  box-shadow: 0 !important;
  border: 0;
  border-radius: 0;
}

.tableContent .accordianPart .accordianPartsummary {
  padding: 0 20px;
}

.reportTopHeader {
  position: absolute;
  left: 0;
  top: -290px;
  width: 100%;
  display: none;
}

.studentProfileContent .topHeader {
  position: relative;
  width: 100%;
  margin-bottom: 60px;
  display: block;
}

.studentProfileContent .commonBtnPrimary {
  white-space: nowrap;
  position: absolute;
  right: 0;
  top: 20px;
}

.studentProfileContent .blockVision {
  position: relative;
  border-radius: 8px;
  padding: 25px 40px;
  text-align: center;
  min-height: 228px;
  margin-bottom: 45px;
  display: none;
}

.studentProfileContent .blockVision p {
  font-size: 18px;
  line-height: 26px;
}

.titleStudent {
  font-size: 24px;
  text-decoration: underline;
  text-align: center;
}

.studentProfileContent {
  height: auto;
  position: relative;
  padding: 40px;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
}

.studentProfileContent .schoolTitle {
  width: 100%;
  text-align: center;
}

.paginationRightside {
  text-align: right;
  margin: 40px 0 30px;
}

.paginationRightside span {
  background: #fff;
  border: solid 1px #7f6cfc;
  padding: 12px 20px;
  border-radius: 8px;
  margin-left: 14px;
  font-weight: 600;
  color: #7f6cfc;
  cursor: pointer;
}

.paginationRightside span:hover, .paginationRightside span.active {
  background: #7f6cfc;
  color: #fff;
}

#page-numbers {
  list-style: none;
  display: flex;
}

#page-numbers > li {
  margin-right: 0.3em;
  color: blue;
  user-select: none;
  cursor: pointer;
}

@media only screen and (max-width: 1366px) {
  .profileContent {
    padding: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .schoolLogo {
    margin: 0 auto;
    position: static;
  }

  .studentProfileContent .commonBtnPrimary {
    position: static;
  }

  .schoolTitle h3 {
    font-size: 28px;
    line-height: 45px;
    margin: 10px 0;
  }
}

@media only screen and (max-width: 768px) {
  .schoolTitle h3 {
    font-size: 18px;
  }

  .schoolLogo {
    margin: 0 auto;
  }

  .schoolTitle {
    margin: 20px 0;
  }

  .studentProfile .uploadPhoto {
    margin: 0 auto 40px;
  }

  .profileContent h5 {
    margin: 20px 0;
  }

  .topHeader {
    display: block;
  }
}