.admin_classes_parent_container {
    margin: 14px 0px 21px 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.admin_classes_title {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
}
.admin_classes_upload_button {
    cursor: pointer p !important;
    border-radius: 6px !important;
    padding: 11px 20px 11px 21px !important;
    margin: 0px 10px 0px 0px !important;
    text-transform: capitalize !important;
}
.admin_classes_add_button {
    cursor: pointer !important;
    border-radius: 6px !important;
    padding: 11px 32px 11px 33px !important;
    text-transform: capitalize !important;
}
.admin_classes_search_box {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    border-radius: 8px !important;
    height: 60px !important;
}
.admin_classes_search_textfield {
    border:none;
    outline: none;
    color: #3f526d;
    opacity: 0.5;
    font-size: 1.125rem;
    width: 100%;
    padding-right: 10px !important;
    padding-left:20px !important;
}
.admin_classes_search_icon {
    height: 25px;
    width: 25px;
    margin-right: 15px;
}
.admin_classes_container_box {
    padding: 22px 20px 26px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.admin_classes_filter_box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px !important;
}
.admin_classes_filter_button {
    text-transform: capitalize !important;
    font-size: 1.25rem !important;
    font-weight: 600 ip !important;
    padding: 5px 15px 5px !important;
    border-radius: 6px ip !important;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: #fff !important;
    color: #3f526d !important;
}
.admin_classes_filter_button_img {
    width: 26px;
    height: 26px;
    margin: 0 28px 0px 0;
}
.admin_classes_listing_box_head {
    background-color: #ded9ff;
    border-radius: 6px;
}
.admin_classes_listing_head_text {
    padding: 17px 0px 16px 20px !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
}
.admin_classes_listing_head_text_center {
    padding: 17px 0px 16px 0px !important;
    text-align: center !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
}
.admin_classes_no_record_text {
    width: 176px;
    height: 27px;
    font-family: "Open sans";
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-top: 20px;
}
.admin_classes_listing_box_body {
    background-color: #f9f8fd;
    padding: 16px 0px 16px 0px;
    border-radius: 6px,
}
.admin_classes_listing_box_body_text {
    padding: 0px 0px 0px 20px !important;
    font-size: 1.125rem !important;
    font-weight: 600 !important;
}
.admin_classes_listing_box_body_text_center {
    text-align: center !important;
    font-size: 1.125rem !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}
.admin_classes_listing_box_body_text_end {
    text-align: end !important;
    font-size: 1.125rem !important;
    font-weight: 600 !important;
}
.admin_classes_more_icon_box{
    text-align: end !important;
    color: #3f526d !important;
    font-family: 'Open sans' !important;
    font-size: 1.125rem !important;
    font-weight: 600 !important;
    margin: 0px 4px 0px 0px !important;
}
.admin_classes_more_icon {
    height: 28px !important;
    width: 28px !important;
    opacity: 0.12 !important;
    color: #3f526d !important;
}
.admin_classes_menu_box {
    width: 203px !important;
}
.admin_classes_menu_options {
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}
.admin_classes_paginate_box {
    text-align: end !important;
    margin-top: 25px !important;
}