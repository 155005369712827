.App {
  font-family: sans-serif;
  text-align: center;
}
.search_input_class_section {
  font-weight: 600 !important;
}

.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #2b2eff;
  color: black;
  cursor: pointer;
}
.table_body_cell_text {
  font-weight: 600 !important;
}

.paginationBttns a:hover {
  color: black;
  border-radius: 6px;

  background-image: linear-gradient(to top, #af38cb 101%, #471c51 33%);
}

.paginationActive a {
  color: black;
  border-radius: 6px;
  background-image: linear-gradient(to top, #af38cb 101%, #471c51 33%);
}

.paginationDisabled a {
  color: grey;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to top, #af38cb, #471c51);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #fff, #fff),
    linear-gradient(to top, #af38cb, #471c51);
  background-origin: border-box;
  background-clip: border-box;
}

.paginationDisabled a:hover {
  color: grey;
  cursor: auto;
  border-radius: 6px;
}

.MuiTableCell-root.gradebook-header-cell {
  text-align: center;
  border: 0;
}

.MuiTableCell-root.vertical-header-cell {
  border: 1px solid #d1d1d1;
  padding: 0;
  height: 160px;
  max-height: 160px;
  max-width: 30px;
  min-width: 30px;
}

.gradebookTable .MuiTableCell-root.gradebook-first-column-textlabel {
  z-index: 11;
  border-radius: 0;
  border-top-left-radius: 0;
  display: block;
  width: 137px;
  max-width: 210px;
  border-bottom: 1px solid #eaeaea;
  /* box-shadow: 5px 5px 5px -3px #888; */
  /* width: 15%; */
  background: #fff;
  position: sticky;
  left: 0;
  text-align: center;
}

.gradebookTable {
  width: 100%;
  overflow: scroll;
  max-height: 600px;
}

.gradebookTable .MuiTableRow-root td:last-child {
  border-radius: 0;
}

.gradebookTable .MuiTableRow-root th:last-child {
  border-radius: 0;
}

.gradebookTable .MuiTableCell-root.gradebook-header-cell {
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
}

.gradebookTable .MuiTableCell-root.gradebook-header-cell-border-end {
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  text-align: center;
}

.gradebook-sub-header-cell {
  transform: rotate(270deg);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Open sans';
  font-size: 14px;
  font-weight: 600;
  color: #3f526d;
}

.export-box {
  border: solid 1px #c6c6c6;
  border-radius: 6px;
  height: 50px;
  width: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.export-text {
  font-size: 16px;
  font-weight: normal;
  color: #3f526d;
  margin-left: 15px;
}

.gradebook-header-cell-textlabel {
  font-family: 'Open sans';
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #327095;
}

.MuiTableCell-head.gradebook-first-column-textlabel {
  font-family: 'Open sans';
  font-size: 18px;
  font-weight: 600;
  color: #3f526d;
  width: 210px;
}

.to-text {
  font-size: 16px;
  font-weight: 600;
  color: #3f526d;
}

.gradeBookContent .topGrid {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
}

.gradeBookContent .topGrid > .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gradeBookContent .topGrid > .item:first-child {
  padding-right: 120px;
}

.gradeBookContent .yearBg {
  background: #fff;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  padding: 10px 25px;
}

.tabingSection {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  padding: 0 40px;
  width: 100%;
  margin-bottom: 30px;
}

.tabingSection .teacher_tab {
  height: auto;
  margin: 0;
  width: auto;
  padding: 20px;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: none;
}

.gradeBookContent .tabingSectionContent {
  width: 100%;
}

.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}

.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  z-index: 1;
  overflow: hidden;
  border-radius: 0;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
}

.ant-table-layout-fixed table {
  table-layout: fixed;
}

.ant-table .ant-table-content .ant-table-body table .ant-table-tbody {
  border-collapse: collapse;
}

.ant-table .ant-table-content .ant-table-body table .ant-table-tbody tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 10px 5px;
  font-size: 12px;
  font-weight: 600;
  color: rgb(67, 75, 93);
  max-width: 200px;
}

.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-spin-nested-loading {
  position: relative;
}

.ant-table-wrapper::after,
.ant-table-wrapper::before {
  display: table;
  content: '';
}

.ant-spin-container {
  position: relative;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.ant-table {
  position: relative;
  clear: both;
}

.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: scroll;
  opacity: 0.9999;
}

.ant-table .ant-table-content .ant-table-fixed .ant-table-tbody tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 10px 5px;
  font-size: 12px;
  font-weight: 600;
  color: rgb(67, 75, 93);
  max-width: 200px;
}

.rrll {
  position: relative;
}
.dropshadowtd {
  min-width: 137px;
  max-width: 210px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: calc(100% - 12px);
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}

.gradebookTable .assignmentTitle {
  max-height: 152px;
  margin-top: -2px;
  border-bottom: 1px solid #fff !important;
  padding-top: 70px ​ !important;
  max-width: 210px;
  min-width: 137px;
  line-height: 7;
}

.gradeTitle {
  border-bottom: 1px solid #fff !important;
  max-width: 210px;
  min-width: 137px;
  line-height: 2.5 !important;
}

.grade-book-filter-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px !important;
}

.margin-lr-20 {
  margin: 0 20px;
}

.view-filter {
  width: 50px;
  height: 50px;
  margin: 0 0 0 20px;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.filter-grade-book-icon {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.gradebook-outer {
  padding-left: 10px;
  width: 100%;
}

@media (max-width: 767px) {
  .tabingSection .teacher_tab {
    padding: 16px 20px;
  }

  .to-text {
    margin: 15px 0;
  }

  .list-menu {
    display: block;
    width: 100%;
  }
  .list-menu .MuiSelect-select {
    width: 100%;
  }
  .assessment-table table {
    width: 700px;
    max-width: 700px;
  }

  .assessment-table table th,
  .assessment-table table td {
    font-size: 16px;
  }
}

@media (max-width: 599px) {
  .grade-book-filter-div {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .grade-book-filter-div .date-input {
    margin: 0 0 20px 0 !important;
  }

  .view-filter {
    margin-bottom: 20px !important;
  }
}
