.inputColor .css-1yn2e29-InputColor .css-1vpurlb-InputColor{
    background-color: transparent !important;
}

.inputColor .css-1yn2e29-InputColor{
    border:none;
    width: 100%;
    height: 100%;
    padding: 0px;
    position: absolute;
    background-color: transparent !important;


}

.backgroundColorPicker .css-j4ndc3 .css-trkpwz{
    background-color: transparent !important;
}

.backgroundColorPicker .css-j4ndc3{
    border:none;
    width: 100%;
    height: 100%;
    padding: 0px;
    position: absolute;
    background-color: transparent !important;


}

.inputColor .css-j4ndc3 .css-trkpwz{
    background-color: transparent !important;
}

.inputColor .css-j4ndc3{
    border:none;
    width: 100%;
    height: 100%;
    padding: 0px;
    position: absolute;
    background-color: transparent !important;


}

.backgroundColorPicker .css-1yn2e29-InputColor .css-1vpurlb-InputColor{
    background-color: transparent !important;
}

.backgroundColorPicker .css-1yn2e29-InputColor{
    border:none;
    width: 100%;
    height: 100%;
    padding: 0px;
    position: absolute;
    background-color: transparent !important;
}

.textlabel_welcomeText {
    font-size: 2.1937rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.textlabel_font {
    font-size: 1.375rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.textlabel_Header {
    font-size: 1.125rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
}
.forrmInput_textfield {
    width: 100%;
    border-radius: 6px;
    text-align: start;
    color: #03014c;
    font-size: 1rem;
}
.textlabel_school_des {
    font-size: 0.812rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.textlabel_Title {
    font-size: 1.125rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3b3b3b;
}
.CustomThemesParentBox .mt_6 {
    margin-top: 6px;
}
.CustomThemesParentBox .customIdBox {
    margin-top: 3.75 rem;
    padding: 0 1.875rem 0 0;
}
.CustomThemesParentBox .ItemCenter {
    align-items: center;
}
.CustomThemesParentBox .ItemBox {
    border-radius: 12px;
    border: solid 1px #979797;
    padding: 5px 13px 4px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.CustomThemesParentBox .SpanStyle {
    width: 3.9375rem;
    height: 1.9375rem;
    border-radius: 12px;
}
.CustomThemesParentBox .IconBtn {
    position: relative;
    padding: 0 0 0 10px;
}
.CustomThemesParentBox .ImgEdit {
    width: 1.0625rem;
    height: 1.0625rem;
}
.CustomThemesParentBox .Box {
    margin-top: 3.125rem;
    padding:0 1.875rem 0 0;
}
.CustomThemesParentBox .InputPropsStyles {
    font-size: 18px;
    color: #3b3b3b;
    background: #fff;
    padding: 9px 18.6px 4px 8px;
    border-radius:12px;                                  text-transform: capitalize;
}
.CustomThemesParentBox .ButtonsContainer {
    width: 100%;
    height: 11.0625rem;
    border: solid 1px #979797;
    border-radius: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.CustomThemesParentBox .UpdateBtnBox {
    margin: 3.125rem 0px 10px 0px;
    display: flex;
    justify-content: flex-end;
    padding-right: 2.75rem;
}
.CustomThemesParentBox .UpdateBtn {
    background-image:  linear-gradient(289deg, #aca2ff 119%, #776be8);
    border-radius: 8px;
    color: #fff;
    width: 17%;
    padding: .85rem;
    text-transform: capitalize;
    font-size: 1rem;
}
.CustomThemesParentBox .ColorBtn {
    background-image: linear-gradient(289deg, #aca2ff 119%, #776be8);
    border-radius: 6px;
    color: #fff;
    padding: 1px 20px 1px 21px;
    font-size: 22px;
    position: relative;
    text-transform: capitalize;
}
.CustomThemesParentBox .ContainedBtnFileBox {
    width: 100%;
    height: 11.0625rem;
    border: solid 1px #979797;
    border-radius: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: round;
    background-size: cover;
}
.CustomThemesParentBox .DNone {
    display: none;
}
.CustomThemesParentBox .BrowseBtnStyle {
    background: #d8d8d8;
    border-radius: 6px;
    color: #262626;
    padding: 1px 20px 1px 21px;
    text-transform: capitalize;
    font-size: 22px;
    font-weight: 600;
    border: solid 1px #979797;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    opacity: .5;
}