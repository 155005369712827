.apply_btn {
  font-weight: 600 !important;
}
.no_data_img {
  object-fit: contain;
}
.cancel_btn {
  font-weight: 600 !important;
}
.no_data_title {
  font-weight: 500 !important;
}
