.TimeTableEventWrapperLunchTimeMainDiv {
    display: flex;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 2px;
}

.TimeTableEventWrapperLunchTimeImg {
    height: 36px;
    width: 36px
}

.TimeTableEventWrapperLunchTimeTitleTxt {
    font-family: "Open sans" !important;
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 8px;
}

.TimeTableEventWrapperMainSubDiv {
    padding: 5px 15px 5px 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: inherit !important;
    ;
}

.TimeTableEventWrapperSubjectImageDiv {
    display: flex;
    justify-content: center;
}

.TimeTableEventWrapperSubjectImageSubDiv {
    border-radius: 6px;
    background-color: #fff2de;
    display: flex;
    justify-content: center
}

.TimeTableEventWrapperFlexDiv {
    display: flex;
}

.TimeTableEventWrapperFlexSubDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.TimeTableEventWrapperClassTitle {
    font-family: "Open sans" !important;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 20px;
}

.TimeTableEventWrapperClassTxt {
    font-family: "Open sans";
    font-size: 14px !important;
    color: #9aa3b2;
    margin-top: 1%;
}

.TimeTableEventWrapperEventDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TimeTableEventWrapperEventImg {
    height: 20px;
    width: 32px;
    object-fit: contain
}

.TimeTableEventWrapperEventTxt {
    font-family: "Open sans";
    font-size: 14px;
    margin-top: 7px;
    font-weight: 600;
    line-height: 1.67;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.TimeTableEventWrapperEventTitleDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.TimeTableEventWrapperEventTitleTxt {
    font-family: "Open sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 1.67;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.TimeTableEventWrapperInfoImgDiv {
    display: flex;
    align-items: center
}

.TimeTableEventWrapperInfoImg {
    height: 20px;
    width: 20px;
    margin-left: 10px
}

.TimeTableEventWrapperDurationDiv {
    display: flex;
    justify-content: space-between;
}

.TimeTableEventWrapperDurationTxt {
    font-family: "Open sans" !important;
    font-size: 18px !important;
    color: #3f526d !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

}

.TimeTableEventWrapperDurationImg {
    height: 20px;
    width: 20px;
    margin-right: 10px
}

.TimeTableEventWrapperDurationTitleTxt {
    font-weight: 600 !important;
    font-size: 14px !important;
}