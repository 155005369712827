.StudentAttendanceSelectedTab {
    margin: 20px 0px !important;
}

.StudentAttendanceParentBox {
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    padding: 19px 20px 10px;
    margin: 19px 0px 20px;
    background: #fff;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.StudentAttendanceParentBox .FlexCol {
    display: flex;
    flex-direction: column;
}

.StudentAttendanceParentBox .W100 {
    width: 100%;
}

.StudentAttendanceParentBox .FlexCenter {
    display: flex;
    align-items: center;
}

.StudentAttendanceParentBox .FlexJustifyCenter {
    display: flex;
    justify-content: center;
}

.StudentAttendanceParentBox .FlexJustifyBetween {
    display: flex;
    justify-content: space-between;
}

.StudentAttendanceParentBox .FlexJustifyStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.StudentAttendanceParentBox .Pb15 {
    padding-bottom: 15px;
}

.StudentAttendanceParentBox .HeaderBox {
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-right: 20px;
    justify-content: center;
    padding: 9px 10px;
    cursor: pointer;
}

.StudentAttendanceParentBox .StatusBox {
    display: flex;
    width: 65%;
    align-items: center;
    justify-content: flex-end;
}

.StudentAttendanceParentBox .StatusIcons {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.StudentAttendanceParentBox .StatusIcons2 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.StudentAttendanceParentBox .bgGreen {
    background: #3dbb3d;
}

.StudentAttendanceParentBox .bgRed {
    background: #e02020;
}

.StudentAttendanceParentBox .bgBlue {
    background: #327095;
}

.StudentAttendanceParentBox .bgYellow {
    background: #e69416;
}

.StudentAttendanceParentBox .Mb20 {
    margin-bottom: 20px;
}

.StudentAttendanceParentBox .Mt15 {
    margin-top: 15px;
}

.StudentAttendanceParentBox .TableWidth {
    min-width: 650px;
}

.StudentAttendanceParentBox .TableHeadStyle {
    background: #f2f2f2;
}

.StudentAttendanceParentBox .TableCellStyle {
    font-size: 18px;
    font-weight: bold;
    padding: 12px;
}

.StudentAttendanceParentBox .W40_Per {
    width: 40%;
}

.StudentAttendanceParentBox .W30_Per {
    width: 30%;
}

.StudentAttendanceParentBox .W35_Per {
    width: 35%;
}

.StudentAttendanceParentBox .Mr15 {
    margin-right: 15px;
}

.StudentAttendanceParentBox .h10 {
    height: 10px;
}

.StudentAttendanceParentBox .TableRowstyle {
    border-radius: 6px;
    background: #f9f8fd;
}

.StudentAttendanceParentBox .TableCellPadding {
    padding: 10px 12px;
}

.StudentAttendanceParentBox .Avatar {
    width: 34px;
    height: 34px;
}

.StudentAttendanceParentBox .SubjectNameTypo {
    font-size: 20px;
    margin: 0 23px;
    font-weight: 600;
}

.StudentAttendanceParentBox .p10 {
    padding: 10px;
}

.StudentAttendanceParentBox .FlexBoxCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.StudentAttendanceParentBox .CommentBox {
    padding: 10px;
    font-style: 16px;
    margin: 10px 0px;
    font-weight: 600;
}

.StudentAttendanceParentBox .CommentTypo {
    line-height: initial;
    word-break: break-all;
}

.StudentAttendanceParentBox .NoRecordFoundText {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: 10px 23px;
    color: #3f526d;
    font-weight: 600;
}

.StudentAttendanceParentBox .AttendanceReportGrph {
    display: flex;
    margin-right: 5px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.StudentAttendanceParentBox .AttendanceReportGrphMainBox {
    align-items: center;
    width: 100%;
}

.StudentAttendanceParentBox .AttendanceReportInnerBox {
    background: #fff;
    border-radius: 8px;
    padding: 12px 20px 4px 30px;
    border: solid 0.5px #3f526d;
}

.StudentAttendanceParentBox .Flex {
    display: flex;
}

.StudentAttendanceParentBox .BoxMargin {
    margin: 15px 20px 0 0px;
}

.StudentAttendanceParentBox .JustifyCenter {
    justify-content: center;
}

.StudentAttendanceParentBox .Mt15 {
    margin-top: 15px;
}

.StudentAttendanceParentBox .ChartMainBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}

.StudentAttendanceParentBox .ChartInnerBox {
    width: 80%;
}

.StudentAttendanceParentBox .PieChart {
    background: transparent;
    margin: -3.9rem 0 0 -1.25rem;
}

.StudentAttendanceParentBox .ChartInfoW {
    width: 22%;
}

.StudentAttendanceParentBox .NoTextBox {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.StudentAttendanceParentBox .NotFoundImg {
    width: 30%;
    object-fit: contain;
}

.StudentAttendanceParentBox .NoTextTypo {
    font-size: 1.75rem;
    color: #3f526d;
    font-weight: 600;
    margin-top: 1rem;
}

.StudentAttendanceParentBox .year1 {
    width: 150px;
    padding: 10px 18px 10px 18px;
    position: relative;
    font-size: 18px;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.2;
    border-radius: 4px;
    background-color: #fff;
    text-align: center;
}

.StudentAttendanceParentBox .attendance_under_present {
    height: 19px;
    width: 50%;
    margin: 6px 2px 2px 9px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.StudentAttendanceParentBox .attendance_number {
    height: 19px;
    margin: 6px 0 2px 0px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.StudentAttendanceParentBox .presentAbsent {
    height: 24px;
    margin: 0px 24px 0px 10px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.StudentAttendanceParentBox .attendance_modale_graph_heading {
    height: 33px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 20px 50px 10px 0;
}

.StudentAttendanceParentBox .attendance_letter_table_selected {
    width: 12px;
    height: 20px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: #fff;
}

.StudentAttendanceParentBox .attendance_ltoday {
    height: 27px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.StudentAttendanceParentBox .attendance_nottoday {
    height: 27px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #471c51;
}

.StudentAttendanceParentBox .attendance_letter_table_notselected_p {
    width: 12px;
    height: 20px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: #3dbb3d;
}

.StudentAttendanceParentBox .attendance_letter_table_notselected_a {
    width: 12px;
    height: 20px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: #e02020;
}

.StudentAttendanceParentBox .attendance_letter_table_notselected_l {
    width: 12px;
    height: 20px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: #3f526d;
}

.StudentAttendanceParentBox .attendance_letter_table_outer_notselected_p {
    display: flex;
    width: 2.4375rem;
    margin: 0 10px;
    border-radius: 3px;
    padding: 7px 16px 7px 17px;
    border: solid 1px #3dbb3d;
    justify-content: center;
}

.StudentAttendanceParentBox .attendance_letter_table_outer_notselected_a {
    display: flex;
    width: 2.4375rem;
    margin: 0 10px;
    border-radius: 3px;
    padding: 7px 16px 7px 17px;
    border: solid 1px #e02020;
    justify-content: center;
}

.StudentAttendanceParentBox .attendance_letter_table_outer_notselected_l {
    display: flex;
    width: 2.4375rem;
    margin: 0 10px;
    border-radius: 3px;
    padding: 7px 16px 7px 17px;
    border: solid 1px #3f526d;
    justify-content: center;
}

.StudentAttendanceParentBox .attendance_letter_p {
    display: flex;
    width: 2.4375rem;
    margin: 0 10px;
    border-radius: 3px;
    padding: 7px 16px 7px 17px;
    background-color: #3dbb3d;
    justify-content: center;
    border: solid 1px #3dbb3d;
}

.StudentAttendanceParentBox .attendance_letter_a {
    display: flex;
    width: 2.4375rem;
    margin: 0 10px;
    border-radius: 3px;
    padding: 7px 16px 7px 17px;
    background-color: #e02020;
    justify-content: center;
    border: solid 1px #e02020;
}

.StudentAttendanceParentBox .attendance_letter_l {
    display: flex;
    width: 2.4375rem;
    margin: 0 10px;
    border-radius: 3px;
    padding: 7px 16px 7px 17px;
    background-color: #3f526d;
    justify-content: center;
    border: solid 1px #3f526d;
}

.Date_of_birth_calendar_Icon_register:focus {
    outline: none;
}

.Date_of_birth_calendar_Icon_register {
    background-repeat: no-repeat;
    background-image: url('../../assets/image-support@3x.jpg');
    background-size: 33px 33px;
    background-position: top 11px right 10.3px;
}