.CustomHeaderDiv {
    background-color: #f2f2f2;
    padding: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    height: 4.5vh;
    font-size: 16px;
    font-weight: 600;
    color: #3f526d;
    font-family: "Open sans";
    margin: 8% 5%
}