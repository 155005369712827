.common_select_div_popover {
    height: auto !important;
}
.common_select_div_popover .search_container {
    border-radius: 8px;
    height: 60px;
    position: inherit;
    top: 0%;
}
.common_select_div_popover .search {
    height: 25px;
    width: 25px;
    margin-right: 24px;
    margin-left: 20px;
}
.common_select_div_popover .search_input {
    border: none;
    outline: none;
    color: #3f526d;
    opacity: 0.5;
    font-size: 1.125rem;
    width: "100%",
}
.common_select_div_popover .container {
    width: auto !important;
    max-height: 200px !important; 
    overflow-y: scroll !important;
    padding-right: 10px !important;
}
.common_select_div_popover .no_record_found {
    width: 176px;
    height: 27px;
    font-family: "Open sans";
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-top: 10px;
    margin-bottom: 10px;
}
.common_select_div_popover .data_list {
    padding: 10px 0px 0px 0px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-bottom: solid 0.5px rgba(63, 82, 109,0.34) !important;
    cursor: pointer !important;
}
.common_select_div_popover .data_list_title {
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
}
.transparent_bg {
    background-color: transparent !important;
}
.common_select_div_popover .btn_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    align-items: center;
}
.common_select_div_popover .save_btn {
    text-transform: capitalize;
    font-size: 18px;
    border-radius: 8px;
    padding: 12px 41px 12px 42px;
    margin: 0px 24px 0px 0px;
}
.common_select_div_popover .cancel_btn {
    text-transform: capitalize;
    font-size: 16px;
}
.common_select_div_popover .mt_5 {
    margin-top: 5px !important;
}
.common_select_div_popover .search_container2 {
    border-radius: 6px;
    height: 40px;
    position: inherit;
    top: 0%;
    background-color: #f9f8fd;
}
.common_select_div_popover .bg_color {
    background-color: #f9f8fd !important;
}
.common_select_div_popover .w_225 {
    width: 225px !important;
}
.common_select_div_popover .container2 {
    max-height: 200px !important; 
    overflow-y: scroll !important;
}
.common_select_div_popover .data_list2 {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-bottom: solid 0.5px rgba(63, 82, 109,0.34) !important;
    cursor: pointer !important;
}
.common_select_div_popover .btn_container2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    align-items: center;
}
.common_select_div_popover .save_btn2 {
    text-transform: capitalize;
    font-size: 18px;
    border-radius: 8px;
    padding: 5px 30px;
    margin: 0px 24px 0px 0px;
}
.common_select_div_popover .title {
    font-size: 18px;
    font-family: "Open sans";
    font-weight: 600;
    color: #3f526d;
}