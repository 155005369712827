.SuperAdminLoginRoot {
    flex-grow: 1;
    height: 100%;
}

.SuperAdminLoginH100 {
    height: 100%;
}

.SuperAdminLoginLoginImageDiv {
    position: relative;
}

.SuperAdminLoginHalfCircleImage {
    width: 100%;
    height: 100%;
    background-color: rgba(142, 123, 253, 1);
    position: absolute;
    z-index: 0;
    object-fit: cover
}

.SuperAdminLoginImageStudenIllustratorImage {
    width: 56%;
    height: auto;
    bottom: 0%;
    position: absolute;
    transform: translateX(105%)
}

.SuperAdminLoginLogoImage {
    width: auto;
    height: 68px;
    position: relative;
    margin: 33px 29px 29px 30px;
}

.SuperAdminLoginTextlabelQuoteText {
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    position: relative;
    font-family: 'Open sans';
    font-size: 4.5rem;
    margin: 25px 34px;
}

.SuperAdminLoginImageCloudSmallImage {
    width: 65px;
    height: 51px;
    position: absolute;
    top: 61%;
    left: 30%;
}

.SuperAdminLoginImageCloudBigImage {
    width: 151px;
    height: 117px;
    bottom: 10%;
    left: 8%;
    position: absolute;
}

.SuperAdminLoginLoginDiv {
    justify-content: center;
    width: 60%;
    margin: 5% 0px 4% 0px;
}

.SuperAdminLoginAttrbutedTextlabelDiv {
    text-align: right;
    font-family: 'Open sans';
    font-size: 16px;
    margin: 5% 0px 4% 0px;
}

.SuperAdminLoginAttrbutedTextlabelSigninText {
    color: #03014c
}

.SuperAdminLoginAttrbutedTextlabelSigninText2 {
    font-weight: bold;
    margin: 59px 3.5px 50px 9px;
    font-size: 16px;
    color: #000000;
}

.SuperAdminLoginTextlabelWelcomeText {
    font-size: 35.1px;
    font-weight: bold;
    margin: 4% 0px 3% 0px;
    font-family: 'Open sans';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-top: 25px;
}

.SuperAdminLoginTextlabelFollowInstructionText {
    color: #03014c;
    font-size: 14px;
    font-family: 'Open sans';
    line-height: 1.5;
    opacity: 0.5;
    width: 90%;
    padding: 0 41.5px 0 0px;
    margin: 19.3px 0px 29.1px 0px;
}

.SuperAdminLoginTextField {
    width: 100%
}

.SuperAdminLoginForgotPasswordText {
    text-align: right;
    font-weight: bold;
    font-family: 'Open sans';
    cursor: pointer;
    margin: 20px 0 30px 0;
}

.SuperAdminLoginOrTextLine {
    text-align: center;
    font-family: 'Open sans';
    border-bottom: 1px solid rgba(183, 183, 204, 1);
    line-height: 0.1em;
    font-size: 14px;
}

.SuperAdminLoginOrTextLabel {
    background: white;
    padding: 0 10px;
    color: rgba(183, 183, 204, 1);
}

.SuperAdminLoginSocialMediaDiv {
    margin-bottom: 14px;
    margin-top: 20px;
    justify-content: space-around;
}

.SuperAdminLoginMainDiv {
    height: 100vh;
}

.SuperAdminLoginMT20 {
    margin-top: 20px;
}

.SuperAdminLoginSocialMedia {
    display: flex;
    justify-content: flex-end;
}