.teacherViewModal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.teacherViewModal .addTaskPopup {
    background: #fff;
    border: 1px solid #979797;
    outline: none;
    border-radius: 29px;
    width: 75%;
    max-height: 85%;
    padding: 32px 34px 34px 34px;
}

.teacherViewModal .centerFText {
    display: flex;
    align-items: center;
}

.teacherViewModal .taskTitle {
    font-family: "Open sans";
    font-size: 1.375rem;
    font-weight: 600;
    color: #3f526d;
}

.teacherViewModal .dividerSection {
    margin: 20px 0;
    opacity: 0.5;
    border: solid 0.5px #999;
}

.teacherViewModal .teacherTaskAttributsGrid {
    display: flex;
    justify-content: space-between;
}

.teacherViewModal .subjTitle {  
    font-size: 1.625rem;
    font-weight: 600;
    color: #3f526d;
    font-family: "Open sans";
    text-transform: capitalize;
    overflow-wrap: anywhere;
}

.teacherViewModal .dayList {
    font-size: 1rem;
    font-weight: 600;
    color: #79869f;
    font-family: "Open sans";
}

.teacherViewModal .ml12 {
    margin-left: 12px;
}

.teacherViewModal .flName {
    font-family: "Open sans";
    font-size: 1.11rem;
    color: #3f526d;
    font-weight: 600;
    overflow-wrap: anywhere;
}

.teacherViewModal .emailAttributes {
    font-family: "Open sans";
    font-size: 1rem;
    color: #999;
    font-weight: normal;
    overflow-wrap: anywhere;
}

.teacherViewModal .subjectCenter {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.teacherViewModal .memberLength {
    font-size: 1.111rem;
    font-family: "Open sans";
    letter-spacing: normal;
    text-transform: capitalize;
    color: #3f526d;
}

.teacherViewModal .pendingBtn {
    margin: 0px 7px 1px 16px;
    border-radius: 4px;
}

.teacherViewModal .subjectName {
    font-family: "Open sans";
    font-size: 1.25rem;
    color: #3f526d;
    font-weight: 600;
}

.teacherViewModal .fileGrid {
    margin: 25px;
    display: flex;
    align-items: center;
}

.teacherViewModal .fileIcon {
    width: 24px;
    height: 24px;
}

.teacherViewModal .fileText {
    font-family: "Open sans";
    font-size: 1.25rem;
    color: #3f526d;
    font-weight: 600;
    margin-right: 12px;
}

.teacherViewModal .fileDataName {
    color: #3f526d;
    font-size: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 4px 4px 7px;
    font-family: "Open sans";
}

.teacherViewModal .fileSize {
    color: #79869f;
    font-size: 0.875rem;
    font-weight: normal;
    text-transform: capitalize;
    margin: 0 64px 4px 0;
    font-family: "Open sans";
}

.teacherViewModal .urlImg {
    width: 36px;
    height: 40px;
    margin: 4px 7px 5px;
}

.teacherViewModal .gradeGrid {
    display: flex;
    align-items: center;
}

.teacherViewModal .createAvtar {
    margin-left: 12px;
    width: 50px;
    height: 50px;
}

.teacherViewModal .subjectNameAttributs {
    font-family: "Open sans";
    font-size: 1.25rem;
    font-weight: normal;
    color: #79869f;
    margin-left: 12px;
    text-transform: capitalize;
}

.teacherViewModal .link {
    margin: 0px 9px 0px 12px;
}

.teacherViewModal .mt15 {
    margin-top: 15px;
}

.teacherViewModal .mt25 {
    margin-top: 25px;
}

.teacherViewModal .taskDetails {
    font-family: "Open sans";
    font-size: 1.25rem;
    color: #3f526d;
    font-weight: 600;
}

.teacherViewModal .gradeMainGrid {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.teacherViewModal .descriptionGrid {
    margin-top: 12px;
    padding: 13px 21px 14px 16px;
    border-radius: 6px;
    background: #f9f8fd;
}

.teacherViewModal .descriptionText {
    font-family: "Open sans";
    font-size: 1.11rem;
    color: #3f526d;
    font-weight: normal;
    line-height: 1.51px;
}