.FilterScreenDialogModal {
    margin-top: 5px;
    height: auto;
}
.FilterScreenDialogModal .innerBox {
    width: 100%;
    padding: 10px 20px 30px;
}
.FilterScreenDialogModal .headText {
    text-transform: none;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 8px;
}
.FilterScreenDialogModal .listingContainer {
    width: 100%;
    border-radius: 4px;
    border: 0.3px solid rgba(63, 82, 109, .5);
    background: #f9f8fd;
    cursor: pointer;
    margin: 10px 0px;
}
.FilterScreenDialogModal .item {
    padding: 0px 6px;
    margin-top: 5px;
}
.FilterScreenDialogModal .listingData {
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: space-between;
}
.FilterScreenDialogModal .listContainer {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.FilterScreenDialogModal .flex {
    display: flex;
}
.FilterScreenDialogModal .listDataTypo {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.FilterScreenDialogModal .clearIcon {
    font-size: 14px;
}
.FilterScreenDialogModal .avatarImg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}
.FilterScreenDialogModal .btnContainer {
    margin-top: 20px;
}
.FilterScreenDialogModal .btnApply {
    border-radius: 5px;
    text-transform: capitalize;
    font-size: 1rem;
    margin-right: 12px;
    padding: 5px 30px;
    max-width: 40%;
}
.FilterScreenDialogModal .cancelBtn {
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    padding: 4px 30px;
    box-shadow: none;
    max-width: 40%;
}