.TaskGradebookMarginLR20 {
    margin: 0 20px;
}

.gradebookTable .TaskGradebookSubTable {
    width: auto
}

.TaskGradebookDueDateTableCell {
    min-width: 72px;
}

.TaskGradebookTableCell {
    word-wrap: break-word;
    text-align: center;
    width: 52px;
}

.TaskGradebookFirstTableCell {
    position: sticky;
    left: 0px;
    top: 0px;
}