.delete_division_modal_container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.delete_division_modal {
    background-color: white !important;
    border: 1px solid #979797 !important;
    outline: none !important;
    border-radius: 8px !important;
    width:auto !important;
    height: auto !important;
    box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.2) !important;
    text-align: center !important;
}
.delete_division_success_modal_padding {
    padding: 25px 20px !important;
}
.delete_division_alert_modal_padding {
    padding: 30px 30px !important;
}
.delete_division_success_img {
    width:90px !important;
    height:90px !important;
}
.delete_division_delete_text_head {
    font-family: "Open sans" !important;
    font-size: 4.4vh !important;
    font-weight: bold !important;
    color: #3f526d !important;
    text-align: center !important;
}
.delete_division_delete_text_para {
    font-family: "Open sans" !important;
    font-size: 3vh !important;
    color: #3f526d !important;
    text-align: center !important;
}
.delete_division_delete_btn_ok {
    background: #7f6cfc !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    border-radius: 8px !important;
    border: solid 1px #7f6cfc !important;
}
.delete_division_action_container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}
.delete_division_delete_button {
    background: #ee404c !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    border-radius: 8px !important;
}
.delete_division_cancel_button {
    background: #fff !important;
    color: #3f526d !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
}
.p_40 {
    padding: 40px !important;
}
.mt_10 {
    margin-top: 10px !important;
}
.mt_30 {
    margin-top: 30px !important;
}
.mt_14 {
    margin-top: 14px !important;
}
.mt_24 {
    margin-top: 24px !important;
}
.mt_40 {
    margin-top: 40px !important;
}
.dlt_division_modal_ok_btn_p {
    padding: 7px 49px 7px 50px !important;
}
.dlt_classes_modal_ok_btn_p {
    padding: 13px 49px 13px 50px !important;
}
.dlt_modal_divisions_delete_btn_spacing {
    margin: 0px 50px 0px 0px !important;
    padding: 7px 51px 7px 52px !important;

}
.dlt_modal_classes_delete_btn_spacing {
    margin: 0px 25px 0px 0px !important;
    padding: 10px 25px 10px 25px !important;
}