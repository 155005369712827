.TeacherTimeTableMainGrid {
    margin: 10px 0px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TeacherTimeTableSubMainGrid {
    margin-left: 0px !important;
    width: unset !important;
    align-items: center;
}

.TeacherTimeTableTitleMainGrid {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.TeacherTimeTableTitleMainDiv {
    justify-content: flex-start !important;
    align-items: center !important;
    width: 90px;
    display: flex;
}

.TeacherTimeTableYearTxt {
    width: 140px;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 8px 12px 8px 12px;
    position: relative;
    font-size: 18px;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    transition:
        border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.2;
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
    margin-left: 5px;
}

.TeacherTimeTableTitleTxt {
    text-transform: none;
    font-weight: 600 !important;
}

.TeacherTimeTableCreateEventBtnGrid {
    display: flex;
    justify-content: flex-end;
}

.TeacherTimeTableCreateEventBtn {
    padding: 10px 20px 10px 20px !important;
    border-radius: 8px !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #ed664d !important;
    background-color: #fff !important;
}

.TeacherTimeTableCreateEventBtnTxt {
    font-family: Open sans !important;
    font-weight: 600 !important;
    color: #ed664d;
    text-transform: none;
}

.TeacherTimeTableTodayBtnGrid {
    background-color: white;
    border-radius: 8px;
    margin: 19px 2px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    padding: 20px 20px 60px;
    min-height: 55vh;
    position: relative;
    flex-direction: row;
}

.TeacherTimeTableTodayBtnSubGrid {
    flex-direction: row;
}

.TeacherTimeTableTodayBtnSubDiv {
    display: flex;
    margin-bottom: 20px !important
}

.TeacherTimeTableCalendarDiv {
    display: flex !important;
    height: 58px;
    align-items: center !important;
}

.TeacherTimeTableBtnMainDiv {
    display: flex;
    justify-content: flex-end;
    min-width: 50%;
}

.TeacherTimeTableDailyBtnDiv {
    width: 113px;
    margin-right: 10px !important;
    border-radius: 6px !important;
    margin-left: 50px !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-align: center;
}

.TeacherTimeTableBtn {
    font-size: 20px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.TeacherTimeTableWeeklyBtnDiv {
    width: 113px;
    margin-right: 10px !important;
    border-radius: 6px !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-align: center;
}

.TeacherTimeTableAddClassBtnDiv {
    height: 56px !important;
    border-radius: 6px !important;
    text-transform: capitalize !important;
    color: #fff !important;
    padding-right: 25px !important;
    background: #ed664d !important
}

.TeacherTimeTableAddClassBtnImg {
    padding: 0 12px 0 7px
}

.TeacherTimeTableAddClassBtnTxt {
    font-size: 16px !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.TeacherTimeTableFullCalendarDiv {
    margin-top: 0.3% !important;
    width: 100% !important;
    overflow: auto !important
}