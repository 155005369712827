.AddEditClassPeriodDialogBox {
    border-radius: 8px !important;
}

.AddEditClassPeriodDialogBox .innerBoxContainer {
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.AddEditClassPeriodDialogBox .flexBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.AddEditClassPeriodDialogBox .headingText {
    color: #3f526d;
    font-size: 1.5rem;
    font-weight: 600;
}

.AddEditClassPeriodDialogBox .closeIcon {
    cursor: pointer;
    font-size: 38px;
    color: lightgray;
}

.AddEditClassPeriodDialogBox .formContainer {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.AddEditClassPeriodDialogBox .pt15 {
    padding-top: 15px;
}

.AddEditClassPeriodDialogBox .checkboxStyle {
    color: #7f6cfc;
}

.AddEditClassPeriodDialogBox .errorText {
    color: red;
    margin: 3px 14px 0;
}

.AddEditClassPeriodDialogBox .btnContainer {
    margin-top: 2rem;
    padding-left: 15px;
}

.AddEditClassPeriodDialogBox .updateBtn {
    padding: 8px 40.5px 8px 41.5px;
    border-radius: 8px;
    background: #7f6cfc;
}

.AddEditClassPeriodDialogBox .updateBtnTypo {
    text-transform: none;
    font-size: 1.125rem;
    font-weight: 600;
    color: #fff;
}

.AddEditClassPeriodDialogBox .inputField {
    font-size: 1.125rem;
    color: #3f526d;
    opacity: 1 !important;
    padding-left: 0;
    font-weight: 600;
}

.AddEditClassPeriodDialogBox .inputFieldDisabled {
    color: #3f526d;
    padding-left: 0;
    font-weight: 600;
    opacity: 0.5;
}

.AddEditClassPeriodDialogBox .cursorDefault {
    cursor: default;
}