.AutomaticClassPeriodDialogBox {
    border-radius: 8px;
}

.AutomaticClassPeriodDialogBox .innerBox {
    padding: 30px 40px 30px 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.AutomaticClassPeriodDialogBox .mb10 {
    margin-bottom: 10px;
}

.AutomaticClassPeriodDialogBox .addDetailHeading {
    font-size: 20px;
    color: #3f526d;
    font-weight: 600;
}

.AutomaticClassPeriodDialogBox .errorText {
    color: red;
    margin: 3px 14px 0;
}

.AutomaticClassPeriodDialogBox .flexBetween {
    display: flex;
    justify-content: space-between;
}

.AutomaticClassPeriodDialogBox .violetColor {
    color: #7f6cfc;
}

.AutomaticClassPeriodDialogBox .dividerStyles {
    width: 100%;
    margin: 20px 0 10px;
}

.AutomaticClassPeriodDialogBox .btnContainer {
    margin-top: 20px;
}

.AutomaticClassPeriodDialogBox .addButton {
    padding: 8px 16px;
    border-radius: 8px;
    border: solid 1px #7f6cfc;
    font-size: 1.125rem;
}

.AutomaticClassPeriodDialogBox .AddButtonImg {
    width: 14px;
}

.AutomaticClassPeriodDialogBox .addButtonTypo {
    text-transform: none;
    font-size: 1.125rem;
    font-weight: 600;
    margin-left: 10px;
    color: #7f6cfc;
}

.AutomaticClassPeriodDialogBox .canceButtonTypo {
    text-transform: none;
    font-size: 1.125rem;
    font-weight: 600;
    color: #3f526d;
}

.AutomaticClassPeriodDialogBox .cancelButton {
    max-width: 40%;
    padding: 11px 24px 11px 25px;
    border-radius: 6px;
    background: #fff;
    margin-left: 20px;
}

.AutomaticClassPeriodDialogBox .mt2rem {
    margin-top: 2rem;
}

.AutomaticClassPeriodDialogBox .createButton {
    max-width: 40%;
    padding: 8px 40.5px 8px 41.5px;
    border-radius: 8px;
    background: #7f6cfc;
}

.AutomaticClassPeriodDialogBox .createButtonTypo {
    text-transform: none;
    font-size: 1.125rem;
    font-weight: 600;
    color: #fff;
}

.AutomaticClassPeriodDialogBox .textFieldStyles {
    color: #3f526d;
    opacity: 1;
}