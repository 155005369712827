@media (max-width:1023px) {
.react-router-modal__modal{
    transform: translate(0%, 0%) !important;
    width: auto!important;
    left: 0!important;
    right: 0!important;
    margin: 0 15px!important;
    top: 18% !important;
}

.react-router-modal__modal-open {
    position: relative !important;
}

}

@media (max-width:767px) {
    .Inner-Alert {
        padding: 15px !important;
    }

    .Inner-Alert p {
        font-size: 20px !important;
    }

    .Inner-Alert .otptext{
        font-size: 16px !important;
        margin-top: 20px !important;
    }
     .ok-btn{
        margin-top: 30px !important;
     }

     .ok-btn button{
        padding: 8px 30px 8px 30px !important;
        font-size: 14px !important;
     }


}
@media (max-width:415px) {
.react-router-modal__modal {
    width: 92% !important;
    
}

.react-router-modal__modal-open {
    position: fixed !important;
}

}

