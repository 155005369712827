.WeekEventLunchTimeDiv {
    display: flex;
    align-items: center !important;
    padding-top: 32px !important
}

.WeekEventLunchTimeImg {
    height: 36px;
    width: 36px
}

.WeekEventLunchTimeTxt {
    font-family: "Open sans" !important;
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 8px;
}

.WeekEventPeriodMainDiv {
    padding: 5px 0px 0px 5px
}

.WeekEventPeriodMainDivImg {
    height: 24px
}

.WeekEventPeriodMainImg {
    height: 16px;
}

.WeekEventPeriodMainTxt {
    font-family: "Open sans";
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.WeekEventDateDiv {
    display: flex;
    justify-content: space-between
}

.WeekEventDateTxt {
    font-family: "Open sans" !important;
    font-size: 12px !important;
    line-height: 1.67 !important
}

.WeekEventDateImg {
    height: 14px;
    width: 14px;
    margin-right: 15px;
    margin-top: 2px;
}