.uploadclass-popup{
  background-color: #fff;
  border:1px solid #979797 !important;
  outline: none;
  border-radius: 8px;
  width: 50%;
  height: auto;
  padding: 36px 32px 31px 30px;
  box-shadow:0 0 24px 4px rgba(0, 0, 0, 0.2);
}
.add_class_modal_container {
  display: flex !important;
  align-items:center !important;
  justify-content: center !important;
}
.add_class_modal_title {
  font-size: 20px !important;
  font-weight: 600 !important;
}
.add_class_form_box {
  max-height: 300px !important;
  overflow-y: scroll;
  padding-right: 10px !important;
}
.add_class_form_label {
  font-size: 20px !important;
  font-weight: 600 !important;
}
.add_class_cursor_pointer {
  cursor: pointer;
}
.add_class_select_options_inner_box {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 10px !important;
    border-radius: 6px !important;
    margin-top: 10px !important;
    align-items: center;
    background-image:linear-gradient(to bottom, #fff, #fff), linear-gradient(to top, #af38cb 126%, #7f6cfc -23%);
    overflow: auto !important;
}
.add_class_input_text {
    font-size: 20px !important;
    color: #3f526d;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.add_class_text {
  font-size: 20px !important;
  color: rgb(63, 82, 109,0.32) !important;
}
.add_class_flex_box {
  display: flex;
}
.add_class_flex_box_row {
  display: flex;
  flex-direction: row;
}
.add_class_error_text {
  color: red !important;
  margin: 3px 14px 0 !important;
}
.add_class_full_width {
  width: 100% !important;
}
.add_class_mt_10 {
  margin-top: 10px !important;
}
.add_class_textfield {
  width: 100% !important;
  background-color: #fff !important;
  border-radius: 8.8px !important;
  margin: 10px 0px 0px 0px !important;
  border: none !important;
}
.add_class_native_select {
    width: 100% !important;
    margin-top: 10px !important;
    color: red !important;
}
.add_class_opacity {
  opacity: 0.32 !important;
}
.add_class_action_btn_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px !important;
  align-items: center;
}
.add_class_save_btn {
  text-transform: capitalize !important;
  font-size: 18px !important;
  border-radius: 8px !important;
  padding: 13px 59px 13px 60px !important;
  margin: 0px 26px 0px 0px !important;
}
.add_class_cancel_btn {
  background: #fff !important;
  color: #3f526d !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}

@media screen and (max-width: 767px) {

  .flex-wrap {
    flex-wrap: wrap;
  }

  .user-account-left, .user-account-right{
    width: auto !important;
  }

  .flex-wrap button {

    padding: 10px 15px !important;
    margin-top: 15px;
  }
  
  .admindivision-popup{
    padding: 20px 15px !important;
  }
  .division-table1 table{
    width: 700px !important;
    max-width: 700px !important;
  }

  .division-table1{
    width: 100% !important;
    overflow-x: auto;
  }

  .admindivision-popup .MuiFormControl-root {
    width: auto !important;
  }

  .row-scroll {
    width: 700px !important;
    max-width: 700px;
  }
  .uploadclass-popup{
    width: 94%;
    padding: 15px;
  }
  .uploadclass-popup button {
    padding: 5px 30px 4px 31px !important;
  }

  .filterpopup .MuiPopover-paper {
    width: 80% !important;
  }
}
