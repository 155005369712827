.admin_division_parent_container {
    margin: 14px 0px 21px 0px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.admin_division_main_title {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
}
.admin_division_add_button {
    cursor: pointer !important;
    border-radius: 6px !important;
    padding: 11px 32px 11px 33px !important;
    text-transform: capitalize !important;
}
.admin_division_table_container {
    padding: 30px 20px 30px !important;
    margin-top: 20 !important;
    border-radius: 8 !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
}
.admin_division_table {
    width: 100% !important;
}
.admin_division_border_b_none {
    border-bottom: none !important;
}
.admin_division_table_cell {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: normal !important;
    background-color: #ded9ff !important;
    border-bottom: none !important;
}
.admin_division_w_60_percent {
    width: 60% !important;
    padding:12px 12px 12px 25px !important;
}
.admin_division_w_20_percent {
    width: 20% !important;
    padding:12px !important;
}
.admin_division_border_left_radius {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}
.admin_division_border_right_radius {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}
.admin_division_pl_25 {
    padding:12px 12px 12px 25px !important;
}
.admin_division_p_12 {
    padding: 12px 12px 12px 12px !important;
}
.admin_division_table_row{
    background: #f9f8fd !important;
}
.admin_division_table_column{
    border-bottom: none !important;
}
.admin_division_table_column_data {
    font-size: 1.125rem !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}
.admin_division_flex_evenly {
    display: flex !important;
    justify-content: space-evenly !important;
}
.admin_division_cursor {
    cursor: pointer !important;
}
.admin_division_no_data {
    display: flex !important;
    justify-content: center !important;
    font-size: 16px !important;
    margin: 10px 23px !important;
    font-family: "Open Sans";
    color: #3f526d !important;
    font-weight: 600 !important;
}
.admin_division_pagination_box {
    text-align: end !important;
    margin-top: 25 !important;
}
.admin_division_popup_box {
    padding: 60px 62px 62px 62px !important;
    border-radius: 8 !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
    min-width: 600px !important;
}
.admin_division_formInput_textField {
    width: 100% !important;
    border-radius: 6px !important;
    text-align: start;
    color: #3f526d !important;
    font-size: 1.125rem !important;
    font-family: "Open sans" !important;
}
.admin_division_update_btn_box{
    display: flex !important;
}
.admin_division_update_btn_text{
    text-transform: none !important;
    font-size: 1.125rem !important;
    font-weight: 600 !important;
}
.admin_division_cancel_btn{
    /* border:solid 0.5px #7f6cfc; */
    padding: 11px 24px 11px 25px !important;
    border-radius: 6 !important;
    background-color: #fff;
}
.admin_division_cancel_btn_text {
    text-transform: none !important;
    font-size: 1.125rem !important;
    font-family: "Open Sans" !important;
    font-weight: 600 !important;
    color: #3f526d !important;
}
.admin_division_add_edit_text {
    font-size:1.25rem !important;
    font-weight:600 !important;
}
.mt_0 {
    margin-top: 0 !important;
}
.h_10 {
    height: 10px !important;
}
.border_radius_8 {
    border-radius: 8px !important;
}
.mb_27 {
    margin-bottom: 27px !important;
}
.mb_14 {
    margin-bottom: 14px !important;
}
.mt_30 {
    margin-top: 30px !important;
}
.ml_20 {
    margin-left: 20px !important;
}