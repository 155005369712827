.QuestionBankMainBlock {
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-top: 2%;
}

.topRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 !important;
}
.GoBackIcon {
  margin-right: 10px !important;
}
.schedule_modal_flex_direction {
  flex-direction: column;
}
.date-input-schedule > input {
  width: 100%;
  padding: 16px 28px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid #c1c1c1;
  position: relative;
  color: #3f526d;
  font-family: 'Open sans';
  cursor: pointer;
}
.Schedule_modal_root_box {
  flex-direction: column;
  position: relative;
}
.date-input-schedule > input:hover {
  outline: none;
  border: 1px solid black;
}

.time_picker_icon:focus {
  outline: none;
}

.time_picker_icon {
  background-repeat: no-repeat !important;
  background-image: url('../../assets/clock.png') !important;
  background-size: 33px 33px !important;
  background-position: top 11px right 10.3px !important;
}

.Date_of_start_end_Icon:focus {
  outline: none;
}

.Date_of_start_end_Icon {
  background-repeat: no-repeat !important;
  background-image: url('../../assets/image-calendar.jpg') !important;
  background-size: 33px 33px !important;
  background-position: top 11px right 10.3px !important;
}

.scheduleTimePicker {
  width: 100%;
}

.scheduleTimePicker .MuiInputBase-input {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.scheduleTimePicker .MuiFormHelperText-contained {
  margin-left: 0;
  margin-right: 0;
  margin-top: 3%;
  margin-bottom: 2%;
}

.location {
  width: 100%;
}

.go-back {
  display: flex;
  width: 100%;
  align-items: center;
}

.go-back .BackText{
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.filter-span {
  display: flex;
  padding: 3%;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 2px #ccc;
  font-family: Open Sans;
  width: 200px;
}

.filter {
  margin-left: 10%;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f526d;
}

.marginTop {
  margin-top: 1% !important;
}

.clearFilter {
  margin-right: 12px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
}

.filterBtn {
  text-transform: capitalize !important;
  width: 160px !important;
  height: 58px !important;
  padding: 18px 36px !important;
  border-radius: 6px !important;
  font-size: 15px !important;
  cursor: pointer !important;
}

.filterBtn:disabled.btn_disabled {
  text-transform: capitalize !important;
  width: 160px !important;
  height: 58px !important;
  padding: 18px 36px !important;
  border-radius: 6px !important;
  font-size: 15px !important;
  cursor: pointer !important;
  opacity: 0.5;
  color: #fff;
}

.justifyCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justifyStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.justifyEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.alignEnd {
  display: flex;
  align-items: flex-end !important;
}

.fieldLabels {
  font-size: 18px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f526d;
  margin: 19px 30px 10px 27px;
}

.nativeSelect {
  margin-top: 2%;
  width: 90%;
}
.nativeSelect svg {
  font-size: 34px !important;
  color: #3f526d !important;
}
.fieldGrid {
  margin-top: 2% !important;
}

.sharedroot {
  width: 100%;
  height: auto;
  max-height: 250px;
  overflow: auto;
}

.questionText {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  color: #3f526d;
}

.iconsSpan {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconsBorder {
  border: 1px solid #3f526d;
  padding: 2%;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.icons {
  object-fit: contain;
}

.underline {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.checkboxSpan {
  margin-right: 2%;
}

.checkbox.MuiCheckbox-colorSecondary.Mui-checked {
  color: #3a60d7;
}

.teacherInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teacherName {
  font-size: 12px;
  margin-left: 4%;
  color: #3f526d;
}

.avatar {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain;
  border-radius: 50%;
}

.avatarSpan {
  border-radius: 50%;
  border: 1px solid #979797;
  width: 45px;
  height: 45px;
  object-fit: contain;
  margin: 0 7px !important;
}

.questionContainer {
  border-bottom: 1px solid #ccc;
}

.QuestionRow {
  margin-bottom: 1% !important;
  margin-top: 1% !important;
}

.QuestionRow .QuestionCheckBox{
  margin-left: 0px;
}

.QuestionRow .QuestionText{
  font-size: 18px;
  font-weight: 600;
}
.QuestionRow .PreviewQuestion{
  margin-right: 15px;
  cursor: pointer;
}

.QuestionRow .TeacherName{
  margin-left: 4%;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.QuestionBankMainBlock .PreviewBtn {
  padding: 1%;
  font-size: 16px;
  text-transform: capitalize;
  width: 120px;
}

.QuestionBankMainBlock .SubjectTextColor {
  font-size: 18px;
  font-weight: 600;
}

.loadMoreBtnDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.loadMoreBtnDiv .loadMoreBtn {
  padding: 1%;
  font-size: 16px;
  text-transform: capitalize;
  width: 150px;
  height: 48px;
  font-weight: 600;
}

.PreviewQuestionListModal {
  max-height: 90vh;
  background: #fff;
  width: 65%;
  border-radius: 8px;
  padding: 20px 24px;
}

.PreviewQuestionsDiv {
  max-height: 64vh;
  overflow: auto;
}

.QuestionTypeComponent {
  font-size: 16px;
  font-weight: 600;
}

.HTMLContent{
  margin-left: 10px;
}

.DFlex{
  display: flex;
}

.QuestionDetails{
  padding: 0px 0px 25px 0px;
  display: flex;
}

.QuestionDetails .AnswerTypeComponent {
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CreateAssessmentDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.CreateAssessmentDiv .CreateAssessmentBtn {
  padding: 12px 15px ;
  border-radius: 6px ;
  text-transform: capitalize ;
  width: 210px;
  height: 60px;
  font-size: 16px ;
  font-weight: 600 ;
}

.no-items {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: rgb(63, 82, 109);
}

.no-items > span {
  font-family: 'Open sans';
  font-size: 18px;
}

.QuestionDivider {
  border-bottom: 0.5px solid #979797;
  margin-bottom: 30px !important;
}
.PreviewQuestionListModal .TitleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PreviewQuestionListModal .PreviewTitle {
  font-size: 18px;
  font-weight: 600;
}

.PreviewQuestionListModal .CloseIcon{
  cursor: pointer;
  font-size: 36px;
  color: lightGray;
}

.PreviewQuestionListModal .TotalQuestionBox{
  margin-top: 15px;
  margin-bottom: 15px;
}

.PreviewQuestionListModal .ButtonGrid{
  text-align: center;
}
.PreviewQuestionListModal .OkButton{
  padding: 0px 16px;
  height: 48px;
  width: 130px;
  border-radius: 6px;
  text-transform: capitalize;
  margin-top: 10px;
}

.PreviewQuestionModal {
  max-height: 90%;
  overflow: auto;
  width: 65% !important;
  background: #fff;
  border-radius: 6px;
  padding: 20px 24px;
}

.PreviewQuestionModal .FlexEnd{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.PreviewQuestionModal .CloseIcon{
  cursor: pointer;
  font-size: 36px;
  color: lightGray;
}

.PreviewQuestionModal .DFlex{
  display: flex;
}

.PreviewQuestionModal .ML2P{
  margin-left: 2%;
}

.PreviewQuestionModal .AnswerGrid{
  padding: 0px 20px 8px 20px;
}

.PreviewQuestionModal .AnswerGrid .MR10{
  margin-right: 10px;
}

.PreviewQuestionModal .AnswerGrid .MTN20{
  margin-top: -20px;
}

.PreviewQuestionModal .AnswerGrid .VideoGrid{
  display: flex;
  flex-direction: row;
  margin: 30px 0px;
  position: relative;
}

.PreviewQuestionModal .AnswerGrid .VideoGrid .ColumnCenter{
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.PreviewQuestionModal .AnswerGrid .TrueFalseGrid{
  align-items: center; 
  margin-bottom: 16px;
}

.TrueFalseGrid .MainContainer{
  padding: 13px 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  border: solid 1px #fff;
  background-color: #fff;
  align-items: center;
}

.TrueFalseGrid .MainContainer .TitleBox{
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  width: 44px;
  height: 44px;
}

.TrueFalseGrid .MainContainer .TitleBox .Title{
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color:#fff;
}

.TrueFalseGrid .MainContainer .OptionGrid{
  font-size: 20px;
  font-weight: 600;
  padding-left: 10px;
  margin-left: 10px;
  word-break: break-all;
}

.TrueFalseGrid .MainContainer .OptionGrid .OptionImage{
  height: 35px;
  cursor: pointer;
}

.TrueFalseGrid .MainContainer .CorrectOptionGrid{
  justify-content: flex-end;
  display: flex; 
}

.TrueFalseGrid .MainContainer .CorrectOptionGrid .ImageBox{
  width: 25px;
  height: 25px;
  background: green;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TrueFalseGrid .MainContainer .CorrectOptionGrid .ImageBox .Image{
  width: 16px;
  height: 16px;
  object-fit: contain;
}


.PreviewQuestionModal .AnswerGrid .FlashCardGrid{
  position: relative;
  height: 520px;
  margin-bottom: 20px;
  padding: 16px 16px 18px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  border: solid 1px #e1e1e1;
  background-color: #fff;
}

.FlashCardGrid .FlashCardImageDiv{
  height: 267px;
  margin: 0px 0px 20px;
  border-radius: 20px;
  background-color: #ebeef8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.FlashCardGrid .FlashCardImageDiv .FlashCardImage{
  width: 100%; 
  height: 100%;
  object-fit: contain;
}

.FlashCardGrid .FlashCardHintDiv{
  width: 100%;
  height: 52px;
  border-radius: 6px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 11px;
  text-align: center;
}

.FlashCardGrid .FlashCardAnswerDiv{
  width: 100%;
  height: 52px;
  border-radius: 6px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 11px;
}

.FlashCardGrid .MV20{
  margin: 20px 0px;
}

.FlashCardGrid .CardCount{
  opacity: 0.54;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-top: 28px;
}

.PreviewQuestionModal .AnswerGrid .HotSpotGrid{
  overflow: auto;
}

.HotSpotGrid .W90P{
  width: 90%;
}

.HotSpotGrid .HotSpotImage{
  height: 500px;
  width: 500px;
  object-fit: contain;
}

.PreviewQuestionModal .AnswerGrid .LabelImageDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  min-height: 300;
  justify-content: space-evenly;
  position: relative;
}

.LabelImageDiv .PositionRelative{
  position: relative;
}

.LabelImageDiv .BoxCoordinate{
  position: absolute;
  width: 130px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.LabelImageDiv .BoxCoordinate .AnswerText{
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.PreviewQuestionModal .AnswerGrid .MatchGrid{
  display: flex;
  flex-direction: column;
}

.MatchGrid .QuestionListItemGrid{
  margin-bottom: 20px;
}

.MatchGrid .QuestionListItemGrid .LeftSideDiv{
  border: 1px solid #3f526d;
  padding: 10px 20px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
}

.MatchGrid .QuestionListItemGrid .QuestionText{
  font-size: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.MatchGrid .QuestionListItemGrid .QuestionImage{
  height: 50px;
  width: 60px;
  object-fit: contain;
  margin-right: 20px;
}

.MatchGrid .QuestionListItemGrid .RightSideDiv{
  border: 1px dashed #3f526d;
  padding: 10px 20px;
  border-radius: 6;
  height: 100%;
  display: flex;
  align-items: center;
}

.MatchGrid .QuestionListItemGrid .FlexCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.PreviewQuestionModal .AnswerGrid .Content{
  margin-top: 20px;
  /* justify-content: space-evenly; */
}


.PreviewQuestionModal .FlexCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.PreviewQuestionModal .OkButton{
  padding: 0px 16px;
  height: 48px;
  width: 130px;
  border-radius: 6px;
  text-transform: capitalize;
  margin-top: 10px;
}

.QuestionBankSubject {
  background-color: #f1f4ff;
  padding: 19px 20px 20px 50px;
  border-radius: 6px;
  align-items: center;
}

.libraryTab {
  padding: 10px 30px 30px 30px;
  height: 100%;
  min-height: 420px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
}

.assessmentLibraryTab {
  height: 72px;
  font-family: 'Open sans' !important;
  font-size: 18px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-transform: none !important;
}

.assessment-library-outer p {
  margin-bottom: 0;
}

.assessment-library-outer .checkboxSpan {
  margin-bottom: 0;
  margin-right: 5px;
}

.QuestionBankMainGrid .ClassBox {
  padding: 0px;
}

.ClassBox .ClassDropDownTitle{
  padding: 15px 0px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.QuestionBankMainGrid .ListBox{
  padding: 0px; 
  margin-top: 30px
}

.ListBox .NoRecordText {
  font-size: 18px;
}

.ListBox .ListItem{
  margin-top: 10px
}

.ListBox .ListItem .SubjectName{
  font-size: 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.ListBox .ListItem .TotalQuestions{
  font-size: 18px;
  font-weight: 600;
}


.ListBox .ListItem .ImageDiv{
  text-align: center;
}

.ImageDiv .IconButton{
  padding: 0px 7px;
}

.IconButton .Icon{
  height: 22px;
  width: 22px;
  object-fit: contain;
}

/* Preview Questions START*/
.PreviewQuestionsMainModal{
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Preview Questions END */

.Width90P{
    width: 90%;
}

.LibraryAlertModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 29px;
  padding: 32px 18px;
  width: 576px;
}

.LibraryAlertModalBox .ImgDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
}

.LibraryAlertModalBox .AlertImage{
  width: 100px;
  height: 100px;
  object-fit: contain;

}
.LibraryAlertModalBox .TitleDiv{
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 14px;
}
.LibraryAlertModalBox .TitleText{
  margin: 30px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.LibraryAlertModalBox .FlexCenter{
  display: flex;
  justify-content: center;
  gap: 10px !important;
}

.LibraryAlertModalBox .Button{
  padding: 1%;
  text-transform: capitalize;
  font-size: 18px;
}

.schedule_modal .CloseIconDiv{
  position: absolute;
  right: 5px;
  top: 5px;
}

.schedule_modal .BodyText{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
.schedule_modal .ErrorText{
  color: red; 
  font-size: 14px;
}

@media only screen and (min-width: 375px) {
  .PreviewQuestionsDiv {
    max-height: 50vh !important;
  }
}

@media only screen and (min-width: 599px) {
  .PreviewQuestionsDiv {
    max-height: 70vh !important;
  }
}

@media only screen and (min-width: 959px) {
  .PreviewQuestionsDiv {
    max-height: 65vh !important;
  }
}

@media only screen and (min-width: 1279px) {
  .PreviewQuestionsDiv {
    max-height: 63vh !important;
  }
}

@media (max-width: 959px) {
  .PreviewQuestionListModal {
    width: 80%;
  }

  .PreviewQuestionModal {
    width: 80% !important;
  }

  .CreateAssessmentBtn {
    height: 50px;
  }

  .questionBankClass {
    width: 250px !important;
  }
}

@media (max-width: 599px) {
  .PreviewQuestionListModal {
    width: 90%;
    padding: 15px;
  }

  .PreviewQuestionsDiv {
    max-height: 48vh !important;
  }

  .QuestionDivider {
    margin-bottom: 10px !important;
  }

  .PreviewQuestionListModal .PreviewTitle {
    font-size: 16px;
  }

  .PreviewQuestionModal {
    width: 90% !important;
    padding: 15px;
  }

  .CreateAssessmentBtn {
    margin: 10px 0 ;
  }

  .iconsBorder {
    margin-top: 5px;
  }

  .questionBankClass {
    width: 180px !important;
  }

  .QuestionBankSubject {
    padding: 19px 20px 20px 20px;
  }

  .libraryTab {
    padding: 10px 15px 15px 15px;
  }
}
.FilterBox {
  display: flex !important;
  margin-top: 0.5rem !important;
  align-items: center;
}
.FilterBox .DropDownIcon{
  font-size: 36px !important;
}
.AccordionStylesQuestionBank .MuiAccordionSummary-root {
  padding: 0 0 !important;
}
.AccordionStylesQuestionBank .MuiAccordionDetails-root {
  padding: 0 0 0 !important;
}
.AccordionStylesQuestionBank .MuiAccordion-root:before {
  opacity: 0 !important;
}
.AccordionStylesQuestionBank .MuiAccordionSummary-content {
  margin: 0 0 !important;
}
.horizontalLine {
  margin-top:8px !important;
  margin-bottom: 0px !important;
  border-top: 1px solid #ccc !important;
}
.horizontalLine2 {
  margin-top:20px !important;
  margin-bottom: 0px !important;
  border-top: 1px solid #ccc !important;
}
.AccordionStylesQuestionBank .MuiAccordion-root.Mui-expanded {
  margin: 0px 0px !important;
}
.AccordionStylesQuestionBank .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto !important;
}
