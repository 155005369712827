.GradeBookMT20 {
    margin-top: 20px !important;
}

.GradeBookP20 {
    padding: 20px 0px !important;
}
.GradeBookLP20 {
    padding: 0px 20px !important;
}
.GradeBookTabMainDiv {
    align-items: center !important;
    margin: 0px !important;
    width: 100% !important;
}

.GradeBookW100 {
    width: 100% !important;
}