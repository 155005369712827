.edit_class_modal_container {
    display: flex !important;
    align-items:center !important;
    justify-content: center !important;
}
.edit_class_popup{
    background-color: #fff !important;
    border:1px solid #979797 !important;
    outline: none !important;
    border-radius: 8px !important;
    width: 50% !important;
    height: 75% !important;
    padding: 20px 62px !important;
    box-shadow:0 0 24px 4px rgba(0, 0, 0, 0.2) !important;
}
.edit_class_modal_head_title {
    font-family: "Open sans" !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: "#3f526d" !important;
}
.edit_class_form_box {
    max-height: 300px !important;
    overflow-y: scroll !important;
    padding-right: 10px !important;
}
.edit_class_mt_10 {
    margin-top: 10px !important;
}
.edit_class_cursor_pointer {
    cursor: pointer !important;
}
.edit_class_select_options_inner_box {
    display: flex !important;
    justify-content: space-between !important;
    padding: 10px 20px 10px !important;
    border-radius: 6px !important;
    margin-top: 14px !important;
    align-items: center !important;
    background-image:linear-gradient(to bottom, #fff, #fff), linear-gradient(to top, #af38cb 126%, #7f6cfc -23%) !important;
}
.edit_class_input_text {
    font-size: 20px !important;
    color: #3f526d !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.edit_class_text {
    font-size: 20px !important;
    color: rgb(63, 82, 109,0.32) !important;
}
.edit_class_flex_box {
    display: flex !important;
}
.edit_class_flex_box_row {
    display: flex !important;
    flex-direction: row !important;
}
.edit_class_error_text {
    color: red !important;
    margin: 3px 14px 0 !important;
}
.edit_class_full_width {
    width: 100% !important;
}
.edit_class_mt_10 {
    margin-top: 10px !important;
}
.edit_class_textfield {
    width: 100% !important;
    background-color: #fff !important;
    border-radius: 8.8px !important;
    margin: 14px 0px 0px 0px !important;
    border: none !important;
}
.edit_class_native_select {
    width: 100% !important;
    margin-top: 14px !important;
    color: red !important;
}
.edit_class_opacity {
    opacity: 0.32 !important;
}
.edit_class_action_btn_container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    margin-top: 20px !important;
    align-items: center !important;
}
.edit_class_save_btn {
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    border-radius: 8px !important;
    padding: 13px 59px 13px 60px !important;
    margin: 0px 26px 0px 0px !important;
    background-image: linear-gradient(to top, rgb(175, 56, 203), rgb(71, 28, 81));
}
.edit_class_cancel_btn {
    background: #fff !important;
    color: #3f526d !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
}