.App {
  font-family: sans-serif;
  text-align: center;
}

.sidebar {
  /* position: absolute; */
  left: -200px;
  transition: left 0.3s ease-in-out;
  background-image: 'linear-gradient(to top, #a439cb 125%, #481c51 0%)';
}

.sidebar-toggle {
  position: absolute;
  right: -20px;
  height: 24px;
  width: 24px;
  z-index: 1;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  transition: left 0.3s ease-in-out;
  background-color: lightgray;
}

.sidebar.open {
  left: 0;
}

.content.open {
  left: 200px;
}

.sidebarHidden {
  /* height: calc(100vh - 80px) !important; */
  overflow: hidden;
}
.sidebarHidden:hover {
  overflow-y: auto !important;
  overflow-x: hidden;
}
.sidebarHidden::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.sidebarHidden::-webkit-scrollbar-thumb {
  box-shadow: 0 3px 4px 0 #471c51 !important;
}

.logout-modal {
  width: 576px;
}

.SidebarListIcon {
  padding-left: 5px;
}

.SidebarListIcon>svg {
  height: 22px;
  width: 27px;
}

@media screen and (max-width: 767px) {
  .logout-modal {
    width: 93%;
  }
}
