.answerSheet_mainDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 10px;
}

.answerSheet_backArrowDiv {
    font-family: "Open sans";
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #3f526d;
    cursor: pointer;
}

.answerSheet_backArrowImg {
    height: 14px;
    width: 18px;
    object-fit: contain;
    margin-right: 10px
}

.answerSheet_topicDiv {
    padding: 15px 20px 15px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    font-family: "Open sans";
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #999999;
}

.answerSheet_userTxt {
    cursor: pointer;
    border-bottom: 1px solid black;
    color: #506078;
}

.answerSheet_spantxt {
    color: black;
}

.answerSheet_questionDiv {
    padding: 22px 20px 26px;
    margin-bottom: 25px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1)
}

.answerSheet_subQuestionDiv {
    background-color: #9D3B89;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    color: white;
    font-family: "Open sans";
    font-weight: 600 !important
}

.answerSheet_scoreDiv {
    display: flex;
}

.answerSheet_answerDiv {
    margin-top: 10px;
    background-color: #F9F8FD;
    border-radius: 10px;
    font-family: "Open sans";
    font-size: 16px !important;
    font-weight: 600 !important;
}

.answerSheet_subAnswerDiv {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 15px;
    padding-left: 25px;
}

.answerSheet_questionNoDiv {
    max-width: 70%;
}

.answerSheet_checkIconDv {
    display: flex;
    justify-content: center;
}

.answerSheet_checkIcon {
    height: 14px;
}

.answerSheet_myAnsBlockDiv {
    padding: 0px 15px;
    padding-left: 25px;
    display: block;
}

.answerSheet_myAnsNoneDiv {
    padding: 0px 15px;
    padding-left: 25px;
    display: none;
}

.answerSheet_myAnsSubDiv {
    margin-top: 10px;
    margin-bottom: 30px;
}

.answerSheet_myAnsSubTxt {
    margin-bottom: 10px;
    font-size: 20px !important;
    color: black;
}

.answerSheet_optionDiv {
    display: flex;
    align-items: center;
}

.answerSheet_optionDivTxt {
    background-color: #43B945;
    border-radius: 8px;
    padding: 4px 10px;
    color: #fff;
    font-weight: 600 !important
}

.answerSheet_optionAnswerTxt {
    margin: 0px;
    margin-left: 10px;
    margin-right: 20px
}

.answerSheet_grayOptionDivTxt {
    background-color: #7C6ACC;
    border-radius: 8px;
    padding: 4px 10px;
    color: #fff;
    font-weight: 600 !important
}

.answerSheet_yellowOptionDivTxt {
    background-color: #FDB757;
    border-radius: 8px;
    padding: 4px 10px;
    color: #fff;
    font-weight: 600 !important
}

.answerSheet_blueOptionDivTxt {
    background-color: #1EB0B5;
    border-radius: 8px;
    padding: 4px 10px;
    color: #fff;
    font-weight: 600 !important
}

.answerSheet_answerDetailsDiv {
    margin-bottom: 30px;
}

.answerSheet_answerDetailsTxt {
    font-size: 20px !important;
}

.answerSheet_answerDetailcontainer {
    font-size: 14px !important;
    font-weight: normal;
}

.answerSheet_answerDetailImg {
    height: 200px;
    width: 90%
}

.answerSheet_correctAnswerIcon {
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 23px;
    width: 23px;
    background-color: #43B945;
    border-radius: 50%;
    color: #fff;
}

.answerSheet_answerDetailsMainTxt {
    font-size: 20px !important;
    color: black;
}