.WithStyles\(ForwardRef\(StepConnector\)\)-line-10 {
    border-top: 0 !important;
}
.otp_input_props {
    font-size: 29px !important;
    color: #000 !important;
    font-family: 'Open sans' !important;
    font-weight: bold !important;
    text-align: center;
    padding: 20px 14px !important;
}

.arrow_style_button_left {
    border-radius: 8.8px !important;
    padding: 6px !important;
    margin: 10px !important;
    background-color: #374c88 !important;
    color: #fff !important;
    font-size: 13.1px !important;
    height: fit-content;
    position: absolute !important;
    top: 50%;
    left: 25px;
}

.arrow_style_button_right {
    border-radius: 8.8px !important;
    padding: 6px !important;
    margin: 10px !important;
    background-color: #374c88 !important;
    color: #fff !important;
    font-size: 13.1px !important;
    height: fit-content;
    position: absolute !important;
    top: 50%;
    right: 50px;
}

.white-back-arrow {
    color: white
}

.successfull_div {
    border-radius: 15px;
    border: solid 1px #ebeaed;
    background: #fff;
    padding: 22px 35px 22px 35px;
    margin-top: 8px;
}

.success_container {
    justify-content: space-between;
    align-items: center;
}

.pending_approval_div {
    border-radius: 15px;
    border: solid 1px #ebeaed;
    background: #fff;
    padding: 32px 35px 50px 35px;
    margin-top: 20px;
}

.pending_approval_sub_div {
    padding: 0 30px;
}

.reg_name_text {
    color: #413f63;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: bold;
}

.account_detail_container {
    justify-content: space-between;
    margin-top: 30px;
}

.account_detail_title {
    font-size: 16px !important;
    font-family: 'Open sans' !important;
    font-weight: normal !important;
    color: #677788;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
}

.account_detail_text {
    font-size: 16px !important;
    font-family: 'Open sans' !important;
    font-weight: 500 !important;
    color: #000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.success_okay_div {
    margin-top: 40px !important;
}

.success_okay_btn {
    background-image: linear-gradient(290deg, #374c88 120%, #243a76);
    border-radius: 8.8px !important;
    color: #fff !important;
    width: 35%;
    padding: .85rem !important;
    text-transform: capitalize !important;
    font-size: 1rem !important;
}

.justify_content_start {
    display: flex;
    justify-content: flex-start;
}

.justify_content_center {
    display: flex;
    justify-content: center;
}

.justify_content_end {
    display: flex;
    justify-content: flex-end;
}

.verify_main_div {
    height: 100vh;
}

.verify_container {
    position: relative;
}

.otp_form_div {
    display: flex;
    margin-top: 20px;
}

.justify-space-between {
    display: flex;
    justify-content: space-between;
}

.otp_text_input_props {
    background-color: #f1f1f1;
}

.otp_error_text {
    color: red !important;
    margin: auto !important;
    font-size: 14px !important;
    text-align: center !important;
}

.dynamic_email {
    color: #8a77fd;
    font-weight: bold;
}

@media (max-width: 1279px) {
    .enter_OTP_sent_text {
        width: 50% !important;
    }

    .account_created_div {
        width: 70% !important;
    }

    .arrow_style_button_left {
        left: 10px !important
    }

    .arrow_style_button_right {
        right: 10px !important;
    }
}

@media (max-width: 959px) {
    .enter_OTP_sent_text {
        width: 60% !important;
    }

    .verification_text {
        padding: 15px 10px !important;
        font-size: 18px !important;
    }

    .account_created_div {
        width: 80% !important;
    }

}

@media (max-width: 599px) {
    .otp_textfield_box {
        width: 60px !important;
        margin: auto 5px auto 5px !important;
    }

    .enter_OTP_sent_text {
        width: 90% !important;
        font-weight: 400 !important;
        margin: 10px auto !important;
    }

    .shield_image {
        width: 130px !important;
        height: 130px !important;
    }

    .email_image {
        width: 130px !important;
        height: 120px !important;
    }

    .verify_main_div {
        margin-top: 45px !important;
        width: 90% !important;
    }

    .verify_phone_text {
        font-size: 25px !important;
        margin-top: 0 !important;
    }

    .verification_text {
        padding: 15px 10px !important;
        font-size: 16px !important;
    }

    .resendotp_text_verify {
        font-size: 18px !important;
    }

    .check_image {
        margin-top: 15px;
        margin-left: 0px !important;
    }

    .receive_link_text {
        font-size: 15px !important;
    }

    .account_created_div {
        width: 90% !important;
    }

    .successfull_div {
        padding: 15px !important;
    }

    .successFull_img {
        height: 50px;
        width: 50px;
        object-fit: contain;
    }

    .successFull_text {
        font-size: 20px !important;
    }

    .pending_approval_div {
        padding: 15px !important;
    }

    .pending_img {
        height: 110px;
        width: 100px;
        object-fit: contain;
    }

    .pending_approval_sub_div {
        padding: 0 !important;
    }

    .pending_approval_text {
        font-size: 28px !important;
    }

    .xs_justify_content_center {
        justify-content: center !important;
    }

    .xs_mt_15 {
        margin-top: 15px !important;
    }

    .arrow_style_button_left {
        top: 85px !important;
        left: 0 !important
    }

    .arrow_style_button_right {
        top: 85px !important;
        right: 0 !important;
    }

    .otp_input_props {
        font-size: 24px !important;
        padding: 14px 12px !important;
    }

    .center {
        text-align: center !important;
    }

    .verify-mob {
        height: 100% !important;
    }
}

.view_firstnameboc {
    width: 76px;
    height: 76px;
    margin: 0 0 0 41px;
    padding: 27px 30px 25px 31px;
    border-radius: 8.8px;
    border: solid 1px #cfcfcf;
    background-color: #f1f1f1;
}
.root {
    flex-grow: 1 !important;
    height: 100% !important;
}
.root .check_image {
    width: 40px;
    margin-left: 30px;
    object-fit: contain;
}
.root .accountRegistration_div {
    justify-content: space-between;
    display: flex;
}
.root .accountRegistration_helpIcon {
    color: #f57b42;
    padding: 0px;
}
.root .shieldImage  {
    width: 195px;
    height: 195px;
    margin: 10px;
    object-fit: contain;
}
.root .logoImage {
    width: 151px;
    position: relative;
    object-fit: cover;
}
.root .typesofAccount_div {
    width: 60%;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    text-align: center;
}
.root .typesofAccount_textLabel {
    font-size: 35.1px;
    font-weight: bold;
    color: #000;
    margin-top: 13px;
}
.root .textlabel_forgotpasswordText {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    margin: 7.7px 0.1px 10px 0px;
}
.root .accountRegistration_footer {
    justify-content: space-between;
    display: flex;
}
.root .Follow_the_instructions {
    margin-top: 15px;
    width: 37%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8c8aad;
    margin-bottom: 15px;
}
.root .textfield_div {
    justify-content: center;
    margin-bottom: 10px;
}
.root .forrmInput_textfield {
    width: 76px;
    border-radius: 8.8px;
    background-color: #f1f1f1;
    margin: auto 20px auto 21px;
}
.root  .resendotp_text {
    font-size: 22px;
    font-weight: bold;
    color: #8a77fd;
    cursor: pointer;
}
.root .spinnerBar {
    color: rgba(255, 255, 255, 1);
}
.root .formDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
