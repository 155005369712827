.SummaryModalMainDiv {
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 32px 18px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    max-height: 800px;
    overflow: auto;
}

.SummaryModalMainDiv .TitleText {
    font-size: 20px;
    font-weight: 600;
}

.SummaryModalMainDiv .SubTitleText {
    margin: 10px 0px;
    font-weight: 600;
}

.SummaryModalMainDiv .ContentBox {
    margin-top: 10px;
    margin-bottom: 10px;
}

.SummaryModalMainDiv .ContentDetailGrid {
    margin-top: 7px;
}


.SummaryModalMainDiv .ContentDetailTitleBox {
    display: flex;
    margin-top: 10px;
}

.SummaryModalMainDiv .IconImage {
    height: 22px;
    width: 22px
}

.SummaryModalMainDiv .DetailLabelText {
    font-weight: 600;
    margin-left: 20px;
}

.SummaryModalMainDiv .DetailSectionTitle {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
}

.SummaryModalMainDiv .DetailItemLabel {
    font-weight: 600;
    margin: 10px 0px;
}

.SummaryModalMainDiv .DetailItemLabelNegativeMarking {
    font-weight: 600;
    margin: 10px 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.SummaryModalMainDiv .DetailColumnGrid {
    margin: 7px 0px;
}
.SummaryModalMainDiv .NegativeMarkingText {
    font-size: 15px;
}

.ActionBtnGrid {
    display: flex;
    justify-content: center;
}

.ActionBtnGrid .OkButton {
    margin: 0px 0px 0px 20px;
    padding: 9px 20px 12px 22px;
    border-radius: 3px;
    text-transform: initial;
}

@media (max-width: 959px) {
    .SummaryModalMainDiv {
        width: 80%;
        max-height: 80%;
    }
}


@media (max-height: 800px) {
    .SummaryModalMainDiv {
        max-height: 740px;
    }
}


@media (max-width: 599px) {
    .SummaryModalMainDiv {
        width: 80%;
        max-height: 70%;
    }

    .DetailSectionTitle {
        font-size: 16px;
    }
}