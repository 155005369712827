.textlabel_welcomeText {
    font-size: 2.1875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.textlabel_Header {
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}

.forrmInput_textfield {
    width: 100% !important;
    border-radius: 8.8px;
    text-align: start;
    color: #03014c !important;
    font-size: 1rem;
    height: auto !important;
    background-color: rgb(241, 241, 241) !important;
    fontSize: 1rem !important;
    color: #03014c !important;
    opacity: 0.5 !important;
    backgroundColor: #f1f1f1 !important;
}

.GradesAndLevelParentBox .HeadingBox {
    padding: 2rem 20px 0 20px;
}

.GradesAndLevelParentBox .BoardName {
    margin-top: 2rem;
    padding: 1rem 1.95rem 1rem 1.25rem;
    background: #9584fd;
}

.GradesAndLevelParentBox .Container {
    padding: 1.5rem 2.6875rem;
}

.GradesAndLevelParentBox .DataBox {
    align-items: center;
    margin: 12px 0;
}

.GradesAndLevelParentBox .Typo {
    font-size: 1rem;
    font-weight: 600;
    color: #565656;
}

.GradesAndLevelParentBox .NextBtn {
    background-image: linear-gradient(289deg, #aca2ff 119%, #776be8);
    border-radius: 8px;
    color: #fff;
    width: 17%;
    padding: .85rem;
    text-transform: capitalize;
    font-size: 1rem;
}

.GradesAndLevelParentBox .BtnContainer {
    margin: 3.125rem 0 10px 0px;
    display: flex;
    justify-content: flex-end;
    padding-right: 44px;
}

.GradesAndLevelParentBox .CheckboxContainer {
    margin: 0 10px;
}

.GradesAndLevelParentBox .checkbox {
    color: #9584fd;
}