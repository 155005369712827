.accountRegistration_div {
    justify-content: space-between;
    display: flex;
    box-shadow: 0px 3px 3px gray;
  }
.accountRegistration_helpIcon {
    color: #f57b42;
    padding: 20px;
  }
.logoImage {
    width: 151px;
    position: relative;
    object-fit: cover;
}
.typesofAccount_textLabel {
    font-size: 35.1px;
    font-weight: bold;
    color: #000;
    margin-top: 10px;
}
.Follow_the_instructions {
    font-family: "Open sans";
    margin-top: 30px;
    width: 50%;
    margin: auto;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8c8aad;
    margin-bottom: 20px;
    padding: 0 20%;
  }
.textfield_div  {
    margin: auto;
    justify-content: space-between;
    margin-top: 18.9px;
  }
.forrmInput_textfield {
    width: 95%;
    border-radius: 8.8px !important;
    margin-bottom: 5px;
    text-align: start;
}
.AdminAccountRegistrationParentBox .mb3_perc {
    margin-bottom: 3%;
}
.AdminAccountRegistrationParentBox .InputPropsStyle {
    font-style: 15.8px;
    color: #03014c;
    background: #f6f6f6;
    padding: 5px 20px 5px 20px;
    height: 60px;
    border-radius: 8.8px;
}
.AdminAccountRegistrationParentBox .IconStyle {
    color: #7c7ba0;
    padding-left: 20px;
}
.AdminAccountRegistrationParentBox .w95_per { 
    width: 95%;
}
.AdminAccountRegistrationParentBox .PhoneInputBox {
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
}
.AdminAccountRegistrationParentBox .ErrorText {
    color: red;
    margin: 3px 14px 0;
}
.AdminAccountRegistrationParentBox .InputPropsStyle2 {
    font-style: 15.8px;
    color: #03014c;
    background: #f6f6f6;
    padding: 5px 20px 5px 20px;
    height: 70px;
    border-radius: 8.8px;
}
.AdminAccountRegistrationParentBox .MenuItemStyle {
    color: #03014c;
}
