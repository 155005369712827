.date-grid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dropdown-select {
  justify-content: space-between;
}

.dropdown-select-task {
  flex-basis: 10% !important;
}

@media screen and (max-width: 1279px) {
  .date-grid {
    display: flex;
  }

  .dropdown-select {
    justify-content: space-between;
  }

  .dropdown-select-task {
    flex-basis: 10% !important;
  }
}

@media screen and (max-width: 959px) {
  .date-grid {
    display: flex;
  }

  .dropdown-select {
    justify-content: space-between;
  }

  .dropdown-select-task {
    flex-basis: 10% !important;
  }
}

@media screen and (max-width: 767px) {
  .date-grid {
    display: block;
    text-align: center;
  }

  .dropdown-select {
    justify-content: center;
  }

  .dropdown-select-task {
    flex-basis: 100% !important;
  }
}
