.UploadModalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.UploadModalContainer .innerBox {
    background: #fff;
    border-radius: 20px;
    width: 770px;
    padding: 28px 36px;
}
.UploadModalContainer .titleText {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-bottom: 20px !important;
    color: #3f526d !important;
}
.UploadModalContainer .closeIcon {
    cursor: pointer;
    font-size: 36px;
    color: lightgray;
}
.UploadModalContainer .btnContainer {
    border-width: 3px;
    border-style: dashed;
    border-color: #cadaf1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 200px;
    justify-content: center;
    background: #fff;
    margin-bottom: 3%;
}
.UploadModalContainer .downlaodExcelBtn {
    border-radius: 6px;
    border: solid 1px #0e723a;
    align-items: center;
    padding: 10px 15px;
}
.UploadModalContainer .downlaodExcelBtnTypo {
    text-transform: none;
    color: #117f44;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
}
.UploadModalContainer .paragraphText {
    color: #253873;
    font-size: 16px;
    margin-top: 20px;
}
.UploadModalContainer .uploadFileContainer {
    border-width: 3px;
    border-style: dashed;
    border-color: #cadaf1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 200px;
    justify-content: center;
    background: #f9f8fd;
}
.UploadModalContainer .uploadFileBtn {
    background: linear-gradient(to top, rgb(175, 56, 203), rgb(71, 28, 81));
    color: #fff !important;
    height: 50px !important;
    text-transform: capitalize !important;
    font-style: 18px !important;
    cursor: pointer !important;
    font-size: 18px !important;
}
.UploadModalContainer .cancelBtn {
    color: linear-gradient(to top, rgb(175, 56, 203), rgb(71, 28, 81));
    background: #f6f6f6 !important;
    width: 100% !important;
    height: 50px !important;
    margin-left: 30px !important;
    text-transform: capitalize !important;
    font-style: 18px !important;
    font-size: 18px !important;
}
.UploadModalContainer .pad {
    padding: 8px 30px;
}
.UploadModalContainer .w20 {
    width: 20%;
}
.UploadModalContainer .w100 {
    width: 100%;
}
.UploadModalContainer .dNone {
    display: none;
}
.UploadModalContainer .itemText {
    text-transform: none;
    font-size: 20px;
    font-weight: 500;
    color: #3f526d;
}
.UploadModalContainer .alignStart {
    text-align: start;
}
.UploadModalContainer .w22 {
    width: 22%;
}
.UploadModalContainer .w28 {
    width: 28%;
}
.UploadModalContainer .img {
    width: 61px;
    height: 63px;
}
.UploadModalContainer .filesMainBox {
    border-width: 3px;
    border-style: dashed;
    border-color: #cadaf1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 200px;
    height: 100%;
    margin-top: 10px;
    padding: 20px;
    justify-content: center;
    background: #f9f8fd;
}
.UploadModalContainer .fileUploadText {
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    color: #959393;
    margin: 10px 0 0 3px;
}
.UploadModalContainer .mt20 {
    margin-top: 20px;
}
.UploadModalContainer .reUploadBtnBox {
    padding: 0 0px;
    margin-top: 30px;
}
.UploadModalContainer .uploadBtnContainer {
    padding: 0 30px;
    margin-top: 30px;
}
.uploadBtnContainer .flexCenter {
    display: flex;
    justify-content: center;
}
.UploadModalContainer .filesBox {
    text-align: center;
    display: flex;
    margin: 0px 15px;
    flex-direction: column;
}
.UploadModalContainer .filesText {
    text-transform: none;
    font-style: 14px;
    font-weight: 500;
    color: #2e2c34;
}