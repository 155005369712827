.text_logo_type_heading {
    font-size: 15.8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #525252;
    opacity: 0.98;
}

.text_logo_type {
    font-size: 15.8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #03014c;
    opacity: 0.5;
}

.checkbox_button {
    width: 14px;
    height: 14px;
    border: solid 1px #979797;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.checkbox_button_checked {
    width: 14px;
    height: 14px;
    border: solid 1px #979797;
    border-radius: 50%;
    background-color: #7f6cfc;
    cursor: pointer;
}

.textlabel_welcomeText {
    font-size: 35.1px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.textlabel_school {
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 0 7px;
    position: relative;
    background-color: white;
}

.textlabel_school_des {
    font-size: "12px" !important;
    font-weight: "normal" !important;
    font-stretch: "normal" !important;
    font-style: "normal" !important;
    line-height: "normal" !important;
    letter-spacing: "normal" !important;
    padding: "0 7px" !important;
    position: "relative" !important;
    background-color: "white";
}

.textlabel_logo {
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 0 7px;
    position: relative;
    margin-top: -68px;
    background-color: white;
}

.textlabel_picture {
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 0 7px;
    position: relative;
    margin-top: -46px;
    background-color: white;
}

.LogoAndPictureParentBox {
    padding: 46px 20px !important;
}

.LogoAndPictureParentBox .px6 {
    padding: 0 6px;
}

.LogoAndPictureParentBox .Mt5 {
    margin-top: 5px;
}

.LogoAndPictureParentBox .Mt20 {
    margin-top: 20px;
}

.LogoAndPictureParentBox .Mt14 {
    margin-top: 14px;
}

.LogoAndPictureParentBox .Mt1 {
    margin-top: 1px;
}

.LogoAndPictureParentBox .W100 {
    width: 100%;
}

.LogoAndPictureParentBox .Hidden {
    display: none;
}

.LogoAndPictureParentBox .InnerContainer {
    margin-top: 56px;
    border: solid 1px #979797;
    border-radius: 17px;
    padding: 48px 15px 23px 15px;
}

.LogoAndPictureParentBox .ImgDiv {
    padding: 44px 13.1px 43.7px 13px;
    background: #f6f6f6;
    border: solid 1px #979797;
    border-radius: 9px;
    position: relative;
    margin: auto 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.LogoAndPictureParentBox .ImgStyling {
    width: 10.5rem;
    height: 2.9925rem;
    object-fit: contain;
}

.LogoAndPictureParentBox .LogoErrorText {
    color: red;
    margin: 3px 5px 0;
    position: absolute;
}

.LogoAndPictureParentBox .UpdateLogoBtn {
    background-image: linear-gradient(289deg, #aca2ff 119%, #776be8);
    border-radius: 7px;
    color: #fff;
    width: 100%;
    height: 40px;
    text-transform: capitalize;
    font-size: 16px;
}

.LogoAndPictureParentBox .DeleteLogoBtn {
    border: solid 1px #f00;
    border-radius: 7px;
    width: 100%;
    height: 40px;
    text-transform: capitalize;
    font-size: 16px;
}

.LogoAndPictureParentBox .TopNavLogoDiv {
    border: solid 1px #979797;
    background-color: #f6f6f6;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LogoAndPictureParentBox .ObjectFitContain {
    object-fit: contain;
}

.LogoAndPictureParentBox .LogoTypeDiv {
    margin-top: 25px;
    padding: 0 0 0 6px;
}

.LogoAndPictureParentBox .AlignCenter {
    align-items: center;
}

.LogoAndPictureParentBox .FlexCenterCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.LogoAndPictureParentBox .UploaMaindDiv {
    margin-top: 59px;
    border: solid 1px #979797;
    border-radius: 17px;
    padding: 26px 25px 20px 25px;
}

.LogoAndPictureParentBox .UploadInnerBox {
    width: 128px;
    height: 112px;
    border: solid 1px #979797;
    background-color: #f6f6f6;
    border-radius: 9px;
    position: relative;
    margin: auto 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.LogoAndPictureParentBox .UploadImg {
    cursor: pointer;
    width: 41px;
    height: 41px;
}

.LogoAndPictureParentBox .LogoPicturePadBox {
    padding: 0 6px 0 12px;
}

.LogoAndPictureParentBox .UploadFile {
    width: 86px;
    height: 74px;
    border: solid 1px #979797;
    background-color: #f6f6f6;
    border-radius: 12px;
    position: relative;
    margin: auto 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.LogoAndPictureParentBox .UploadFileImg {
    width: 100%;
    height: 100%;
    border: solid 1px #979797;
    background: #f6f6f6;
    border-radius: 12px;
}

.LogoAndPictureParentBox .UploadFileLength {
    color: white;
    padding: 5px;
    border-radius: 50%;
    background-color: black;
    opacity: 0.51;
    font-size: 22px;
    box-shadow: 0 2px 4px 0 #525050;
    border: solid 1px #525050;
    position: absolute;
}

.LogoAndPictureParentBox .EmptyDiv {
    width: 86px;
    height: 74px;
    border: solid 1px #979797;
    background-color: #f6f6f6;
    border-radius: 12px;
    position: relative;
    margin: auto 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.LogoAndPictureParentBox .NextBtnContainer {
    margin: 3.125rem 0px 10px 0px;
    display: flex;
    justify-content: flex-end;
}

.LogoAndPictureParentBox .NextButton {
    background-image: linear-gradient(289deg, #aca2ff 119%, #776be8);
    border-radius: 8px;
    color: #fff;
    width: 17%;
    padding: .85rem;
    text-transform: capitalize;
    font-size: 1rem;
}