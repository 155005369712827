.taskMarkContainer .taskMarkBox {
    width: 100%;
    margin: 20px 0;
    border-radius: 10px;
    padding: 27px 23px;
    display: flex;
    flex-direction: row;
  }
  
  .taskMarkContainer .teacherMarksBox {
    width: 100%;
    border: none;
    outline: none;
    font-size: 22px;
    font-weight: 600;
    text-align: end;
    background: transparent;
  }
  
  .taskMarkContainer .studentTotalMarks {
    font-size: 22px;
    font-weight: 600;
  }
  
  .taskMarkContainer .teacherCommentBox {
    padding: 25px 20px 25px 30px;
    box-shadow: 0 -1px 5px 2px rgba(0, 0, 0, 0.09);
  }
  
  .taskMarkContainer .taskGradeMainBox {
    margin-top: 26px;
  }
  
  .taskMarkContainer .teacherCommentInput {
    width: 100%;
    background: #fff;
    border-radius: 8.8px;
    margin: 0 35px 0 1px;
    border: none;
    margin: 21px 0 26px 0;
    font-family: "Open sans";
    font-size: 15.8px;
    color: #03014c;
    opacity: 0.5;
  }
  
  .taskMarkContainer .teacherCommentBorder {
    border-bottom: 1px solid #d9d9d9;
  }
  
  .taskMarkContainer .fontSizeTeacher {
    font-size: 16px;
  }
  
  .taskMarkContainer .studentNameSection {
    width: 25%;
    text-align: start;
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .taskMarkContainer .toText {
    width: 25%;
    text-align: center;
  }
  
  .taskMarkContainer .fromText {
    width: 25%;
    text-align: end;
    margin-right: 10px;
  }
  
  .taskMarkContainer .teacherScoreDataBox {
    display: flex;
    margin: 9px 0;
    flex-direction: row;
    width: 100%;
    background: #f6f8fa;
    justify-content: space-between;
    font-size: 14px;
  }
  
  .taskMarkContainer .markAttributesName {
    width: 25%;
    text-align: start;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .taskMarkContainer .maxMarksAttributes {
    width: 25%;
    text-align: center;
  }
  
  .taskMarkContainer .minMarksAttributes {
    width: 25%;
    text-align: end;
    margin-right: 10px;
  }
  
  .taskMarkContainer .markAttributesScore {
    text-align: center;
  }
  
  .taskMarkContainer .studentGradeSection {
    width: 25%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .taskMarkContainer .nameGradeBox {
    display: flex;
    margin-top: 26px;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
  }
  
  .taskMarkContainer .taskSubmissionGrid {
    margin: 20px 0;
  }
  
  .taskMarkContainer .taskSubmissionBox {
    background: #f6f8fa;
    padding: 36px;
    border: solid 1px #979797;
  }
  
  .taskMarkContainer .taskSubmitBtn {
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    box-shadow: none;
    width: 100%;
    color: #fff;
  }
  
  .taskMarkContainer .backIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: #fff;
  }
  
  .taskMarkContainer .selectShort {
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .taskMarkContainer .taskMarkStudentGrid {
    margin: 20px 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .taskMarkContainer .markStudentTitle {
    font-size: 18px;
    font-weight: 600;
  }
  
  .taskMarkContainer .markStudentMainGrid {
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  
  .taskMarkContainer .taskStudentAttribute {
    font-size: 20px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .taskMarkContainer .taskGradeMark {
    font-size: 18px;
    font-weight: 600;
  }
  
  .taskMarkContainer .studentMarkBox {
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 30px;
  }
  
  .taskMarkContainer .marginTop15 {
    margin-top: 15px;
  }
  
  .taskMarkContainer .markStudentGrid {
    display: flex;
    justify-content: center;
  }
  
  .taskMarkContainer .start {
    text-align: start;
  }
  
  .taskMarkContainer .studentAvtar {
    height: 36px;
    width: 36px;
  }

  @media (max-width: 959px) {
    .taskMarkContainer .teacherMarksBox {
        width: 0%
    }
  }

  @media (max-width: 767px) {
    .taskMarkContainer .teacherMarksBox {
        width: 0%
    }
  }
