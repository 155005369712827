.emailLoginOTP{
    width: 100% !important;
    font-family:'Open sans';
    font-weight:600;
    font-size:24px;
    height: auto;   
    margin: 0px 20px 0px 0px;
    border-radius: 8.8px;
    padding: 14px 22px 15px;    
    border: 1px solid #cfcfcf;
    background-color: #f1f1f1;
}
.emailLoginOTP:focus{
    border: 1px solid rgba(142, 123, 253, 1);
    outline: rgba(142, 123, 253, 1);
}
