.ward_task_box {
  display: flex;
  justify-content: space-between;
  background-color: #e5ebf7;
  padding: 2%;
  border-radius: 5px;
  height: 94px;
}

.child_activity_tab_height {
  max-height: 45vh !important;
  overflow: auto;
}

.task_item_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.task_item_icon img {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.task_justify_center {
  justify-content: center;
}

.task_flex_column {
  flex-direction: column;
}

.task_display_flex {
  display: flex;
}

.task_center_align {
  align-items: center;
}

.task_name {
  font-size: 14px !important;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.task_mark {
  display: flex;
  align-items: center;
  width: 100%;
}

.task_subject {
  margin-left: 4%;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.task_profile_img {
  height: 30px !important;
  width: 30px !important;
  padding: 3px;
}

.task_teacher {
  font-size: 14px;
  margin-left: 4%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.subject_box {
  display: flex;
  background-color: #e0e9fb;
  padding: 2%;
  border-radius: 8px;
  height: 60px;
  width: 90%;
  align-items: center;
}

.subject_teacher {
  font-size: 18px;
  margin-left: 4%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.subject_icon {
  height: 40px !important;
  width: 40px !important;
  padding: 3px;
  border-radius: 50% !important;
  background-color: #ffffff;
  text-align: center;
}

.subject_icon_img {
  height: 30px !important;
  width: 30px !important;
}

.rescource_item_block {
  margin-bottom: 2% !important;
}

.resource_item img {
  height: 40px;
  width: 50px;
  object-fit: contain;
}

.resource_item_subject {
  font-size: 16px !important;
  font-weight: 600;
}

.resource_item_name {
  font-size: 14px !important;
  word-break: break-all;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.detail-main-grid {
  border-radius: 8px !important;
  padding: 8px 16px !important;
  overflow-x: auto;
}

.detail-img-school-logo {
  width: 32px;
  height: 32px;
  margin: 9px 9px 9px 0;
  object-fit: contain;
}

.detail-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.detail-flex-center {
  display: flex;
  justify-content: center !important;
  align-items: center
}

.detail-fw-600 {
  font-weight: 600 !important;
}

.detail-text {
  display: block;
  margin-left: 4%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.detail-class-name-text{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 24px;
}

@media only screen and (max-width: 767px) {
  .task_item_icon img {
    height: 25px !important;
    width: 25px !important;
  }

  .task_mark {
    font-size: 12px Im !important;
  }

  .task_teacher {
    font-size: 12px;
  }

  .subject_box {
    width: 100%;
  }

  .rescource_item_block {
    margin-bottom: 3% !important;
  }

  .detail-main-grid {
    padding: 8px 16px !important;
  }
}

@media only screen and (max-width: 1050px) {
}

@media only screen and (max-width: 1200px) {}