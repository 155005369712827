:root {
  --color-scrollBar: rgb(56, 44, 124);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.student-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.student-progress-text {
  margin-left: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fae8ff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /*background: #eda0ff; */
  background: var(--color-scrollBar);
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 #eda0ff;
}

.noborder {
  border: 0px;
  width: auto;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar__month-view__weekdays__weekday,
.react-calendar__month-view__weekdays__weekday * {
  display: flex;
  text-decoration: none;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-image: linear-gradient(153deg, #753cef 16%, #603cef 95%);
  color: white;
  border-radius: 8px;
}

.react-calendar__tile--now {
  background-color: #ef3c40;
  opacity: 0.31;
  border-radius: 8px;
  color: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #ef3c40;
  color: white;
  opacity: 0.31;
  border-radius: 8px;
}
.react-calendar__navigation__label {
  font-size: 20px;
  color: #3f526d;
  opacity: 0.3;
}
.react-calendar__navigation button > span {
  font-size: 16px;
  color: #3f526d;
}
.react-calendar__tile--active {
  background: linear-gradient(153deg, #753cef 16%, #603cef 95%);
  color: white;
  border-radius: 8px;
}
.react-calendar button {
  font-size: 16px;
  font-weight: 600;
  opacity: 0.7;
}

.excerpt {
  height: 1.5em;
  overflow: hidden;
  overflow-wrap: break-word;
}
.excerpt:hover {
  height: auto;
}

.react-calendar__navigation {
  border-bottom: 1px solid rgba(63, 82, 109, 0.1);
  padding-bottom: 8px;
}

.react-calendar__navigation .react-calendar__navigation__arrow {
  font-size: 40px;
  font-weight: 400;
  opacity: 0.3;
  line-height: 42px;
  position: relative;
  top: -7px;
}

.cal-title {
  margin-bottom: 22px;
}
.down-arrow .MuiSelect-icon {
  right: 7px;
}
.down-arrow {
  margin-bottom: 20px;
}

rect {
  rx: 7;
  ry: 7;
}

.reminder-quote-outer p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.react-calendar__month-view__weekdays__weekday {
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .student-progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: flex-start; */
    width: 100%;
  }
  .student-progress-text {
    margin-left: 0px !important ;
    margin-top: 3px !important;
    margin-bottom: 10px !important;
  }
}
