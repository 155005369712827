.studentTaskContent .mt20 {
  margin-top: 20px;
}

.studentTaskContent .flexCenterGrid {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.studentTaskContent .fileNameAttributes, .taskDetailsDialog .fileNameAttributes {
    color: #3f526d;
    font-size: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 4px 4px 7px;
    font-family: "Open sans";
}

.studentTaskContent .fileNameSize, .taskDetailsDialog .fileNameSize  {
  color: #79869f;
  font-size: 0.875rem;
  font-weight: normal;
  text-transform: capitalize;
  margin: 0 64px 4px 0;
  font-family: "Open sans";
}

.studentTaskContent .downloadFile, .taskDetailsDialog .downloadFile  {
  margin: 0 9px 0 12px;
}

.studentTaskContent .downloadFileIcon, .taskDetailsDialog .downloadFileIcon {
  width: 24px;
  height: 24px;
}

.studentTaskContent .textColorWhite {
  color: #fff;
}

.studentTaskContent .white {
  color: #fff;
}

.studentTaskContent .ddText {
  background: #fff;
  color: #3f526d;
  text-align: center;
}

.studentTaskContent .submitCourseTitle, .taskDetailsDialog .submitCourseTitle  {
  color: #3f526d;
  font-size: 1.125rem;
  font-family: 600;
}

.studentTaskContent .submitCourseBtn, .taskDetailsDialog .submitCourseBtn {
  background: transparent;
  border-radius: 8px;
  border: solid 0.5px #af38cb;
  text-transform: none;
  font-family: "Open sans";
  width: 100%;
  box-shadow: none;
  align-items: center;
}

.studentTaskContent .submitCourseworkBtn, .taskDetailsDialog .submitCourseworkBtn {
  padding: 12px 0px 12px 0px;
  background: transparent;
  border-radius: 6px;
  text-transform: none;
  font-family: "Open sans";
  width: 100%;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: default;
  margin-bottom: 10px;
}

.studentTaskContent .submitCourseworkTitle, .taskDetailsDialog .submitCourseworkTitle  {
  color: #3f526d;
  font-size: 1.125rem;
  font-weight: 600;
}

.studentTaskContent .marginLeft20, .taskDetailsDialog .marginLeft20  {
  margin-left: 20px;
}

.studentTaskContent .paginationBox {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.studentTaskContent .viewAllBox {
  width: 100%;
  text-align: end;
  margin-top: 26px;
}

.studentTaskContent .viewAllBtn {
  background: #efefef;
  margin: 0 20px 0 0;
  padding: 14px 36px 13px 37px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  color: #484848;
  box-shadow: none;
}

.taskDetailsDialog {
  border-radius: 8px;
}

.studentTaskContent .submitBtnImageUploadIcon, .taskDetailsDialog .submitBtnImageUploadIcon  {
  margin: 0px 15px;
  width: 20px;
  height: 20px;
}

.taskDetailsDialog .dividerTask {
  margin: 20px 0;
  opacity: 0.5;
  border: solid 0.5px #999;
}

.studentTaskContent .fullDividerTask, .taskDetailsDialog .fullDividerTask {
  margin: 30px 0 20px 0;
  border: solid 0.5px #999;
  opacity: 0.5;
  width: 100%;
}

.studentTaskContent .selectedGrid, .taskDetailsDialog .selectedGrid {
  display: flex;
  justify-content: space-between;
}

.studentTaskContent .multipleFilePopup, .taskDetailsDialog .multipleFilePopup  {
  overflow: auto;
  max-height: 155px;
  width: 100%;
}

.studentTaskContent .multipleFilePopupBox, .taskDetailsDialog .multipleFilePopupBox {
  display: flex;
  flex-direction: row;
  margin: 10px 8px 0 0;
  background: transparent;
  padding: 6px 20px 6px 2px;
  border-radius: 8px;
  border: solid 0.5px #af38cb;
  text-transform: none;
  width: 100%;
  box-shadow: none;
  align-items: center;
  justify-content: space-between;
}

.studentTaskContent .taskOuter {
  width: 100%;
  height: auto;
  margin: 19px 0px 0 0;
  padding: 25px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  flex-grow: 1;
}

.studentTaskContent .searchTaskInputBox {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 50px;
  background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to top, #af38cb, #471c51);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.studentTaskContent .activeColor {
  color: #bababa;
}

.studentTaskContent .disableColor {
  color: #3f526d;
}

.studentTaskContent .studentTaskNoRecordText {
  margin: 20px 0 20px 10px;
  font-family: "Open sans";
  font-size: 1.125rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f526d;
}

.studentTaskContent .dueDateGrid {
  width: 100%;
  margin-bottom: 10px;
}

.studentTaskContent .dueDateBg {
  padding: 13px 18px 15px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
}

.studentTaskContent .dialogOpenBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  text-align: start;
}

.studentTaskContent .fileGrid { 
  margin: 0px 0px 7px 0;
  opacity: 0.5;
}

.studentTaskContent .bgFirstOpacity {
  background: rgba(154, 163, 178,0.2);
}

.studentTaskContent .bgSecondOpacity {
  background: rgba(50, 112, 149,0.2);
}

.studentTaskContent .bgThirdOpacity {
  background: rgba(61, 187, 61,0.2);
}

.studentTaskContent .bgFirst {
  background: rgba(154, 163, 178);
}

.studentTaskContent .bgSecond {
  background: rgba(50, 112, 149);
}

.studentTaskContent .bgThird {
  background: rgba(61, 187, 61);
}

.studentTaskContent .colorFirst {
  color: rgba(154, 163, 178);
}

.studentTaskContent .colorSecond {
  color: rgba(50, 112, 149);
}

.studentTaskContent .colorThird {
  color: rgba(61, 187, 61);
}

.studentTaskContent .ddhhmmText {
  font-size: 0.875rem;
  font-weight: 600;
  color: #79869f;
  font-family: "Open sans";
}

.studentTaskContent .studentTasksubjectName {
  margin: 15px 0 13px 0;
  opacity: 0.5;
}

.studentTaskContent .studentAttributeText {
  font-size: 1.125rem;
  font-weight: 600;
  font-family: "Open sans";
  text-transform: capitalize;
}

.studentTaskContent .subjectNameGrid {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
}

.studentTaskContent .subjectNameText {
  font-family: "Open sans";
  font-size: 1rem;
  color: #3f526d;
  font-weight: 600;
  margin: 0 6px 0 0;
}

.studentTaskContent .dueDateGrid {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.studentTaskContent .dueDateText {
  font-family: "Open sans";
  font-size: 1rem;
  color: #3f526d;
  font-weight: 600;
  margin: 0 6px 0 0;
}

.studentTaskContent .dueDateAttributes {
  color: #79869f;
  font-family: "Open sans";
  font-size: 1rem;
}

.studentTaskContent .submissionStatus {
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
}

.studentTaskContent .subjectNameBox {
  padding: 4px 5px 5px;
  width: 100px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.studentTaskContent .subjectNameStatus {
  font-family: "Open sans";
  font-size: 1rem;
  color: #3f526d;
  font-weight: 600;
  margin: 0 6px 0 0;
}

.studentTaskContent .subjectNameTextAttirbutes {
  color: #fff;
  font-size: 0.625rem;
  font-family: "Open sans";
  letter-spacing: 0;
  font-weight: 600;
  text-transform: capitalize;
}

.studentTaskContent .subjectNameTextAttirbutesBox {
  color: #79869f;
  font-family: "Open sans";
  font-size: 1rem;
  text-transform: capitalize;
}

.studentTaskContent .fileTextName {
  margin: 0 0 11px 0;
  font-family: "Open sans";
  font-size: 1rem;
  font-weight: 600;
  color: #3f526d;
  text-align: start;
}

.studentTaskContent .studentFileBox {
  display: flex;
  flex-direction: row;
  background: transparent;
  padding: 0 20px 5px 7px;
  border-radius: 8px;
  text-transform: none;
  width: 100%;
  box-shadow: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.studentTaskContent .fileExtensionBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.studentTaskContent .fileNameAttribute {
  color: "#3f526d";
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 4px 0 0;
  font-family: "Open sans";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
}

.studentTaskContent .fileDownloadIconLink {
  margin: 9px 9px 0px 9px;
}

.studentTaskContent .fileDownloadIcon {
  width: 18px;
  height: 18px;
  margin: 0px 0px 0px 19.5px;
}

.studentTaskContent .spanFileSize {
    color: #79869f;
    font-size: 0.875rem;
    font-weight: normal;
    text-transform: capitalize;
    font-family: "Open sans";
}

.studentTaskContent .fileExtensionImg, .studentTaskContent .docIcon, .taskDetailsDialog .fileExtensionImg, .taskDetailsDialog .docIcon {
  width: 34px;
  height: 34px;
  margin: 4px 7px 5px 7px;
}

.taskDetailsDialog .fullWidth {
  width: 100%;
}

.studentTaskContent .multipleFileIconBox, .taskDetailsDialog .multipleFileIconBox  {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.studentTaskContent .fileDocIcon, .taskDetailsDialog .fileDocIcon  {
  width: 36px;
  height: 40px;
  margin: 4px 7px 5px 7px;
}

.studentTaskContent .borderFirst {
  border: 1px solid rgba(154, 163, 178);
}

.studentTaskContent .borderSecond {
  border: 1px solid rgba(50, 112, 149);
}

.studentTaskContent .borderThird {
  border: 1px solid rgba(61, 187, 61);
}

.studentTaskContent .opacityborderFirst {
  border: 0.5px solid rgba(154, 163, 178);
}

.studentTaskContent .opacityborderSecond {
  border: 0.5px solid rgba(50, 112, 149);
}

.studentTaskContent .opacityborderThird {
  border: 0.5px solid rgba(61, 187, 61);
}

.studentTaskContent .dateMonthBackground {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
}

.studentTaskContent .searchTaskInput {
  border: none;
  outline: none;
  opacity: 0.75;
  font-size: 1.125rem;
  font-weight: 600;
  width: 100%;
  padding: 0 10px 0 20px;
}

.studentTaskContent .inputSearchIcon {
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.studentTaskContent .todayBtnOuter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.studentTaskContent .todayBtnOuterGrid {
  display: flex;
  justify-content: center;
}

.studentTaskContent .todayButton {
  padding: 10px 0;
  width: 100%;
  border-radius: 6px;
  color: #fff;
  text-transform: none;
  font-size: 1.125rem;
  font-weight: 600;
  box-shadow: none;
}

.studentTaskContent .dateGrid {
  display: flex;
  justify-content: flex-end;
}

.studentTaskContent .dFlex {
  display: flex;
}

.taskDetailsDialog .taskDetailPopup {  
    padding: 21px 64px 35px 50px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.taskDetailsDialog .taskTitle {
  font-family: "Open sans";
  font-size: 1.375rem;
  font-weight: 600;
  color: #3f526d;
}

.taskDetailsDialog .subjTitle {
  font-size: 1.625rem;
  font-weight: 600;
  color: #3f526d;
  font-family: "Open sans";
  text-transform: capitalize;
  overflow-wrap: anywhere;
}

.studentTaskContent .dayList, .taskDetailsDialog .dayList {
  font-size: 1rem;
  font-weight: 600;
  color: #79869f;
  font-family: "Open sans";
}

.studentTaskContent .subjectTitle, .taskDetailsDialog .subjectTitle  {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.studentTaskContent .fileTitle {
  font-family: "Open sans";
  font-size: 1.25rem;
  color: #3f526d;
  font-weight: 600;
  margin-right: 12px;
}

.studentTaskContent .fileTypeBox, .taskDetailsDialog .fileTypeBox {
  display: flex;
  align-items: center;
}

.studentTaskContent .fileIcons, .taskDetailsDialog .fileIcons {
    width: 36px;
    height: 40px;
    margin: 4px 7px 5px 7px;
}

.studentTaskContent .subjectTitleName, .taskDetailsDialog .subjectTitleName {
  font-family: "Open sans";
  font-size: 1.25rem;
  color: #3f526d;
  font-weight: 600;
}

.studentTaskContent .subjectTitleAttribute, .taskDetailsDialog .subjectTitleAttribute {
  font-family: "Open sans";
  font-size: 1.25rem;
  font-weight: normal;
  color: #79869f;
  margin-left: 12px;
  text-transform: capitalize;
}

.studentTaskContent .gradeStatuGrid, .taskDetailsDialog .gradeStatuGrid {
  display: flex;
  align-items: center;
}

.studentTaskContent .dueDateTitle, .taskDetailsDialog .dueDateTitle {
  font-family: "Open sans";
  font-size: 1.25rem;
  color: #3f526d;
  font-weight: 600;
}

.studentTaskContent .dueAttribute, .taskDetailsDialog .dueAttribute {
  font-family: "Open sans";
  font-size: 1.25rem;
  font-weight: normal;
  color: #79869f;
  margin-left: 12px;
}

.studentTaskContent .statusTitle, .taskDetailsDialog .statusTitle {
  font-family: "Open sans";
  font-size: 1.25rem;
  color: #3f526d;
  font-weight: 600;
}

.studentTaskContent .statusBox, .taskDetailsDialog .statusBox  {
  margin: 0 7px 1px 16px;
  padding: 4px 26px 5px;
  border-radius: 4px;
}

.studentTaskContent .statusAttirbutes, .taskDetailsDialog .statusAttirbutes {
  color: #fff;
  font-size: 1.111rem;
  font-family: "Open sans";
  letter-spacing: normal;
  text-transform: capitalize;
}

.studentTaskContent .activeStatus, .taskDetailsDialog .activeStatus {
  background: #9aa3b2;
}

.studentTaskContent .pendingStatus, .taskDetailsDialog .pendingStatus {
  background: #3dbb3d;
}

.studentTaskContent .taskDetailsGrid, .taskDetailsDialog .taskDetailsGrid  {
  margin-top: 15px;
}

.studentTaskContent .taskDetailsTitle, .taskDetailsDialog .taskDetailsTitle  {
  font-family: "Open sans";
  font-size: 1.125rem;
  color: #3f526d;
  font-weight: 600;
}

.studentTaskContent .subjectDescriptionAttribute, .taskDetailsDialog .subjectDescriptionAttribute {
  margin-top: 12px;
  padding: 13px 21px 14px 16px;
  border-radius: 6px;
  background: #f9f8fd;
}

.studentTaskContent .disc, .taskDetailsDialog .disc {
  font-family: "Open sans";
  font-size: 1.11rem;
  color: #3f526d;
  font-weight: normal;
  line-height: 1.52px;
}

.taskDetailsDialog .subjectTitleTeacher  {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.taskDetailsDialog .subjectTitleTeacherGrid {
  display: flex;
  align-items: center;
}

.taskDetailsDialog .subjectTitleTeacherTitle {
  font-family: "Open sans";
  font-size: 1.25rem;
  color: #3f526d;
  font-weight: 600;
}

.taskDetailsDialog .teacherAvtar {
  margin-left: 12px;
  width: 50px;
  height: 50px;
}

.taskDetailsDialog .tMl12 {
  margin-left: 12px;
}

.taskDetailsDialog .seletectedTaskName {
  font-family: "Open sans";
  font-size: 1.11rem;
  color: #3f526d;
  font-weight: 600;
  overflow-wrap: anywhere;
}

.taskDetailsDialog .seletectedTaskEmail {
  font-family: "Open sans";
  font-size: 1rem;
  color: #999999;
  font-weight: normal;
  overflow-wrap: anywhere;
}

@media (max-width: 767px) {
  .studentTaskContent .taskOuter {
    padding: 15px 10px;
    margin-top: 10px;
  }

  .studentTaskContent .taskOuter button {
    height: 44px;
    font-size: 14px;
  }

  .studentTaskContent .taskOuter .Date_of_due_date_Icon {
    padding: 12px 15px;
    font-size: 14px;
    background-size: 25px 24px;
  }

  .studentTaskContent .taskOuter input {
    font-size: 15px;
    padding-left: 15px;
  }

  .studentTaskContent .subjectTitle, .taskDetailsDialog .subjectTitle {
    margin-bottom: 8px;
  }

  .studentTaskContent .subjectTitle p {
    font-size: 14px;
  }

  .studentTaskContent .taskTitle {
    font-size: 17px;
  }

  .studentTaskContent .subjTitle {
    font-size: 17px;
  }

  .studentTaskContent .dayList {
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .studentTaskContent .disc {
    font-size: 13px;
    line-height: 18px;
  }

  .studentTaskContent .taskDetails {
    margin-top: 4px;
    font-size: 16px;
  }

  .studentTaskContent .taskDetails p {
    font-size: 16px !important;
  }

  .studentTaskContent .taskDetailPopup hr {
    margin: 15px 0px 15px;
  }

  .studentTaskContent .taskTitle {
    font-size: 18px;
    margin-bottom: 10px;
  }
}