.createTaskmodal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.createTaskmodal .inputDueDateError > input {
  width: 100%;
  padding: 16px 7px;
  position: relative;
  font-size: 18px;
  opacity: 1;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid red;
  color: #000;
  font-family: 'Open sans';
}

.createTaskmodal .inputDueDate > input {
  width: 100%;
  padding: 16px 7px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  position: relative;
  color: #000;
  opacity: 1;
  font-family: 'Open sans';
}

.createTaskmodal .taskDetailsBox {
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 0.3px solid #979797;
  margin: 0 10px 26px 10px;
}

.createTaskmodal .addTaskPopup {
  background: #fff;
  border: 1px solid #979797;
  outline: none;
  border-radius: 29px;
  width: 75%;
  height: 85%;
  padding: 32px 34px 34px 34px;
}

.createTaskmodal .categoryBox {
  align-items: center;
  justify-content: center;
  text-align: start;
  margin: 0px 12px 0px 0px;
}

.createTaskmodal .enterTaskInput {
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 8.8px;
  border: none;
}

.createTaskmodal .helpText {
  color: red;
  margin: 3px 14px 0;
}

.createTaskmodal .dueDateBox {
  align-items: center;
  justify-content: center;
  text-align: start;
}

.createTaskmodal .dueDateBoxTitle {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 3px 0;
}

.createTaskmodal .h100 {
  height: 100%;
}

.createTaskmodal .uploadBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  padding: 34px 136px 34px 98px;
}

.createTaskmodal .tasktitleMain {
  text-transform: none;
  font-size: 24px;
  font-weight: bold;
  color: #3f526d;
  margin: 0 0px 26px 0px;
}

.createTaskmodal .innerScrollBox {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 150px);
}

.createTaskmodal .taskNameBox {
  align-items: center;
  justify-content: center;
  text-align: start;
}

.createTaskmodal .tasknameTitle {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}

.createTaskmodal .halfOpacity {
  opacity: 0.5;
}

.createTaskmodal .fullOpacity {
  opacity: 1;
}

.createTaskmodal .selectSubjectDropdown {
  width: 100%;
  text-transform: capitalize;
  font-family: "Open sans";
}

.createTaskmodal .transformNone {
  text-transform: none;
}

.createTaskmodal .redBorder {
  border: 1px solid red;
}

.createTaskmodal .normalBorder {
  border: 1px solid #ebebeb;
}

.createTaskmodal .capitalize {
  text-transform: capitalize;
}

.createTaskmodal .activeColor {
  color: #bababa;
}

.createTaskmodal .disableColor {
  color: #000;
}

.createTaskmodal .commonTitle {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin: 0px 15px 11px 0;
}

.createTaskmodal  .textStart {
  text-align: start;
  max-width: 280px;
  margin: 0 auto;
}

.createTaskmodal .updateBox {
  display: flex;
  flex-direction: row;
  max-width: 215px;
  margin: 0 auto;
}

@media (max-width: 959px) {
  .createTaskmodal .addTaskPopup {
    width: 95%;
    border-radius: 12px;
    padding: 25px;
  }

}

@media (max-width: 767px) {
  .createTaskmodal .addTaskPopup .MuiGrid-grid-xs-4 {
    max-width: 100%;
    flex-basis: 100%;
  }

  .createTaskmodal .addTaskPopup input {
    padding: 12px 13px;
  }

  .createTaskmodal .addTaskPopup .audioPlayer input {
    padding: 0 0;
  }

  .createTaskmodal .addTaskPopup .input_due_date_css_format > input {
    padding: 12px 12px;
    font-size: 14px;
    background-size: 25px 25px;
  }

  .createTaskmodal .addTaskPopup select {
    padding: 12px 12px;
    font-size: 14px;
    background-size: 23px 23px;
  }

  .createTaskmodal .addTaskPopup p {
    font-size: 14px;
    margin-top: 0;
  }

  .createTaskmodal .addTaskPopup .file-drop-target {
    padding: 20px 8px 20px 8px;
  }
}