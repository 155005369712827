.DeleteModal {
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 29px;
    padding: 32px 18px;
    width: 600px;
}


.DeleteModalDivider {
    height: 1px;
    margin: 14px 0 31px 0;
    border: solid 0.3px #979797;
}


.DeleteModalActionDiv {
    display: flex;
    justify-content: center;
}


.DeleteModal .DeleteTitle {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 14px;
}

.DeleteModal .DeleteSubTitle {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 31px 32px 6px 33px;
}

.DeleteModal .DeletePara {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 6px 53px 40px 54px;
}

.DeleteModal .ButtonText {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.1px;
}

.DeleteModalActionDiv .CancelButton {
    margin: 0px 20px 0px 0px;
    padding: 9px 20px 12px 22px;
    border-radius: 3px;
    text-transform: initial;
}

.DeleteModalActionDiv .DeleteButton {
    margin: 0px 0px 0px 20px;
    padding: 9px 20px 12px 22px;
    border-radius: 3px;
    text-transform: initial;
}

@media (max-width: 599px) {
    .DeleteModal {
        width: 80%;
    }
}