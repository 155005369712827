.studentAssessmentResult_backArrowDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.studentAssessmentResult_backArrowTxt {
    font-family: 'Open sans';
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #3f526d;
    margin-top: 20px;
    cursor: pointer;
}

.studentAssessmentResult_backArrowImg {
    height: 14px;
    width: 18px;
    object-fit: contain;
    margin-right: 10px;
}

.studentAssessmentResult_viewVerticalBox {
    margin: 20px 0px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.studentAssessmentResult_subViewVerticalBox {
    padding: 20px 0px 30px 0px
}

.studentAssessmentResult_tableContainer {
    border: 1px solid #471c51;
    border-radius: 5px
}

.studentAssessmentResult_tableHead {
    color: #af38cb
}

.studentAssessmentResult_tableCell {
    border-right: 1px solid #471c51;
    border-bottom: 1px solid #471c51;
    font-size: 18px !important;
    color: #471c51;
    font-weight: 600 !important
}

.studentAssessmentResult_tabCellCenter {
    text-align: center;
}

.studentAssessmentResult_tableCellRight {
    border-right: 1px solid #471c51;
    font-size: 18px !important;
    color: #471c51;
    font-weight: 500 !important;
    background-color: #f9f8fd
}

.studentAssessmentResult_tableCellRightCenter {
    text-align: center;
}

.studentAssessmentResult_mainGridContainer {
    justify-content: space-between;
}

.studentAssessmentResult_mainGridContainerTxt {
    font-family: 'Open sans';
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #3f526d;
}

.studentAssessmentResult_queryDiv {
    display: flex;
}

.studentAssessmentResult_queryDivTxt {
    font-family: 'Open sans';
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #af38cb;
    text-decoration: underline;
    cursor: pointer;
    margin-right: 30px;
}

.studentAssessmentResult_assignedDiv {
    padding: 25px 0px
}

.studentAssessmentResult_subAssignedDiv {
    margin-top: 1%;
}

.studentAssessmentResult_assignedDivTxt {
    font-family: 'Open sans';
    font-size: 20px !important;
    font-weight: 600 !important;
    color: gray;
    text-align: center;
}

.studentAssessmentResult_userIconDiv {
    font-family: 'Open sans';
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #3f526d;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.studentAssessmentResult_userIconImg {
    height: 40px;
    width: 40px;
    object-fit: contain;
    margin-right: 10px;
}

.studentAssessmentResult_assignedSubTxt {
    font-family: 'Open sans';
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #3f526d;
    padding-top: 20px;
    text-align: center;
}