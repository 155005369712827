.taskMarkSubmissionTest {
    margin-bottom: 15px;
  }
  
  .taskMarkSubmissionTest .showMarkImg {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 80vh;
    text-align: center;
  }
  