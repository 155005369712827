.NavIcon {
  display: none !important;
}
.noborder {
  border: 0px;
  font-family: 'Open Sans';
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar__month-view__weekdays__weekday,
.react-calendar__month-view__weekdays__weekday * {
  display: flex;
  text-decoration: none;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-image: linear-gradient(153deg, #753cef 16%, #603cef 95%);
  color: white;
  border-radius: 8px;
}

.react-calendar__tile--now {
  background-color: #ef3c40;
  opacity: 0.31;
  border-radius: 8px;
  color: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #ef3c40;
  color: white;
  opacity: 0.31;
  border-radius: 8px;
}
.react-calendar__navigation__label {
  font-size: 20px;
  color: #3f526d;
  opacity: 0.3;
  font-family: 'Open Sans';
}
.react-calendar__navigation button > span {
  font-size: 16px;
  color: #3f526d;
  font-family: 'Open Sans';
}
.react-calendar__tile--active {
  background: linear-gradient(153deg, #753cef 16%, #603cef 95%);
  color: white;
  border-radius: 8px;
}
.react-calendar button {
  font-size: 16px;
  font-weight: 600;
  opacity: 0.7;
  font-family: 'Open Sans';
}
.fc td,
.fc th {
  border-style: none !important;
}
.fc-event-container {
  display: none;
}

.MyClasses {
  margin-right: 20px !important;
}

.Md-col {
  padding: 0 35px !important;
  width: 90%;
}

/* Remove inline css */
.GridBox {
  border-radius: 20px;
  box-shadow: 0 8px 27px 0 rgba(224, 224, 224, 0.5);
  background-color: #fff;
  margin-top: 35px !important;
  padding: 25px 30px;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row .Avatar-Dashboard {
  width: 60px;
  height: 60px;
}
.flex-row .Avatar-Dashboard img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: -webkit-fill-available;
}
.thoughtpopup {
  opacity: 1;
  border: 1px solid rgb(151, 151, 151);
  background-color: white;
  outline: none;
  border-radius: 29px;
  width: 100%;
  max-width: 505px;
  padding: 34px 25px 34px;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.notepopup {
  opacity: 1;
  border: 1px solid rgb(151, 151, 151);
  background-color: white;
  outline: none;
  border-radius: 29px;
  width: 100%;
  max-width: 505px;
  height: 70%;
  padding: 34px 25px 10px;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.teacher-profile-card {
  padding: 15px 50px;
  border-radius: 20px;
  box-shadow: 0 8px 27px 0 rgba(224, 224, 224, 0.5);
  background-color: #fff;
}

.leftsidebar-col {
  width: 65.5%;
  display: flex;
  flex-direction: column;
  padding-right: 35px;
}

.right-sidebar {
  display: flex;
  flex-direction: column;
  width: 34.5%;
  padding-left: 15px;
}

.leftsidebar-col .card-subject {
  padding: 7px 13px 4px 14px;
  border-radius: 10px;
  width: 170px;
  background-size: cover;
  margin-right: 24px;
  height: 84px;
}
.cal-pd {
  padding: 35px 30px;
}

.asslist {
  padding: 25px 30px;
  justify-content: flex-start;
}

.create-notepopup {
  max-width: 543px;
  width: 100%;
  padding: 30px 35px;
  border-radius: 29px;
  border: 1px solid #979797;
  background-color: #fff;
  outline: none;
}

.MuiDialog-paper {
  overflow-x: hidden !important;
}

.reminder-quote-outer p  {
  -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
.flex-justify-bw {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.grey-icon {
  color: rgba(151, 135, 219, 0.57);
}

@media only screen and (max-width: 1199px) {
  .GridBox {
    padding: 20px !important;
  }
  .Colxs8 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .fluid-flex {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1023px) {
  .NavIcon {
    display: inline-block !important;
  }

  .Z-icon .MuiAvatar-fallback {
    z-index: inherit !important;
  }

  .sidebarNav {
    width: 0 !important;
    background: #a439cb;
    visibility: hidden;
    z-index: 9999;
    padding-top: 30px;
  }

  .open-sidebar {
    width: 210px !important;
    visibility: visible;
    background: #a439cb;
    z-index: 9999;
  }
  .main-wrapper {
    padding-left: 0 !important;
  }

  .main-wrapper header {
    border-top-left-radius: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
    box-shadow: 0 8px 27px 0 rgba(224, 224, 224, 0.5) !important;
    border-bottom: 0 !important;
  }

  .NavIcon {
    position: absolute !important;
    z-index: 9999999;
    left: 15px;
    top: 19px;
  }

  .MobileHeader {
    border-bottom: 0 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .NavIcon {
    display: block;
    -webkit-user-select: none;
    user-select: none;
  }

  .NavIcon a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  .NavIcon a:hover {
    color: tomato;
  }

  .NavIcon input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
  * Just a quick hamburger
  */
  .NavIcon span {
    display: block;
    width: 25px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  .NavIcon span:first-child {
    transform-origin: 0% 0%;
  }

  .NavIcon span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /*
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  .NavIcon input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-6px, -15px);
  }

  /*
  * But let's hide the middle one.
  */
  .NavIcon input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
  * Ohyeah and the last one should go the other direction
  */
  .NavIcon input:checked ~ span:nth-last-child(2) {
    transform: rotate(-44deg) translate(0px, 10px);
  }

  .Header-Right {
    width: 254px !important;
    justify-content: end !important;
    margin-right: -10px !important;
  }

  .fluid {
    width: 100% !important;
    margin: 0 !important;
  }

  .fluid-flex {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  header .MuiIconButton-root {
    padding: 8px !important;
  }
  .Headerhight {
    margin-top: 56px !important;
  }

  .NavIcon {
    top: 18px;
  }

  .Avatar-Dashboard .MuiAvatar-colorDefault {
    width: 50px !important;
    height: 50px !important;
  }
  .TeachaerInfo {
    margin-left: 20px !important;
  }
  .TeachaerInfo p {
    font-size: 16px !important;
  }
  .TeachaerInfo span {
    font-size: 18px !important;
  }
  .GridBox {
    padding: 20px 15px !important;
    margin-top: 30px !important;
  }
  .Colxs8 .MuiGrid-grid-xs-4 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .MyClasses {
    margin-right: 0 !important;
  }
  .fluid {
    padding-right: 0 !important;
    margin-bottom: 30px !important;
  }

  .SelectMenu .MuiNativeSelect-select {
    font-size: 14px;
  }
  .MyClasses p {
    font-size: 16px !important;
  }

  .SelectMenu {
    width: 50% !important;
    margin-right: 9px !important;
  }

  .ScrollTb .MuiAvatar-rounded {
    height: 50px !important;
    width: 50px !important;
  }
  .ScrollTb {
    padding: 15px;
  }
  .Md-col {
    padding: 0 15px !important;
  }

  .ScrollTb .MuiBox-root-86,
  .ScrollTb .MuiBox-root-87,
  .ScrollTb .MuiBox-root-88,
  .ScrollTb .MuiBox-root-89,
  .ScrollTb .MuiBox-root-90 {
    width: 140px !important;
  }
  .SelectMenu2 {
    width: 45% !important;
    margin-right: 0px !important;
  }
  .Upcoming-Classes {
    overflow: auto;
  }
  .Upcoming-Classes .MuiGrid-container {
    width: 800px !important;
  }
  .create-notepopup {
    border-radius: 8px !important;
    width: 94% !important;
    padding: 15px !important;
  }

  .notepopup {
    width: 90%;
    padding: 15px;
    border-radius: 15px;
  }
  .thoughtpopup {
    opacity: 1;
    border: 1px solid rgb(151, 151, 151);
    background-color: white;
    outline: none;
    border-radius: 29px;
    width: 90%;
    max-width: 505px;
    padding: 34px 25px 34px;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .teacher-profile-card {
    padding: 15px;
  }

  .leftsidebar-col .card-subject {
    margin-right: 12px;
    height: 70px;
  }
  .leftsidebar-col .card-subject:last-child {
    margin-right: 0;
  }
  .Create-Popup-outer {
    overflow-y: scroll;
    height: 330px;
    overflow-x: hidden;
  }

  .createnote-popup .MuiDialog-paper {
    width: 342px;
    margin: 0;
    border-radius: 15px;
  
  }
  .mob-padd {
    overflow-x: visible !important;
    display: block !important;
    padding: 15px !important;

  }
}
