.badges-text {
    /* font-family: "Open sans"; */
    font-size: 18px;
    font-weight: bold;
    /* color: #000; */
    text-transform: capitalize;
    letter-spacing: 1px;
}

.badges-text-assign {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    word-break: break-all;
    text-align: center;
    padding: 0 10px;
}

.create-new-badge-btn {
    width: 160px;
    height: 60px;
    margin: 20.5px 0 21px 0px !important;
    border-radius: 8px !important;
    /* background-color: #826ffc !important; */
    font-family: "Open sans" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    /* color: #fff !important; */
    text-transform: capitalize !important;
}

.badges-top-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0px 0 30px
    
}

.view-whitebox {
    padding: 22px 40px 5px 32px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.view-badgecard {
    width: 100%;
    height: 272px;
    border-radius: 6px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 20px 0;
}

.badges-list-view {
    display: flex;
    flex-direction: row;
    margin: 20px 0 42px 0;
}

.badge-img {
    width: 140px;
    height: 140px;
    margin: 0px 0px 28px 0;
    object-fit: contain;
}

.more-img {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.badge-box {
    display: flex;
    justify-content: center;
}

.assign-badge-modal {
    width: 744px;
    /* height: 80%; */
    padding: 32px 40px 40px 38px;
    border-radius: 8px;
    box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.user-avatar {
    width: 48px !important;
    height: 48px !important;
    margin: 0px 25px 0px 0px;
    object-fit: contain;
    border-radius: 50% !important;
}

.assign-badge-detail-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.assign-badge-btn-modal {
    width: 148px;
    height: 54px;
    border-radius: 8px !important;
    margin: 0 20px 0 0 !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    box-shadow: none !important;
    text-transform: none !important;
}

.assign-badge-radio-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.create-new-badges-text {
    font-size: 18px;
    font-weight: bold;
    margin: 37.5px 0px 20px 0px;
}

.badges-templates-div {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #f8f7fc;
    padding: 15px;
    height: calc(100% - 30px);
}

.badge-template-card {
    width: 100%;
    height: 160px;
    margin: 10px 0px 10px 0px;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(53, 36, 115, 0.2);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.selected-template-card {
    border: solid 2px #826ffc;
}

.badge-img-template {
    width: 80px;
    height: 80px;
    margin: 0px 0px 21px 0;
    object-fit: contain;
}

.category-rectangle {
    height: 28px;
    margin: 0px 6px 3px 6px;
    padding: 5px 18px 5px 20px;
    border-radius: 14px;
    box-shadow: 0 2px 4px 0 rgba(53, 36, 115, 0.1);
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
}

.selected-category-rectangle {
    height: 28px;
    margin: 0px 6px 3px 6px;
    padding: 5px 18px 5px 20px;
    border-radius: 14px;
    border: 1px solid;
    box-shadow: 0 2px 4px 0 rgba(53, 36, 115, 0.1);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
}

.category-name {
    font-family: "Open sans";
    font-size: 12px;
    font-weight: normal;
    color: #000;
    text-transform: capitalize;
}

.badge-detail-field {
    display: flex;
    flex-direction: column;
}

.badge-detail-title {
    font-family: "Open sans";
    font-size: 16px;
    font-weight: 600;
}

.badge-detail-view {
    height: 40px;
    margin: 8px 0 0;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 0.5px #d9d8de;
    background-color: #ffffff;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.transform-capital {
    text-transform: capitalize;
}

.badge-img-rectangle {
    width: 70%;
    height: 600px;
    margin: 26px auto 26px;
    padding: 16px;
    box-shadow: 0 2px 4px 0 rgba(53, 36, 115, 0.2);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bottom-assign-div {
    display: flex;
    justify-content: flex-end;
}

.assign-badge-btn {
    width: 160px;
    height: 60px;
    margin: 0px 0 30px 0px !important;
    border-radius: 8px !important;
    font-family: "Open sans" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.selected-badge-img {
    width: 267px;
    height: 280px;
    margin: 0px 0px 61px 0px;
    object-fit: contain;
}

.selected-badge-name {
    font-size: 36px;
    font-weight: 600;
}

.create-badge-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
}

.create-badge-main-div {
    padding: 30px;
    border-radius: 8px;
    border: solid 1px #d9d8de;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bottom-create-badge-div {
    display: flex;
    justify-content: center;
}

.add-new-badge-btn {
    width: 200px;
    height: 60px;
    border-radius: 8px !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    box-shadow: none !important;
    text-transform: none !important;
}

.create-badge-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.badge-upload-img-rectangle {
    width: 600px;
    height: 600px;
    margin: 26px 0px 26px 0px;
    padding: 16px;
    box-shadow: 0 2px 4px 0 rgba(53, 36, 115, 0.2);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.red-border {
    border: 1px solid red;
    border-radius: 8px;
}

.label-select-img {
    /* margin: 0 0 36px 0; */
    font-size: 16px;
    font-weight: normal;
}

.upload-rectangle {
    width: 320px;
    height: 322px;
    margin: 36px 0;
    padding: 20px;
    border: dotted 2px #84818a;
    position: relative;
}

.upload-badge-img {
    position: absolute;
    right: 20px;
    bottom: 16px;
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
}

.uploaded-rectangle {
    width: 320px;
    height: 322px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploaded-badge-img {
    height: 100%;
    object-fit: contain;
}

.delete-img {
    position: absolute;
    right: 20px;
    top: 16px;
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
}

.badge-text-field {
    border-radius: 8px;
    margin-top: 8px;
}

.pt-10 {
    padding-top: 10px;
}

.back-arrow-img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin-right: 18px;
}

.back-text-badge {
    font-size: 18px;
    font-weight: bold;
    margin: 20.5px 0px 34px 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.mr-15 {
    margin-right: 15px !important;
}

.no-record-text {
    font-size: 18px;
    font-weight: bold;
}

.ellipsis-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 90%;
}



.badges-list-view .badge-box{
    padding: 0 15px;
}

.search-badge {
  height: 40px;
  margin: 0 0px 23px 0;
  padding: 9px 15px 12px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(53, 36, 115, 0.1);
  background-color: #fff;
  display: flex;
} 

.rightside-badge{
    padding-left: 6px !important;
}
.white-radio .MuiSvgIcon-root {
    width: 35px;
    height: 34px;
}

.MuiFormHelperText-root.BadgeCategoryError {
    color: red;
    margin: 3px 14px 0;
}

.AdminHiddenInput {
    display: none !important;
}

.MuiFormHelperText-root.BadgeImgError {
    color: red;
    margin: -10px 0px 16px 0px;
}

.MuiSvgIcon-root.AdminBadgeSearch {
    height: 24px;
    width: 24px;
    color: #b2bac6;
    margin: 0px;
}

.AdminBadgeSearchInput {
    border: none;
    outline: none;
    font-family: Open sans;
    color: #6d7278;
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 0 10px;
    width: 100%;
    background-color: rgba(218, 218, 218,0);
}

.AdminBadgeItemDiv {
    display: flex; 
    overflow: auto;
    padding-bottom: 10px;
}

.MuiNativeSelect-root.CategoryInputError {
    border-color: 1px solid red;
    margin-top: 8px;
    text-transform: capitalize;
}

.MuiNativeSelect-root.CategoryInput {
    border-color: #ebebeb;
    margin-top: 8px;
    text-transform: capitalize;
}

.AssignBadgeModal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.TeacherAssignBadgeModal {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
}

.BadgeMemberGrid {
    width: 100%;
}

.MuiTypography-root.BadgeSelectMembers {
    text-transform: none;
    font-size: 24px;
    font-weight: bold;
    margin: 0 0px 22px 0px;
}

.BadgeSearchMemberBox {
    margin: 22px 0px 18px;
    height: 60px;
    border-radius: 8px;
    background-color: #f9f8fd;
}

.TeacherBadgeSearchMemberBox {
    margin: 22px 0 25px 0px;
    height: 60px;
    border-radius: 8px;
    background-color: #f9f8fd;
}

.MuiSvgIcon-root.BadgeSearchMember {
    height: 32px;
    width: 32px;
    color: #7f6cfc;
    margin: 0px 0px 2px 25px;
}

.MuiSvgIcon-root.TeacherBadgeSearchMember {
    height: 30px;
    width: 30px;
    color: #7f6cfc;
    margin: 1px 0px 2px 20px;
}

.BadgeSearchMemberInput {
    border: none;
    outline: none;
    color: #6d7278;
    font-size: 20px;
    margin: 0 0 0 20px;
    width: 100%;
    background-color: rgba(218, 218, 218,0);
}

.BadgeRadioBox {
    display: flex;
}

.MuiRadio-root.BadgeRadio {
    padding: 0px 10px 0px 0px; 
}

.MuiRadio-root.StudentBadgeRadio {
    padding: 0px 10px; 
}

.MuiTypography-root.TeachersText {
    text-transform: none;
    font-size: 20px;
    font-weight: 600;
}

.UserDataDiv {
    height: 300px;
    overflow: auto;
    padding: 0px 10px;
}

.TeacherUserDataDiv {
    height: 300px;
    overflow: auto;
}

.UserMainDiv {
    border-bottom: 0.5px solid #979797;
}

.UserDetailsDiv {
    display: flex;
    flex-direction: row; 
    justify-content: space-between; 
    margin: 17px 0px 17px 0px; 
    align-items: center; 
}

.DisplayFlex {
    display: flex;
}

.FlexColumn {
    display: flex;
    flex-direction: column;
}

.UserNameText {
    font-size: 18px;
    font-weight: 600;
}

.UserEmailText {
    margin: 5px 0px 0px 0px;
    font-size: 16px;
    color: #999 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked.AssignUserCheck {
    color: #7f6cfc; 
    padding: 0px;
    margin-right: 15px;
}

.MuiCheckbox-colorSecondary.AssignUserCheck {
    color: #7f6cfc; 
    padding: 0px;
    margin-right: 15px;
}

.AssignBadgeBtnBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    flex-flow: wrap;
}

.MuiButton-root.CancelAssignBtn {
    background-color: #f6f6f6;
    color: #000;
}

.FlexAlignCenter {
    display: flex;
    align-items: center;
}

.MuiTypography-root.SelectAllText {
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    margin-right: 15px;
}

.MuiTypography-root.TeacherSelectAllText {
    text-transform: none;
    font-size: 18px;
    font-weight: 600;
}

.MuiTypography-root.SelectedText {
    text-transform: none;
    font-size: 22px;
    font-weight: 600;
}

.MuiCheckbox-root.SelectAllCheck {
    color: #7f6cfc;
}

.MuiCheckbox-colorSecondary.Mui-checked.SelectAllCheck {
    color: #7f6cfc; 
}

@media (max-width: 1279px) {
    .view-whitebox {
        padding: 22px 32px;
    }

    

    .badge-img {
        width: 100px;
        height: 100px;
        margin: 0px 0px 18px 0;
    }

    .badges-list-view {
        margin: 15px 0 30px 0;
    }

    .badge-upload-img-rectangle {
        width: 500px;
        height: 500px;
    }

    .badge-template-card {
        width: 90%;
        height: 140px;
    }

    .badge-img-rectangle {
        height: 500px;
    }

    .badge-img-template {
        width: 60px;
        height: 60px;
        margin: 0px 0px 15px 0;
    }

    .selected-badge-name {
        font-size: 34px;
    }
}

@media (max-width: 959px) {
    .assign-badge-modal {
        width: 90%;
    }

    .create-badge-container {
        width: 80%;
    }

    .selected-badge-img {
        width: 200px;
        height: 211px;
        margin: 0px 0px 30px 0px;
    }

    .selected-badge-name {
        font-size: 30px;
        word-break: break-all;
        text-align: center;
    }

    .badges-list-view .badge-box{
        padding: 0 10px;
    }
}

@media (max-width: 767px) {
    .view-whitebox {
        padding: 25px 15px
    }
    .Headerhight {
        overflow-x: hidden !important;
    }

    .view-badgecard {
        width: 100%;
        margin: 12px 0;
    }

    .create-new-badge-btn {
        width: 150px;
        height: 50px;
    }

    .assign-badge-modal {
        height: 90%;
        overflow-y: auto;
        padding: 20px;
    }

    .assign-badge-radio-div {
        margin-top: 15px !important;
    }

    .assign-badge-btn {
        width: 148px;
        height: 45px;
        margin: 0 10px 10px 0 !important;
        font-size: 16px !important;
    }

    .assign-badge-btn-modal {
        width: 130px;
        height: 45px;
        margin: 0 10px 10px 0 !important;
        font-size: 18px !important;
    }

    .user-avatar {
        margin: 0px 10px 0px 0px;
    }

    .create-badge-container {
        width: 100%;
    }

    .xs-pt-0 {
        padding-top: 0 !important;
    }

    .badge-upload-img-rectangle {
        width: 100%;
        height: 350px;
        margin: 16px 0px 16px 0px;
        padding: 10px;
    }

    .upload-rectangle {
        width: 80%;
        height: 225px;
        margin: 20px 0;
        padding: 15px;
    }

    .uploaded-rectangle {
        width: 80%;
        height: 225px;
    }

    .uploaded-badge-img {
        width: 100%;
    }

    .add-new-badge-btn {
        width: 180px;
        height: 50px;
        font-size: 20px !important;
    }

    .badge-img-rectangle {
        height: 300px;
    }

    .selected-badge-img {
        width: 150px;
        height: 160px;
        margin: 0px 0px 30px 0px;
    }

    .selected-badge-name {
        font-size: 22px;
    }

    .badges-top-grid{
 padding: 0;

    }
    .badges-text {
       font-size: 16px;
    }
    .badges-list-view .badge-box {
        padding: 0 0;
    }
    .create-new-badges-text {
        font-size: 16PX;
    }
    .rightside-badge {
        padding-left: 17px !important;
        padding-top: 0 !important;
    }
    .badge-detail-title{
        font-size: 14px;
    }
    .rightside-badge .MuiGrid-spacing-xs-5 > .MuiGrid-item {
        padding: 10px 20px;
    }
}