.notes-parent-box{
  border-radius: 10px;
}
.notes-note-item-box {
    cursor: pointer;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    height: auto;
    padding: 16px 23px 23px 23px;
}
.notes-flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notes-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notes-view-all-btn{
  width:117px !important;
  height: 41px !important;
  border-radius: 20px !important;
}

.notes-text-capitalize {
  text-transform: capitalize
}

.margin-top-14{
  margin-top: 14px !important;
}

.text-transform-none {
  text-transform: none;
}

.notes-empty-img-div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 7%;
}

.notes-empty-img{
  height: 224px;
  object-fit: contain;
}

.notes-empty-text-div{
  width: 100%;
  text-align: center;
  margin-top: 7%;
}

.notes-empty-text{
  opacity: 0.5;
  font-size: 22px;
  font-weight: 600;
  color: #3f526d;
}