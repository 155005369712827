.studentTaskUploadModal .none {
  display: none;
}

.studentTaskUploadModal .center {
  text-align: center;
}

.studentTaskUploadModal .mt20 {
  margin-top: 20px;
}

.studentTaskUploadModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.studentTaskUploadModal .addMoreFiles {
  margin: 0 20px 0 0;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1px;
  box-shadow: none;
  cursor: pointer;
}

.studentTaskUploadModal .fileUploadGrid {
  margin-top: 34px;
  display: flex;
  justify-content: center;
}

.studentTaskUploadModal .taskUploadBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f9f8fd;
  border-radius: 12px;
  padding: 20px;
}

.studentTaskUploadModal .uploadFileImage {
  margin: 0 0px 55px 0px;
  width: 86px;
  height: 100px;
  object-fit: contain;
}

.studentTaskUploadModal .dragMessage {
  text-transform: none;
  font-size: 16px;
}

.studentTaskUploadModal .srcGrid {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.studentTaskUploadModal .srcGridImg {
  width: 62px;
  height: 62px;
}

.studentTaskUploadModal .uploadFileGrid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.studentTaskUploadModal .uploadFileName {
  font-size: 18px;
  font-weight: 500;
}

.studentTaskUploadModal .studentuploadFileSize {
  font-size: 14px;
  opacity: 0.5;
}

.studentTaskUploadModal .studentUploadCancelBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  border-radius: 12px;
  padding: 20px 16px;
  margin: 0 1px;
}

.studentTaskUploadModal .studentUploadBtn {
  margin: 0 20px 0 0;
  border-radius: 8px;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: normal;
  box-shadow: none;
  padding: 13px 30px;
  cursor: pointer;
}

.studentTaskUploadModal .studentCancelBtn {
  margin: 0 20px 0 0;
  border-radius: 8px;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  box-shadow: none;
  padding: 13px 30px;
}

.studentTaskUploadModal .supportText {
  text-transform: none;
  font-size: 16px;
}

.studentTaskUploadModal .studentBrowseBtn {
  text-decoration: underline;
  box-shadow: none;
  text-transform: none;
  line-height: 0;
  font-size: 16px;
  font-weight: 600;
}

.studentTaskUploadModal .maxFileSize {
  text-transform: none;
  font-size: 16px;
  margin: 4px 0 0;
}

.studentTaskUploadModal .maxFileSize span {
  font-weight: 600;
}

.studentTaskUploadModal .halfOpcaity {
  opacity: 0.4;
}

.studentTaskUploadModal .fullOpcaity {
  opacity: 1;
}

.studentTaskUploadModal .pImgIconGrid {
  display: flex;
  justify-content: space-around;
}

.studentTaskUploadModal .pImgIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.studentTaskUploadModal .bulkFileUploadBox {
    padding: 24px 44px 24px 32px;
    border-radius: 8px;
    box-shadow: 0 8px 27px 0 rgba(224, 224, 224, 0.5);
    background: #fff;
    display: flex;
    align-items: center;
}

.studentTaskUploadModal .bulkFileUpload {
  overflow-y: scroll;
  height: calc(100% - 80px);
  border: 1px solid #3f526d;
  padding: 24px 10px;
  border-radius: 8px;
  background: #f9f8fd;
}

.studentTaskUploadModal .studentUploadFilesTitle {
  text-transform: none;
  font-size: 1.375rem;
  font-weight: 600;
  margin: 0 0 24px 0;
}

.studentTaskUploadModal .taskUploadContent {
  background: #fff;
  border: 1px solid #979797;
  outline: none;
  border-radius: 8px;
  width: 70%;
  height: 80%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 50px 20px 51px;
}

.studentTaskUploadModal .taskUploadContentForm {
  height: 100%;
}

.studentTaskUploadModal .uploadTaskFileBox {
  align-items: center;
  justify-content: center;
  text-align: start;
  height: 78%;
}


@media (max-width: 767px) { 
  .studentTaskUploadModal .uploadFileImage {
    margin-bottom: 5px
  }

  .studentTaskUplaodModal {
    padding: 20px 20px 10px 20px
  }
}
