.AccountVerifyParentBox {
    flex-grow: 1 !important;
    height: 100% !important;
  }
  .AccountVerifyParentBox .accountRegistration_div {
    justify-content: space-between;
    display: flex;
  }
  .AccountVerifyParentBox .accountRegistration_helpIcon {
    color: #f57b42;
    padding: 0px;
  }
  .AccountVerifyParentBox .shieldImage {
    width: 169px;
    height: 156px;
    margin: 10px;
    object-fit: contain;
  }
  .AccountVerifyParentBox .logoImage {
    width: 151px;
    position: relative;
    object-fit: cover;
  }
  .AccountVerifyParentBox .check_image {
    width: 40px;
    object-fit: contain;
    margin-left: 15px;
    align-self: center;
  }
  
  .AccountVerifyParentBox .typesofAccount_div {
    width: 60%;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    text-align: center;
  }
  
  .AccountVerifyParentBox .typesofAccount_textLabel {
    font-size: 35.1px;
    font-weight: bold;
    color: #000;
    margin: 13px 0;
  }
  
  .AccountVerifyParentBox .textlabel_forgotpasswordText {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    margin: 7.7px 0.1px 10px 0px;
  }
  
  .AccountVerifyParentBox .spinnerBar {
    color: rgba(255, 255, 255, 1);
  }
  
  .AccountVerifyParentBox .accountRegistration_footer {
    justify-content: space-between;
    display: flex;
  }
  
  .AccountVerifyParentBox .textfield_div {
    margin: auto;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .AccountVerifyParentBox .resendotp_text {
    font-size: 22px;
    font-weight: bold;
    color: #8a77fd;
    cursor: pointer;
  }
  
  .AccountVerifyParentBox .verification_text_box {
    padding: 28px 30px 28px 30px;
    border-radius: 15px;
    border: solid 1px #cfcfcf;
    background-color: #f1f1f1;
    color: #000;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
  }
  