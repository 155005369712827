
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');

body{
    font-family: 'Open Sans', sans-serif;
}
.feedback-form-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* grid-gap: 50px; */
}

@media only screen and (max-width: 1030px) {
    
    .feedback-form-heading h5 {
        font-size: 17px !important ;
    }
    .feedback-form-heading h6{
        font-size: 12px !important ;
    }
    .questions img{
        width: 20px;
        height: 20px;
    }
    .questions h4{
        font-size: 11px !important ;
        font-weight: bold;
    }
    .feedback-details-container h5{
        font-size: 13px !important;
    }
    .btn{
        padding: 4px 12px !important ;
    }
    .btn span{
        font-size: 6px;
    }
    .answer-button{
        margin-left: 36px !important;
    }
    .feedback-details-container input{
        height: 40px !important;
    }
    .submit-button-container .form-btn{
        height: 30px !important;
        width: 50px !important;
    }

    .form-btn span p{
        font-size: 12px !important ;
    }
    .form-btn span{
        font-size: 12px !important ;
    }
    
}

.font-family-feedback {
    font-family: Open Sans !important;
}

.questions{
    display: flex;
    align-items: center;
}



.questions img{
    margin-right: 16px;
}

.answer-button{
    margin-left: 46px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.btn:first-child{
    margin-right: 20px !important;
}

.btn:focus{
    outline: none;
}

.btn{
    padding: 20px 50px;
    border-radius: 2px !important ;
}

.btn span{
    font-size: 10px;
}

.active-btn{
    background: linear-gradient(to top, #af38cb, #471c51 200%) !important;
}

.active-btn span {
    color: #fff;
}

.inactive-btn{
    /* margin-left: 20px !important ; */
    border: 1px solid #af38cb !important ;
}

.inactive-btn span{
    color: #3f526d;
}

.submit-button-container{
    /* width: 200px;   
    margin: 0 auto; */
    text-align: center;
}

.success-popup-container{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
}

.success-popup-img img{
    width: 100px;
    height: 100px;
}


.success-popup-text h6{
    color: #3f526d ;
    font-weight: normal;
    font-size: 16px;
}

