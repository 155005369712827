#root .editor-wrapper {
  position: relative;
}

#root .ql-toolbar {
  position: absolute;
  bottom: 0;
  background-color: #f9f8fd;
  width: 100%;
  height: 39px;
  border-radius: 0px 0px 8px 8px;
  /* transform: translateY(100%); */
}

#root .ql-editor {
  height: 90px;
  border-radius: 8px 8px 0px 0px;
}
#root .ql-container.ql-snow {
  border: 0px;
}

.postModal .rdtPicker {
  bottom: 51px;
}