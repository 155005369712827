.studentAssesmentGuideline_backArrowDiv {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin: 20px 0px !important;
    text-transform: capitalize !important
}

.studentAssesmentGuideline_backArrowImg {
    height: 14px !important;
    width: 18px !important;
    object-fit: contain;
    margin-right: 10px !important;
    cursor: pointer!important;
}

.studentAssesmentGuideline_guidelineTxt {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-left: 15px !important;
    margin-top: 20px !important;
}

.studentAssesmentGuideline_noRecordTxt {
    font-size: 18px !important;
    margin-left: 15px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.studentAssesmentGuideline_btnDiv {
    display: flex;
    justify-content: flex-end;
}

.studentAssesmentGuideline_activeBtn {
    color: #fff;
    text-transform: capitalize !important;
    padding: 8px 20px !important;
    font-size: 16px !important;
    opacity: 1
}

.studentAssesmentGuideline_disabledBtn {
    color: #fff !important;
    text-transform: capitalize !important;
    padding: 8px 20px !important;
    font-size: 16px !important;
    opacity: 0.5
}