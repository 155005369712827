.rbc-calendar
  .rbc-time-content
  .rbc-day-slot
  .rbc-events-container
  .rbc-event
  .rbc-event-content
  .day-event {
  font-size: 12px;
  line-height: 16px;
  padding-top: 5px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rbc-calendar
  .rbc-time-content
  .rbc-day-slot
  .rbc-events-container
  .rbc-event
  .rbc-event-content
  .day-event {
  font-size: 12px;
  line-height: 16px;
  padding-top: 5px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.rbc-time-header-content {
  border-left: none;
}

.rbc-time-header-gutter {
  padding: 15px 12px !important;
}

.rbc-time-view {
  border: none;
}
.rbc-time-content > * + * > * {
  border-left: none;
}

.rbc-time-view .rbc-allday-cell {
  display: none !important;
}

.rbc-header {
  border-bottom: none;
}

.rbc-header a div{
  width: 140px;
  height: 44px;
  padding: 11px 38px 11px 39px !important;
  border-radius: 6px !important;
  background-color: #f2f2f2 !important;
}

.rbc-header + .rbc-header {
  border-left: none;
}

.rbc-time-header.rbc-overflowing {
  border-right: none;
  margin-right: 0 !important;
}
.rbc-timeslot-group {
  min-height: 100px;
}

.rbc-row-content {
  height: inherit;
}

.rbc-label {
  font-size: 14px;
  font-weight: 600;
  color: #999;
}

.rbc-calendar .rbc-time-content .rbc-day-slot .rbc-events-container .rbc-event {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px !important;
  margin-left: 5px;
}

.rbc-event-label {
  display: none;
}

.popover-inner {
  padding: 5px 30px 10px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 16px 0 rgba(98, 98, 98, 0.37);
  background-color: #fff;
}

.view_joinnow {
  width: 110px;
  height: 48px;
  border-radius: 6px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-image-slice: 1;
  border-image-source: linear-gradient(to top, #af38cb, #471c51);
  background-image: linear-gradient(to top, #af38cb, #471c51),
    linear-gradient(to top, #af38cb, #471c51);
  text-transform: capitalize !important;
}

.live_classes_view_joinnow {
  width: 110px;
  height: 48px;
  border-radius: 6px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-image-slice: 1 !important;
  /* border-image-source: linear-gradient(to top, #af38cb, #471c51) !important; */
  /* background-image: linear-gradient(to top, #af38cb, #471c51),
    linear-gradient(to top, #af38cb, #471c51) !important; */
  text-transform: capitalize !important;
}

.textlabel_joinnow {
  font-size: 18px !important;
  font-weight: 600 !important;
  text-align: center;
  color: #fff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.timeTableTooltip .MuiMenu-paper {
  border-radius: 10px;
}

.timetable-datepicker .rdtSwitch {
  pointer-events: none;
}

.liveclasses-datepicker.input_today_date_css_format > input {
  padding: 13px 23px !important;
}

.daily-btn {
  padding: 12px 40px 12px 41px;
}

.today-btn{
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-right: 20px;
  justify-content: center;
  padding: 15px 40px 15px 40px;
  cursor: pointer;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.content-center{
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1279px) {
  .small-calendar {
    min-width: 160vw !important;
  }
}

@media (max-width: 959px) {
  .small-calendar {
    min-width: 160vw !important;
  }
  .calendar-view-btn {
    margin-top: 3% !important;
    width: 50% !important;
  }
  .today-btn {
    width: auto !important;
  }
  .grid-sibling1, .grid-sibling2{
    justify-content: space-between !important;
    width: 100%;
    
  }

  .faltuCalender{
    justify-content: flex-end;
  }

 
}

@media (max-width: 800px) {
  .small-calendar {
    min-width: 310vw !important;
  }
  .today-btn {
    padding: 0px 18px !important;
    margin-right: 0px !important;
  }
.today-btn span{
  height: 45px !important;
  font-size: 16px !important;
}
  
  .calendar-view-btn {
    height: 40px !important;
    width: 50% !important;
    margin: 10px 2px !important;
  }

  .small-event-button {
    width: 50% !important;
  }
  .calendar-view-btn p{
    font-size: 14px !important;
  }
  .timetable-datepicker > input {
    width: 100% !important;
    font-size: 14px !important;
    margin-top: 3% !important;
  }
  .liveclasses-datepicker.input_today_date_css_format > input {
    width: 96% !important;
    margin: 0% 0 0 11px;
    font-size: 14px;
    background-size: 25px 25px !important;
  }
  .today-live-class-btn {
    width: auto !important;
  }
  .flex-justify-start {
    justify-content: flex-start !important;
  }
  .ellipse-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Date_of_due_date_Icon{
    flex-direction: column;
  }
  .today-btn {
    padding: 10px 18px !important;
 
}

}

@media (max-width: 600px){
  .faltuCalender{
    width: 100%;
  }

  .date-input{
    margin: auto !important;
  }

  .content-center{
    
    justify-content: center;
    margin: 5px 0 20px !important;
  }
  .date-input-student > input {
    width: 100% !important;
  }

  .date-input > input {
    width: 100% !important;
  }
}

/* for time gutter period text space and border*/
/* .rbc-time-column .rbc-timeslot-group{
  margin-top: 25px;
  border-top: 1px solid lightgray;
} */

