.teacher_create_event_parent_modal {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.teacher_create_event_parent_modal .create_event_popup {
    background-color: white !important;
    border: 1px solid #979797 !important;
    outline: none !important;
    border-radius: 29px !important;
    width: 75% !important;
    height: 85% !important;
    padding: 32px !important;
}
.teacher_create_event_parent_modal .h_100 {
    height: 100%;
}
.teacher_create_event_parent_modal .w_100 {
    width: 100%;
}
.teacher_create_event_parent_modal .title_box {
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: .3px solid #979797;
    margin: 0 19px 26px 1px;
}
.teacher_create_event_parent_modal .title_typo {
    text-transform: none;
    font-size: 24px;
    font-weight: bold;
}
.teacher_create_event_parent_modal .form_container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - 190px);
}
.teacher_create_event_parent_modal .form_group {
    align-items: center;
    justify-content: center;
    text-align: start;
    margin-right: 10px;
}
.teacher_create_event_parent_modal .form_label {
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0px 3px 0px;
}
.teacher_create_event_parent_modal .error_text {
    color: red;
    margin: 3px 14px 0;
}
.teacher_create_event_parent_modal .select_form_group {
    align-items: center;
    justify-content: center;
    text-align: start;
    width: 100%;
}
.teacher_create_event_parent_modal .w_96 {
    width: 96%;
}
.teacher_create_event_parent_modal .btn_container {
    padding: 0 30px;
    margin-top: 30px;
}
.teacher_create_event_parent_modal .flex_center {
    display: flex;
    text-align: center;
    justify-content: center;
}
.teacher_create_event_parent_modal .add_btn {
    width: 100%;
    height: 50px;
    text-transform: capitalize;
    font-size: 18px;
}
.teacher_create_event_parent_modal .cancel_btn {
    background: #f6f6f6;
    color: linear-gradient(to top, rgb(175, 56, 203), rgb(71, 28, 81));
    width: 100%;
    height: 50px;
    margin-left: 30px;
    text-transform: capitalize;
    font-size: 18px;
}
.teacher_create_event_parent_modal .margin_12 {
    margin: 0 12px 0px 0px !important;
}
.teacher_create_event_parent_modal .checkbox_label {
    text-transform: none;
    font-size: 16;
    font-weight: 600;
    margin: 0px 24px 9px 3px;
}
.teacher_create_event_parent_modal .checkbox {
    color: #3462bf;
    padding: 9px 9px 9px 0px;
}