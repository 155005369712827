.calendar-dropdown-grid{
  margin-top: 30px !important;
  margin-bottom: 12px !important;
  display:flex;
  align-items:center;
  justify-content:flex-end;
}
.calendar-event-grid{
  padding: 20px 17px 2px 18px;
  background-color: rgba(124, 106, 247, 0.27);
  border-left: 3px solid #7c6af7;
  overflow-y: auto;
  max-height: 320px;
}
.calendar-no-event-img-box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 7%;
}
.calendar-no-event-img{
  height: 180px; 
  object-fit: contain;
}
.calendar-no-event-text-box{
  width: 100%;
  text-align: center;
  margin-top: 7%;
  margin-bottom: 7%;
}
.calendar-no-event-text{
  font-weight: 600;
  font-size: 22px;
  opacity: 0.5;
}
.calendar-event-box{
  padding: 12px 64px 12px 13px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  margin-bottom: 7px;
}
.calendar-mb-8{
  margin-bottom: 8px !important;
}
.calendar-view-all-box{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 17px;
}
.calendar-view-all-btn{
  width:117px !important;
  height: 41px !important;
  border-radius: 20px !important;
}
.calendar-view-all-btn-text{
  text-transform: none;
}
@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 1023px) {
}

@media only screen and (max-width: 1200px) {
  
}
