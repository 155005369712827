#fileNameTextResource {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.display_flex {
  justify-content: flex-end;
}
.table_padding {
  padding: 16px !important;
}
.doc_flex {
  flex-direction: row;
}
.selectCheckbox {
  padding: 9px 0 !important;
  position: relative;
  left: 5px;
}
.Res-Folder {
  text-align: left;
  position: relative;
  left: -14px;
}

.paginationBttns a {
  margin: 7px !important;
}

.folder-icon {
  margin: 0 -3px 0 -10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.folderNameInput {
  height : 56px !important;
}

.studentResourcepart .selectAlltext {
  text-transform: none;
  font-size: 18px;
  font-weight: 600;
  margin-right: 18px;  
}

.boxList {
  width: 48px;
  height: 48px;
  margin: 5px;
  border: 0.5px solid #3f526d;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
}

.studentResourcepart .searchFiles {
  border: 0;
  outline: 0;
  color: #484848;
  opacity: 0.45px;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  padding: 0 1%;
}

.studentResourcepart .select-all-outer {
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.studentResourcepart .cloudIconupload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin: 5px;
  border: solid 1px #3f526d;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
}

.selectallBgshare {
  background-color: #3f526d;
}

.studentResourcepart .folder-block {
  border-radius: 4px;
  border: 0.5px solid #3f526d;
  background: #fff;
  align-items: center;
  justify-content: space-around;
  padding: 6px;
}

.avtarIcon {
  margin: 5px 0 5px 5px;
}

.studentResourcepart .folderTitle {
  text-transform: none;
  font-size: 20px;
  font-weight: 600;
}

.studentResourcepart .forrmInput_textfield {
  width: 100%;
  border-radius: 8px;
  text-align: start;
  margin: 5px 0;
  height: 50px !important;
}

.whiteBg {
  background: #fff;
}

#basic-menu .menuIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.studentResourcepart .openFolderbg {
  border-radius: 6px;
  display: flex;
  justify-content: space-around;
  height: 58px;
  margin: 5px;  
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.studentResourcepart .listFoldericon {
  width: 28px;
  height: 28px;
  margin: 5px;
}

.folderIcon {
  width: 24px;
  height: 24px;
  margin-right: 13px;
}

.studentResourcepart .folderTitlename {
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  width: 50%;
}

.openText {
  margin: 5px;
  display: flex;
}

.cursor {
  cursor: pointer;
}

.studentResourcepart .ListIcon {
  cursor: pointer;
  padding: 5px;
  font-size: 45px;
  border-radius: 6px;
  margin-left: 10px;
}

.norecordText {
  font-size: 18px;
  width: 176px;
  height: 27px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.createModal {
  border-radius: 4px;
  border: 0.5px solid #3f526d;
  background: #fff;
  align-items: center;
  justify-content: space-around;
  padding: 6px;
}

.studentResourcepart .createFoldername {
  text-transform: none;
  font-weight: 600;
  margin: 0 10px;
}

.studentResourcepart .titleMain {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin: 0 15px;
}

.studentResourcepart .checkouter button {
  border-radius: 0;
}

.checkouter {
  margin: 5px;
  padding: 10px;
  border-radius: 4px; 
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.displayItem {
  display: flex;
  align-items: center;
}

.studentResourcepart .fileNameText1 {
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
}

.studentResourcepart .outerPanel {
  display: flex;
}

.teacherResourcepart .Dragfile input[type="file"].nodisplay, .studentResourcepart input[type="file"].nodisplay {
  display: none !important;
}

.studentResourcepart .uploadText {
  text-transform: none;
  font-size: 12px;
  font-weight: 600;
  margin-top: 0;
}

.studentResourcepart .selectBg {
  background-color: #dae6d8;
}

.studentResourcepart .folderBoxouter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  margin-bottom: 10px;
}

.studentResourcepart .foldersNamebox {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
}

.studentResourcepart .sfolderLists {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 10px;
}

.studentResourcepart .boxShadow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 5px;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.studentResourcepart .flexbasis {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.studentResourcepart .topHeaderpanel {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.17) !important;
  margin-top: 25px !important;
}

.studentResourcepart .outerBorder {
  border-radius: 0;
}

.studentResourcepart .sizeText {
  text-transform: none;
  font-size: 12px;
  font-weight: 600;
}

.h-55 {
  height: 55px;
}

.margin10 {
  margin: 10px;
}

.w-203 {
  width: 203px;
}

.itemDisplay {
  display: flex;
  justify-content: center;
}

#basic-menu .menuText {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
}

#basic-menu .closeImg {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin: 2.5px;
}

.fullWidthselect {
  width: 100%;
}

.studentResourcepart .getlinkIcon {
  flex-basis: 0;
  display: flex;
  align-items: center;
}

.underlineNone {
  text-decoration: none;
}

.studentResourcepart .flexBase {
  flex-basis: 0;
}

.gridMain {
  display: flex;
  align-items: center;
  border-radius: 8px;
  /* padding: 1% 1.2% 1% 1.7%; */
  padding: 10px !important;
  margin-top: 15px !important;
}

.searchFiles {
  border: 0;
  outline: 0;
  color: #484848;
  opacity: 0.45px;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  padding: 0 1%;
}

.rightMargin {
  margin-right: 1%;
}

.studentResourcepart .classesButtonText {
  text-transform: none;
  font-weight: 600;
}

.studentResourcepart .gridCenter {
  margin-top: 20px;
  align-items: center;
}

.studentResourcepart .folderBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.17);
  margin-bottom: 10px;
}

.uploadpopup {
  padding: 15px !important;
}

.commonPopup .movetoselectbox {
  width: 100%;
}

.w100 {
  width: 100% !important;
  height: 56px !important;
}

.commonPopup .purpleButton {
  /* margin-right: 0 !important;
  margin-top: -10px; */
}

@media screen and (max-width: 1023px) {
  .uploadpopup {
    margin: 0 15px !important;
  }

  .uploadpopup-content {
    padding: 34px 58px !important;
  }

  .studentResourcepart .outerPanel {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .studentResourcepart .display_flex {
    justify-content: flex-start;
  }
  
  .studentResourcepart .doc_flex {
    flex-direction: column;
  }

  .studentResourcepart .table_padding {
    padding: 9px;
  }

  .studentResourcepart .hide-mob {
    display: none;
  }

  .studentResourcepart .selectall {
    padding-right: 0;
    justify-content: end;
    margin: 0;
    padding-bottom: 0;
    position: relative;
  }

  .studentResourcepart .selectall .StudentResources-create_icon_outer-66 {
    padding: 3px 6px;
    min-width: 48px;
    margin-right: 0;
  }

  .studentResourcepart .selectall .Checkboxleft {
    position: absolute;
    left: 0px;
  }

  .studentResourcepart .uploadpopup-content {
    padding: 20px;
  }

  .studentResourcepart .uploadpopup {
    width: 95%;
    padding: 20px 10px 10px 10px;
  }

  .studentResourcepart .dragFile {
    flex-wrap: wrap;
    justify-content: center;
  }

  .studentResourcepart .commonPopup .movetoselectbox {
    width: 100%;
  }

  .boxList {
    margin: 0 5px;
    padding: 4px;
  }
}
