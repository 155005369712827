.borderClass .MuiTableRow-root .MuiTableCell-root {
  border-bottom: none;
}

.borderClass .MuiTableRow-head .MuiTableCell-root:first-child,
.borderClass .MuiTableRow-root:nth-of-type(even) .MuiTableCell-root:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.borderClass .MuiTableRow-head .MuiTableCell-root:last-child,
.borderClass .MuiTableRow-root:nth-of-type(even) .MuiTableCell-root:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.Student_ExamTime_clock {
  justify-content: center;
}

.Student_ExamTime_marks {
  justify-content: end;
}

.modal_mainLayout_Box {
  padding: 20px 32px;
}

.modal_ContentLayoutView {
  padding: 10px;
}

.modal_fileUpload_Content {
  padding: 20px 0;
}

.file_Upload_text {
  padding: 20px 48px 10px;
}

.studentAssessment_mainDiv {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: '100%'
}

.studentAssessment_subDiv {
  display: flex;
  flex-direction: column;
}

.studentAssessment_inputDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.studentAssessment_subInputDiv {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 60px;
  width: 95%;
  border-radius: 8px;

}

.studentAssessment_input {
  border: none;
  outline: none;
  margin-left: 10px;
  opacity: 0.45;
  font-size: 18px !important;
  width: 100%;
  padding-right: 10px;
  font-weight: 600 !important
}

.studentAssessment_searchIcon {
  height: 30;
  width: 30;
  cursor: pointer;
  margin-right: 5
}

.studentAssessment_dropDown {
  height: 100%;
  width: 90%
}

.studentAssessment_tableContainerDiv {
  overflow: auto;
  width: 100%;
  border-radius: 6
}

.studentAssessment_tableHead {
  color: #fff;
  background-color: #9f388b
}

.studentAssessment_tableCell {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.studentAssessment_tableCellCenter {
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center;
}

.studentAssessment_tableCellResults {
  text-align: center;
  font-size: 20px !important;
  font-weight: 600 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5;
  border-bottom-left-radius: 5
}

.studentAssessment_blankTableCell {
  text-align: center;
  font-size: 20px !important;
  font-weight: 600 !important;
  border-top-right-radius: 5;
  border-bottom-right-radius: 5;
}

.studentAssessment_noRecords {
  font-size: 18px !important;
  font-family: "Open sans";
  color: #3f526d;
  margin-top: 20px;
  margin-left: 20px
}

.studentAssessment_tableSubCell {
  font-size: 18px !important;
  font-weight: 500 !important;
  background-color: #f9f8fd;
  border-bottom: none;
  text-transform: capitalize;
}

.studentAssessment_tableSubCellCenter {
  font-size: 18px !important;
  font-weight: 500 !important;
  background-color: #f9f8fd;
  border-bottom: none;
  text-transform: capitalize;
  text-align: center;
}
.studentAssessment_tableResultCellCenter {
  text-align: center !important;
}
.studentAssessment_tableCellImg {
  height: 24px;
  width: 24px;
  object-fit: contain;
  cursor: pointer
}

.studentAssessment_isString {
  height: 24px;
  width: 24px;
  object-fit: contain;
  cursor: pointer;
  opacity: 1;
}

.studentAssessment_notString {
  height: 24px;
  width: 24px;
  object-fit: contain;
  cursor: default;
  opacity: 0.5;
}

.studentAssessment_tableCellHeight {
  height: 10px
}

.studentAssessment_headingTxt {
  color: #fff !important
}
.studentAssessment_subDiv_Title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 10px 0px 10px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}
@media (max-width: 1279px) {}

@media (max-width: 959px) {}

@media (max-width: 767px) {
  .Student_ExamTime_clock {
    justify-content: start;
    margin-top: 10px !important;
  }

  .Student_ExamTime_marks {
    justify-content: start;
    margin-top: 10px !important;
  }

  .modal_mainLayout_Box {
    padding: 20px !important;
  }

  .modal_ContentLayoutView {
    padding: 10px !important;
  }

  .modal_fileUpload_Content {
    padding: 0px !important;
  }

  .file_Upload_text {
    padding: 0px 10px 0px;
  }
}