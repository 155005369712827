.marks_section {
    margin: 20px 10px;
    display: flex;
    flex-direction: row;
    padding: 0 28px 0 0;
    justify-content: space-between;
}

.question_flex_column {
    display: flex;
    flex-direction: column;
}

.MuiTypography-root.back_with_arrow {
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.question_back_arrow {
    height: 14px;
    width: 18px;
    object-fit: contain;
    margin-right: 10px;
}

.marks_wrap_div {
    display: flex;
    padding-top: 25px;
    flex-flow: wrap;
}

.MuiTypography-root.question_marks_text {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 15px;
}

.red_border {
    border: 1px solid red;
}

.none_border {
    border: none;
}

.question_marks_input {
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-right: 20px
}

.word_limit_input {
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.MuiFormHelperText-root.red_text {
    color: red;
}

.justify_center {
    justify-content: center;
}

.MuiTypography-root.remaining_marks {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.circular_div {
    position: relative;
    margin-top: 15px;
}

.grey_circular {
    color: grey !important;
}

.colored_circular {
    color: #4c4cb5 !important;
    position: absolute;
    left: 0;
}

.MuiTypography-root.circular_marks_text {
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.MuiFormHelperText-root.question_error {
    color: red;
    margin-top: -20px;
    margin-bottom: 20px;
}

.question_main_image_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    height: 300px;
    justify-content: space-evenly;
    position: relative;
}

.img_plus_icon {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.hidden_input {
    display: none !important;
}

.MuiTypography-root.upload_image_text {
    font-size: 16px;
    font-weight: 600;
    width: 80%;
    text-align: center;
    margin-top: 20px;
    opacity: 0.3;
}

.image_uploading_div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MuiTypography-root.image_uploading_text {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
}

.uploaded_question_image {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.MuiButtonBase-root.remove_question_image {
    cursor: pointer;
    border-radius: 6px;
    border: none;
    padding: 6px 30px 6px 30px;
    text-transform: capitalize;
    background-color: lightGray;
    height: 48px;
    font-size: 18px;
    font-weight: 600;
    width: 120px;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.question_action_items {
    padding: 50px 0;
    display: flex;
    justify-content: flex-end;
}

.preview_btn_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.eye_preview {
    margin-right: 10px;
}

.video_list {
    display: flex;
    flex-direction: row;
    margin: 30px 0 !important;
    position: relative;
}

.video_name_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.video_actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 32px;
}

.audio_recording_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    height: 360px;
    justify-content: space-evenly;
    position: relative;
    margin-top: 40px;
}

.audio_recording_grid {
    padding: 30px;
}

.position_relative {
    position: relative;
}

.one_word_answer_div {
    width: 250px;
    margin-top: 20px;
}

.one_word_text {
    font-size: 20px !important;
}

.match_type_div {
    margin-top: 35px;
}

.MuiTypography-root.match_list_text {
    font-size: 20px;
    font-weight: 600;
    margin: 30px 0px 20px 0px;
}

.match_questions_list {
    padding-top: 20px;
    padding-bottom: 20px;
}

.match_question_div {
    touch-action: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0px 0px 20px 0px;
    justify-content: space-between;
}

.match_drag_icon {
    touch-action: none;
    width: 25px;
    height: 25px;
    object-fit: contain;
    cursor: pointer;
    margin-right: 10px;
}

.upload_option_img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    object-fit: contain;
    opacity: 0.3;
}

.remove_match_icon {
    font-size: 35px;
    color: rgba(63, 82, 109, 0.1);
    cursor: pointer;
}

.MuiButtonBase-root.add_new_match_btn {
    padding: 9px 30px 9px 20px;
    border-radius: 6px;
    border: 1px solid #3a60d7;
    background-color: #fff;
    text-transform: none;
}

.add_new_match_icon {
    margin: 0px 20px 0px 0px;
}

.match_img_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border: 1px solid lightgray;
    margin-right: 15px;
    border-radius: 4px;
    padding: 2px 14px;
}

.match_question_img {
    height: 50px;
    width: 60px;
    cursor: pointer;
    object-fit: contain;
    margin-right: 20px;
}

.MuiTypography-root.match_img_name {
    font-size: 18px;
    font-weight: 600;
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 20px;
}

.match_img_clear {
    font-size: 25px !important;
    cursor: pointer;
}

.match_responses_list {
    padding-top: 20px;
}

.match_drag_icon_2 {
    touch-action: none;
    width: 25px;
    height: 25px;
    object-fit: contain;
    cursor: pointer;
    margin-right: 15px;
}

.set_answers_container {
    margin-bottom: 20px;
}

.set_answers_div {
    border: 1px solid #3f526d;
    padding: 10px 20px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
}

.MuiTypography-root.match_pair_question {
    font-size: 20px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.match_pair_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.match_pair_answer_div {
    touch-action: none;
    border: 1px dashed #3f526d;
    padding: 10px 20px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
}

.drag_drop_div {
    margin-top: 35px;
    background-color: #f9f8fd;
    height: fit-content;
    padding: 25px;
}

.MuiTypography-root.drag_and_drop_text {
    font-size: 20px;
    font-weight: 600;
}

.possible_response_div {
    display: flex;
    flex-direction: row !important;
    margin-top: 25px;
    flex-flow: wrap;
}

.possible_response_item {
    border: 1px solid #3f526d;
    padding: 10px 20px;
    border-radius: 6px;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.MuiInputBase-input.match_text_field {
    font-size: 15.8px;
}

.upload_image_error_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    height: 300px;
    justify-content: space-evenly;
    position: relative;
    border: 1px solid red;
}

.upload_image_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    min-height: 300px;
    justify-content: space-evenly;
    position: relative;
}

.hot_spot_image {
    height: 500px;
    width: 500px;
    object-fit: contain;
}

.HotSpotMarkerImage{
    display: block;
    object-fit: cover;
    width: 100%;
    height: 500px;
    text-align: center;
}

.MuiFormHelperText-root.upload_image_warning {
    color: red;
    margin-bottom: 20px;
}

.hot_spot_set_answer_div {
    margin-top: 16px;
    margin-bottom: 16px;
}

.MuiTypography-root.hot_spot_set_answer_text {
    font-size: 26px;
    margin-top: 30px;
    font-weight: 600;
}

.hot_spot_draw_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    min-height: 300px;
    justify-content: space-evenly;
    position: relative;
}

.hot_spot_draw_btn_div {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 100%;
}

.hot_spot_draw_btn {
    height: 50px;
}

.flash_card_main_div {
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    min-height: 620px;
    position: relative;
}

.flash_card_img_upload_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 267px;
    border-radius: 20px;
    background-color: #ebeef8;
    margin: 0 0 20px;
}

.flash_card_img_div {
    height: 267px;
    margin: 0 0 20px;
    border-radius: 20px;
    background-color: #ebeef8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.flash_card_img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.flash_card_img_close {
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    object-fit: contain;
    top: 8%;
    right: 10%;
}

.flash_card_upload_icon {
    height: 60px;
    width: 60px;
    object-fit: contain;
    cursor: pointer;
}

.flash_card_drag_drop_text {
    margin: 20px 30px;
    font-size: 12px;
    font-weight: normal;
    color: #959393;
}

.flash_card_hint_field {
    width: 100%;
    height: 52px;
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 11px !important;
}

.flash_card_ans_div {
    margin: 20px 0;
}

.flash_card_number {
    opacity: 0.54;
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-top: 28px;
}

.flash_card_delete {
    position: absolute;
    cursor: pointer;
    width: 25px;
    height: 25px;
    object-fit: contain;
    bottom: 5%;
    right: 5%;
}

.add_flash_card_div {
    cursor: pointer;
    width: 200px;
    height: 200px;
    margin: 160px 0 160px 0px;
    border-radius: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e1e1e1;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.MuiSvgIcon-root.add_flash_card_icon {
    height: 30px;
    width: 30px;
    margin-bottom: 10px;
}

.MuiTypography-root.add_flash_card_text {
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 15px;
}

.label_image_question {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
}

.label_image_answer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label_image_response {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0px 0px 20px 0px;
    justify-content: space-between;
}

.full_width_select {
    width: 100%;
}

.question_ellipsis_text {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}

.chart_table_container {
    border-radius: 6px;
    border: 0;
    padding: 5px;
}

.MuiTableCell-root.first_blank_cell {
    border: 0;
}

.MuiTableCell-root.chart_header {
    font-size: 16px;
    font-weight: 600;
    border: 0;
    white-space: nowrap;
    text-align: center;
}

.MuiTableCell-root.axis_header {
    font-size: 16px;
    font-weight: 600;
    border: 0;
    white-space: nowrap;
}

.MuiTableCell-root.table_cell_value {
    border: 0;
    text-align: center;
}

.MuiGrid-root.plot_graph_div {
    display: flex;
    text-align: center;
}

.MuiGrid-root.graph_correct_answer_div {
    display: flex;
    align-items: center;
    text-align: center;
}

.MuiTypography-root.enter_correct_ans_text {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.MuiTypography-root.graph_no_lines_text {
    font-size: 16px;
    font-weight: 600;
}

.MuiTypography-root.graph_start_end_text {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.MuiTableCell-root.line_num_table_cell {
    border: 0;
    text-align: center;
    padding-bottom: 48px;
}

.graph_axis_value_div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MuiFormHelperText-root.visible_graph_error {
    visibility: visible;
    color: red;
    margin-top: 10px;
    width: 150px;
    text-align: center;
}

.MuiFormHelperText-root.hidden_graph_error {
    visibility: hidden;
    color: red;
    margin-top: 10px;
    width: 150px;
    text-align: center;
}

.add_graph_line_div {
    margin-top: 30px;
}

.MuiTableCell-root.delete_table_cell {
    padding-bottom: 54px;
}

.delete_cell_box {
    cursor: pointer;
    text-align: left;
}

.plotly_graph_main_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.MuiTypography-root.graph_title_text {
    font-size: 16px;
    font-weight: 600;
}

.MuiFormHelperText-root.graph_init_value_error {
    color: red;
    margin-top: 10px;
    width: 150px;
    text-align: center;
}

.graph_setting_grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 32px !important;
}

.MuiFormControlLabel-root.graph_setting_check {
    background-color: #f9f8fd;
    margin-left: 0;
    width: 100%;
    margin-bottom: 0;
    height: 58px;
    padding-left: 10px;
}

.MuiTableRow-root.graph_attributes_row {
    display: flex;
}

.graph_bar_box {
    margin-bottom: 40px;
}

.align_item_center_question {
    align-items: center;
}

.fill_blank_option_div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.MuiFormControlLabel-root.fill_blank_option_item {
    margin-right: 8px;
    margin-left: 5px;
}

.MuiButtonBase-root.fill_blank_option {
    margin-right: 10px;
}

.fill_blank_text_type {
    width: 250px;
    margin-top: 20px;
}

.MuiSvgIcon-root.fill_option_clear_icon {
    font-size: 35px;
    cursor: pointer;
}

.graph_main_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.MuiRadio-root.rating_scale_radio {
    padding: 0px 10px 0px 0px;
}

.question_modal_main_div {
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 29px;
    padding: 32px 18px;
    width: 600px;
}

.audio_file_name_input_div {
    padding: 20px;
}

.audio_file_name_action {
    display: flex;
    justify-content: center;
}

.allow_camera_access_text {
    font-weight: 600;
    color: red;
    font-size: 16px;
    text-align: center;
}

.video_web_cam_box {
    position: relative;
    margin: 10px;
    object-fit: contain;
    border-radius: 8px;
}

.capture_video_div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.MuiButton-root.cursor_pointer {
    cursor: pointer;
}

.MuiButton-root.cursor_default {
    cursor: default;
}

.question_preview_video_modal {
    justify-content: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 29px;
    padding: 32px 18px;
    width: 1000px;
    height: 600px;
}

.preview_text_div {
    display: flex;
    justify-content: center;
    position: relative;
}

.MuiDivider-root.preview_video_divider {
    margin: 0px 0px 32px 0px;
}

.MuiTypography-root.no_record_text {
    font-size: 18px;
    font-weight: 600;
}

.MuiTypography-root.choose_back_text {
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.choose_back_img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    margin-right: 10px;
}

.choose_tabs_div {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
}

.choose_tabs {
    width: 100%;
    height: 60px;
}

.choose_right_image_div {
    padding-left: 8px;
}

.question_opacity_1 {
    opacity: 1;
}

.question_opacity_light {
    opacity: 0.5;
}

.choose_right_image {
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
}

.selected_text_grid {
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px;
    margin-top: 20px !important;
}

.MuiTypography-root.selected_text {
    font-size: 18px;
    font-weight: 600;
    display: flex;
}

.galley_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.gallery_check_img {
    width: 86px;
    height: 86px;
    object-fit: contain;
}

.flex_check_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hidden_check_img {
    display: none;
    justify-content: center;
    align-items: center;
}

.pagination_margin {
    margin: 0 10px;
}

.PrivateTabIndicator-colorSecondary-410.choose_from_gallery_tab_color {
    background: #3a60d7;
}

.audio_recorder_div {
    display: flex;
    justify-content: center;
    font-size: 24px;
    margin-top: 16px;
}

.audio_analyser_div {
    height: 200px;
    width: 600px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
}

.audio_recorder_actions {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.MuiSvgIcon-root.audio_record_icon {
    height: 50px;
    width: 50px;
    color: #6407B8;
}

.MuiSvgIcon-root.audio_record_stop_icon {
    height: 24px;
    width: 24px;
    color: #6407B8;
    border-radius: 6px;
}

.MuiIconButton-root.audio_record_stop {
    border: 1px solid rgb(100, 7, 184);
    height: 32px;
    width: 32px;
}

.MuiTypography-root.mcq_multiple_res {
    font-size: 20px;
    font-weight: 600;
    text-transform: none;
}

.mcq_checkbox_div {
    display: flex;
    margin-top: 32px;
    align-items: center;
}

.MuiCheckbox-root.mcq_checkbox {
    color: rgba(63, 82, 109,0.1);
    padding: 0px 10px 0px 0px;
    background-color: transparent;
}

.theme_option_box {
    padding: 23px 20px 23px 25px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.theme_rect_block_option_box {
    padding: 23px 20px 23px 25px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    align-items: center;
}

.theme_fill_blank_rect_block_option_box {
    padding: 23px 20px 23px 25px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    align-items: center;
}

.MuiTypography-root.theme_option_text {
    font-size: 18px;
    font-weight: 600;
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 20px;
}

.add_answer_field {
    width: 100%;
    border-radius: 8.8px;
    text-align: start;
    font-size: 15.8px;
}

.theme_option_image_box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.theme_option_image {
    height: 50px;
    width: 50px;
    cursor: pointer;
    object-fit: contain;
    margin: 0px 20px;
}

.theme_option_img_upload_div {
    display: flex;
    justify-content: space-between;
}

.theme_option_img_upload {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.MuiInputBase-input.add_answer_input {
    font-size: 15.8px;
}

.fill_option_image {
    height: 50%;
    width: 30%;
    cursor: pointer;
    object-fit: contain;
    margin-right: 20px;
}

.fill_option_img_upload_div {
    display: flex;
    justify-content: flex-end;
}

.true_false_theme_option_box {
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.true_false_rect_block_option_box {
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    align-items: center;
}

.true_false_option_img_upload_div {
    display: flex;
    justify-content: flex-end;
}

.outline_round_rect_theme {
    padding: 6px 21.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
}

.rect_block_layout_theme {
    padding: 6px 21.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.circle_layout_theme {
    padding: 6px 21.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.pic_option_image {
    height: 50px;
    width: 50px;
    cursor: pointer;
    object-fit: contain;
    margin-right: 20px; 
}

.MuiInputBase-input.fill_blank_input {
    font-size: 20px;
}

@media (max-width: 1279px) {
    .question_preview_video_modal {
        width: 80% !important;
        height: auto !important;
    }
}

@media (max-width: 599px) {
    .question_modal_main_div {
        width: 95% !important;
    }

    .question_preview_video_modal {
        width: 90% !important;
        height: auto !important;
    }
}