.periodTimePicker .MuiInputBase-input {
    color: #3f526d;
    font-size: 1.125rem;
    font-family: 'Open Sans';
}

.timePeriodBox {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 0 15px;
    margin-left: 15px;
    height: 58.3px;
}   

.timePeriodText {
    color: #3f526d;
    font-size: 1.125rem !important;
    font-weight: 500 !important;
}

.dynamic_popup_width {
    width: 55% !important;
}

.periodTimePicker .MuiFormHelperText-contained {
    margin-left: 0;
    margin-right: 0;
}

.period_list_header {
    font-size: 20px !important;
    font-weight: 600 !important;
}

.period_list_header_center {
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
}

.period_list_item {
    font-size: 18px !important;
    font-weight: 500 !important;
    background-color: #f9f8fd;
    border-bottom: none !important;
}

.period_list_item_center {
    font-size: 18px !important;
    font-weight: 500 !important;
    background-color: #f9f8fd;
    border-bottom: none !important;
    text-align: center !important;
}

/* pascal case css */
.BatchPeriodMainDiv {
    min-width: 920px;
    overflow: auto;
}

.BatchPeriodHeadDiv {
    margin: 20px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.MuiTypography-root.CreationText {
    font-size: 1.25rem;
    font-weight: 600;
}

.MuiButton-root.ViewBatchBtn {
    cursor: pointer;
    border-radius: 6px;
    padding: 6px 20px 4px 20px;
    margin: 0px 20px 0px 0px;
    text-transform: capitalize;
}

.MuiButton-root.AddPeriodBtn {
    cursor: pointer;
    border-radius: 6px;
    padding: 6px 20px 4px 20px;
    text-transform: capitalize;
}

.BatchPeriodSpacingDiv {
    padding: 0 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.BatchPeriodBox {
    padding: 22px 20px 26px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.SelectBatchDropDown {
    width: 180px;
    margin-bottom: 20px;
}

.PeriodsListContainer {
    overflow: auto;
    width: 100%;
    border-radius: 6px;
}

.PeriodsListTableHead {
    color: #3f526d;
    background-color: #ded9ff;
    border-radius: 6px;
}

.MuiTypography-root.NoPeriodsRecordFound {
    font-size: 18px;
    margin-top: 20px;
    margin-left: 20px;
}

.BlankPeriodCell {
    height: 10px;
}

.PeriodsActionDiv {
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
}

.MuiIconButton-root.EditPeriodIconBtn {
    padding: 0px 15px 0px 0px;
}

.EditBatchPeriodImg {
    height: 22px;
    width: 22px;
    object-fit: contain;
}

.MuiIconButton-root.DeletePeriodIconBtn {
    padding: 0px 0px 0px 15px;
}

.DeleteBatchPeriodImg {
    height: 22px;
    width: 22px;
    object-fit: contain;
    opacity: 0.3;
}

.BatchPeriodPaginateDiv {
    text-align: end;
    margin-top: 25px;
}

.DeleteBatchPeriodModal {
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 29px;
    padding: 32px 18px;
    width: 600px;
}

.DeleteBatchDivider {
    height: 1px;
    margin: 14px 0 31px 0;
    border: solid 0.3px #979797;
}

.DeleteBatchBtnDiv {
    display: flex;
    justify-content: center;
}

.MuiPaper-root.ViewBatchModal {
    border-radius: 8px;
}

.ViewBatchModalDiv {
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    min-width: 525px;
}

.ViewBatchHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.MuiTypography-root.ViewBatchText {
    font-size: 1.5rem;
    font-weight: 600;
}

.MuiSvgIcon-root.ViewBatchClose {
    cursor: pointer;
    font-size: 38px;
    color: lightGray;
}

.BatchListDiv {
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: auto;
    max-height: 350px;
}

.BatchListHeader {
    background-color: #ded9ff;
    border-radius: 6px;
}

.BatchHeadText {
    padding: 17px 0px 16px 20px;
    font-size: 1.25rem;
    font-weight: 600;
}

.BatchHeadTimeText {
    padding: 17px 0px 16px 0px;
    font-size: 1.25rem;
    font-weight: 600;
}

.BatchHeadActionText {
    padding: 17px 0px 16px 0px;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
}

.MuiTypography-root.NoBatchRecordFound {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 20px;
}

.BatchDataDiv {
    margin-top: 10px;
}

.BatchDataContainer {
    background-color: #f9f8fd;
    padding: 16px 0px 16px 0px;
    border-radius: 6px;
}

.BatchNameText {
    padding: 0px 0px 0px 20px;
    font-size: 1.125rem;
    font-weight: 600;
}

.BatchTimeText {
    font-size: 1.125rem;
    font-weight: 600;
    text-transform: capitalize;
}

.BatchActionDiv {
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
}

.MuiIconButton-root.BatchActionIconBtn {
    padding: 0 15px 0 12px;
}

.NewBatchDiv {
    margin-top: 1.5rem;
}

.MuiButton-root.NewBatchBtn {
    padding: 8px 40.5px 8px 41.5px;
    border-radius: 8px;
}

.MuiTypography-root.AddNewBatchText {
    text-transform: none;
    font-size: 1.125rem;
    font-weight: 600;
}

.PeriodModalDiv {
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.AddEditFields {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.MuiTypography-root.AddEditTitle {
    font-size: 1.125rem;
    font-weight: 600;
    padding-bottom: 10px;
}

.AddEditFieldSpace {
    padding-top: 15px;
}

.MuiFormHelperText-root.SelectBatchText {
    color: red;
    margin: 3px 14px 0;
}

.AddEditFlexRow {
    display: flex;
    flex-direction: row;
}

.CreatePeriodDiv {
    margin-top: 2rem;
    padding-left: 15px;
}

.MuiButton-root.CreatePeriodBtn {
    padding: 8px 40.5px 8px 41.5px;
    border-radius: 8px;
}

.MuiTypography-root.CreatePeriodText {
    text-transform: none;
    font-size: 1.125rem;
    font-weight: 600;
}

.MuiTypography-root.BatchDurationText {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 20px 0 5px 0;
}

.MuiInputBase-input.PeriodInputProps {
    font-size: 1.125rem;
    padding: 1rem 1.2rem;
}

.PeriodNameInputProps {
    font-size: 1.125rem;
    opacity: 1;
    padding-left: 0;
}

.AddPeriodTextfieldStyle {
    width: 100%;
    border-radius: 6px;
    text-align: start !important;
    font-size: 1.125rem;
}

@media (max-width: 1279px) {
    .padding-7 {
        padding: 7px !important;
    }
    .dynamic_popup_width {
        width: 80% !important;
    }
    .period_list_item {
       padding: 10px !important;
    }
    .period_list_item_center { 
       padding: 10px !important;
    }
}

@media (max-width: 959px) {
    .dynamic_popup_width {
        width: 90% !important;
    }
}

@media (max-width: 599px) {
    .padding-top-15 {
        padding-top: 15px;
    }
    .modal-width-90 {
        width: 95% !important;
    }

    .DeleteBatchPeriodModal {
        width: 95% !important;
    }
}