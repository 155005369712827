.shareModalPopup .p20 {
    padding: 20px;
  }
  
  .shareModalPopup .userAvtar {
    width: 50px;
    height: 50px;
  }
  
  .shareModalPopup .studentFLname {
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
  }
  
  .shareModalPopup .studentEmail {
    text-transform: none;
    font-size: 14px;
    font-weight: bold;
    word-break: break-all;
  }
  
  .shareModalPopup .noDataBox {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .shareModalPopup .noDataText {
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .shareModalPopup .mtb4 {
    margin: 4px 0;
  }
  
  .shareModalPopup .addTeacherTitle {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    padding-top: 0;
  }
  
  .shareModalPopup .addTeacherGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 0;
  }
  
  .shareModalPopup .searchParticipantsInput {
    border: none;
    outline: none;
    opacity: 0.5;
    font-size: 18px;
    width: 100%;
    background: transparent;
  }
  
  .shareModalPopup .selectAllTitle {
    font-size: 18px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .shareModalPopup .addStudentGridlist {
    margin: 8px 0;
    max-height: 210px;
    overflow-y: auto;
    padding: 12px;
  }
  
  .shareModalPopup .addGridName {
    border-bottom: 1px solid #000;
    margin: 4px 0;
    cursor: pointer;
    width: 100%;
  }
  
  .shareModalPopup .avtarGrid {
    align-self: center;
    justify-content: center;
    display: flex;
  }
  
  .shareModalPopup .teacherListGrid {
    margin: 4px 0;
    display: block;
  }
  
  .shareModalPopup .teacherTitle {
    font-size: 18px;
    font-weight: 600;
  }
  
  .shareModalPopup .teacherListGridColoumn {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
  }
  
  .shareModalPopup .iconSize {
    height: 25px;
    width: 25px;
  }
  
  .shareModalPopup .closeIconBox {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  
  .shareModalPopup .addtaskSharePopup {
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 8px;
    width: 70%;
    height: auto;
    max-height: 80%;
    max-width: 650px;
  }