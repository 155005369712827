.input_date_cal_css_format1 > input{
    width: 100%;
    padding:1rem 1.875rem;
    font-size:1.125rem;
    border-radius: 6px;
    border: 1px solid lightgray;
    position: relative;
    color: #3f526d;
    }
.input_date_css_error_format1 > input{
    width: 100%;
    padding:1rem 1.875rem;
    position: relative;
    font-size:1.125rem;
    border-radius: 6px;
    border: 1px solid red;
    color: #3f526d;
}
.Date_of_birth_calendar_Icon1:focus{
    outline: none;
}
.react-tel-input .country-list .country{
    text-align: start !important;
    margin:0px 0px 0px 10px !important;
}
.rdtPicker{
    position:absolute !important;
}
.Date_of_birth_calendar_Icon1{
    background-repeat: no-repeat;
    background-image: url("../../assets/addCalendar.png");
    background-size: 19px 19px;
    background-position: top 17px right 28.3px;
}

.fileNameText22 {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Enroll-icons{
    width: 50px;
    height: 50px;
    justify-content: center;
  }

  @media only screen and (max-width: 767px) {
    .filterright{
        width: 35% !important;
    }
    .filterright button{
        font-size: 14px !important;
    }

    .filterright button img{
        width: 18px !important;
        height: 18px !important;
        margin: 0px 10px 0px 0px !important;
    }
  }