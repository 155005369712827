.timeTableTooltip .MuiMenu-paper {
  left: 0 !important;
  right: 0 !important;
  top: 70px !important;
  margin: 0 auto;
}

.Tooltip-outer {
  padding: 15px !important;
}

.Tooltip-outer p {
  font-size: 16px !important;
}

.Tooltip-outer .Tooltiptitle {
  font-size: 19px !important;
}

.EventToolTipMainDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.EventToolTipEventTxt {
  font-family: "Open sans" !important;
  font-size: 22px !important;
  color: #3f526d !important;
  font-weight: 600 !important;
  margin-bottom: 2% !important;
}

.EventToolTipRecordingBtnDiv {
  display: flex;
}

.EventToolTipRecordingBtn {
  font-family: "Open sans" !important;
  color: white !important;
  height: 40px;
  border-image-source: linear-gradient(to top, #af38cb, #471c51) !important;
  background-image:
    linear-gradient(to top, #af38cb, #471c51), linear-gradient(to top, #af38cb, #471c51) !important;
}

.EventToolTipCreateIconBtn {
  padding: 0 15px !important
}

.EventToolTipCreateIcon {
  font-size: 38px !important;
  cursor: pointer
}

.EventToolTipSubjectImageDiv {
  height: 69px;
  width: 69px;
  border-radius: 6px;
  background-color: #fff2de;
  display: flex;
  justify-content: center;
}

.EventToolTipSubjectImage {
  width: 40px;
  height: 40px;
  align-items: center;
}

.EventToolTipSubjectTitleTxt {
  font-family: "Open sans" !important;
  font-size: 22px !important;
  color: #3f526d !important;
  font-weight: 600 !important;
  align-self: center !important;
  margin-left: 20px !important;
}

.EventToolTipEventStartMonthDiv {

  height: fit-content;
  width: 100%;
  border-radius: 8px;
  display: flex;
  margin: 20px 0;
  padding: 3% 0;
}

.EventToolTipEventStartMonthSubDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.EventToolTipEventStartMonthTxt {
  font-family: "Open sans" !important;
  font-size: 20px !important;
  color: #fff !important;
  font-weight: 600 !important;
  margin-left: 20px !important;
}

.EventToolTipPeriodNameDiv {
  display: flex;
  flex-direction: column;
  justify-content: center
}

.EventToolTipEventTitleTxt {
  font-family: "Open sans" !important;
  font-size: 18px !important;
  color: #fff !important;
  margin-left: 20px !important;
}

.EventToolTipImageClockDiv {
  display: flex;
  flex-direction: row;
  padding: 12px 0;
}

.EventToolTipImageClockImg {
  height: 24px;
  width: 24px;
}

.EventToolTipDurationTxt {
  font-family: "Open sans" !important;
  font-size: 18px !important;
  color: #fff !important;
  margin-left: 10px !important;
}

.EventToolTipTxtWeight {
  font-weight: 600 !important;
}

.EventToolTipUserDiv {
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  align-items: center;
}

.EventToolTipImg {
  height: 24px;
  width: 24px;
}

.EventToolTipTeacherDiv {
  font-family: "Open sans" !important;
  font-size: 18px !important;
  color: #3f526d !important;
  margin-left: 10px !important;
  width: 100%;
  display: flex
}

.EventToolTipTeacherAvtarDiv {
  width: 24px;
  height: 24px;
  margin: 0 2%
}

.EventToolTipTeacherAvtar {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.EventToolTipTitleDiv {
  display: flex;
  flex-direction: row;
  padding: 12px 0;
}

.EventToolTipTitleTxt {
  font-family: "Open sans" !important;
  font-size: 18px !important;
  color: #3f526d !important;
  margin-left: 10px !important;
}

.EventToolTipDescDiv {
  margin: 20px 0
}

.EventToolTipDescTitle {
  font-family: "Open sans" !important;
  font-size: 18px !important;
  color: #3f526d !important;
  font-weight: 600 !important;
}

.EventToolTipDescTxt {
  font-family: "Open sans" !important;
  font-size: 18px !important;
  color: #3f526d !important;
  margin-top: 10px !important;
}