.addTaskPopup .fullWidth {
  width: 100%;
}

.addTaskPopup .noteBox {
  align-items: center;
  justify-content: center;
  text-align: start;
  margin: 0 12px 0 0;
}

.addTaskPopup .noteBoxTitle {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin: 26px 24px 9px 3px;
}

.addTaskPopup .timeBox {
  align-items: center;
  justify-content: center;
  text-align: start;
  margin: 0 12px 0 0;
}

.addTaskPopup .subjectBox {
  align-items: center;
  justify-content: center;
  text-align: start;
}

.addTaskPopup .timeBoxTitle {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 3px 0;
}

.addTaskPopup .fileUploadTitle {
  text-transform: none;
  font-size: 20px;
  font-weight: 500;
}

.addTaskPopup .none {
  display: none;
}

.addTaskPopup .browseBtn {
  background-color: white;
  box-shadow: none;
  text-transform: none;
  line-height: 0;
  font-size: 16px;
  font-weight: bold;
}

.addTaskPopup .updateBox {
  display: flex;
  flex-direction: row;
}

.addTaskPopup .supportFormats {
  text-transform: none;
  font-size: 12px;
  margin: 4px 0 0 ;
}

.addTaskPopup .updateBoxButton {
  margin: 0 20px 0 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  box-shadow: none;
  text-transform: none;  
}

.addTaskPopup .removeBtn {
  background-color: #fff;
}

.addTaskPopup .textStart {
  text-align: start;
}

.addTaskPopup .fileNameTitle {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;  
  margin: 10px 24px 0 3px;
}

.addTaskPopup .fileTextField {
  width: 100%;
  margin: 5px 35px 16px 1px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  font-family: "Open sans";
  font-size: 16px;
  color: #03014c;
}

.addTaskPopup .shareWithBox {
  align-items: center;
  justify-content: center;
  text-align: start;
  margin: 0 33px 0 1px;
}

.addTaskPopup .shareWithTitle {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin: 26px 24px 9px 3px;
}

.addTaskPopup .teachersBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 12px;
}

.addTaskPopup .teachersBoxButton {
  margin: 0 20px 0 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  box-shadow: none;
  text-transform: none;
}

.addTaskPopup .studentsButton {
  margin: 0 20px 0 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  box-shadow: none;
  text-transform: none;
}

.addTaskPopup .browse_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #fff;
  border-radius: 12px;
  padding: 34px 0;
}         

.addTaskPopup .fileUploadIcon {
  width: 61px;
  height: 63px;
  margin: 0 0 27px 0;
}

.addTaskPopup .uploadBoxIcon {
  width: 60px;
  height: 60px;
  margin: 0 0px 27px 0px;
}

.addTaskPopup .gradeTitle {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin: 0px 15px 11px 0;
}

.addTaskPopup .dragDrop {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.addTaskPopup .dragDropTitle {
  text-transform: none;
  font-size: 16px;
}

.addTaskPopup .noneTransform {
  text-transform: none;
}

.addTaskPopup .markTextField {
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 8.8px;
  border: none;
  font-family: "Open sans";
  font-size: 16px;
  color: #03014c;
}

.addTaskPopup .notAllow {
  cursor: not-allowed;  
}

.addTaskPopup .selectSubjectBox {
  margin: 11px 0 0px 3px;
}

.addTaskPopup .updateBtn {
  margin: 0 20px 0 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  box-shadow: none;
}

.addTaskPopup .updateBtnbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  border-radius: 12px;
  padding: 14px 0px 0 0px;
}

.addTaskPopup .updateCancelBtn {
  background: #f6f6f6;
  border-radius: 3px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  box-shadow: none;
}

.saveCancelRow .saveBtn {
  width: 50%;
  padding: 10px 0;
  border-radius: 6px;
  background: #7f6cfc;
  text-transform: capitalize;
  color: #fff;
  font-size: 16px;
  font-family: "Open sans";
  float: right;
}

.saveCancelRow .cancelBtn {
  width: 50%;
  padding: 10px 0;
  border-radius: 6px;
  background: #7f6cfc;
  text-transform: capitalize;
  color: #000;
  font-size: 16px;
  font-family: "Open sans";
  float: left;
}

.taskUnitContentainer {
  width: 100%;
}

.taskUnitContentainer .classesGrid {
  margin-top: 20px;
  align-items: center;
}

.taskUnitContentainer .classBox {
  text-align: start;
}

.taskUnitContentainer .classesButtonText {
  text-transform: none;
  font-weight: 600;
}

.taskUnitContentainer .mt20 {
  margin-top: 20px;
}

.taskUnitContentainer .searchTaskBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.taskUnitContentainer .searchOuter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.taskUnitContentainer .searchMob {
  padding: 12px 20px 12px 20px;
  margin: 23px 0px 0px 0px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  width: 30%;
}

.taskUnitContentainer .searchIcon {
  height: 22.3px;
  width: 22.3px;
}

.taskUnitContentainer .searchTaskInput {
  border: none;
  outline: none;
  opacity: 0.8;
  font-size: 18px;
  width: 100%;
  font-family: "Open sans";
  font-weight: 600;
}

.taskUnitContentainer .todayButton {
    margin: 0px 20px 0px 20px;
    padding: 5px 15px;
    border-radius: 8px;
    text-transform: none;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
    box-shadow: none;
}

.taskUnitContentainer .calBoxTask {
  display: flex;
  align-items: center;
  width: 30%;
}

.taskUnitContentainer .mt23 {
  margin-top: 23px;
}

.taskUnitContentainer .addtaskCol {
  justify-content: end;
  margin: 23px 11px 0 28px;
  display: flex;
  align-items: center;
  width: 40%;
}

.taskUnitContentainer .addTaskButton {
  padding: 5px 25px;
  border-radius: 8px;
  text-transform: none;
  height: 56px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: none;
}

.taskUnitContentainer .taskOuterBox {
  display: flex;
  flex-direction: column;
  margin: 28px 0px 30px 0px;
  padding: 25px 20px 25px 20px;
  border-radius: 13px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.19);
  background: #fff;
}

.taskUnitContentainer .taskCenter {
  display: flex;
  flex-direction: row;
}

.taskUnitContentainer .noRecordText {
  width: 176px;
  height: 27px;
  font-family: "Open sans";
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f526d;
}

.taskUnitContentainer .taskMainMemberBox {
  padding: 26px 17px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  max-height: 75vh;
}

.taskUnitContentainer .taskAttributesList { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.taskUnitContentainer .attributeImg {
  display: flex;
  flex-direction: row;
}

.taskUnitContentainer .attributeAvtar {
  height: 32px;
  width: 35px;
  margin: 0 15px 19px 0;
}

.taskUnitContentainer .taskSubjectName {
  font-size: 12px;
  font-family: "Open sans";
  font-weight: 600;
  color: #000;
  opacity: 1;
  margin: 0 0 2px;
}

.taskUnitContentainer .viewType { 
  text-transform: none;
  font-size: 16px;
  font-family: "Open sans";
  font-weight: 600;
  color: #3f526d;
}

.taskUnitContentainer .assigneeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.taskUnitContentainer .flName {
  margin: 0 0 0 5px;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  font-family: "Open sans";
  letter-spacing: 0.24px;
  text-transform: capitalize;
}

.taskUnitContentainer .dueDateTimeBox {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.taskUnitContentainer .assigneeAvtar {
  height: 25px;
  width: 25px;
  margin: 0 5px 0 0;
  border-radius: 60px;
}

.taskUnitContentainer .assigneeBoxTitle {
  margin: 3px 62px 2px 0;
  color: #79869f;
  font-size: 12px;
  font-family: "Open sans";
  letter-spacing: 0.24px;
}

.taskUnitContentainer .submissionBox {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.taskUnitContentainer .submissionTitle {
  color: #79869f;
  font-size: 11.4px;
  font-family: "Open sans";
  letter-spacing: 0.21px;
}

.taskUnitContentainer .submissionlength {
  color: #0a043c;
  font-size: 11.4px;
  font-family: "Open sans";
  letter-spacing: 0.21px;
  margin-left: 5px;
}

.taskUnitContentainer .memberLength {
  color: #79869f;
  font-size: 11.4px;
  font-family: "Open sans";
  letter-spacing: 0.21px;
}

.taskUnitContentainer .taskMembersAttributesBox {
  display: flex;
  flex-direction: row;
}

.taskUnitContentainer .taskMemberAvtar {
  height: 25px;
  width: 25px;
  margin: 0 -10px 0px 0;
  border-radius: 60px;
}

.taskUnitContentainer .taskMembersAttributes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.taskUnitContentainer .borderLineProgressGrid {
  direction: row;
  justify-content: space-between;
  align-items: center;
}

.taskUnitContentainer .borderBottomFirst {
  border-bottom: solid 0.2px #f26767;
}

.taskUnitContentainer .borderBottomSecond {
  border-bottom: solid 0.2px #327095;
}

.taskUnitContentainer .borderBottomThird {
  border-bottom: solid 0.2px #e69416;
}


.taskUnitContentainer .colorFirst {
  color: #f26767;
}

.taskUnitContentainer .colorSecond {
  color: #327095;
}

.taskUnitContentainer .colorThird {
  color: #e69416;
}

.taskUnitContentainer .taskTitle {
  color: #fff;
  font-size: 10px;
  font-family: "Open sans";
  letter-spacing: 1.5px;
}

.taskUnitContentainer .taskBg {
  margin: 0 7px 1px 16px;
  padding: 4px 10px 5px;
  border-radius: 3.7px;
}

.taskUnitContentainer .taskBgFirst {
  background: #f26767;
}

.taskUnitContentainer .taskBgSecond {
  background: #327095;
}

.taskUnitContentainer .taskBgThird {
  background: #e69416;
}

.taskUnitContentainer .unitAssignBox {
  display: flex;
  flex-direction: row;
}

.taskUnitContentainer .taskAttributesName {
  margin: 0px 7px 0px 0;
  font-size: 16px;
  font-family: "Open sans";
  font-weight: 600;
  letter-spacing: 0.32px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 270px;
  text-transform: capitalize;
}

.taskUnitContentainer .taskName {
  font-size: 10px;
  font-family: "Open sans";
  font-weight: normal;
  color: #9aa3b2;
  margin: 2px 0 0 0;
}

.taskUnitContentainer .menuIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
}

.taskUnitContentainer .moreIconPoint {
  height: 24px;
  width: 24px;
  color: #000;
  cursor: pointer;
}

.taskUnitContentainer .width203 {
  width: 203px;
}

.taskUnitContentainer .flexBase {
  flex-basis: 0;
}

.taskUnitContentainer .boxBgfirst {
  background: #fff0ef;
}

.taskUnitContentainer .boxBgsecond {
  background: #eff9ff;
}

.taskUnitContentainer .boxBgthird {
  background: #fff7ea;
}

.taskUnitContentainer .teacherTaskTab {
  display: flex;
  flex-direction: row;
}

.taskUnitContentainer .dueDate {
  margin: 5px 10px 14px 0;
  color: #79869f;
  font-size: 14px;
  font-family: "Open sans";
  font-weight: 600;
  letter-spacing: 0.28px;
}

.taskUnitContentainer .dueDateTimeBox .dueDate {
  margin: 0 10px 0 0;
  color: #79869f;
  font-size: 12px;
  font-family: "Open sans";
  letter-spacing: 0.24px;
}

.taskUnitContentainer .dueDateAttributes {
  margin: 0 0 3px 10px;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  font-family: "Open sans";
  letter-spacing: 1.01px;
}

.taskUnitContentainer .unitNameAttributs {
  margin: 5px 0 14px 0;
  color: #79869f;
  font-size: 14px;
  font-family: "Open sans";
  font-weight: 600;
  letter-spacing: 0.28px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}

.taskUnitContentainer .taskAssignBox {
  margin: 0 0 18px 0;
  height: 1px;
}

.taskUnitContentainer .paginateBox {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.taskUnitContentainer .viewBtnBox {
  width: 100%;
  text-align: center;
  margin-top: 26px;
}

.taskUnitContentainer .viewAllBtn {
  background: #efefef;
  margin: 0 20px 0 0;
  padding: 14px 36px 13px 37px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #484848;
  box-shadow: none;
}

@media (max-width: 1199px) {
  .taskUnitContentainer .teacherTaskTab .MuiGrid-grid-xs-4 {
    max-width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 959px) {
  .taskUnitContentainer .teacherTaskTab .MuiGrid-grid-xs-4 {
    max-width: 100%;
    flex-basis: 100%;
  }

  .taskUnitContentainer .date-input-student > input {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .taskdetailPopup {
    padding: 15px;
  }

  .taskUnitContentainer .addTaskButton, .taskUnitContentainer .todayButton {
    font-size: 15px;
    height: 45px;
  }

  .paginateBox .paginationBttnsBtm a {
    padding: 5px 10px;
    margin: 2px;
  }

  .taskUnitContentainer .calBox {
    width: 48%;
  }

  .taskUnitContentainer .dueDate {
    width: 50%;
  }

  .taskUnitContentainer .taskOuterBox {
    padding: 15px;
  }

  .taskUnitContentainer .viewAllBtn {
    padding: 10px 25px 10px 25px;
    font-size: 14px;
  }

  .taskUnitContentainer .searchMob {
    width: 100%;
  }

  .taskUnitContentainer .addtaskCol {
    width: 40%;
    justify-content: start;
    margin: 23px 11px 0px 0;
  }

  .taskUnitContentainer .calBoxTask {
    width: 100%;
    font-size: 14px;
  }

  .taskUnitContentainer .searchOuter {
    flex-wrap: wrap;
  }

  .taskUnitContentainer .todayButton {
    margin: 0;
  }

  .taskUnitContentainer .addTaskButton span {
    white-space: nowrap;
  }
}