:root {
  --color-scrollBar: rgb(56, 44, 124);
}

.profile_img {
  cursor: pointer;
  height: 100;
  width: 100;
  padding: 3;
}

.parent_grid_box_shadow {
  box-shadow: 0 24px 48px 0 rgba(30, 28, 46, 0.08);
  background-color: var(--calcite);
  padding: 17px 35px 24px 33px;
  border-radius: 15px;
}

.parent_grid_box {
  margin-top: 31px !important;
}

.bold_heading {
  font-weight: 600 !important;
}

.hello_text {
  font-size: 27px !important;
  font-weight: 600 !important;
}

.flex_direction {
  display: flex !important;
  flex-direction: row !important;
}

.center_justify {
  justify-content: space-between !important;
  align-items: center !important;
}

.center_align {
  display: flex;
  align-items: center;
}

.parent_info_block {
  margin-left: 36px !important;
  min-height: 120px !important;
}

.parent_info_block p {
  font-size: 19px !important;
}
.parent_info_block span {
  font-size: 27px !important;
}
.mt-11{
  margin-top:11px !important;
}
.parent_note_widget_create_icon {
  width: 17px;
  height: 17px;
  margin-right: 40px;
}
.fixed_sms_icon {
  position: fixed;
  right: 2%;
  bottom: 4%;
}
.parent_sms_icon {
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.parent_reminder-quote-outer p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.reminder_event_block {
  padding: 30px !important;
  display: flex;
  flex-direction: column;
  border-radius: 15px !important;
  min-width: 280px !important;
  height: 200px !important;
  background-size: cover;
  margin-left: 10px;
  margin-right: 10px;
}

.reminder_thought_block {
  padding: 30px !important;
  width: 400px !important;
  height: 200px !important;
  display: flex;
  flex-direction: column;
  border-radius: 15px !important;
  background-size: cover;
  margin-left: 10px;
  margin-right: 10px;
}

.parent_right_sidebar {
  display: flex;
  flex-direction: column;
  width: 34.5%;
  /* padding-left: 15px; */
}

.parent_tab {
  height: 72px;
  font-size: 18px !important;
  text-transform: capitalize !important;
  /* color: #3f526d !important; */
}

.parent_tab_indicator{
  width: 100% !important;
}

.parent_tab_indicator_underline{
  background: #3a5dbdff !important;
}

.parent_tab.MuiTab-root {
  /* max-width: 100% !important; */
  min-width: 100px !important;
}

.ward_name_tab{
  overflow-x: auto;
  width: inherit;
  display: flex;
  align-items: center;
}

.ward_name{
  padding: 2% 3%;
  border-radius: 10px;
  width: 160px !important;
  margin-right: 3%;
}

.ward_name_inactive{
  background-color: #ffffff !important;
}

.ward_name span{
text-align: center !important;
text-transform: capitalize !important;
display: block;
width: 100%;
}

.tabs_details{
  padding: 2% !important;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.parent_result_grid{
  padding:0px !important;
  margin-top:16px !important;
}
.parent_detail_grid{
  padding:0px !important;
  margin-top:16px !important;
  margin-bottom: 16px !important;
}
.parent-flex-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.parent-edit-widget-btn{
  width: 123px;
  height: 39px;
  text-transform: none !important;
  margin: 15px 2px 0 15px !important;
  padding: 9px 20px 10px !important;
  border-radius: 8px !important;
}
.parent-avatar-grid{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.mt-10{
  margin-top: 10px !important;
}
@media only screen and (max-width: 767px) {
  .parent_info_block p {
    font-size: 18px !important;
  }
  .parent_info_block span {
    font-size: 25px !important;
  }
}

@media only screen and (max-width: 1050px) {
  .parent_right_sidebar {
    width: 100%;
  }
}
