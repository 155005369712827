@media (max-width: 1279px) {
    .learn_together_text {
        font-size: 50px !important;
    }

    .student_illustrator_img {
        width: 50% !important;
        right: 0% !important;
        transform: translateX(10%) !important;
    }

    .attributed_textlabel {
        margin-top: 0 !important;
    }

    .welcome_text {
        margin-top: 0 !important;
        padding-top: 5% !important;
    }

    .welcome_text_otp {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
}

@media (max-width: 959px) {
    .learn_together_text {
        font-size: 42px !important;
        margin-top: 0 !important;
    }

    .student_illustrator_img {
        width: 42% !important;
    }

    .cloud_big_img {
        width: 100px !important;
        height: 70px !important;
        bottom: 2% !important;
    }

    .cloud_small_img {
        width: 45px !important;
        height: 35px !important;
        bottom: 10% !important;
        top: auto !important;
    }

}
@media (max-width: 767px) {
 @supports (-webkit-text-size-adjust:none) and (not (-ms-accelerator:true)) {
.ios .input_pass_icon{
    padding: 0 15px !important;
    height: 60px;
    display: flex;
    align-items: center;
}
}

}

@media (max-width: 599px) {
    .learn_together_text {
        font-size: 32px !important;
        max-width: 185px !important;
        margin-bottom: 65px !important;
    }

    .login_div {
        width: 90% !important;
    }

    .welcome_text {
        font-size: 25px !important;
        padding-top: 15px !important;
    }

    .cloud_big_img {
        width: 70px !important;
        height: 50px !important;
        bottom: 1% !important;
    }

    .cloud_small_img {
        left: 40% !important;
    }

    .student_illustrator_img {
        width: 50% !important;
        bottom: 5% !important;
    }

    .social_media_div {
        width: 80% !important;
        font-size: 12px !important;
    }

    .social_media_img {
        height: 22px !important;
        width: 22px !important;
    }

    .resendotp_text {
        font-size: 18px !important;
    }

    .xs-mb-25 {
        margin-bottom: 25px !important;
    }

    .max_width_100 {
        max-width: 100% !important;
    }
}