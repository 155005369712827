.TeacherGradeBookMainDiv {
    padding: 20px 0px;
}

.TeacherGradeBookAlignItemsDiv {
    align-items: center;
}

.TeacherGradeBookTypographyTxt {
    text-transform: none !important;
    font-size: 18px !important;
    font-weight: 600 !important
}

.TeacherGradeBookDropDownSelect {
    width: 96% !important;
}

.TeacherGradeBookSelectTabDiv {
    margin: 20px 0px;
}

.TeacherGradeBookTabWidthDiv {
    width: 100%;
}