.textlabel_classes {
    font-family: "Open sans" !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #3f526d;
    margin: 21px 0 23px 0 !important;
}

.view_whitebox_bg {
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 20px;
    height: 78vh;
    overflow: auto;
}

.view_whitebox_bg_right {
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 41px 28px 30px 36px;
    height: 78vh;
    overflow: auto;
}

.textlabel_recordings {
    font-family: "Open sans" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #471c51;
    margin: 10px 0px !important;
}

.calendar_icon {
    width: 34px;
    height: 34px;
    object-fit: contain;
}

.recording_section {
    margin: 14px 0 5px 0;
    cursor: pointer;
    padding: 10px;
}

.recording_section_selected {
    margin: 14px 0 5px 0;
    cursor: pointer;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.recording_title {
    font-family: "Open sans" !important;
    font-size: 18px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000;
    margin: 0 0 9px 0 !important;
    text-transform: capitalize;
}

.recording_detail {
    font-family: "Open sans" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #999;
}

.recording_header {
    font-family: "Open sans" !important;
    font-size: 28px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000;
    margin: 0 0px 22px 0 !important;
    text-transform: capitalize;
}

.detail_section_text {
    font-family: "Open sans" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #999;
    margin-bottom: 22px !important;
}

.view_recording_btn {
    width: 164px;
    height: 41px;
    margin: 17px 0px 24px 0 !important;
    padding: 7px !important;
    border-radius: 8.8px !important;
    background-image: linear-gradient(289deg, #aca2ff 119%, #776be8) !important;
    font-family: "Open sans" !important;
    font-size: 19.8px !important;
    font-weight: 600 !important;
    text-align: center;
    color: #fff !important;
    text-transform: capitalize !important;
}

.recording_video {
    border-radius: 10px;
    height: 414px;
    object-fit: cover;
}

.recording_video_small {
    border-radius: 10px;
    object-fit: cover;   
    display: flex;
    justify-content: center;
    align-items: center;
}

.recording_header_small {
    font-family: "Open sans" !important;
    font-size: 24px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000;
    margin: 10px 0px 10px 0 !important;
    text-transform: capitalize;
}

.right_date_section {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 1279px)  {
    .right_date_section {
        justify-content: flex-start;
    }
    .recording_video {
        height: 325px;
    }
}

@media (max-width: 599px)  {
    .view_whitebox_bg {    
        height: auto;
    }
    
    .view_whitebox_bg_right {
        height: auto;
    }

    .recording_video {
        height: 275px;
    }

    .recording_header {
        font-size: 24px !important;
    }
}