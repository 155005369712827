.view_marksbox {
  width: 160px;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  align-items: center;
}

.padded-span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
}

.min-padded-span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
}

.image_marks {
  width: 30px;
  height: 30px;
  object-fit: contain;
  width: 50%;
  cursor: pointer;
  margin-left: 10px;
}

.no-underline .MuiInputBase-root::before,
.no-underline .MuiInputBase-root::after {
  display: none;
}

.textlabel_breadcrum {
  font-family: 'Open sans';
  font-size: 18px;
  font-weight: 600;
  color: #3f526d;
  margin-left: 20px;
}

.textlabel_breadcrum .text-style-light {
  font-weight: normal;
  /* color: #6d7278; */
}

.layer {
  position: absolute;
  background-color: transparent;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  border-radius: 3px;
  opacity: 0.94;
}

.view_verticalbox {
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.assessment_state_block {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
  height: 100%;
  border-right: 2px solid #e2e8f970;
}

.state_grid {
  padding: 48px 0;
}

.state_text {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.state_count {
  font-size: 52px !important;
  font-weight: 600 !important;
}

.remaining_section_marks {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.marks_div {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  max-width: 170px;
}

.meta_data_title {
  /* font-family: "Open sans" !important; */
  font-size: 20px !important;
  font-weight: 600 !important;
  /* color: #3f526d !important; */
  margin-top: 20px;
  margin-bottom: 20px;
}

.question_save_btn {
  cursor: pointer !important;
  border-radius: 8px !important;
  padding: 6px 30px 6px 30px !important;
  margin: 0px 20px 0px 0px !important;
  text-transform: capitalize !important;
  /* background-color: #3a60d7 !important; */
  /* color: #ffffff !important; */
  height: 60px;
  width: 160px;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.question_cancel_btn {
  cursor: pointer !important;
  border-radius: 8px !important;
  border: solid 0.5px #3a60d7 !important;
  padding: 6px 30px 6px 30px !important;
  text-transform: capitalize !important;
  /* background-color: #fff !important;
  color: #3f526d !important; */
  height: 60px;
  width: 160px;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.question_option_text {
  height: 74px;
  width: 74px;
  border-radius: 8px;
}

.question_option_title {
  font-family: 'Open sans' !important;
  font-size: 48px !important;
  font-weight: 600 !important;
  color: #fff;
  text-align: center;
}

.question_text {
  /* font-family: "Open sans" !important; */
  font-size: 20px !important;
  font-weight: 600 !important;
  /* color: #3f526d; */
  margin: 20px 0px 20px 0px !important;
}

.match_grp_img {
  width: 122px;
  height: 22px;
  object-fit: contain;
}

.match_grp_img_preview {
  width: 90px;
  height: 22px;
  object-fit: contain;
}

.assessment_module_text {
  font-family: 'Open sans' !important;
  font-size: 36px !important;
  font-weight: 600 !important;
  color: #3f526d;
  padding-bottom: 40px;
}

.centered_card {
  display: flex;
  justify-content: center;
}

.assessment_card {
  border-radius: 17px;
  box-shadow: 0 11px 32px 1px rgba(255, 143, 100, 0.74);
  background-image: radial-gradient(circle at 50% 3%, #ff8d71, #ff9153 96%);
  padding: 100px 49px 49px 49px;
  text-align: center;
  position: relative;
  /* height: 459px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.library_card {
  border-radius: 17px;
  box-shadow: 0 11px 32px 1px #54a3ff;
  background-image: radial-gradient(circle at 50% 3%, #71f9ff, #53a0ff 96%);
  padding: 100px 49px 49px 49px;
  text-align: center;
  position: relative;
  /* height: 459px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.new_test_card {
  border-radius: 17px;
  box-shadow: 0 11px 32px 1px #ff5354;
  background-image: radial-gradient(circle at 50% 3%, #db71ff, #ff5353 96%);
  padding: 100px 49px 49px 49px;
  text-align: center;
  position: relative;
  /* height: 459px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_image {
  height: 180px;
  width: 180px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card_text {
  /* font-family: "Open sans" !important; */
  font-size: 32px !important;
  /* color: #fff; */
  font-weight: bold !important;
  min-height: 150px;
  display: flex;
  justify-content: center;
  place-items: center;
  line-height: 1.1 !important;
}

.width_100_per {
  width: 100%;
}

.theme_box_mt {
  margin-top: 53px;
}

.theme_main_box {
  overflow: hidden;
  padding: 0 5px;
}

.theme_image {
  /* max-height: 25vh;
  max-width: 23vw; */
  object-fit: cover;
  width: 100%;
}

.choose_from_gallery_tab {
  width: 25%;
  /* font-family: "Open sans" !important; */
  font-size: 18px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  /* color: #3f526d !important; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.summary_main_div {
  padding: 20px 24px 30px 24px;
  height: 100%;
  margin: 20px 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
}

.summary_publish_now_btn {
  width: 200px;
  height: 60px;
  border-radius: 10px !important;
  /* background-color: #3a60d7 !important; */
  font-family: 'Open sans' !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center;
  /* color: #fff !important; */
  margin-right: 20px !important;
  text-transform: none !important;
}

.summary_schedule_later_btn {
  width: 200px;
  height: 60px;
  border-radius: 10px !important;
  /* border: solid 1px #3a60d7 !important; */
  /* background-color: #fff !important; */
  font-family: 'Open sans' !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center;
  /* color: #3f526d !important; */
  text-transform: none !important;
}

.create_new_assessment {
  font-size: 24px !important;
  font-weight: bold !important;
  margin-left: 40px !important;
}

.kudos_text {
  font-size: 45px !important;
  /* font-family: "Open Sans" !important; */
  font-weight: bold !important;
  /* color: #3f526d; */
  padding-bottom: 32px;
}

.choose_from_gallery {
  width: 100%;
}

.img_orange_bg {
  height: 150px;
  width: 150px;
  z-index: 1;
}

.img_two_hand {
  height: 65px;
  width: 224px;
  position: absolute;
  z-index: 2;
  object-fit: contain;
}

.go_to_dashboard_btn {
  /* background: linear-gradient(to top, rgb(175, 56, 203), rgb(71, 28, 81));
  color: #fff !important; */
  width: 250px;
  height: 60px;
  font-size: 18px !important;
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
  text-transform: none !important;
}

.schedule_img_orange_bg {
  height: 159px;
  width: 159px;
  z-index: 1;
}

.schedule_modal_img {
  height: 159px;
  width: 159px;
  position: absolute;
  z-index: 2;
  object-fit: contain;
}

.publish_this_text {
  font-size: 24px !important;
  /* font-family: "Open Sans" !important; */
  font-weight: 600 !important;
  /* color: #3f526d; */
  margin: 30px 0 !important;
}

.flex_end_portion {
  display: flex;
  justify-content: flex-end;
}

.save_as_draft_btn {
  width: 200px;
  height: 60px;
  margin-right: 18px !important;
  border-radius: 8px !important;
  border: solid 1px #3a60d7 !important;
  /* background-color: #fff !important; */
  cursor: pointer !important;
  text-transform: capitalize !important;
  /* color: #3f526d !important; */
  font-size: 16px !important;
  padding: 5px !important;
}

.next_btn {
  width: 160px;
  height: 60px;
  border-radius: 8px !important;
  border: solid 1px #3a60d7 !important;
  /* background-color: #3a60d7 !important; */
  cursor: pointer !important;
  text-transform: capitalize !important;
  /* color: #fff !important; */
  font-size: 16px !important;
  padding: 5px !important;
}

.total_que_btn {
  width: 216px;
  height: 60px;
  margin-right: 20px !important;
  border-radius: 8px !important;
  border: solid 1px #3a60d7 !important;
  /* background-color: #fff !important; */
  cursor: pointer !important;
  text-transform: capitalize !important;
  /* color: #3f526d !important; */
  font-size: 18px !important;
  padding: 5px !important;
}

.total_marks_btn {
  width: 216px;
  height: 60px;
  border-radius: 8px !important;
  border: solid 1px #3a60d7 !important;
  /* background-color: #fff !important; */
  cursor: pointer !important;
  text-transform: capitalize !important;
  /* color: #3f526d !important; */
  font-size: 18px !important;
  padding: 5px !important;
}

.import_from_library_btn {
  width: 244px;
  height: 60px;
  margin-right: 20px !important;
  border-radius: 8px !important;
  border: solid 1px #3a60d7 !important;
  /* background-color: #fff !important; */
  cursor: pointer !important;
  text-transform: capitalize !important;
  /* color: #3f526d !important; */
  font-size: 16px !important;
  padding: 5px !important;
  opacity: 0.6;
}

.create_question_btn {
  width: 216px;
  height: 60px;
  border-radius: 8px !important;
  border: solid 0.5px #3a60d7 !important;
  /* background-color: #3a60d7 !important; */
  cursor: pointer !important;
  text-transform: capitalize !important;
  /* color: #ffffff !important; */
  font-size: 16px !important;
  padding: 5px !important;
}

.MuiTable-root.separte-border-collapse {
  border-collapse: separate;
}

.selectedRow {
  background-color: rgba(58, 96, 215, 0.1);
}

.MuiTableRow-root.selectedRow td {
  border-top: solid 1px #3a60d7 !important;
  border-bottom: solid 1px #3a60d7 !important;
}

.MuiTableRow-root.selectedRow td:first-child {
  border: solid 1px #3a60d7 !important;
  border-right: none !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.MuiTableRow-root.selectedRow td:last-child {
  border: solid 1px #3a60d7 !important;
  border-left: none !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.unSelectedRow {
  background-color: #f9f8fd;
}

.create_new_assessment_block {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.filter_dropdown {
  height: 100%;
  width: 90%;
}

.dashboard_assessment {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.bubble_img {
  position: absolute;
  left: 0;
  bottom: 0;
}

.bubble_square_img {
  position: absolute;
  right: 0;
  bottom: 0;
}

.create_bubble_img {
  position: absolute;
  right: 0;
  bottom: 0;
}

.teacher_assessment_box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.questions-outer {
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.pb0 p {
  margin-bottom: 0;
}

.select-question-modal {
  padding: 50px 60px 50px;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  overflow: auto;
  max-height: 90%;
  max-width: 1060px;
  width: 100%;
}

.question_option_box {
  padding: 12px 14px 12px 12px;
}

.list-dropdown .MuiNativeSelect-icon {
  font-size: 40px;
  top: 4px;
}

.module_cards {
  padding: 0 30px;
}

.Headerhight {
  overflow-x: hidden;
}

.select-image-modal {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  padding: 50px;
  width: 800px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px 0px;
  overflow: auto;
  max-height: 90%;
  text-align: center;
}

.gallery-modal {
  padding: 40px;
  width: 75%;
  max-height: 80%;
}

.preview-modal {
  border: 1px solid rgb(151, 151, 151);
  background-color: white;
  outline: none;
  max-width: 1008px;
  height: 75%;
  padding: 40px 90px;
  border-radius: 24px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px 0px;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
}

.quetext {
  font-size: 20px;
  margin: 16px 0 35px 0;
}

.record-video-btn-div {
  width: 206px;
  height: 60px;
  padding: 15px 21px 15px 20px;
  margin-right: 20px;
  border-radius: 8px;
  border: solid 1px #3f526d;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.video-thumbnail-img {
  width: 160px;
  height: 110px;
  object-fit: contain;
  border-radius: 12px;
  margin-right: 26px;
  cursor: pointer;
}

.video-play-img {
  width: 22px;
  height: 22px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  top: 42px;
  left: 69px;
}

.video-recording-text {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.video-recording-duration {
  font-size: 18px;
  font-weight: 600;
}

.record-video-box {
  margin: 30px 0;
  padding: 40px 40px 40px 40px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
}

.video_webcam_style_assessment {
  width: 60%;
  height: 420px;
  object-fit: cover;
  position: relative;
  border-radius: 26px;
}

.recording-time-div {
  position: absolute;
  top: 15px;
  right: 21%;
  margin: 10px 10px 0px 0px;
  padding: 9px 21px 8px;
  opacity: 0.3;
  border-radius: 18px;
  background-color: #000;
  display: flex;
}

.record_red_dot {
  width: 12px;
  height: 12px;
  margin: 3px 10px 4px 0;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #e02020;
}

.recording-time-text {
  color: white;
  font-size: 14px;
  font-weight: normal;
}

.MuiTypography-root.select-image-text {
  font-size: 26px;
  font-weight: 600;
}

.MuiTypography-root.choose-from-gallery-text {
  font-size: 18px;
  font-weight: 600;
  padding-top: 20px;
}

.MuiGrid-root.select-image-grid {
  padding: 11% 0 !important;
}

.choose-small-img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  cursor: pointer;
}

.MuiButton-root.select-image-cancel-btn {
  cursor: pointer;
  border-radius: 8px;
  border: solid 1px #3a60d7;
  padding: 6px 30px 6px 30px;
  text-transform: capitalize;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  width: 174px;
}

.save-video-modal-main-div {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 32px 18px;
  width: 576px;
}

.MuiInputBase-input.video-name-error-field {
  padding: 18px 20px;
  border-radius: 6px;
  background-color: #f9f8fd;
  border: 1px solid red;
}

.MuiInputBase-input.video-name-field {
  padding: 18px 20px;
  border-radius: 6px;
  background-color: #f9f8fd;
  border: none;
}

.save-video-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
}

.view-save-video-btn {
  width: 120px;
  height: 48px;
  margin: 30px 9px 0px 9px !important;
  padding: 13px 42px !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.justify-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessment_video_recording_icon {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.assessment_video_pause_icon {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.remove-video-icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.background_button_no_hover {
  background-color: #f8f8f8;
  border: none;
}

.rating-text-field {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 10px 12px !important;
  min-width: 42px !important;
  max-width: 174px !important;
  overflow: auto;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rating-text-field-evaluation {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 15px 12px !important;
  min-width: 42px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.evaluation-rating {
  border-radius: 6px;
  background-color: white;
  width: 75%;
  margin-left: 20px !important;
}

.stepper-img-close {
  width: 18px;
  height: 18px;
  margin: 0px 0px 0px 10px;
  object-fit: contain;
  cursor: pointer;
}

.question-step-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.choice-for-response-text {
  font-size: 24px;
  font-weight: 600;
  margin: 30px 0;
}

.add-new-choice-div {
  width: 206px;
  height: 46px;
  margin: 16px 0px 40px 0px;
  padding: 12px 20px;
  border-radius: 6px;
  border: 1px solid #253873;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-add-stepper {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.view-stepper-ans-box {
  margin: 30px 0 30px 0px;
  padding: 26px 8px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.small-que-type-img {
  height: 62px;
  width: 62px;
  object-fit: contain;
}

.emoji-icon {
  margin-bottom: 15px;
}

.emoji-icon > span {
  font-size: 44px;
}

.emoji-picker {
  z-index: 1;
  position: 'absolute';
  bottom: '58px';
  left: '23px';
}

.rating-step-custom-color .MuiStepLabel-label,
.rating-step-custom-color .MuiStepLabel-label.MuiStepLabel-active,
.rating-step-custom-color .MuiStepLabel-label.MuiStepLabel-completed {
  color: black !important;
}

.hotspot_question_delete_icon {
  height: 15px;
  object-fit: contain;
  cursor: pointer;
}

.label_image_block {
  position: absolute;
  width: 130px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  font-size: 16px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px;
  border: 0.5px solid rgb(63, 82, 109);
}

@media (max-width: 1279px) {
  .modal-width-dynamic {
    width: 80% !important;
  }

  .heightAuto {
    height: auto !important;
  }

  .font-16-md {
    font-size: 16px !important;
  }

  .small-create-image {
    width: 50px !important;
    height: 60px !important;
  }

  .question_option_text {
    height: auto;
    width: auto;
  }

  .question_option_title {
    font-size: 40px !important;
  }

  .assessment_module_text {
    font-size: 34px !important;
  }

  .assessment_card {
    padding: 100px 39px 39px 39px;
    height: 400px;
  }

  .library_card {
    padding: 100px 39px 39px 39px;
    height: 400px;
  }

  .new_test_card {
    padding: 100px 39px 39px 39px;
    height: 400px;
    width: 300px;
  }

  .card_image {
    height: 140px;
    width: 140px;
  }

  .card_text {
    font-size: 30px !important;
  }

  .md_mt_10 {
    margin-top: 10px !important;
  }

  .theme_box_mt {
    margin-top: 40px;
  }

  .bg_img_height {
    min-height: 170px !important;
  }

  .create_new_assessment {
    font-size: 20px !important;
    margin-left: 20px !important;
  }

  .state_count {
    font-size: 42px !important;
  }

  .kudos_text {
    font-size: 42px !important;
  }

  .state_grid {
    padding: 30px 0;
  }
}

@media (max-width: 959px) {
  .module_cards {
    padding: 0 0px;
  }

  .small-que-type-img {
    width: 50px !important;
    height: 50px !important;
  }

  .font-12-sm {
    font-size: 12px !important;
  }

  .font-30-sm {
    font-size: 30px !important;
  }

  .meta_data_title {
    font-size: 18px !important;
  }

  .mcq_question_option_box {
    padding: 15px 20px 15px 15px !important;
  }

  .question_option_box {
    padding: 15px 20px 15px 15px !important;
  }

  .match_grp_img {
    width: 90px;
  }

  .match_grp_img_preview {
    width: 90px;
  }

  .assessment_module_text {
    font-size: 30px !important;
    padding-bottom: 25px;
  }

  .assessment_card {
    padding: 70px 30px 30px 30px;
    height: 300px;
  }

  .library_card {
    padding: 70px 30px 30px 30px;
    height: 300px;
  }

  .new_test_card {
    padding: 70px 30px 30px 30px;
    height: 300px;
  }

  .card_image {
    height: 120px;
    width: 120px;
  }

  .card_text {
    font-size: 28px !important;
    min-height: 90px;
  }

  .sm_font_22 {
    font-size: 22px !important;
  }

  .section_title {
    width: 220px !important;
    font-size: 16px !important;
  }

  .section_input {
    width: 220px !important;
    font-size: 16px !important;
  }

  .theme_box_mt {
    margin-top: 30px;
  }

  .choose_from_gallery_tab {
    width: 40%;
  }

  .state_count {
    font-size: 32px !important;
  }

  .kudos_text {
    font-size: 40px !important;
  }

  .schedule_modal {
    width: 80% !important;
  }

  .save_as_draft_btn {
    height: 50px;
  }

  .next_btn {
    height: 50px;
  }

  .total_que_btn {
    height: 50px;
  }

  .total_marks_btn {
    height: 50px;
  }

  .import_from_library_btn {
    height: 50px;
  }

  .create_question_btn {
    height: 50px;
  }

  .create_bubble_img {
    width: 100px;
  }

  .modal-width-dynamic {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .xs_mt_10 {
    margin-top: 10px !important;
  }

  .create_new_assessment {
    font-size: 18px !important;
    margin-left: 20px !important;
    line-height: 24px !important;
  }

  .create_new_assessment_block {
    align-items: center;
  }

  .t-assessment-table table {
    max-width: 1200px;
    width: 1200px;
  }
}
.table-container-progress {
  border-radius: 6px !important;
  border: 0 !important;
}

@media (max-width: 599px) {
  .small-que-type-img {
    width: 40px !important;
    height: 40px !important;
  }

  .font-22 {
    font-size: 22px !important;
  }

  .font-20 {
    font-size: 20px !important;
  }

  .font-16 {
    font-size: 16px !important;
  }

  .font-14-xs {
    font-size: 14px !important;
  }

  .padding-10 {
    padding: 10px !important;
  }

  .padding-30 {
    padding: 30px !important;
  }

  .choose-small-img {
    width: 60px !important;
    height: 60px !important;
  }

  .padding-top {
    padding-top: 30px !important;
    padding-bottom: 0 !important;
  }

  .padding-bottom {
    padding-bottom: 30px !important;
  }

  .select-image-modal {
    border-radius: 10px;
  }

  .remaining_section_marks {
    align-items: flex-start;
    margin-top: 10px !important;
  }

  .marks_div {
    margin-right: 10px;
    max-width: 130px;
    margin-bottom: 10px;
  }

  .view_marksbox {
    width: 130px;
  }

  .question_option_box {
    padding: 10px !important;
  }

  .mcq_question_option_box {
    padding: 10px 20px 10px 10px !important;
  }

  .question_save_btn {
    height: 50px;
    width: 140px;
  }

  .question_cancel_btn {
    height: 50px;
    width: 140px;
  }

  .match_grp_img {
    width: 50px;
  }

  .match_grp_img_preview {
    width: 40px;
  }

  .assessment_module_text {
    font-size: 25px !important;
    text-align: center;
    padding-bottom: 15px;
  }

  .new_test_card,
  .assessment_card,
  .library_card {
    height: auto;
  }

  .module_cards {
    margin: 20px 0 !important;
    width: 100% !important;
  }

  .library_card {
    margin-top: 60px;
  }

  .new_test_card {
    margin-top: 60px;
  }

  .card_text {
    font-size: 24px !important;
    min-height: 80px;
  }

  .card_image {
    height: 100px;
    width: 100px;
  }

  .centered_card {
    padding: 15px !important;
  }

  .modal-width-dynamic {
    width: 90% !important;
  }

  .choose_from_gallery_tab {
    width: 50%;
    font-size: 14px !important;

    padding: 6px 7px !important;
  }

  .image_right_spacing {
    padding: 10px !important;
    height: 45px !important;
    width: 45px !important;
  }

  .summary_main_div {
    padding: 15px;
    margin: 10px;
  }

  .summary_publish_now_btn {
    height: 50px;
  }

  .summary_schedule_later_btn {
    height: 50px;
  }

  .publish_modal {
    width: 90% !important;
    padding: 20px !important;
  }

  .schedule_modal {
    width: 90% !important;
    padding: 20px !important;
  }

  .xs_display {
    display: none;
  }

  .padded-span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
  }

  .choose_from_gallery .paginationBttns a {
    padding: 6px 10px !important;
  }

  .kudos_text {
    font-size: 34px !important;
    padding-bottom: 10px;
  }

  .img_orange_bg {
    height: 110px;
    width: 110px;
  }

  .img_two_hand {
    width: 180px;
  }

  .go_to_dashboard_btn {
    width: 200px;
    height: 50px;
  }

  .schedule_img_orange_bg {
    height: 110px;
    width: 110px;
  }

  .schedule_modal_img {
    height: 110px;
    width: 110px;
  }

  .xs_p_10 {
    padding: 10px !important;
  }

  .publish_this_text {
    font-size: 20px !important;
    margin: 15px 0 20px 0 !important;
  }

  .flex_end_portion {
    justify-content: flex-start;
    margin-top: 10px !important;
  }

  .save_as_draft_btn {
    height: 50px;
  }

  .next_btn {
    height: 50px;
  }

  .total_que_btn {
    height: 50px;
    margin-right: 6px !important;
    font-size: 14px !important;
  }

  .total_marks_btn {
    height: 50px;
    font-size: 14px !important;
  }

  .import_from_library_btn {
    height: 50px;
    margin-right: 6px !important;
    font-size: 14px !important;
  }

  .create_question_btn {
    height: 50px;
    font-size: 14px !important;
  }

  .bg_img_height {
    min-height: 120px !important;
  }

  .create_new_assessment_block {
    flex-direction: row;
  }

  .state_count {
    font-size: 26px !important;
  }

  .filter_dropdown {
    width: 100%;
  }

  .dashboard_assessment {
    margin-bottom: 0px !important;
  }

  .state_grid {
    padding: 20px 0;
  }

  .bubble_img {
    height: 90px;
  }

  .bubble_square_img {
    height: 90px;
  }

  .xs_mt_0 {
    margin-top: 0px !important;
  }

  .wrs_editor .wrs_panelContainer {
    overflow: auto !important;
  }

  .wrs_header {
    overflow: auto;
  }

  .questions-outer {
    padding: 15px;
  }

  .MuiTableRow-root th:first-child {
    padding-left: 20px !important;
  }

  .modal-width-dynamic {
    padding: 20px 15px;
  }

  .save-video-modal-main-div {
    width: 90% !important;
    padding: 20px 15px;
  }
}

.MuiTableRow-root th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.MuiTableRow-root th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.MuiTable-table {
  min-width: 700;
}

.record-video-btn-div {
  width: 206px;
  height: 60px;
  padding: 15px 21px 15px 20px;
  margin-right: 20px;
  border-radius: 8px;
  border: solid 1px #3f526d;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.record-audio-btn-div {
  width: 206px;
  height: 60px;
  padding: 15px 21px 15px 20px;
  margin-right: 20px;
  border-radius: 8px;
  border: solid 1px #3f526d;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

audio::-webkit-media-controls-panel {
  background-color: #fff;
  outline: none;
}

.label_question_type_image {
  width: 500px;
  height: 500px;
  object-fit: contain;
}

.graph_plotting .MuiInputBase-input {
  text-align: center;
}

.custom_markerjs2_ {
  justify-content: center;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 70% !important;
  z-index: 1300 !important;
  max-height: 90%;
}

.custom_markerjs2_ > div > div:nth-child(2) > div > div:last-child {
  display: none !important;
}

.custom_markerjs2_toolbar {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 50px !important;
  align-items: center;
}

.custom_markerjs2_toolbox-button-row {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

.custom_markerjs2_toolbar_button_colors {
  fill: black !important;
  background-color: white;
}

.custom_markerjs2_toolbar_button {
  padding: 7px !important;
  margin: 5px !important;
}

.custom_markerjs2_toolbox-button_colors {
  fill: black !important;
  background-color: white;
}

.custom_markerjs2_toolbox_button {
  padding: 7px !important;
  margin: 5px !important;
}

.flash_card {
  position: relative;
  min-height: 520px;
  margin: 0 40px 20px 0 !important;
  padding: 16px 16px 18px !important;
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e1e1e1;
  background-color: #fff;
}

.flash_card_img_upload_box {
  min-height: 267px;
  margin: 0 0 20px !important;
  padding: 56px 40px 16px !important;
  border-radius: 20px;
  background-color: #ebeef8;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.assessmentSummaryReport_AssessmentTest_main_table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.assessmentSummaryReport_AssessmentTest_no_data_image {
  max-width: '401.5px';
  height: 'auto';
  margin-top: '99px';
  object-fit: 'contain';
}
.assesment_static_color_txt {
  color:#fff
}
@media (max-width: 767px) {
  .record-audio-btn-div {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 480px) {
  .flash_card {
    margin: 0 0 20px 0 !important;
  }

  .flash_card_img_upload_box {
    padding: 0 0 0 !important;
  }
}

@media screen and (max-width: 500px) {
  .label_question_type_image {
    width: 250px;
    height: auto;
    object-fit: contain;
  }
}

/* pascal case css */
.AssessmentMargin {
  margin: 0;
}

.AssessmentStateDiv {
  background: linear-gradient(to right, rgba(58,96,215,1) 0%,rgba(61,187,61,1) 51%,rgba(6,176,182,1) 100%);
  background-size: cover;
  position: relative;
  border-radius: 10px;
  min-height: 237px;
  display: flex;
}

.OngoingState {
  border-right: none;
}

.AssessmentCreateDiv {
  background: linear-gradient(to right, rgba(59,90,177,1) 0%,rgba(61,98,202,1) 100%);
  background-size: cover;
  border-radius: 10px;
  min-height: 237px;
  display: flex;
  align-items: center;
  position: relative;
}

.AssessmentCreateIcon {
  width: 90px;
  height: 100px;
  object-fit: contain;
  margin-left: 20px;
}

.AssessmentFlexColumn {
  display: flex;
  flex-direction: column;
}

.AssessmentFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.MuiTypography-root.ListOfAssessment {
  font-size: 18px;
  font-weight: bold;
}

.AssessmentTableContainer {
  border-radius: 6px;
  border: 0;
}

.AssessmentListTableHead {
  background-color: rgba(58, 96, 215, 0.14);
}

.MuiTableCell-root.AssessmentListTitle {
  padding: 17px 5px 17px 20px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 0px;
  text-align: center;
}

.MuiTableCell-root.AssessmentListName {
  padding: 17px 5px 17px 20px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 0px;
}

.MuiTableRow-root th:first-child.AssessmentListName {
  border-bottom-left-radius: 10px; 
}

.MuiTableCell-root.AssessmentListResult {
  padding: 17px 20px 17px 5px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 0px;
  text-align: center;
}

.AssessmentListAction {
  padding: 4px 5px 4px 5px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 0;
}

.MuiTableRow-root th:last-child.AssessmentListAction {
  border-bottom-right-radius: 10px;
}

.AssessmentListTableBody {
  margin-top: 10px;
}

.MuiTableCell-root.NoAssessmentListFound {
  padding: 17px 0px 16px 20px;
  font-size: 18px;
}

.MuiTableCell-root.AssessmentBlankRow {
  padding: 4px 0px 4px 0px;
  border: 0;
}

.AssessmentDataRow {
  background-color: rgb(249, 248, 253);
  border-radius: 15%;
}

.MuiTableCell-root.AssessmentListData {
  padding: 17px 5px 17px 20px;
  border: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.MuiTableCell-root.AssessmentDataName {
  padding: 17px 5px 17px 20px;
  border: 0;
  font-size: 18px;
  font-weight: 600;
}

.MuiTableCell-root.AssessmentDataOther {
  padding: 17px 5px 17px 20px;
  border: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.MuiTableCell-root.AssessmentDataResult {
  padding: 17px 20px 17px 5px;
  border: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.MuiTableCell-root.AssessmentDataAction {
  padding: 4px 5px 4px 5px;
  border: 0;
}

.AssessmentPaginate {
  overflow-x: auto;
}

.CreateAssessmentBox {
  margin: 25px 0px;
  position: relative;
}

.MuiTypography-root.CreateNewAssessmentText {
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
}

.MuiTypography-root.AssessmentDetailText {
  font-size: 24px;
  font-weight: 600;
}

.AssessmentTitleBox {
  padding-bottom: 20px;
}

.MuiFormHelperText-root.CreateAssessmentErrorText {
  color: red;
}

.AssessmentRelativeDiv {
  position: relative;
}

.AssessmentDetailImg {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.AssessmentOverflowAuto {
  overflow: auto;
}

.SectionListDiv {
  padding-top: 15px; 
}

.MuiTypography-root.NoAssessmentGuideline {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.GuidelineDesc {
  font-size: 16px;
  font-weight: 600;
}

.AssessmentThemeBox {
  padding: 20px 22px 23px 18px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(224, 224, 224, 0.5);
  background-color: #fff;
  height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MuiTypography-root.AssessmentThemeName {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 18px;
  cursor: pointer;
}

.CreateSectionHeaderDiv {
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.MuiTypography-root.CreateSectionBackText {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.CreateSectionBackArrow {
  height: 14px;
  width: 18px;
  object-fit: contain;
  margin-right: 10px;
}

.MuiTypography-root.CreateSectionAssessmentName {
  font-size: 26px;
  font-weight: 600;
}

.MuiTypography-root.CreateSectionTotalTime {
  font-size: 20px;
  font-weight: 600;
}

.SectionMainDiv {
  padding: 0 5px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.SectionDetailDiv {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.MuiTypography-root.SectionName {
  font-size: 24px;
  font-weight: 600;
}

.SectionTableContainer {
  border-radius: 6px;
  border: 0;
  padding: 5px;
}

.SectionTableHead {
  background-color: #3f526d;
}

.MuiTableCell-root.SectionDetailQuestion {
  padding: 17px 5px 17px 49px;
  font-size: 20px; 
  font-weight: 600; 
  border-bottom: 0;
  min-width: 400px;
  width: 55%;
  color: #fff;
}

.MuiTableCell-root.SectionDetails {
  padding: 17px 5px 17px 5px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 0;
  color: #fff;
}

.MuiTableCell-root.NoQuestionFound {
  padding: 17px 0px 16px 20px;
  font-size: 18px;
}

.SectionTableBody {
  margin-top: 10px;
  max-height: 340px;
  overflow: auto;
}

.SectionDataBlankCell.SectionDataBlankCell {
  padding: 5px 0px 5px 0px;
  border: 0;
}

.MuiIconButton-root.QuestionActionsIconBtn {
  padding-top: 0;
  padding-bottom: 0;
}

.QuestionEditCopyImg {
  height: 22px;
  width: 22px;
  object-fit: contain;
}

.QuestionDeleteImg {
  height: 22px;
  width: 22px;
  object-fit: contain;
  opacity: 0.3;
}

.SectionBtnDiv {
  margin: 30px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.MuiTableCell-root.SectionQuestionData {
  padding: 17px 5px 17px 20px;
  border: 0;
  font-size: 18px; 
  font-weight: 600; 
  min-width: 400px;
  width: 55%;
}

.SectionQuestionDataDiv {
  padding: 0px 0px 0px 0px;
  font-size: 18px; 
  font-weight: 600; 
  display: flex;
  align-items: center;
  margin-top: 0px;
}

.MuiTableCell-root.SectionQuestionType {
  padding: 17px 5px 17px 5px;
  border: 0;
  font-size: 18px; 
  font-weight: 600; 
  text-transform: capitalize;
}

.MuiTableCell-root.SectionQuestionMarks {
  padding: 17px 5px 17px 5px;
  border: 0;
  font-size: 18px; 
  font-weight: 600; 
}

.MuiTableCell-root.SectionQuestionNegMarks {
  padding: 17px 5px 17px 5px;
  border: 0;
  color: red;
  font-size: 18px; 
  font-weight: 600; 
}

.SectionQuestionActionDiv {
  display: flex;
  justify-content: center;
}

.MuiTypography-root.QuestionText {
  margin-right: 15px;
  font-size: 20px; 
  font-weight: 600; 
}

.QuestionWordBreak {
  word-break: break-all;
}

.MoveQuestionDiv {
  padding: 5px 20px;
  border-radius: 6px;
}

.MuiTypography-root.MoveQuestionsText {
  font-size: 18px; 
  font-weight: 600; 
}

.MoveQuestionCursor {
  cursor: context-menu;
}

.MoveRightArrow {
  margin-left: 15px;
  cursor: pointer;
}

.MuiTypography-root.MoveSectionText {
  font-size: 17px; 
  font-weight: 600; 
}

.SelectQuestionTypeModal {
  z-index: 999999 !important;
}

.MuiTypography-root.SelectQuestionTypeText {
  font-size: 24px; 
  font-weight: 600; 
}

.MuiTypography-root.QuestionTypeName {
  font-size: 22px; 
  font-weight: 600; 
  padding: 20px 0;
}

.QuestionTypeDiv {
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 23px 5px 15px 5px;
  border: solid 2px transparent;
  cursor: pointer;
  opacity: 1;
  height: 100%;
  justify-content: space-between;
}

.MuiTypography-root.QuestionName {
  font-size: 18px; 
  font-weight: 600; 
  padding-top: 15px;
  text-transform: capitalize;
  text-align: center;
}

.AssessmentWarningModal {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 29px;
  padding: 32px 18px;
  width: 600px;
}

.AssessmentWarningModalDivider {
  height: 1px;
  margin: 14px 0 31px 0;
  border: solid 0.3px #979797;
}
