.AssessmentGradeBookMarginLR20 {
    margin: 0 20px;
}

.AssessmentGradeBookSubTable {
    width: auto !important
}

.AssessmentGradeBookTableCell {
    word-wrap: break-word;
    text-align: center;
    width: 52px;
}

.AssessmentGradeBookDueDateTableCell {
    min-width: 72px;
    text-align: center;
}

.AssessmentGradeBookFirstTableCell {
    position: sticky;
    left: 0px;
    top: 0px;
}