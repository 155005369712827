.teacherResourcepart .fileNameText1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.teacherResourcepart .m5 {
  margin-right: 10px;
}

.teacherResourcepart .uploadText {
  text-transform: none;
  font-size: 12px;
  font-weight: 600;
  margin-top: 0;
}

.teacherResourcepart .btnDot {
  border-radius: 0;
}


.teacherResourcepart .sizeText {
  text-transform: none;
  font-size: 12px;
  font-weight: 600;
}

.commonPopup {
  position: absolute;
  top: 50%;
  border-radius: 6px;
  padding: 20px;
  left: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.34);
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 500px;
  background: #fff;
}

.viewBody {
  margin: 0;
}

.sharepeople .viewBody {
  position: relative;
}

.commonPopup h5 {
  font-size: 15px;
  margin: 0;
  font-weight: 500;
}

.commonPopup ul {
  margin: 15px 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
}

.commonPopup ul li {
  padding: 0 15px 0 0;
  margin: 0;
  font-size: 16px;
}

.commonPopup .listdata {
  display: block;
}

.commonPopup .listdata li {
  padding: 0;
  margin: 0 0 18px;
  display: flex;
  color: #3f526d;
  font-size: 16px;
}

.commonPopup .listdata li span {
  min-width: 95px;
  padding-right: 30px;
}

.commonPopup .listdata li:first-child {
  border: 0;
}

.commonPopup ul li:first-child {
  border-right: 1px solid #c7c7c7;
  margin-right: 15px;
}

.commonPopup ul li img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
}

.titleMain {
  border-bottom: 1px solid #c7c7c7;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  padding-bottom: 10px !important;
}

.titleMain .w34 {
  width: 34px;
  margin-right: 10px;
}
.titleMain h4 {
  display: flex;
  line-height: 34px;
  margin-top: 1px;
  align-items: center;
}

.foldericon {
  height: 38px;
  margin-right: 28px;
}

.commonPopup .closeIcon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.sharepeople ul {
  display: block;
  height: 240px;
  overflow: auto;
}

.listPeople {
  margin: 0;
  padding: 0;
}

.listPeopleitem {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.pt-10 {
  padding: 10px 0;
}

.gridCenter {
  margin-top: 20px;
  align-items: center;
}

.teacherResourcepart .classesButtonText {
  text-transform: none;
  font-weight: 600;
}

.teacherResourcepart .folder-block {
  border-radius: 4px;
  border: 0.5px solid #3f526d;
  background: #fff;
  align-items: center;
  justify-content: space-around;
  padding: 6px;
}

.teacherResourcepart .avtarIcon {
  margin: 5px 0 5px 5px;
}

.teacherResourcepart .mt-20 {
  margin-top: 20px;
}

.teacherResourcepart .disFlex {
  display: flex;
  justify-items: flex-start;
  align-items: center;
}

.alignCenter {
  text-align: center;
}

.sharepeople fieldset {
  border: 0;
}

.searchOuter {
  border-radius: 8px;
  /* padding: 1% 1.2% 1% 1.7%; */
  width: 100%;  
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 10px !important;
  margin-top: 15px !important;
}

.searchFile {
  border: 0;
  outline: 0;
  color: #484848;
  font-size: 1rem;
  width: 100%;
  padding: 0 1%;
}

.mr-2 {
  margin-right: 1%;
}

.boxList {
  width: 48px;
  height: 48px;
  margin: 5px;
  border: 0.5px solid #3f526d;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
}

.teacherResourcepart .createBtn {
  border-radius: 6px;
  border: solid 0.5px #3f526d;
  background-color: #fff;
  align-items: center;
  justify-content: space-around;
  margin: 5px 0px 5px 5px;
  padding: 10px;
}

.teacherResourcepart .createBtn .createListIcon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.marginSpace {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0 5px 5px;
}

.teacherResourcepart .folderBoxouter {
  display: flex;
  width: 100%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  margin-bottom: 10px;
  justify-content: space-between;
}

.uploadpopup-content input[type="file"] {
  display: none;
}

.teacherResourcepart .foldersNamebox {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
}

.teacherResourcepart .sfolderLists {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 10px;
}

.teacherResourcepart .titleMainfolder {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.folderBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 10px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  background-color: #fff;
}

.teacherResourcepart .folderTitle {
  text-transform: none;
  font-size: 20px;
  font-weight: 600;
}

.teacherResourcepart .listFoldericon {
  width: 28px;
  height: 28px;
  margin: 5px;
}

.teacherResourcepart .listIconUpload { 
  width: 28px;
  margin: 0;
}

.teacherResourcepart .listIcon {
  cursor: pointer;
  padding: 5px;
  font-size: 45px;
  border-radius: 6px;
  margin-left: 10px;
}

.teacherResourcepart .deletelistIcon {
  cursor: pointer;
  padding: 5px;
  width: 30px;
  border-radius: 6px;  
}

.teacherResourcepart .gridSpacem {
  margin: 20px 10px 10px 10px;
}

.teacherResourcepart .selectAlltext {
  text-transform: none;
  font-size: 18px;
  font-weight: 600;
  margin-right: 18px;  
}

.teacherResourcepart .selectBg {
  background-color: #dae6d8;
}

.teacherResourcepart .getlinkIcon {
  flex-basis: 0;
  display: flex;
  align-items: center;
}

.teacherResourcepart .whitebg {
  background-color: #fff;
}

.teacherResourcepart .flexBaseauto {
  flex-basis: 0;
}

.teacherResourcepart .underlineNone {
  text-decoration: none;
}

.teacherResourcepart .forrmInput_textfield {
  width: 100%;
  border-radius: 8px;
  text-align: start;
  margin: 5px 0;
}


#basic-menu .closeImg {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin: 2.5px;
}


.itemDisplay {
  display: flex;
  justify-content: center;
}

.w-203 {
  width: 203px;
}

#basic-menu .menuIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.teacherResourcepart .openFolderbg {
  border-radius: 6px;
  display: flex;
  justify-content: space-around;
  height: 58px;
  margin: 5px;  
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.teacherResourcepart .openText button {
  border-radius: 0;
}

.paddRight {
  padding-right: 5px;
}

.textsearcField {
  border-radius: 5px;
}

.folderNameInput {
  height : 56px !important;
}

.sharepeople .textsearcField {
  background: #f1f1f1;
}

.activeClass {
  background: #eaeaea;
}

.sizeBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 5px;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.mspace {
  margin: 5px 5px 5px 1px;
}

.cancelButton {
  margin-left: 20px;
}

.sharepeople ul li:first-child {
  border: 0;
}

.bottomButton {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.bottomButton div:last-child {
  float: right;
  text-align: right;
  padding: 8px 0;
}

.bottomButton div:last-child a {
  margin-right: 30px;
}

.sharepeople ul li {
  margin-bottom: 20px;
  padding: 0;
}

.textCenter {
  text-align: center;
}

.pt {
  padding-top: 30px;
}

.pb {
  padding-bottom: 30px;
}

.commonPopup .renameInput {
  width: 100%;
  border-radius: 5px;
  height:58px !important;
}

.reverseFlex {
  flex-direction: row-reverse;
}

.commonPopup .reverseFlex .purpleButton {
  margin: 0 0 0 20px;
}

.listPeople .listEmail {
  font-size: 14px;
  color: #666;
}

.pt10 {
  padding: 10px 0;
}

.teacherResourcepart .folderTitlename {
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  width: 50%;
}

.selectallBgshare {
  color: #3f526d;
}

.teacherResourcepart .checkouter-row {
  display: flex;
  align-items: center;
}

.openText {
  margin: 5px;
  display: flex;
}

.cursor {
  cursor: pointer;
}

.m10 {
  margin: 10px;
}

.norecordText {
  font-size: 18px;
  width: 176px;
  height: 27px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.shareSelectall {
  color:  #3f526d;
  cursor: pointer;
}

.selectCheckbox {
  color: #927ffc;
}

.tableWidth {
  min-width: 650px;
}

.bgColort {
  background-color: #f3f1ff;
}

.commonPopup .purpleButton {
  margin-right: 20px;
  box-shadow: none;
  text-transform: capitalize;
}

.selectAll {
  position: absolute;
  right: 100px;
  bottom: 8px;
  cursor: pointer;
}

.commonPopup .greyButton {
  background: #eaeaea;
  color: #3f526d;
  box-shadow: none;
  text-transform: capitalize;
}

.commonPopup .greyButton:hover {
  background: #fff;
}

.commonPopup .purpleButton:disabled {
  opacity: 0.4;
}

.deletePopup .bottomButton,
.centerButton {
  align-items: center;
  justify-content: center;
}

.uploadpopup-content {
  border: 2px dotted #3f526d;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
}

.flexbasis {
  display: flex !important;
  justify-content: flex-end !important;
  width: 100%;
  align-items: center !important;
}
.uploadpopup .outerfileList {
  height: 300px;
  overflow: auto;
}

.filelists {
  position: relative;
  display: flex;
  position: relative;
  display: flex;
  margin: 0 0 15px;
  padding: 0 15px 15px 0;
  border-bottom: 1px solid #d1d1d1;
}

.filelists:last-child {
  border: 0;
}

.filelists .fileIcon {
  width: auto;
  height: 50px;
  margin: 10px 0 0 0;
}

.addMorebtn {
  text-align: center;
}

.filelists .cross_icon {
  width: 18px;
  height: auto;
  position: absolute;
  right: 20px;
  top: 8px;
}

.filelists button {
  padding: 0;
  position: absolute;
  right: 20px;
  top: 10px;
  width: 30px;
}

.filelists .fileName {
  padding: 10px 25px;
}

.filelists .fileName span {
  color: #999;
}

.filelists .fileName span {
  display: block;
}

.uploadpopup-content .browseBtn {
  margin: 0 5px;
  border: 0;
  padding: 0;
  line-height: normal;
}

.uploadpopup-content .browseBtn:hover {
  color: #a439cb;
}

.uploadpopup .commonPopup {
  max-width: 800px;
  width: 800px;
}

.uploadpopup-content .uploadFiles {
  text-align: center;
  width: 80px;
  height: 115px;
  margin: 40px auto;
}

.teacherResourcepart .topHeaderpanel .outerPanel {
  width: 100%;
  display: flex;
}

.teacherResourcepart .topHeaderpanel {
  border-top: 1px solid rgba(0, 0, 0, 0.17);
  margin-top: 25px;
}

.teacherResourcepart .paginationBttns {
  padding: 10px 0px;
  position: relative;
  left: 7px;
}

.teacherResourcepart .move-select {
  width: 100%;
}

.teacherResourcepart .move-select #demo-customized-select {
  padding: 7px 26px 5px 12px;
  font-size: 16px;
  width: 100%;
}

.teacherResourcepart .select-all-outer{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}

.teacherResourcepart .createfolder{
  display: flex;
  align-items: center;
  justify-content: center;
}

.teacherResourcepart .folderNametext {
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
}

.teacherResourcepart .listFolderbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.teacherResourcepart .topHeaderpanel {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.17) !important;
  margin-top: 25px !important;
}

.topBorder {
  border-top: 1px solid rgba(0, 0, 0, 0.17) !important;
  margin-top: 25px !important;
}

@media only screen and (max-width: 1024px) {
  .teacherResourcepart .uploadpopup .commonPopup {
    max-width: 400px;
    width: 400px;
  }

  .teacherResourcepart .filelists button {
    position: static;
  }
}

@media only screen and (max-width: 767px) {
  .commonPopup, .uploadpopup .commonPopup {
    width: 310px;
  }

  .teacherResourcepart .topHeaderpanel .outerPanel {
    display: block;
  }
  .commonPopup {
    width: 92% !important;
  }

  .teacherResourcepart .topHeaderpanel {
    margin-top: 5px;
    padding-bottom: 0;
  }
  .select-all-outer{
    display: block !important;
  }
  .select-all-outer .MuiCheckbox-root{
    padding-left: 0 !important;
  }
  .folder-box {
    float: left;
  }
  
  .teacherResourcepart .createfolder{
    justify-content: flex-start;
  }

  .selectAll{
    right: 87px;
    bottom: 9px;
  }

.teacherResourcepart .checkouter {
    margin: 5px 0;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }

.teacherResourcepart .checkouter .checkouter-row{
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.teacherResourcepart .m5 {
  margin: 5px;
}

.teacherResourcepart .h-55 {
  height: 55px;
}
  
.teacherResourcepart .checkouter-row p {
    width: 200px;
}

.teacherResourcepart .checkouter .MuiCheckbox-root {
  padding-left: 0;
}

.teacherResourcepart .checkouter .flexbasis {
    flex-basis: auto ;
    width: 100%; 
    justify-content: space-between;
  }

.teacherResourcepart .folder-block{
    padding: 8px 6px;
}
}
