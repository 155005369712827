.paginationBttns {
  width: 90%;
  padding: 10px;
  height: auto;
  list-style: none;
  display: flex;
  justify-content: center;
}
.MuiTable-root {
  border-collapse: unset !important;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #2b2eff;
  color: black;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: black;
  border-radius: 6px;

  background-image: linear-gradient(to top, #af38cb 101%, #471c51 33%);
}

.paginationActive a {
  color: black;
  border-radius: 6px;
  background-image: linear-gradient(to top, #af38cb 101%, #471c51 33%);
}

.paginationDisabled a {
  color: grey;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to top, #af38cb, #471c51);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #fff, #fff),
    linear-gradient(to top, #af38cb, #471c51);
  background-origin: border-box;
  background-clip: border-box;
}

.paginationDisabled a:hover {
  color: grey;
  cursor: auto;
  border-radius: 6px;
}

.input_today_date_css_format > input {
  width: 237px;
  padding: 16.5px 23px;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  color: #3f526d;
  font-family: 'Open sans';
  cursor: pointer;
}

input.input-search-box::placeholder {
  opacity: 0.45;
  color: #484848;
}

.Date_of_due_date_Icon1:focus {
  outline: none;
}
.Date_of_due_date_Icon1 {
  background-repeat: no-repeat;
  background-image: url('../../assets/image-support@3x.jpg');
  background-size: 27px 27px;
  background-position: top 8px right 10.3px;
}

.input_today_date_css_format1 > input {
  width: 199px;
  padding: 12.5px 15px;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  color: #3f526d;
  font-family: 'Open sans';
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #471c51;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -140px;
  margin-top: 3px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #471c51 transparent;
  margin-left: 60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
#fileNameText {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 991px) {
  .Attendance-flex {
    display: block !important;
  }

  .Attendance-header {
    margin-right: 0 !important;
    padding: 10px 40px 10px !important;
  }
  .Attendance-header span {
    height: 22px !important;
    font-size: 16px !important;
  }

  .Attendance-flex .rdt {
    margin: 15px 0;
  }

  .Attendance-flex .rdt .Date_of_due_date_Icon {
    width: 100% !important;
    padding: 12px 15px !important;
    font-size: 15px !important;
    background-size: 24px 24px !important;
  }
  .status {
    justify-content: flex-start !important;
    width: 100% !important;
  }

  .status .status-icon {
    width: 16px !important;
    height: 16px !important;
  }

  .status span {
    font-size: 15px !important;
    margin-left: 7px !important;
  }

  .Attendance-table thead th {
    font-size: 15px !important;
    padding: 12px 12px !important;
    line-height: normal !important;
    border-radius: 0 !important;
  }

  .Attendance-table tbody th {
    padding: 5px 12px !important;
    line-height: normal !important;
  }
  .Attendance-table tbody th p {
    font-size: 16px !important;
    margin: 0px 11px !important;
  }
  .cal-mob {
    width: 100% !important;
    display: block !important;
  }

  .graphtitle {
    font-size: 17px !important;
    margin: 0px 0 15px !important;
    height: auto !important;
  }

  .graph-info {
    display: block !important;
    position: relative;
    z-index: 99;
  }

  .Attendance-table table {
    min-width: 986px !important;
  }

  .grap-status .Icons {
    width: 15px !important;
    height: 15px !important;
    margin-top: 8px !important;
  }
  .Chart {
    width: 60% !important;
  }
  .Chart-info {
    width: 40% !important;
  }

  .year-menu {
    width: 100px !important;
    font-size: 15px !important;
    padding: 10px 8px !important;
  }
  .customized-select {
    width: 7rem !important;
  }

  .customized-select .MuiSelect-selectMenu {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 767px) {
  .InputBox {
    height: 40px !important;
  }
  .co4Mob {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin: 0 !important;
  }
  .status{
    flex-wrap: wrap;
  }

  .status span {
    font-size: 15px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;

  }

  .cal-mob {
   
    margin-left: 0 !important;
    padding: 1px 0 !important;
}
.first-child {
  margin-left: 0 !important;
}
}

.TeacherAttendanceParentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.TeacherAttendanceParentContainer .HeaderContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
}
.TeacherAttendanceParentContainer .TextStart {
  text-align: start;
}
.TeacherAttendanceParentContainer .TextTypo {
  text-transform: none;
  font-weight: 600;
}
.FlexCenter {
  display: flex;
  justify-content: center !important;
}
.FlexBox {
  display: flex !important;
}
.TeacherAttendanceParentContainer .AlignItemC {
  align-items: center;
}
.TeacherAttendanceParentContainer .Mt15 {
  margin-top: 15px;
}
.TeacherAttendanceParentContainer .InputBoxContainer {
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1% 1.2% 1% 1.7%;
}
.TeacherAttendanceParentContainer .TextBox {
  border: none;
  outline: none;
  color: #484848;
  opacity: .45;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  padding-right: 1%;
  padding-left: 1%;
  height: 38px;
}
.TeacherAttendanceParentContainer .searchIcon {
  margin-right: 1%;
  cursor: pointer;
}
.TeacherAttendanceParentContainer .DateTimeContainerBox {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 1% 1.2% 1% 1.7%;
}
.TeacherAttendanceParentContainer .StatusBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.TeacherAttendanceParentContainer .Ml20 {
  margin-left: 20px;
}
.TeacherAttendanceParentContainer .ErrorMsg {
  position: relative;
  color: red;
}
.TeacherAttendanceParentContainer .TableContainerMainBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 30px;
}
.TeacherAttendanceParentContainer .Table {
  min-width: 650px;
}
.TeacherAttendanceParentContainer .BorderNone {
  border-bottom: none;
}
.TeacherAttendanceParentContainer .TitleText {
  width: 35%;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 12px;
  line-height: normal;
  background-color: #f2f2f2;
  border-bottom: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding-left: 20px;
}
.TeacherAttendanceParentContainer .TitleText2 {
  font-size: 1.125rem;
  font-weight: 600;
  padding: 12px;
  line-height: normal;
  background: #f2f2f2;
  border-bottom: none;
}
.TeacherAttendanceParentContainer .W15 {
  width: 15%;
}
.TeacherAttendanceParentContainer .W10 {
  width: 10%;
}
.TeacherAttendanceParentContainer .Ml10 {
  margin-left: 10px;
}
.Ml30 {
  margin-left: 30px !important;
}
.TeacherAttendanceParentContainer .MlNeg10 {
  margin-left: -10px;
}
.TeacherAttendanceParentContainer .W20 {
  width: 20%;
}
.TeacherAttendanceParentContainer .Mt20 {
  margin-top: 20px;
}
.TeacherAttendanceParentContainer .Mt15 {
  margin-top: 15px;
}
.TeacherAttendanceParentContainer .TitleText3 {
  font-size: 1.125rem;
  font-weight: 600;
  padding: 12px;
  line-height: normal;
  background: #f2f2f2;
  border-top-left-radius: 6px;
  border-bottom-right-radius:6px;
  border-bottom: none;
}
.TeacherAttendanceParentContainer .H10 {
  height: 10px;
}
.TeacherAttendanceParentContainer .TableCellStyle {
  padding: 10px;
  padding-left: 20px;
  border-bottom: none;
  cursor: pointer;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.FlexStart {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.TeacherAttendanceParentContainer .Typo {
  font-size: 16px;
  margin: 0 23px;
  font-weight: 600;
}
.TeacherAttendanceParentContainer .CalenderIcon {
  position: relative;
  top: 5px;
  margin-left: 10px;
}
.TeacherAttendanceParentContainer .TableCell {
  padding: 10px;
  border-bottom: none;
}
.TeacherAttendanceParentContainer .TableCellText {
  height: 22px;
  font-size: 16px;
  margin: 0px 0px;
  font-weight: 600;
  text-transform: capitalize;
}
.TeacherAttendanceParentContainer .CursorPointer {
  cursor: pointer;
}
.FlexCenter {
  display: flex !important;
  align-items: center !important;
}
.FlexBetween {
  display: flex !important ;
  justify-content: space-between !important;
  flex-direction: row !important;
}
.TeacherAttendanceParentContainer .TableCell2 {
  padding: 10px;
  border-bottom: none;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.TeacherAttendanceParentContainer .Mr10 {
  margin-right: 10px;
}
.TeacherAttendanceParentContainer .AttendanceReportModal {
  border-radius: 24px;
}
.AttendanceReportModal .ModalInnerBox {
  justify-content: center !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background: #fff !important;
  box-shadow: 24px;
  border-radius: 24px;
  padding: 17px !important;
  min-width: 485px !important;
}
.AttendanceReportModal .InnerFlexBox {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
}
.AttendanceReportModal .AttendanceReportTypo {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.AttendanceReportModal .FullWidth {
  width: 100%;
}
.AttendanceReportModal .TotalParentDiv {
  margin-top: 20px;
  margin-right: -15px;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 12px 11px;
  border: solid 1px #979797;
  border-radius: 6px;
}
.AttendanceReportModal .FlexOnly {
  display: flex;
}
.AttendanceReportModal .FlexSpaceBetween {
  display: flex;
  justify-content: space-between;
}
.AttendanceReportModal .inputProps {
  font-size: 16px;
  color: #3f526d;
  font-weight: 600;
  line-height: initial;
}
.AttendanceReportModal .Box1 {
  align-items: center;
  margin: 5px;
  width: 100%;
}
.AttendanceReportModal .Box2 {
  display: flex;
  align-items: center;
  margin: 0 20px;
  width: 95%;
}
.AttendanceReportModal .Box3 {
  display: flex;
  margin-top: 16px;
  align-items: center;
}
.AttendanceReportModal .AvatarImg {
  width: 72px;
  height: 72px;
}
.AttendanceReportModal .Box4 {
  align-items: center;
  margin-top: 28px !important;
  background: #f6f4f4 !important;
  border-radius: 8px;
  padding: 15px 37px 13px 32px;
}
.AttendanceReportModal .Box5 {
  z-index: 1000;
  flex-direction: column;
}
.AttendanceReportModal .FromTypo {
  font-size: 1rem;
  margin: 0 0 10px 0;
  font-weight: 600;
}
.AttendanceReportModal .FlexCenterBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}
.TeacherAttendanceParentContainer .ChartStyle {
  background: transparent;
  margin: -2.5rem 0 -2rem -1.25rem;
}
.TeacherAttendanceParentContainer .AttendanceStatus { 
  margin: 0 10px;
  border-radius: 3px;
  padding: 7px 16px 7px 17px;
  background-color: #27d672;
  justify-content: center;
}
.TeacherAttendanceParentContainer .AbsentBox {
  margin: 0 10px;
  border-radius: 3px;
  padding: 7px 16px 7px 17px;
  justify-content: center;
}
.TeacherAttendanceParentContainer .BgAbsent {
  background: #ff6c6c;
}
.AttendanceReportModal .BgLeave {
  background: #13a0c5;
}
.AttendanceReportModal .Year {
  color: #3f526d;
  width: 140px;
  padding: 8px 12px 8px 12px;
  position: relative;
  font-size: 18px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  transition:
    border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.2;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
}
.TeacherAttendanceParentContainer .classDiv {
    color: #3f526d;
    width: 160px;
    padding: 6px 15px 6px 15px;
    position: relative;
    font-size: 18px;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    transition:
      border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    background-color: #fff;
    word-break: break-all;
    height: 37px;
}
.TeacherAttendanceParentContainer .AttendanceLetterP  {
  display: flex;
  width: 2.4375rem;
  margin: 0 10px;
  border-radius: 3px;
  padding: 7px 16px 7px 17px;
  background-color: #3dbb3d;
  justify-content: center;
  border: solid 1px #3dbb3d;
}
.TeacherAttendanceParentContainer .AttendanceLetterTableOuterNotselectedP {
  display: flex;
  width: 2.4375rem;
  margin: 0 10px;
  border-radius: 3px;
  padding: 7px 16px 7px 17px;
  border: solid 1px #3dbb3d;
  justify-content: center;
}
.TeacherAttendanceParentContainer .listIcon {
  width: 36px;
  height: 36px;
}
.TeacherAttendanceParentContainer .attendance_under_modal{
  width: 8px;
  height: 17.3px;
  margin: 1px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: "#fff",
}
.AttendanceReportModal .attendance_under_present {
  height: 19px;
  margin: 3px 62px 2px 9px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.AttendanceReportModal .attendance_no_data {
  height: 19px;
  margin: 10px 62px 2px 9px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f526d;
  display: flex;
  justify-content: center;
}
.AttendanceReportModal .attendance_number {
  height: 19px;
  margin: 3px 0 2px 0px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.AttendanceReportModal .modal_attendance_name {
  margin-left: 23px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
} 
.AttendanceReportModal .modal_attendance_name_type {
  height: 19px;
  margin-left: 23px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.TeacherAttendanceParentContainer .attendance_modale_graph_heading {
  height: 19px;
  margin-left: 23px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f526d;
  margin: 1px 12px 0 0;
}
.TeacherAttendanceParentContainer .attendance_letter_table_selected {
  width: 12px;
  height: 20px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: "normal";
  line-height: 1.11;
  letter-spacing: normal;
  color: #fff;
}
.TeacherAttendanceParentContainer .attendance_letter_table_notselected_p {
  width: 12px;
  height: 20px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #3dbb3d;
}
.TeacherAttendanceParentContainer .attendance_letter_table_notselected_a {
  width: 12px;
  height: 20px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #e02020;
}
.TeacherAttendanceParentContainer .attendance_letter_table_notselected_l {
  width: 12px;
  height: 20px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: "#3f526d",
}
.TeacherAttendanceParentContainer .attendance_letter_table_outer_notselected_a {
  display: flex;
  width: 2.4375rem;
  margin: 0 10px;
  border-radius: 3px;
  padding: 7px 16px 7px 17px;
  border: solid 1px #e02020;
  justify-content: center;
}
.TeacherAttendanceParentContainer .attendance_letter_table_outer_notselected_l {
  display: flex;
  width: 2.4375rem;
  margin: 0 10px;
  border-radius: 3px;
  padding: 7px 16px 7px 17px;
  border: solid 1px #3f526d;
  justify-content: center;
}
.TeacherAttendanceParentContainer .attendance_letter_a {
  display: flex;
  width: 2.4375rem;
  margin: 0 10px;
  border-radius: 3px;
  padding: 7px 16px 7px 17px;
  background-color: #e02020;
  justify-content: center;
  border: solid 1px #e02020;
}
.TeacherAttendanceParentContainer .attendance_letter_l {
  display: flex;
  width: 2.4375rem;
  margin: 0 10px;
  border-radius: 3px;
  padding: 7px 16px 7px 17px;
  background-color: #3f526d;
  justify-content: center;
  border: solid 1px #3f526d;
}
.TeacherAttendanceParentContainer .forrmInput_textfield {
  width: 100%;
  border-radius: 8.8px;
  text-align: start;
  margin-bottom: 5px;
  margin-top: 5px;
}
.TeacherAttendanceParentContainer .presentAbsent {
  margin: 0px 24px 0px 10px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}