.teacher-publish-report-btn {
    width: 186px;
    height: 50px;
    border-radius: 8.8px !important;
    border: solid 1px !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 12px 16px 11px 20px !important;
    justify-content: center !important;
}

.teacher-report-card-text-title .MuiTypography-body1 {
    font-size: 20px;
    font-weight: 600;
}

.teacher-report-input-base {
    width: 118px;
    padding: 10px 10px;
    margin-right: 15px;
    height: 50px;
    font-weight: 600 !important;
    font-size: 18px !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.teacher-report-input-base .MuiInputBase-input {
    text-align: center;
}

.teacher-report-input-select {
    width: 180px;
    height: 50px;
    font-weight: 600 !important;
    font-size: 18px !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.publish-modal-title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin: 38px 0px 25px 0px;
}

.publish-modal-text {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin: 25px 0px 0px 0px;
    padding: 0 20px;
}

.publish-ok-button {
    height: 50px;
    width: 124px;
    border-radius: 8px !important;
    text-transform: capitalize !important;
}

.publish-ok-text {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.teacher-report-rectangle {
    margin: 20px 0 0 0;
    padding: 30px 19px 50px 21px;
    border-radius: 13px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.save-changes-div {
    display: flex;
    align-items: center;
    justify-content: right;
}

.save-changes-btn {
    width: 186px;
    height: 44px;
    border-radius: 8.8px !important;
    border: solid 1px !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: bold !important;
    padding: 12px 16px 11px 20px !important;
    justify-content: space-between !important;
    margin-bottom: 20px;
}

.student-name-with-back {
    display: flex;
    font-size: 20px !important;
    font-weight: 600 !important;
    margin: 22px 0 30px 0 !important;
}

.teacher-back-arrow {
    display: flex;
    align-items: center;
    margin-right: 40px;
    cursor: pointer;
}

.detail-field-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 12px;
}

.detail-field-style {
    font-size: 18px !important;
    font-weight: normal !important;
}

.student-profile-photo-div {
    border: 1px dashed #7f6cfc;
    width: 200px;
    height: 228px;
    border-radius: 5px;
    margin-top: 50px;
}

.student-profile-photo {
    height: 100% !important;
    width: 100% !important;
    border-radius: 5px !important;
}

.student-profile-header {
    font-size: 24px;
    font-weight: 600;
    margin: 40px 0 28px 0;
}

.school-title-header {
    border-bottom: 1px solid #eaeaea;
    font-size: 36px;
    padding: 0 0 10px;
    margin-top: 0;
    margin-bottom: 10px;
}

.vision-of-school-label {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 12px;
}

.school-details-label {
    font-size: 16px;
    font-weight: 600;
}

.view-box-purple {
    width: 800px;
    height: 160px;
    padding: 20px 35px;
    border-radius: 8px;
    background-color: #f9f8fd;
}

.table-details {
    font-size: 16px !important;
    font-weight: normal !important;
}

.bold-details {
    font-weight: bold !important;
}

.attendance-title {
    font-size: 20px !important;
    font-weight: bold !important;
}

.attendance-details {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.health-status {
    font-size: 16px !important;
    font-weight: bold !important;
}

.school-logo {
    height: 200px;
    width: 200px;
    object-fit: contain;
}

.mb10 {
    margin-bottom: 10px !important;
}

.grading-detail-text {
    font-size: 20px;
    font-weight: 600;
    margin: 30px 0 36px 0;
}

.table-field-style {
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center;
}

.assessment-name-header {
    font-size: 20px;
    font-weight: bold;
}

.category-name-header {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.co-scholastic-topic {
    font-size: 20px !important;
    font-weight: 600 !important;
}

/* pascal case css */
.CoScholasticDiv {
    width: 100%;
}

.MuiTableCell-root.MuiTableCell-body.CoScholasticCell {
    border-radius: 0px;
}

.GradingTableContainer {
    border-radius: 6px; 
    border: 0;
}

.MuiTable-root.GradingTable {
    width: calc(100% - 1px);
}

.MuiTableCell-root.GradingHeadCell {
    padding: 17px 20px;
    font-size: 20px;
    font-weight: bold;
}

.GradingTableBody {
    margin-top: 10px;
    background-color: #f9f8fd;
}

.MuiTableCell-root.GradingBodyCell {
    padding: 17px 20px;
    border: 1px solid lightgray;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.MuiButton-root.ReportDetailBtn {
    cursor: pointer;
    padding: 12px;
    min-width: 196px;
    border-radius: 6px;
}

.MuiTypography-root.ReportDetailBtnText {
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    color:#8F99A5;
}

.MuiTypography-root.ReportDetailSelectedBtnText {
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    color: white;
}

.MuiTableCell-root.MuiTableCell-body.ScholasticCell {
    border-radius: 0px;
}

.MuiTableCell-root.ScholasticCategoryCell {
    border-left: 1px solid lightgray;
    border-top: 1px solid lightgray;
}

.MuiTableCell-root.ScholasticLeftBorderCell {
    border-left: 1px solid lightgray;
    padding: 10px;
}

.MuiTableCell-root.ScholasticRightBorderCell {
    border-right: 1px solid lightgray;
    padding: 10px;
}

.ReportSchoolNameDiv {
    display: flex;
    flex-direction: column;
}

.ReportAttendanceTableContainer {
    margin-top: 60px;
}

.ReportAttendanceTable {
    min-width: 700px;
}

.ReportCardGrid {
    display: flex;
    align-items: center;
}

.StudentListTableContainer {
    border-radius: 6px;
    border: 0;
}

.StudentListTableHead {
    background-color: #ded9ff;
}

.StudentListTableBody {
    margin-top: 10px;
}

.MuiTableCell-root.StudentNameCell {
    padding: 17px 5px 17px 40px;
    font-size: 18px; 
    font-weight: 600;
    border-bottom: 0; 
    width: 60%;
}

.MuiTableCell-root.EvaluationStatusCell {
    padding: 17px 5px;
    font-size: 18px; 
    font-weight: 600;
    border-bottom: 0;
    text-align: center;
    text-transform: capitalize;
}

.MuiTableCell-root.ActionCell {
    padding: 17px 20px 17px 5px;
    font-size: 18px; 
    font-weight: 600;
    border-bottom: 0;
    text-align: center;
}

.MuiIconButton-root.ReportEditIconBtn {
    padding-top: 0;
    padding-bottom: 0;
}

.ReportEditImg {
    height: 17px;
    width: 17px;
    object-fit: contain;
}
 
.PaginationOverFlow {
    overflow-x: auto;
}

.MuiTableCell-root.NoStudentRecordFound {
    padding: 17px 0px 16px 20px;
    font-size: 18px;
}

.MuiTableCell-root.BlankReportCell {
    padding: 5px 0px; 
    border: 0; 
}

.ReportStudentDetailsRow {
    border-bottom: 1px solid lightgray;
    background-color: #f9f8fd;
}

.teacherProfileContent .topHeader {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    display: block;
  }
  
  .teacherProfileContent .schoolLogo {
    width: 120px;
    height: auto;
    position: absolute;
    left: 0;
  }
  
  .teacherProfileContent .schoolLogo img {
    max-width: 100%;
  }
  
  
  .teacherProfileContent .schoolTitle {
      width: 100%;
      text-align: center;
  }
  
  .teacherProfileContent .schoolTitle h3 {
    font-size: 36px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    line-height: 80px;
  }
  
  .teacherProfileContent .commonBtnPrimary {
    white-space: nowrap;
    position: absolute;
    right: 0;
    top: 20px;
  }

@media (max-width: 1279px) {
    .student-profile-photo-div {
        margin-top: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .teacherProfileContent .schoolLogo {
      margin: 0 auto;
      position: static;
    }
  
    .teacherProfileContent .commonBtnPrimary {
      position: static;
    }
  
    .teacherProfileContent h3 {
      font-size: 28px;
      line-height: 45px;
      margin: 10px 0;
    }
  }

@media (max-width: 959px) {
    .teacher-report-card-text-title .MuiTypography-body1 {
        font-size: 22px;
    }

    .teacher-publish-report-btn {
        width: 165px;
    }

    .teacher-report-input-base {
        width: 100px;
    }

    .student-profile-photo-div {
        width: 160px;
        height: 188px;
    }
}

@media (max-width: 599px) {
    .xs-mb-15 {
        margin-bottom: 15px !important;
    }

    .teacher-report-input-base {
        margin-right: 10px;
    }

    .publish-modal-title {
        font-size: 26px;
        margin: 28px 0px 15px 0px;
    }

    .publish-modal-text {
        font-size: 18px;
        margin: 15px 0px 0px 0px;
        padding: 0 20px;
    }

    .alert-img-term {
        height: 100px;
        width: 100px;
        object-fit: contain;
    }

    .publish-ok-button {
        height: 40px;
        width: 100px;
    }

    .view-box-purple {
        height: auto;
        padding: 20px 10px;
    }

    .student-name-with-back {
        margin: 15px 0 0 0 !important;
    }

    .teacher-report-tab {
        min-width: 150px !important;
    }
}