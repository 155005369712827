.emailLoginOTP {
    /* width: 100% !important; */
    font-family: 'Open sans';
    font-weight: 600;
    font-size: 24px;
    height: auto;
    margin: 0px 20px 0px 0px;
    border-radius: 8.8px;
    padding: 14px 22px 15px;
    border: 1px solid #cfcfcf;
    background-color: #f1f1f1;
}

.emailLoginOTP:focus {
    border: 1px solid rgba(142, 123, 253, 1);
    outline: rgba(142, 123, 253, 1);
}

.input_pass_icon {
    border-right: none !important;
}

.input_focus_pass {
    border-left: none !important;
}

.input_focus_pass:focus {
    outline: none;
}

.login_otp_input_props {
    font-family: "Open sans" !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #03014c !important;
    text-align: center;
    padding: 17px 14px !important;
    height: 37px !important;
}

.EmailLoginOTPRoot {
    flex-grow: 1;
    height: 100%;
    overflow-x: hidden
}

.EmailLoginOTPH100 {
    height: 100%;
}

.EmailLoginOTPLoginImageDiv {
    position: relative;
}

.EmailLoginOTPHalfCircleImage {
    width: 100%;
    height: 100%;
    background-color: rgba(142, 123, 253, 1);
    position: absolute;
    z-index: 0;
    object-fit: cover
}

.EmailLoginOTPImageStudenIllustratorImage {
    width: 56%;
    height: auto;
    bottom: 0%;
    position: absolute;
    transform: translateX(105%)
}

.EmailLoginOTPLogoImage {
    width: auto;
    height: 68px;
    position: relative;
    margin: 33px 29px 29px 30px;
}

.EmailLoginOTPTextlabelQuoteText {
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    position: relative;
    font-family: 'Open sans';
    font-size: 4.5rem;
    margin: 25px 34px;
}

.EmailLoginOTPImageCloudSmallImage {
    width: 65px;
    height: 51px;
    position: absolute;
    top: 61%;
    left: 30%;
}

.EmailLoginOTPImageCloudBigImage {
    width: 151px;
    height: 117px;
    bottom: 10%;
    left: 8%;
    position: absolute;
}

.EmailLoginOTPTextlabelWelcomeText {
    font-size: 35.1px;
    font-weight: bold;
    font-family: 'Open sans';
    padding-top: 25px;
}

.EmailLoginOTPTextlabelFollowInstructionText {
    width: 100%;
    color: #03014c;
    font-size: 14px;
    font-family: 'Open sans';
    line-height: 1.5;
    opacity: 0.8;
    margin: 20px 0;
    width: '100%',
}

.EmailLoginOTPH100vh {
    height: 100vh;
}

.EmailLoginDiv {
    justify-content: center !important;
    margin: 25% 0px 4% 0px !important
}

.EmailLoginOTPDiv {
    justify-content: center !important;
    margin: 4% 0px 4% 0px !important
}

.EmailLoginOTPTextFieldDiv {
    display: flex;
    justify-content: space-between;
}

.EmailLoginOTPForrmInputTextfield {
    width: 20%;
    border-radius: 8.8px;
    background-color: #f1f1f1;
    margin-top: 20px
}

.EmailLoginOTPErrorColorDiv {
    color: red;
    margin: auto;
    font-size: 14px;
}

.EmailLoginOTPTextlabelForgotpasswordText {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Open sans';
    margin: 24.7px 0.1px 0px 0px;

}

.EmailLoginOTPResendOTPText {
    font-size: 22px;
    font-weight: bold;
    color: #8a77fd;
    cursor: pointer;
}


@media (max-width: 1279px) {
    .email_otp_form_div {
        margin-bottom: 50px !important;
    }
}

@media (max-width: 959px) {
    .email_otp_form_div {
        margin-bottom: 40px !important;
    }
}

@media (max-width: 767px) {
    .login_otp_input_props {
        padding: 8px 14px !important;
        height: 40px !important;
    }

}