.studentAnswersheetQueryResolution_mainDiv {
    flex-direction: row;
}

.studentAnswersheetQueryResolution_subDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 10px;
}

.studentAnswersheetQueryResolution_backArrowDiv {
    font-family: "Open sans";
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #3f526d;
    cursor: pointer;
}

.studentAnswersheetQueryResolution_backArrowImg {
    height: 14px;
    width: 18px;
    object-fit: contain;
    margin-right: 10px,
}

.studentAnswersheetQueryResolution_mainContainer {
    padding: 3% 2%;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    overflow: auto
}

.studentAnswersheetQueryResolution_subMainContainer {
    display: flex;
    justify-content: flex-start;
}

.studentAnswersheetQueryResolution_raiseWQueryTxt {
    font-family: "Open sans";
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #b53bd1;
    text-align: center;
}

.studentAnswersheetQueryResolution_dropDownLabel {
    margin-bottom: 20px;
    margin-top: 2%;
    font-family: "Open sans";
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #3f526d;
}

.studentAnswersheetQueryResolution_dropDown {
    height: 100%;
    width: 100%;
}

.studentAnswersheetQueryResolution_textArea {
    height: 16vh;
    width: 100%;
    background-color: #f9f8fd;
    padding: 20px;
    overflow: auto;
    border-radius: 8px;
    font-family: "Open sans";
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #3f526d;
    border: none;
    outline: none;
}

.studentAnswersheetQueryResolution_btnDiv {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center
}

.studentAnswersheetQueryResolution_Btn {
    padding: 15px 4%;
    text-transform: capitalize;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: "Open sans";
    color: "#ffffff";
    background-color: "#471c51";
    text-align: center;
    border-radius: 8px;
    margin-top: 2%
}

.studentAnswersheetQueryResolution_activeBtn {
    opacity: 1;
}

.studentAnswersheetQueryResolution_disabledBtn {
    opacity: 0.6;
}

.studentAnswersheetQueryResolution_queryDiv {
    margin-top: 12px;
}

.RaiseQuerySelectQuestion {
    margin-bottom: 11px !important;
}