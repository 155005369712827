.inputColor .css-1yn2e29-InputColor .css-1vpurlb-InputColor{
    background-color: transparent !important;
}

.inputColor .css-1yn2e29-InputColor{
    border:none;
    width: 100%;
    height: 100%;
    padding: 0px;
    position: absolute;
    background-color: transparent !important;
}

.backgroundColorPicker .css-j4ndc3 .css-trkpwz{
    background-color: transparent !important;
}

.backgroundColorPicker .css-j4ndc3{
    border:none;
    width: 100%;
    height: 100%;
    padding: 0px;
    position: absolute;
    background-color: transparent !important;
}

.inputColor .css-j4ndc3 .css-trkpwz{
    background-color: transparent !important;
}

.inputColor .css-j4ndc3{
    border:none;
    width: 100%;
    height: 100%;
    padding: 0px;
    position: absolute;
    background-color: transparent !important;
}

.backgroundColorPicker .css-1yn2e29-InputColor .css-1vpurlb-InputColor{
    background-color: transparent !important;
}

.backgroundColorPicker .css-1yn2e29-InputColor{
    border:none;
    width: 100%;
    height: 100%;
    padding: 0px;
    position: absolute;
    background-color: transparent !important;
}

.manageBoxContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .manageBoxContent .addNewCategoryContainer {
    align-items: center;
    margin-top: 14px;
  }
  
  .manageBoxContent .overFlowxy {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  
  .manageBoxContent .addNewBoxGrid {
    align-items: center;
    margin-top: 23px;
  }
  
  .manageBoxContent .centerM10 {
    align-items: center;
    margin-top: 10px;
  }

  .manageBoxContent .addNewCategoryButton {
    width: 87%;
    padding: 10px 0;
    border-radius: 6px;
    background: #7f6cfc;
    text-transform: capitalize;
    color: #fff;
    font-size: 16px;
    font-family: "Open sans";
    margin-left: 45px;
    float: left;
  }
  
  .manageBoxContent .saveButton {
    width: 87%;
    padding: 10px 0;
    border-radius: 6px;
    text-transform: capitalize;
    color: #fff;
    font-size: 16px;
    font-family: "Open sans";
    margin-left: 45px;
    float: left;
  }

  .manageBoxContent .cancelButton {
    width: 87%;
    padding: 10px 0;
    border-radius: 6px;
    text-transform: capitalize;
    color: #000;
    font-size: 16px;
    font-family: "Open sans";
    margin-left: -10px;
  }
  
  .manageBoxContent .activeBtn {
    background: #7f6cfc;
  }
  
  .manageBoxContent .disableBtn {
    background: #9889FC;  
  }
  
  .manageBoxContent .createNewButton {
    margin-left: -10px;
  }

  .manageBoxContent .managePopup {
    background: #fff;
    border: 1px solid#979797;
    outline: none;
    border-radius: 29px;
    width: 65%;
    height: auto;
    padding: 25px 53px 25px 53px;
  }

  .manageBoxContent .manageTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 0.5px #BFBFBD;
    padding-bottom: 33px;
  }
  
  .manageBoxContent .manageIcon {
    font-size: 22px;
    margin-right: 10px;
    cursor: pointer;
    color: rgb(164, 57, 203);
  }
  
  .manageBoxContent .center {
    text-align: center;
  }

  .manageBoxContent .noRecordText {
    font-size: 24px;
    font-family: "Open sans";
    font-weight: 400;
    color: #3f526d;
    align-items: center;
  }
  
  .manageBoxContent .manageCategoryTitle {
    font-size: 24px;
    font-family: "Open Sans";
    font-weight: 600;
    color: #3f526d;
  }
  
  .manageBoxContent .flexBox {
    display: flex;
  }
  
  .manageBoxContent .deleteIcon {
    font-size: 22px;
    cursor: pointer;
  }
  
  .manageBoxContent .settingIcon {
    font-size: 22px;
    cursor: pointer;
    color: #BFBFBD;
  }
  
  .manageBoxContent .gradeSelectTrue {
    color: #696969;
  }
  
  .manageBoxContent .gradeSelectFalse {
    color: #BFBFBD;
  }
  
  .manageBoxContent .taskTypeBox {
    overflow: auto;
    height: 200px;
  }
  
  .manageBoxContent .taskTypeBoxText {
    margin-top: 15px;
    height: calc(100% - 190px);
    padding-right: 10px;
  }
  
  .manageBoxContent .taskTypeText {
    font-size: 18px;
    font-family: "Open sans";
    font-weight: 500;
    color: #3f526d;
    margin-left: 36px;
  }
  
  .manageBoxContent .checkboxContent {
    align-items: center;
    margin-top: 12px;
  }
  
  .manageBoxContent .p0 {
    padding: 0;
  }
  
  .manageBoxContent .taskCategoryTextfield {
    width: 100%;
    border-radius: 6px;
    text-align: start;
    color: #03014c;
    font-size: 15.8px;
    font-family: "Open sans";
    background: #fff;
    text-transform: capitalize;
    opacity: 0.5;
    height: 50px;
  }
  
  .manageBoxContent .selectGradeBox {
    margin-top: 15px;
    height: calc(100% - 190px);
    padding-right: 10px;
  }
  
  .manageBoxContent .selectGradeBoxTitle {
    font-size: 18px;
    font-family: "Open sans";
    font-weight: 500;
    color: #3f526d;
  }
  
  .manageBoxContent .selectanyGradeMenu {
    width: 100%;
    border-radius: 6px;
    text-align: start;
    color: #03014c;
    font-size: 15.8px;
    font-family: "Open sans";
    background: #fff;
    text-transform: capitalize;
    height: 50px;
  }
  
  .manageBoxContent .selectGrid {
    margin-top: 12px;
    align-items: center;
  }

  .createNewScaleContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .createNewScaleContent .manageTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 0.5px #CCCCCC;
    padding-bottom: 25px;
  }

  .createNewScaleContent .cNewScaleTitle {
    font-size: 24px;
    font-family: "Open sans";
    font-weight: 600;
    color: #3f526d;
  }
  
  .createNewScaleContent .closeBox {
    display: flex;
    justify-content: center;
  }
  
  .createNewScaleContent .closeIcon {
    cursor: pointer;
    color: #B3B3B3;
  }
  
  .createNewScaleContent .performanceBandsTitle {
    font-size: 18px;
    font-family: "Open sans";
    font-weight: 500;
    color: #3f526d;
    padding-top: 24px;
  }
  
  .createNewScaleContent .tabularBox {
    margin-top: 15px;
    height: calc(100% - 190px);
    padding-right: 10px;
  }
  
  .createNewScaleContent .tableContent {
    height: 200px;
    overflow-y: auto;
  }
  
  .createNewScaleContent .cellTitle {
    font-weight: 600;
    background: #ded9ff;
    border-radius: 0;
  }
  
  .createNewScaleContent .tableCellBorder {
    border: 1px solid #979797;
    border-radius: 0;
  }

  .createNewScaleContent .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .createNewScaleContent .saveCancelRow {
    align-items: center;
    margin-top: 23px;
    justify-content: center;
    display: flex;
    width: 100%;
  }
  
  .createNewScaleContent .clearBox {
    overflow: hidden;
    position: relative;
    height: 35px;
    width: 35px;
    border-radius: 28%;
    box-shadow: 1px 1px 3px 0px grey;  
  }
  
  .createNewScaleContent .clearIcon {
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -1px;
    top: 0;
    width: 15px;
    height: 15px;
    border: 1px solid #eaeaea;
  }
  
  .createNewScaleContent .clearInput {
    padding: 0;
    width: 150%;
    height: 150%;
    margin: -25%;
  }

  .createNewScaleContent .managePopup {
    background: #fff;
    border: 1px solid#979797;
    outline: none;
    border-radius: 29px;
    width: 65%;
    height: auto;
    padding: 25px 53px 25px 53px;
  }

  .createNewScaleContent .center {
      text-align: center;
  }

  .gradeScaleContainer .manageTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 0.5px #979797;
    padding-bottom: 33px;
  }
  
  .gradeScaleContainer .okBtnRow {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 23px;
    width: 50%;
  }
  
  .gradeScaleContainer .gradeTitle {
    font-size: 24px;
    font-family: "Open sans";
    font-weight: 600;
    color: #3f526d;
  }

  .gradeScaleContainer .greyBorder {
    border: 1px solid #979797;
  }
  
  .gradeScaleContainer .okBtn {
    display: flex;
    width: 100%;
    padding: 10px 0px 10px 0px;
    border-radius: 6px;
    background: #7f6cfc;
    text-transform: capitalize;
    color: #fff;
    font-size: 16px;
    font-family: "Open sans";
  }
  
  .gradeScaleContainer .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .gradeScaleContainer .center {
    text-align: center;
  }

  .gradeScaleContainer .noDataFoundText {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: 10px 23px;
    font-family: "Open Sans";
    color: #3f526d;
    font-weight: 600;
  }

  .gradeScaleContainer .gradeScaleTableBox {
    margin-top: 15px;
    height: calc(100% - 190px);
    padding-right: 10px;
  }
  
  .gradeScaleContainer .gradeScaleTableBox .tableCellHead {
    font-weight: 600;
    background: #ded9ff;
    border-radius: 0;
  }

  .gradeScaleContainer .fCenter {
    display: flex;
    justify-content: center;
  }

  .gradeScaleContainer  .okCancel {
    display: flex;
    width: 100%;
    padding: 10px 0px 10px 0px;
    border-radius: 6px;
    background: #f6f6f6;
    text-transform: capitalize;
    color: #000;
    font-size: 16px;
    font-family: "Open Sans";
  }
  
  .gradeScaleContainer .performanceBandBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .gradeScaleContainer .performanceBoxGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
  
  .gradeScaleContainer .performanceTitle {
    font-size: 18px;
    font-family: "Open sans";
    font-weight: 500;
    color: #3f526d;
  }
  
  .gradeScaleContainer .selectMenu {
    width: 100%;
    margin-right: 32px;
  }
  
  .gradeScaleContainer .selectMenu option {
    color: rgb(63, 82, 109,0.32);
  }
  
  
  
  .gradeScaleContainer .managePopup {
    background: #fff;
    border: 1px solid#979797;
    outline: none;
    border-radius: 29px;
    width: 65%;
    height: auto;
    padding: 25px 53px 25px 53px;
  }

  .gradeScaleContainer .pointer {
      cursor: pointer;
  }

  .gradeScaleContainer .editBtnBox {
    height: 42px;
    width: 42px;  
    background: #f9f8fd;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .gradeScaleContainer .editOutlineIcon {
    height: 22px;
    width: 22px;
    cursor: pointer;
  }

  .gradeScaleContainer .deleteIconBox {
    height: 42px;
    width: 42px;
    margin-left: 5px;
    background: #f9f8fd;
    display: flex;
    align-items: center;
    justify-content: center;  
  }
  
  .gradeScaleContainer .deleteOutlineIcon {
    font-size: 22px;
    cursor: pointer;
    color: gray;
  }

  .editModalPopup .fCenter {
    display: flex;
    justify-content: center;
  }

  .editModalPopup .closeIcon {
    cursor: pointer;
    color: black;
  }
  
  .editModalPopup .editSaveCancelRow {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 23px;
    width: 50%;
  }
  
  .editModalPopup .editSaveCancelRow .editSaveBtn {
    display: flex;
    width: 100%;
    padding: 10px 0;
    border-radius: 6px;
    background: #7f6cfc;
    text-transform: capitalize;
    color: #fff;
    font-size: 16px;
    font-family: "Open sans";
  }
  
  .editModalPopup .editSaveCancelRow .editCancelBtn {
    display: flex;
    width: 100%;
    padding: 10px 0;
    border-radius: 6px;
    text-transform: capitalize;
    color: #000;
    font-size: 16px;
    font-family: "Open sans";
  }

  .editModalPopup .pointer {
    cursor: pointer;
  }
  
  .editModalPopup .editOutlineIcon {
    height: 22px;
    width: 22px;
    cursor: pointer;
  }
  
  .editModalPopup {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .editModalPopup .editTitle {
    font-size: 24px;
    font-family: "Open sans";
    font-weight: 600;
    color: #3f526d;
  }
  
  .editModalPopup .editAttirbuteBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .editModalPopup .editAttributerGrid {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  
  .editModalPopup .editAttributeName {
    font-size: 20px;
    font-family: "Open sans";
    font-weight: 600;
    color: #3f526d;
  }
  
  .editModalPopup .editTableBox {
    margin-top: 15px;
    height: calc(100% - 190px);
    padding-right: 10px;
  }
  
  .editModalPopup .tableCellTitle {
    font-weight: 600;
    background: #ded9ff;
    border-radius: 0;
  }
  
  .editModalPopup .tableCellRow {
    border: 1px solid #979797;
    border-radius: 0;
  }
  
  .editModalPopup .editTableContent {
    height: 200px;
    overflow-y: auto;
  }
  
  .editModalPopup .managePopup {
    background: #fff;
    border: 1px solid #979797;
    outline: none;
    border-radius: 29px;
    width: 65%;
    padding: 25px 53px 25px 53px;
    max-height: 600px;
    overflow-x: auto;
  }
  
  .editModalPopup .manageTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 0.5px #979797;
    padding-bottom: 33px;
  }

  .editModalPopup .center {
      text-align: center;
  }

  .editModalPopup .noDataFoundText {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: 10px 23px;
    font-family: "Open Sans";
    color: #3f526d;
    font-weight: 600;
  }

  .deleteTaskDialog .msgTitle {
    font-size: 18px;
  }
  
  .deleteTaskDialog .redMessage {
    font-size: 20px;
    color: red;
  }
  
  .deleteTaskDialog .dialogAction {
    padding-bottom: 20px;
    padding-right: 20px;
  }
  
  .deleteTaskDialog .deleteOkBtn {
    width: 50px;
    padding: 10px 0;
    border-radius: 6px;
    background: red;
    text-transform: capitalize;
    color: #fff;
    font-size: 16px;
    font-family: "Open sans";
  }
  
  .deleteTaskDialog .okCancelBtn {
      padding: 10px 0px;
      border-radius: 6px;
      text-transform: capitalize;
      color: #000;
      font-size: 16px;
      font-family: "Open sans";
  }