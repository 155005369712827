.TaskEvaluationMainDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 20px 0px
}

.TaskEvaluationGridContainerDiv {
    display: flex;
    justify-content: flex-end;
}

.TaskEvaluationSearchDiv {
    padding: 12px 20px 10px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    width: 100%
}

.TaskEvaluationSearchInput {
    border: none;
    outline: none;
    opacity: 0.8;
    font-size: 18;
    width: 100%;
    font-family: 'Open sans';
    font-weight: 600;
}

.TaskEvaluationSearchIcon {
    height: 22.3px;
    width: 22.3px;
}

.TaskEvaluationAlignIems {
    align-items: center;
}

.TaskEvaluationToTxt {
    font-family: 'Open sans' !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #3f526d !important;
}

.TaskEvaluationTableMainDiv {
    display: flex;
    flex-direction: column;
}


.TaskEvaluationTableContainer {
    border-radius: 6px;
    border: 0px;
}

.TaskEvaluationTableRow {
    width: 100%;
    background-color: #f9f8fd;
}

.TaskEvaluationMainTableCell {
    font-size: 14px !important;
    font-weight: 600 !important;
    border-bottom: 0px !important;

}

.TaskEvaluationW25 {
    width: 25%;
}

.TaskEvaluationW15 {
    width: 15%;
}

.TaskEvaluationW10 {
    width: 10%;
}

.TaskEvaluationW30 {
    width: 30%;
}

.TaskEvaluation20 {
    width: 20%;
}

.TaskEvaluationTextAlign {
    text-align: center;
}

.TaskEvaluationMainTableBody {
    margin-top: 10px;
}

.TaskEvaluationNoRecordFoundTxt {
    color: #3f526d !important;
    font-family: 'Open sans' !important;
    font-size: 18px !important;
    align-items: center !important;
    text-align: center !important;
}

.TaskEvaluationTableRowDiv {
    width: 100%
}

.TaskEvaluationViewAllTxt {
    cursor: pointer !important;
    text-decoration: underline !important;
    text-align: center !important;
}

.TaskEvaluationPaginationDiv {
    overflow-x: auto;
}