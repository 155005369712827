.popup-container{
    padding: 20px;
    background-color: #f8f9ff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 30px;
}

.reaction-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* grid-gap: 50px; */
}

.reaction{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.reaction-img{
    height: 150px;
    width: 150px;
    display: grid ;
    place-content: center;
}

@media only screen and (max-width: 450px) {
    .reaction-img{
        height: 120px;
        width: 120px;
    }

    .reaction-img .selectedImg {
        height: 100px;
        width: 100px
    }

    .reaction-img .unselectedImg {
        height: 60px;
        width: 60px
    }

    .reaction-container > div:first-child .unselectedImg  {
        height: 68px;
        width: 60px
        /* background-color: red; */
    }
}