.audioPlayer {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.currentTime,
.duration {
  font-family: monospace;
  font-size: 16px;
}

.currentTime {
  margin-left: 25px;
}

.progressBar {
  --bar-bg: #ffe3d4;
  --seek-before-width: 0;
  --seek-before-color: #4b8ffb;
  --knobby: #fff;
  appearance: none;
  background: var(--bar-bg);;
  border-radius: 10px;
  position: relative;
  width: 700px;
  height: 5px;
  outline: none;
}

/* progress bar - safari */
.progressBar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 5px;
  outline: none;
}

/* progress bar - firefox */
.progressBar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 5px;
  outline: none;
}

.progressBar::-moz-focus-outer {
  border: 0;
}

/* progress bar - chrome and safari */
.progressBar::before {
  content: '';
  height: 5px;
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

/* progress bar - firefox */
.progressBar::-moz-range-progress {
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 5px;
}

/* knobby - chrome and safari */
.progressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: relative;
  margin: -5px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 6px 0 #7000c5;
  background-color: var(--knobby);
}

/* knobby while dragging - chrome and safari */
.progressBar:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

/* knobby - firefox */
.progressBar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}

/* knobby while dragging - firefox */
.progressBar:active::-moz-range-thumb {
  transform: scale(1.2);
}

.audioContainer {
  margin-top: 32px !important;
  overflow-x: auto;
  border-bottom: 1px solid lightgray;
}

.audioNameDiv {
  font-size: 20px;
  font-weight: 600;
}

.audioName {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.audioDuration {
  font-size: 16px;
}

.audioPlayerGrid {
  display: flex;
  flex-direction: column;
}

.audioRange {
  width: 100%;
}

.durationDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.audioActions {
  display: flex;
  justify-content: center;
}