.admin_enroll_user_w_100 {
    width: 100%;
}
.admin_enroll_user_top_part {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    margin-top: 2%;
    justify-content: space-between;
}
.admin_enroll_user_top_part .left_side {
    display: flex;
    flex-direction: row;
    width: 40%;
    align-items: center;
}
.admin_enroll_user_top_part .left_side_heading {
    display: flex;
    justify-content: flex-start;
}
.admin_enroll_user_top_part .left_side_heading_typography {
    text-transform: none;
    font-size: 20px;
    font-weight: 600 !important;
    font-family: 'Open Sans' !important;
}
.admin_enroll_user_top_part .right_side {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
    padding-right: 20px;
}
.admin_enroll_user_top_part .right_side_upload_btn {
    padding: 11px 24px 11px 25px;
    border-radius: 6px;
    margin-right: 20px;
}
.admin_enroll_user_top_part .right_side_btn_typography {
    text-transform: none;
    font-size: 15px;
    font-weight: 600;
}
.admin_enroll_user_top_part .right_side_add_btn {
    padding: 11px 24px 11px 25px;
    border-radius: 6px;
}
.admin_enroll_user_tabs {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
}
.admin_enroll_user_tabs .tab_btn { 
    height: 39px;
    margin: 0px 10px 0px 0px;
    padding: 0px;
    min-width: 133px;
}
.admin_enroll_user_tabs .tab_btn_text { 
    text-transform: none;
    font-size: 15px;
    font-weight: 600;
}
.admin_enroll_user_listing_screen {
    display: flex;
    align-items: center;
    justify-content: space-between;
}