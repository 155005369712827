.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

.input_date_of_birth_css_format>input {
  padding: 14px;
  outline: none;
  border: 1px solid #ebebeb;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  position: relative;
  width: 90%;
  font-family: "Open sans";
}

.input_date_of_birth_css_format ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray;
  opacity: 0.8;
  /* Firefox */
}

.input_date_of_birth_css_error_format>input {
  padding: 14px;
  outline: none;
  border: 1px solid red;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  position: relative;
  width: 90%;
  font-family: "Open sans";
}

.StudentProfile.Date_Icon:focus {
  outline: none;
}

.StudentProfile.Date_Icon {
  background-repeat: no-repeat;
  /* background-image: url("../assets/dateCalendar.png");
    background-size: 33px 33px;
    background-position: top 8px right 12px; */
  background-image: url("../assets/dateCalendar.png");
  background-size: 30px 30px;
  background-position: top 9px right 12px;
  width: 100%;
  border-radius: 8px;
}

.profile_image_student {
  height: 140px;
  width: 140px;
  border-width: 5px;
  border-style: solid;
  border-color: #471c51;
  padding: 3px;
  border-radius: 50%;
}

.profile_avatar_student {
  height: 140px !important;
  width: 140px !important;
  border-width: 5px;
  border-style: solid;
  border-color: #471c51;
  padding: 3px;
}

.create_video_btn_student {
  border-color: #af38cb !important;
  padding: 11px 24px !important;
  border-radius: 8px !important;
  width: 50%;
  color: #af38cb !important;
  font-weight: bold !important;
  font-size: 22px !important;
  text-transform: capitalize !important;
}

.profile_info_label_student {
  font-size: 16px !important;
  /* color: #3f526d !important; */
  font-weight: 500 !important;
  margin-bottom: 20px;
  margin-top: 13px;
}

.student_update_btn {
  /* background: linear-gradient(to top, #af38cb, #471c51) !important; */
  border-radius: 8px !important;
  /* color: #fff !important; */
  width: calc(50% - 20px);
  height: 50px;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.academic_details_text {
  /* font-family: "Open sans" !important; */
  font-size: 20px !important;
  font-weight: 600 !important;
  /* color: #000; */
  padding-bottom: 32px;
}

.pt_50 {
  padding-top: 50px;
}

.create_video_modal {
  width: 40%;
  border-radius: 29px;
  border: solid 1px #979797;
  background-color: #fff;
}

.create_video_title {
  font-family: "Open sans" !important;
  font-size: 24px !important;
  font-weight: bold !important;
  text-align: center;
  color: #3f526d;
  margin-top: 30px !important;
  width: 100%;
  margin-left: 54px !important;
}

.close_icon {
  margin-top: 32px !important;
  margin-right: 32px !important;
}

.upload_file_dashed_block {
  margin: 20px 32px 10px 32px;
  padding: 20px;
  border-radius: 8px;
  border: dashed 1px #cadaf1;
}

.upload_file_text {
  margin: 16px 0px 4px 0 !important;
  font-family: "Open sans" !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #202020;
}

.upload_icon {
  position: relative;
  width: 62px;
  height: 62px;
  cursor: pointer;
}

.drag_and_drop_instruction {
  margin: 12px 0px 9px 0 !important;
  font-family: "Open sans" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  text-align: center;
  color: #959393;
  padding: 0px 33px;
}

.record_icon {
  position: relative;
  width: 80px;
  height: 82px;
}

.record_video_dashed_block {
  margin: 10px 32px 32px 32px;
  padding: 20px;
  border-radius: 8px;
  border: dashed 1px #cadaf1;
}

.record_your_video_text {
  margin: 16px 0px !important;
  font-family: "Open sans" !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #202020;
}

.video_length_text {
  margin: 0px 0px 26px 0 !important;
  font-family: "Open sans" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  text-align: center;
  color: #959393;
}

.divider_spacing {
  margin: 10px 32px !important;
}

.left_divider {
  margin: 10px 10px 10px 32px !important;
  width: 38%;
}

.right_divider {
  margin: 10px 32px 10px 10px !important;
  width: 38%;
}

.open_video_booth_btn {
  color: #af38cb !important;
  border-color: #af38cb !important;
  border-radius: 8px !important;
  font-size: 20px !important;
  width: 245px;
  height: 58px;
  border: solid 1px #af38cb !important;
  font-weight: 600 !important;
}

.my_badges_text {
  color: #3f526d;
  font-size: 36px !important;
  font-weight: bold !important;
  margin-top: 10px !important;
  font-family: "Open sans" !important;
}

.badge_img_modal {
  position: relative;
  width: 200px;
  height: 151px;
  margin-top: -110px !important;
}

.badge_attachment_img {
  height: 100px;
  object-fit: contain;
}

.badge_name {
  color: #253873;
  font-size: 16px !important;
  width: 80%;
  font-weight: 600 !important;
  font-family: "Open sans" !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.video_success_modal {
  width: 437px;
  height: 494px;
  border-radius: 29px;
}

.check_success_img {
  position: absolute;
  top: 10%;
  left: 35%;
}

.video_uploaded_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 337px;
  font-family: "Open sans" !important;
  font-size: 23px !important;
  font-weight: bold !important;
}

.fill_profile_detail_text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 319px;
  font-family: "Open sans" !important;
  font-size: 16px !important;
}

.success_done_btn {
  position: absolute !important;
  top: 80%;
  left: 27%;
  background-color: #fff !important;
  color: #af38cb !important;
  width: 200px;
  height: 50px;
  text-transform: capitalize !important;
  font-size: 22px !important;
  border-radius: 8px !important;
  font-weight: bold !important;
}

.delete_modal_main_div {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 29px;
  padding: 32px 18px;
  width: 576px;
}

.delete_modal_divider {
  height: 1px;
  margin: 14px 0 31px 0;
  border: solid 0.3px #979797;
}

.delete_title {
  font-family: "Open sans";
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #3f526d;
  margin-bottom: 14px;
}

.delete_msg_1 {
  font-family: "Open sans";
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3f526d;
  margin: 31px 0px 6px 0px;
}

.delete_msg_2 {
  font-family: "Open sans";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #959393;
  margin: 6px 0px 40px 0px;
}

.capture_photo_modal {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 29px;
  padding: 32px 18px;
  width: 50%;
}

.take_picture_title {
  font-family: "Open sans";
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #3f526d;
  margin-bottom: 14px;
}

.camera_icon {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.preview_uploaded_video_model {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 24px;
  border-radius: 10px;
  padding: 18px;
  height: 70%;
  width: 50%;
}

.preview_video_uploaded {
  /* height: "33px", */
  font-family: "Open sans" !important;
  font-size: 24px !important;
  font-weight: bold !important; 
  text-align: center;
  color: #3f526d;
  margin: 30px 50px 30px 0px !important;
}

.uploaded_video_height {
  height: 95%;
}

.back_text {
  font-family: "Open sans" !important;
  font-size: 20px !important;
  font-weight: bold !important; 
  color: #3f526d;
  text-transform: none;
}

.create_video_text_upload_modal{
  font-family: "Open sans" !important;
  font-size: 24px !important;
  font-weight: bold !important;
  text-align: center;
  color: #3f526d;
  padding: 0px 110px;
}

.upload_video_percentage_modal {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 29px;
  height: 460px;
  width: 576px;
  border: solid 1px #979797;  
  padding: 32px;                     
}

.upload_video_divider {
  margin-top: 10px !important;
}

.video_webcam_style {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.video_web_cam_modal {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 24px;
  border-radius: 10px;
  padding: 32px 18px;
  width: 50%;
  margin: 5% 0;
}

.create_video_text_webcam {
  font-family: "Open sans" !important;
  font-size: 24px !important;
  font-weight: bold !important;
  text-align: center;
  color: #3f526d;
  margin-right: 40px !important;
}

.notification_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0px 20px 20px;
}

.notification_header_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
}

.notification_header_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 45px;
}


.notification_header_icon {
  position: relative;
  width: 27px;
  height: 27px;
}

.notification_header_text {
  color: #3f526d;
  font-size: 16px !important;
}

.recent_log_activity {
  /* color: #253873; */
  font-size: 20px !important;
  /* font-family: "Open Sans" !important; */
  font-weight: 600 !important;
}

.add_desc_textfield {
  margin-top: 20px !important;
  width: 340px;
  min-height: 50px;
  margin-bottom: 20px !important;
  font-family: "Open Sans" !important;
  border-color: #ebebeb !important;
}

.update_all_btn {
  /* background: */
    /* linear-gradient(to top, #af38cb 101%, #471c51 33%); */
  /* color: #fff !important; */
  width: 342px;
  height: 58px;
  text-transform: capitalize !important;
  font-size: 18px !important;
  border-radius: 8px !important; 
  margin-top: 30px !important;
}

.file_uploaded_img {
  width: 62px;
  height: 62px;
}

.uploaded_file_name_field {
  width: 342px;
  height: 50px;
  padding-left: 20px !important;
  padding-bottom: 10px !important;
  border-radius: 8px;
  background-color: #fff;
}

.open_cancel_img_div {
  display: flex;
  position: absolute;
  left: 85%;
  top: 40%;
}

.doc_update_btn {
  width: 342px;
  height: 58px;
  margin: 14px 0 0 !important;
  border-radius: 8px !important;
  background: linear-gradient(to top, #af38cb 101%, #471c51 33%);
  font-family: "Open Sans" !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  text-align: center;
  color: #fff !important;
  text-transform: capitalize !important;
  border: none !important;
}

.file_name_textfield {
  width: 342px;
  border-color: #ebebeb !important;
}

.file_name_instruction {
  width: 342px;
  height: 40px;
  margin: 12px 0 14px !important;
  font-family: "Open Sans" !important;
  font-size: 12px !important;
  text-align: center;
  color: #959393;
}

.upload_doc_div {
  box-shadow: 0 8px 27px 0 rgba(224, 224, 224, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 30px;
  position: relative;
}

.document_box {
  box-shadow: 0 8px 27px 0 rgba(224, 224, 224, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 240px;
  margin: 40px 32px 40px 0;
  position: relative;
}

.upload_file_dashed_div {
  border: 1px dashed #cadaf1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 20px 20px 20px;
  padding: 40px;
  background: #f9f8fd;
}

.add_file_btn {
  position: absolute !important;
  cursor: pointer !important;
  top: 30px;
  right: 30px;
  z-index: 1;
  border-color: #af38cb !important;
  width: 187px;
  height: 52px;
  color: #af38cb !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  text-transform: capitalize !important;
}

.preview_video_modal {
  justify-content: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 29px;
  padding: 32px 18px;
  width: 1000px;
  height: 600px;
}

@media (max-width: 1279px) {
  .create_video_btn_student {
    width: 80%;
    font-size: 20px !important;
  }

  .create_video_modal {
    width: 60% !important;
  }

  .preview_video_modal {
    width: 80% !important;
    height: auto !important;
  }

  .my_badges_text {
    font-size: 32px !important;
  }

  .badge_name {
    width: 80%;
  }

  .video_web_cam_modal {
    width: 60% !important;
  }

  .capture_photo_modal {
    width: 60%;
  }

  .uploaded_video {
    width: 100%;
    height: fit-content;
  }

  .preview_uploaded_video_model {
    width: 60%;
    height: fit-content;
  }

  .preview_video_uploaded {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

}

@media (max-width: 959px) {
  .create_video_modal {
    width: 70% !important;
  }

  .upload_file_text {
    margin: 10px 0 !important;
  }

  .record_your_video_text {
    margin: 10px 0 !important;
  }

  .badges_width {
    width: 80% !important;
  }

  .my_badges_text {
    font-size: 30px !important;
  }

  .video_web_cam_modal {
    width: 70% !important;
  }

  .capture_photo_modal {
    width: 70%;
  }

  .preview_uploaded_video_model {
    width: 70%;
  }
}

@media (max-width: 599px) {
  .create_video_btn_student {
    width: 100%;
    padding: 7.5px 24px !important;
    font-size: 18px !important;
  }

  .xs_mb_0 {
    margin-bottom: 0 !important;
  }

  .academic_details_text {
    font-size: 18px !important;
  }

  .pt_50 {
    padding-top: 32px;
  }

  .student_update_btn {
    width: 60%;
  }

  .profile_image_student {
    height: 120px;
    width: 120px;
  }

  .profile_avatar_student {
    height: 120px !important;
    width: 120px !important;
  }

  .create_video_modal {
    width: 90% !important;
  }

  .open_video_booth_btn {
    font-size: 16px !important;
    width: 200px !important;
    height: 50px !important;
  }

  .drag_and_drop_instruction {
    padding: 0 !important;
    margin: 0 !important;
  }

  .upload_file_dashed_block {
    margin: 15px 15px 10px 15px;
    padding: 15px;
  }

  .record_video_dashed_block {
    margin: 10px 15px 20px 15px;
    padding: 15px;
  }

  .upload_icon {
    width: 45px;
    height: 45px;
  }

  .record_icon {
    width: 50px;
    height: 52px;
  }

  .video_length_text {
    margin-bottom: 10px !important;
  }

  .upload_file_text {
    font-size: 18px !important;
  }

  .record_your_video_text {
    font-size: 18px !important;
  }

  .create_video_title {
    font-size: 20px !important;
    margin-top: 20px !important;
  }

  .close_icon {
    margin-top: 20px !important;
    margin-right: 20px !important;
  }

  .divider_spacing {
    margin: 10px 20px !important;
  }

  .left_divider {
    margin: 10px 10px 10px 20px !important;
  }

  .right_divider {
    margin: 10px 20px 10px 10px !important;
  }

  .preview_video_modal {
    width: 90% !important;
    height: auto !important;
  }

  .preview_video_text {
    font-size: 20px !important;
  }

  .badges_width {
    width: 90% !important;
  }

  .my_badges_text {
    font-size: 26px !important;
  }

  .badge_img_modal {
    width: 150px;
    height: 115px;
    margin-top: -90px !important;
  }

  .badge_attachment_img {
    height: 80px;
  }

  .badge_name {
    width: 90%;
  }

  .video_web_cam_modal {
    width: 90% !important;
    padding: 20px 15px;
  }

  .video_success_modal {
    width: 260px;
    height: auto;
  }

  .check_success_img {
    top: 8%;
    height: 90px;
  }

  .video_uploaded_text {
    width: 90%;
    font-size: 18px !important;
  }

  .fill_profile_detail_text {
    top: 67%;
    width: 90%;
    font-size: 14px !important;
  }

  .success_done_btn {
    left: 23%;
    width: 150px;
    height: 40px;
    font-size: 20px !important;
  }

  .delete_modal_main_div {
    width: 90% !important;
    padding: 20px 15px;
  }

  .delete_modal_divider {
    margin: 14px 0 14px 0;
  }

  .delete_title {
    font-size: 20px;
  }

  .delete_msg_1 {
    font-size: 20px;
    margin: 20px 0px 6px 0px;
  }

  .delete_msg_2 {
    margin: 6px 0px 30px 0px;
  }

  .capture_photo_modal {
    width: 90%;
    padding: 20px 15px;
  }

  .take_picture_title {
    font-size: 20px;
  }

  .camera_icon {
    height: 75px;
    width: 75px;
  }

  .preview_uploaded_video_model {
    width: 90%;
  }

  .preview_video_uploaded {
    font-size: 20px !important;
  }

  .back_text {
    font-size: 16px !important;
  }

  .upload_video_percentage_modal{
    width: 90% !important;
    height: 400px;
    padding: 20px;
  }

  .create_video_text_upload_modal{
    font-size: 20px !important;
    padding: 0 0 0 12%;
  }

  .video_webcam_style {
    height: 300px;
  }

  .create_video_text_webcam {
    font-size: 20px !important;
  }

  .badge_scroll_view {
    margin-bottom: 10px !important;
    max-height: 150px;
    overflow: auto;
  }

  .notification_item {
    padding: 10px 0px 10px 10px;
  }

  .notification_header_icon {
    width: 22px;
    height: 22px;
  }
  
  .notification_header_text {
    font-size: 14px !important;
  }

  .notification_header_right {
    margin-left: 15px;
  }

  .recent_log_activity {
    font-size: 18px !important;
  }

  .add_desc_textfield {
    width: 100%;
  }

  .update_all_btn {
    width: 100%;
    height: 50px;
    margin-top: 15px !important;
  } 

  .xs_p_15 {
    padding: 15px !important;
  }

  .file_uploaded_img {
    width: 50px;
    height: 50px;
  }

  .uploaded_file_name_field {
    width: 100%;
    padding-left: 10px !important;
  }

  .open_cancel_img_div {
    left: 73%;
    top: 32%;
  }

  .doc_update_btn {
    width: 100%;
    height: 50px;
    margin: 14px 0 0 !important;
    font-size: 22px !important;
  }

  .file_name_textfield {
    width: 100%;
  }

  .file_name_instruction {
    width: 100%;
  }

  .document_box {
    margin: 30px 0px 30px 0;
  }

  .upload_file_dashed_div {
    padding: 20px 10px;
  }

  .add_file_btn {
    width: 130px;
    height: 45px;
    font-size: 16px !important;
  }
}