.fees-fw-600{
  font-weight: 600;
} 
.fees_button {
  width: 209px;
  height: 58px;
  margin: 23px 0 0 159px;
  padding: 13px 32.5px 15px;
  border-radius: 18px !important;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
}
  
@media only screen and (max-width: 1050px) {
}
  
@media only screen and (max-width: 1200px) {}
  