.FilterPeriodPopover {
    margin-top: 5px !important;
    height: auto;
}
.FilterPeriodPopover .searchBox {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    height: 40px;
    position: inherit;
    top: 0%;
    background: #f9f8fd;
}
.FilterPeriodPopover .searchIcon {
    height: 25px;
    width: 25px;
    margin-right: 24px;
    margin-left: 20px;
}
.FilterPeriodPopover .inputBox {
    border: none;
    outline: none;
    color: #3f526d;
    opacity: .5;
    font-size: 1.125rem;
    width: 100%;
    background: #f9f8fd;
}
.FilterPeriodPopover .listingBox {
    max-height: 200px;
    overflow-y: scroll;
}
.FilterPeriodPopover .noREcordFound {
    width: 176px;
    height: 27px;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-top: 10px;
    margin-bottom: 10px;
}
.FilterPeriodPopover .subjectData {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid .5px rgba(63, 82, 109, 0.34);
    cursor: pointer;
}
.FilterPeriodPopover .attrTypo {
    font-size: 18px;
    font-weight: 600;
}
.FilterPeriodPopover .checkboxStyled {
    background: transparent;
}
.FilterPeriodPopover .btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    align-items: center;
}
.FilterPeriodPopover .saveBtn {
    text-transform: capitalize;
    font-size: 18px;
    border-radius: 8px;
    padding: 5px 30px;
    margin: 0px 24px 0px 0px;
}
.FilterPeriodPopover .cancelBtn {
    text-transform: capitalize;
    font-size: 16px;
}
.FilterPeriodPopover .ml7 {
    margin-left: 7px;
}
.FilterPeriodPopover .flexCenter {
    display: flex;
    align-items: center;
}
.FilterPeriodPopover .avatarImg {
    height: 20px;
    width: 20px;
}