.report-top-section .MuiTypography-body1{
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  line-height: 36px;
  min-height:72px;
}

.report-card-text-title .MuiTypography-body1 {
  font-size: 32px;
  font-weight: 600;
}

.threeBox {
  margin-top: 20px;
}


.teacher_assessment_box {
  display: flex;
  margin: 30px 0;
}

.teacher_assessment_box .rightBtn {
  text-align: right;
}

.published-text {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 20px 0 10px 0 !important;
}

.report-top-section {
  background-size: cover;
  border-radius: 18px;
  min-height: 250px;
  display: flex;
  align-items: center;
  position: relative;
}

.admin-create-icon {
  width: 136px;
  height: 136px;
  object-fit: contain;
  margin: 0 0 0 -16px;
}

.back-text-report {
  font-size: 22px;
  font-weight: 600;
  margin: 18.5px 0px 0px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.report-cards-block {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 32px 32px 47px 32px;
}


.report-cards-spacing {
  padding-bottom: 32px;
}

.create-custom-report-card-text {
  font-size: 26px !important;
  font-weight: 600 !important;
  margin: 22px 0 32px 0 !important;
}

.report-step-text {
  font-size: 26px !important;
  font-weight: 500 !important;
  margin: 22px 0 !important;
}

.create-report-box {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px 32px 32px 32px;
  margin-bottom: 25px;
}

.report-detail-field {
  display: flex;
  flex-direction: column;
}

.report-detail-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.report-text-field {
  border-radius: 8px;
  padding: 10px;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.report-basic-text {
  font-size: 18px;
  font-weight: bold;
  margin: 59px 0 24px 0;
}

.report-bottom-btn-div {
  display: flex;
  justify-content: flex-end;
}

.start-to-create-btn {
  width: 200px;
  height: 60px;
  border-radius: 8px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.margin-btn-section {
  margin-top: 84px;
  margin-bottom: 20px;
}

.report-module-box {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 27px 33px 49px 23px;
  display: flex;
  flex-direction: column;
  height: 299px;
  justify-content: space-between;
}

.module-text {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
}

.module-desc-text {
  font-size: 18px;
  font-weight: bold;
  margin-top: 2px;
}

.module-edit-btn {
  width: 135px;
  height: 49px;
  border-radius: 6px !important;
  justify-content: space-around !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.module-detail-div {
  min-height: 30%;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.module-edit-details-btn {
  width: 160px;
  height: 40px;
  border-radius: 8px !important;
  border: solid 1px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.text-label-part-2 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  margin: 36px 0;
}

.text-label-part-2 .text-style-bold {
  font-weight: bold;
}

.co-scholastic-right-btn-div {
  margin-top: 18.5px;
}

.mr-23 {
  margin-right: 23px !important;
}

.plus-icon {
  cursor: pointer;
}

.add-column-text {
  font-size: 22px;
  font-weight: 600;
}

.name-text {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}

.popover-component {
  background: white;
  padding: 20px;
  margin: 3px;
  box-shadow: 0 2px 16px 0 rgba(98, 98, 98, 0.37);
  width: 540px;
}

.add-sub-column-btn {
  height: 40px;
  border-radius: 8px !important;
  border: solid 1px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 8px 20px !important;
}

.save-column-btn {
  width: 106px;
  height: 52px;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.subject-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-icon {
  cursor: pointer;
  margin-left: 5px;
}

.dropdown-report .MuiNativeSelect-icon {
  top: 0 !important;
  width: 50px;
  height: 50px;
  color: #3f526d;
  right: 0px;
}

.report-card-tabs {
  margin-left: -12px;
}

.report-card-tabs .MuiTab-textColorSecondary.Mui-selected {
  color: transparent;
}

.create-report-card-text {
  font-size: 24px !important;
  font-weight: 600 !important;
  margin: 31.5px 0 20px 0 !important;
}

.enter-grading-scale-text {
  margin: 72px 0px 48px 0;
  font-size: 26px;
  font-weight: 500;
}

.draft-screen-text {
  margin: 32px 0px 32px 0;
  font-size: 26px;
  font-weight: 500;
}

.add-grade-btn {
  height: 40px;
  width: 180px;
  border-radius: 8px !important;
  border: solid 1px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 8px 20px !important;
  margin-top: 42px !important;
}

.add-assessment-task-btn {
  height: 40px;
  width: auto;
  border-radius: 8px !important;
  border: solid 1px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 8px 20px !important;
  margin-top: 16px !important;

}

.grade-detail-title {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 14px;
}

.grade-text-field {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.term-text-field {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 14px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.grade-dialog-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.grade-create-btn {
  width: 160px;
  height: 50px;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.grade-bottom-btn-div {
  margin-top: 72px;
  padding-left: 15px;
}

.grade-scale-textfields {
  width: 95%;
}

.next-btn {
  width: 175px;
  height: 47px;
  border-radius: 8.8px !important;
  border: solid 1px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 12px 16px 11px 20px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.grade-details-div {
  max-height: 35vh;
  overflow: auto;
}

.grade-next-btn-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.term-textfield-div {
  width: 100%;
  padding-right: 40px;
  margin-bottom: 40px;
}


.create-report-card-textfield-div {
  width: 100%;
  padding-right: 40px;
  margin-bottom: 16px;
}

.pr-40 {
  padding-right: 40px;
}

.w-100 {
  width: 100%
}

.mb-16 {
  margin-bottom: 16px;
}

.term-divider {
  margin-bottom: 20px !important;
}

.add-grade-modal {
  padding: 32px 42px 52px 42px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.multi-dropdown-report .MuiSelect-icon {
  top: 0 !important;
  width: 50px;
  height: 50px;
  color: #3f526d;
  right: 0px;
}

.class-text {
  font-size: 18px;
  font-weight: 500;
  margin-top: -25px;
  margin-bottom: 20px;
}


.coloredIcon .MuiSvgIcon-root {
  height: 32px;
  width: 32px;
  color: #c1b8fd;
  opacity: 1;
  margin: 4px 16px 4px 16px;
}

.border-bottom-0 {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: 0 !important;
}

.border-radius-0 {
  border-top-left-radius:0px !important;
  border-top-right-radius:0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.add-assessment-btn {
  height: 40px;
  width: 190px;
  border-radius: 8px !important;
  border: solid 1px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 8px 12px !important;
  margin-top: 42px !important;
}

.done-btn {
  width: 175px;
  height: 47px;
  border-radius: 8.8px !important;
  border: solid 1px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 12px 16px 11px 20px !important;
  display: flex !important;
  justify-content: center !important;
}

.category-tab-div {
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  max-width: 50%;
  padding: 0 12px;
}

.category-tab {
  text-transform: capitalize !important;
  font-weight: bold !important;
  font-size: 18px !important;
  height: 72px;
}

.category-textfield-div {
  margin-top: 30px;
  margin-bottom: 10px;
}

.category-details-spacing {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-img-term {
  object-fit: contain;
  padding-top: 20px;
}

.alert-img {
  object-fit: contain;
  padding-top: 20px;
  padding-bottom: 20px;
}

.category-sub-field-text {
  margin: 20px 0px;
  font-size: 24px;
  font-weight: 500;
}

.category-divider {
  margin-top: 40px !important;
  margin-bottom: 30px !important;
}

.add-grade-divider {
  margin-top: 40px !important;
  margin-bottom: 20px !important;
  width: 105%;
  place-self: center;
}

.grade-name-text {
  margin: 40px 0px 20px 0;
  font-size: 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.grade-table-width {
  max-width: 80%;
}

.delete-grade-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-left: 20px;
  cursor: pointer;
}

.module-text-field {
  width: 100%;
}

.attribute-table-first-column {
  min-width: 320px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.create-evaluation-details-btn {
  width: 260px;
  height: 47px;
  border-radius: 8.8px !important;
  border: solid 1px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 12px 16px 11px 20px !important;
  justify-content: center !important;
  margin-bottom: 20px;
}

.input_date_of_birth_css_format_admin>input {
  padding: 14px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  height: 52px;
  border-radius: 4px;
  font-size: 16px;
  position: relative;
  width: 100%;
  font-family: "Open sans";
}

.input_date_of_birth_css_format_admin ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray;
  opacity: 0.8;
  /* Firefox */
}


.custom-card-title {
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 1279px) {
  .category-tab-div {
    max-width: 70%;
  }

  .module-text-field {
    width: 100%;
  }

  .grade-table-width {
    max-width: 90%;
  }

  .report-top-section .MuiTypography-body1{
    font-size:23px ;
    min-height: 54px;
    line-height: 29px;
  }
}

@media (max-width: 959px) {
  .grade-scale-textfields {
    width: 100% !important;
  }

  .category-tab-div {
    max-width: 80%;
  }

  .module-text-field {
    width: 100%;
  }

  .grade-table-width {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .teacher_assessment_box .rightBtn {
    text-align: left;
  }

  .report-cards-block {
    padding: 20px;
  }

  .report-top-section .MuiTypography-body1{
    min-height: inherit;
    font-size: 21px;
  }

  .admin-create-icon {
    width: 100px;
    height: auto;
  }

  .MobileHeader{
    margin: 0px 0;
    width: auto !important;
  }

}

@media (max-width: 599px) {
  .term-textfield-div {
    padding-right: 0px;
  }

  .create-evaluation-details-btn { 
    width: 100%;
  }

  .pr-40 {
    padding-right: 0px;
  }

  .grade-details-div {
    max-height: none;
    overflow: hidden;
  }

  .add-grade-modal {
    padding: 20px 15px;
  }

  .grade-bottom-btn-div {
    margin-top: 30px;
  }

  .module-edit-details-btn {
    width: 200px;
  }

  .popover-component {
    width: 95%;
  }

  .category-tab-div {
    max-width: 100%;
  }

  .add-grade-btn {
    font-size: 14px !important
  }

  .add-assessment-task-btn {
    font-size: 14px !important
  }

  .module-text-field {
    width: 100%;
  }

  .attribute-table-first-column {
    min-width: auto;
  }
}