.MainModal{
    display: flex;
    align-items: center;
    justify-content: center;
}


.MainModal .MainBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    padding: 40px 32px;
    width: 600px;
}

.MainBox .TitleBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.TitleBox .TitleText {
    font-size: 45px;
    font-weight: 600;
    padding-bottom: 32px;
}

.MainBox .MessageGrid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 16px;
}

.MessageGrid .MessageText{
    font-size: 24px;
    font-weight: 600;
}

.MessageGrid .SubTitleText{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 1279px) {

  .TitleBox .TitleText {
    font-size: 42px;
  }
}

.MainBox .ActionButton{
    width: 250px;
    height: 60px;
    font-size: 18px;
    font-weight: 600;
    text-transform: none;
}


@media (max-width: 959px) {

    .TitleBox .TitleText {
        font-size: 40px;
    }
}


@media (max-width: 767px) {
}

@media (max-width: 599px) {
    .MainBox{
        width: 90%;
        padding: 20px;
    }

    .TitleBox .TitleText {
        font-size: 34px;
        padding-bottom: 10px;
    }

    .MainBox .ActionButton{
        width: 200px;
        height: 50px;
    }
}