.ALBackGrid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.ALBackGrid .BackText {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
}

.ALBackGrid .BackIcon {
    margin-right: 10px;
}

.LibraryAssessmentListContainer {
    margin: 30px 10px 0 5px;
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    padding: 20px 13px 40px 15px;
}

.ALFilterGrid {
    font-size: 20px;
}

.AssessmentListGrid {
    margin-top: 4px;
}

.AssessmentListGrid .AssessmentTitle {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.JustifyStart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.JustifyEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.AssessmentListGrid .TeacherInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LibraryAssessmentListContainer .SubjectTextColor {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.LibraryAssessmentListContainer .SubjectGrid{
    margin-bottom: 16px;
    margin-left: 8px;
}   

.AssessmentListGrid .TeacherName {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 20px;
}

.AssessmentListGrid .AvatarSpan {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 2%;
}

.AssessmentListGrid .Avatar {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    object-fit: contain;
}

.AssessmentListGrid .IconsBorder {
    border: 1px solid #3f526d;
    padding: 2%;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.AssessmentListGrid .PreviewQuestion {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
}

.AssessmentListGrid .ActionIcon {
    height: 40px;
    width: 40px;
    object-fit: contain;
}

.SelectTitle {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.ALFilterGrid .FilterBtn {
    text-transform: capitalize;
    width: 180px;
    height: 58px;
    padding: 18px 36px;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
}

.ALFilterGrid .FilterBtn:disabled.BtnDisabled {
    text-transform: capitalize;
    width: 180px;
    height: 58px;
    padding: 18px 36px;
    border-radius: 6px;
    font-size: 16px;
    opacity: 0.5;
    color: #fff;
}

.ALFilterGrid .ApplyFilterGrid {
    margin-top: 16px;
}

.ClearFilter {
    margin-right: 12px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
}

.MarginTop {
    margin-top: 1% !important;
}

.ALFilterGrid .DropdownLabels {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-left: 4px;
}

.LoadMoreBtnDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
}

.LoadMoreBtnDiv .LoadMoreBtn {
    padding: 1%;
    font-size: 16px;
    text-transform: capitalize;
    width: 150px;
    height: 48px;
    font-weight: 600;
}

.SearchGrid{
    margin: 0px 8px;
}

.SearchGrid .BoxStyle{
    display: flex;
    align-items: center;
    padding: 8px;
    height: 60px;
    width: 100%;
    border-radius: 8px;  
}

.SearchGrid .SearchIcon{
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-right: 5px;
}

.SearchGrid .SearchInput{
    border: none;
    outline: none;
    margin-left: 10px;
    opacity: 0.45;
    font-size: 18px !important;
    width: 100%;
    padding-right: 10px;
    font-weight: 600 !important
}
.AccordionStyles .MuiAccordionSummary-root {
    padding: 0 0 !important;
  }
.AccordionStyles .MuiAccordionDetails-root {
    padding: 0 0 0 !important;
}
.AccordionStyles .MuiAccordion-root:before {
    opacity: 0 !important;
}
.AccordionStyles .MuiAccordionSummary-content {
    margin: 0 0 !important;
}
.horizontalLine {
    margin-top:20px !important;
    margin-bottom: 0px !important;
    border-top: 1px solid #eee !important;
    display:block !important;
    width: 100% !important;
}
.horizontalLine2 {
    margin-top:20px !important;
    margin-bottom: 0px !important;
    border-top: 1px solid #eee !important;
}
.AccordionStyles .MuiAccordion-root.Mui-expanded {
    margin: 0px 0px !important;
}
.AccordionStyles .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
}