.studentAssessmentAnswer_Header {
    height: 80px;
}

.studentAssessmentAnswer_MainDiv {
    margin-top: 20px !important;
    width: 100% !important;
    height: auto !important
}

.studentAssessmentAnswer_QuestionBox {
    margin-right: 20px;
    margin-left: 20px;
}

.studentAssessmentAnswer_SkipQuestionBox {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-top: 50px;
    color: red;
}

.studentAssessmentAnswer_AttachFileBtn {
    display: flex;
    flex-direction: row;
    margin-top: 40px !important;
    align-items: center;
    justify-content: space-between;
}

.studentAssessmentAnswer_NotAttachFileBtn {
    display: flex;
    flex-direction: row;
    margin-top: 40px !important;
    align-items: center;
    justify-content: flex-end;
}

.studentAssessmentAnswer_AttachBtnDiv {
    display: flex;
    align-items: center;
}

.studentAssessmentAnswer_AttachBtn {
    display: flex;
    flex-direction: row;
    padding: 12px 30px 12px 20px;
    border-radius: 6px;
    background-color: #f9f8fd;
    text-transform: none
}

.studentAssessmentAnswer_AttachBtnText {
    opacity: 0.68;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-right: 10px;
}

.studentAssessmentAnswer_AttachBtnImg {
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.studentAssessmentAnswer_AnswerDiv {
    overflow-y: auto;
    display: flex;
    flex-direction: row;
}

.studentAssessmentAnswer_AnswerFileDiv {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.studentAssessmentAnswer_AttachFileName {
    opacity: 0.68;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.studentAssessmentAnswer_AnswerCloseBtn {
    padding: 9px 4px 9px 4px;
    background-color: #fff;
    text-transform: none
}

.studentAssessmentAnswer_AttachFileCloseIcon {
    font-size: 24px !important;
    color: rgba(63, 82, 109, 0.3);
    cursor: pointer;
}

.studentAssessmentAnswer_AnswerFileNameDiv {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.studentAssessmentAnswer_markForRevDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.studentAssessmentAnswer_markForRevCheckBox {
    padding: 0px 10px 0px 0px !important;
    background-color: transparent !important;
}

.studentAssessmentAnswer_markForRevTxt {
    text-transform: none;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.studentAssessmentAnswer_SubmitExamBtn {
    display: flex;
    justify-content: space-between !important;
    margin-top: 23px !important;
    align-items: center;
}

.studentAssessmentAnswer_BottomDiv {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}

.studentAssessmentAnswer_paginationDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.studentAssessmentAnswer_PrevBtn {
    padding: 14px 19px !important;
    border-radius: 8px !important;
    margin-right: 20px !important;
    cursor: pointer !important;
}

.studentAssessmentAnswer_NextBtn {
    padding: 14px 19px !important;
    border-radius: 8px !important;
    cursor: pointer !important;
}
.studentAssessmentAnswer_submitBtn {
    text-transform: none !important;
}