.SubjectAssessmentMainGrid .ClassBox {
    padding: 0px;
}

.SubjectAssessmentMainGrid .ListBox {
    padding: 0px;
    margin-top: 30px
}


.ClassBox .ClassDropDownTitle {
    padding: 15px 0px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.ListBox .NoRecordText {
    font-size: 18px;
}

.ListBox .ListItem {
    margin-top: 10px
}

.ListBox .ListItem .SubjectName {
    font-size: 22px;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-transform: capitalize;  
    font-stretch: normal;
    font-style: normal;  
    line-height: normal;
    letter-spacing: normal;
}

.ListBox .ListItem .TotalAssessments {
    font-size: 18px;
    font-weight: 600;
    min-width: 80%;
    display: flex;
    justify-content: flex-start;
    font-stretch: normal;
    font-style: normal;  
    line-height: normal;
    letter-spacing: normal;
}

.ListBox .ListItem .ImageDiv {
    text-align: center;
}

.SubjectAssessments {
    background-color: #f1f4ff;
    padding: 19px 20px 20px 50px;
    border-radius: 6px;
    align-items: center;
}

.ImageDiv .IconButton {
    padding: 0px 7px;
}

.TotalAssessmentGrid{
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 959px) {
    .ListBox .ListItem .TotalAssessments {
        min-width: 100%;
    }
}

@media (max-width: 599px) {
    .SubjectAssessments {
        padding: 19px 20px 20px 20px;
    }
}