.schedule_container {
    margin-top: 32px !important;
}
.schedule_container .main_box {
    width: 100%;
    background-color: white;
    border-radius: 8px;
    margin: 0px 2px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    position: relative;
}
.schedule_container .head_part {
    display: flex;
}
.schedule_container .head_text {
    font-size: 16px;
    text-transform: capitalize;
    opacity: 0.5;
}
.schedule_container .calender_part {
    margin-top: 2%;
    width: 100%;
    overflow: auto;
    height: 100%;
}
.schedule_container .calender {
    max-height: 120vh !important;
    overflow-x: auto !important;
}
.schedule_container .calender2 {
    height: 120vh !important;
    overflow-x: auto !important;
}

/* Sidebar part */
.sidebar_widgets_container {
    margin-top: 30px !important;
}
.sidebar_widgets_container .flex {
    display: flex;
}
.sidebar_widgets_container .widget_box {
    border-radius: 16px;
    padding: 14px 0px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}
.sidebar_widgets_container .bg1 {
    background-color: #faf1cd;
}
.sidebar_widgets_container .bg2 {
    background-color: #def0c1;
}
.sidebar_widgets_container .bg3 {
    background-color: #dfeef9;
}
.sidebar_widgets_container .bg4 {
    background-color: #d2d4fb;
}
.sidebar_widgets_container .bg5 {
    background: linear-gradient(to bottom, #ffeffe, #ffdbfc);
}
.sidebar_widgets_container .widget_box_img {
    height: 90px;
    width: 90px;
    margin-bottom: 24px;
}

/* Bottom part */
.bottom_content_container {
    margin-top: 20px !important;
    margin-bottom: 50px !important;
}
.bottom_content_container .card_box {
    width: 100%;
    padding: 24px 24px 24px;
    border-radius: 16px;
    max-height: 85vh;
    overflow-y:auto;
    overflow-x:hidden;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}
.bottom_content_container .card_head_part {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.bottom_content_container .view_link_typo {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}
.bottom_content_container .no_data_found_box {
    width: 100%;
    text-align: center;
    margin-top: 7%;
}
.bottom_content_container .no_data_found_text {
    font-family: "Open sans";
    opacity: 0.5;
    font-size: 22px;
    font-weight: 600;
    color: #3f526d;
}
.bottom_content_container .card_data { 
    padding: 20px 26px 27px 26px;
    border-radius: 16px;
    border: solid 1px #ecf1f6;
    background-color: #f7fcff;
    margin-bottom: 25px;
}
.bottom_content_container .data_attr_title {
    margin-bottom: 12px;
    font-family: "Open sans";
    color: #327095;
    font-weight: 500;
    font-size: 24px;
}
.bottom_content_container .attribute_text {
    font-family: "Open sans";
    color: #b2bac6;
    font-size: 16px;
}
.bottom_content_container .data_list_item {
    display: flex;
    margin-top: 16px;    
}
.bottom_content_container .data_list_item_typo {
    font-size: 18px;
    color: #677788;
}
.bottom_content_container .data_list_item_text {
    font-weight: 500;
    color: #394052;
    font-size: 18px;
}
.bottom_content_container .data_list_item_status {
    font-size: 18px;
    font-weight: 500;
}
.bottom_content_container .border_top_rgba {
    border-top: 1px solid rgba(178, 186, 198, 0.32);
}
.bottom_content_container .my {
    margin-top: 10px;
    margin-bottom: 19px;
    align-items: center;
}
.bottom_content_container .img_part {
    width: 60px;
    height: 60px;
}
.bottom_content_container .box {
    padding: 7px 20px 6px;
    border-radius: 20px;
    background-color: rgba(23, 123, 28, 0.32);
}
.bottom_content_container .box_text {
    font-size: 20px;
    font-weight: bold;
    font-family: "Open sans";
    color: #177b1c;
}
.bottom_content_container .status_box {
    height: 40px;
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.bottom_content_container .status_box_text {
    font-size: 20px;
    font-weight: bold;
    font-family: "Open sans";
}
/* Common */
.mt_25 {
    margin-top: 25px !important;
}
.flex_justify_center{
    display: flex !important;
    justify-content: center !important;
}
.flex_align_center{
    display: flex !important;
    align-items: center !important;
}
.flex_justify_end {
    display: flex !important;
    justify-content: flex-end !important;
}
.flex_center_end {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
}
.mb_20 {
    margin-bottom: 20px !important;
}
.mb_16 {
    margin-bottom: 16px !important;
}
.mb_25 {
    margin-bottom: 25px !important;
}
.fs_16 {
    font-size: 16px !important;
}
.fs_20 {
    font-size: 20px !important;
}
.fw_500 {
    font-weight: 500 !important;
}
.border_top {
    border-top: 1px solid #ecf1f6 !important;
}