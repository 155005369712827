.reminder_block {
    overflow-x: auto;
    display: flex;
}

.no_reminder_img_block{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 7%;
}

.no_reminder_img_block img{
    height: 273px;
}

.img_object_fit{
    object-fit: contain;
}

.justify_center{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no_content_text{
    opacity: 0.5;
    font-weight: 600;
    color: #3f526d;
    font-size:"22px !important"
}

@media only screen and (max-width: 767px) {
    .no_reminder_img_block img{
        width: 100%;
    }
  }