.result-main-grid{
    border: 1px sold #4d4d4d !important;
    border-radius: 8px !important;
    padding: 24px !important;
}

.result-title-text{
    font-size: 22px !important;
    font-weight: 600 !important;
}

.result-option-grid{
    margin-top:16px !important;
}

.result-flex-start{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.result-option-item{
    margin-top:4px !important;
    padding-left: 8px !important;
}

.result-fw-600{
    font-weight: 600 !important;
}
.result-flex-end{
    display: flex;
    justify-content: flex-end;
}
.result-student-marks{
    border-radius: 8px !important;
    padding: 16px 24px !important;
    margin-top: 24px !important;
    background-color: rgb(255, 238, 202);
    display: flex !important;
    justify-content: space-between;
}
.result-table-container{
    max-height:160px;
    margin-top: 24px !important;
    white-space: nowrap;
    background-color: #f9f8fd;
    padding: 16px 12px 17px 16px;
    border-radius: 13px;
}

.result-table-header-cell{
    opacity: 0.6;
}

.result-table-cell{
    border:0px !important;
    padding:8px !important;
    font-size: 12px !important;
    font-weight: bold !important;
}
.result_dropdwon {
    width: 212px !important;
}
@media only screen and (max-width: 598px) {
    .result-student-marks{
        flex-direction: column;
    }
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 1023px) {
}

@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1550px) {
    .result_dropdwon {
        width: auto !important;
    }
}