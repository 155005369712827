.membersParentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding: 20px 7px;
}
.membersParentContainer .searchBoxContainer {
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px;
}
.membersParentContainer .textBox {
    border: none;
    outline: none;
    margin-left: 10px;
    opacity: 0.45;
    width: 100%;
    padding-right: 10px;
    font-weight: 600;
}
.membersParentContainer .searchIcon {
    height: 22.3px;
    cursor: pointer;
    width: 22.3px;
}
.membersParentContainer .adminTeacherArrowContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: 14px;
    align-items: center;
    margin-top: 10px;
}
.membersParentContainer .viewAllBox {
    display: flex;
    flex-direction: row;
    align-self: end;
    align-items: center;
    cursor: pointer;
}
.membersParentContainer .heading {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.membersParentContainer .horizontalLine {
    margin: 0;
    border: none;
    border-bottom: 0.5px solid #c6c6c6;
    margin-bottom: 20px;
    margin-right: 10px;
}
.membersParentContainer .noRecordText {
    width: 176px;
    height: 27px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-stretch: normal;
}
.membersParentContainer .flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.membersParentContainer .w100 {
    width: 100% !important;
}
.mt20 {
    margin-top: 20px !important;
}
.membersParentContainer .p10 {
    padding: 10px;
}
.membersParentContainer .loadMoreBox {
    padding: 10px;
    display: flex;
    justify-content: center;
}
.membersParentContainer .loadMoreButton {
    border-radius: 8px;
    max-width: 150px;
    height: 40px;
    text-transform: capitalize;
    margin-right: 12px;
}
.membersParentContainer .filterBoxMob {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 14px;
    margin-top: 10px;
    align-items: center;
}
.membersParentContainer .upDownArrows {
    margin-top: 5px;
    align-self: center;
    align-items: center;
    cursor: pointer;
}
.membersParentContainer .titleText {
    height: 27px;
    margin-top: 10px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}
.membersParentContainer .count {
    margin-top: 13px;
    margin-left: 10px;
    font-family: "Open sans";
}
.membersParentContainer .filterButton {
    border-radius: 8px;
    max-width: 200px;
    height: 40px;
    text-transform: capitalize;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.filter_box .filtersHead {
    text-transform: none !important;
    font-weight: 600 !important;
}
.filter_box .divider {
    margin: 8px 0 !important;
}
.filter_box .filterByBox {
    align-self: center;
}
.filter_box .filterValueContainer {
    margin-top: 0.625rem !important;
    cursor: pointer !important;
}
.filter_box .filterValues {
    text-align: start !important;
    border: solid .3px rgb(112 123 139 / 50%) !important;
    display: flex !important;
    justify-content: space-between !important;
    border-radius: 4px !important;
    background-color: #f9f8fd !important;
    align-items: center !important;
    text-transform: capitalize !important;
}
.membersParentContainer .flexRow {
    display: flex;
    flex-direction: row;
}
.membersParentContainer .flex {
    display: flex;
}
.membersParentContainer .popOver {
    height: auto;
}
.option_menu .optionsBox {
    padding: 0px 0px 5px 0px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    border-bottom: solid .5px rgba(63, 82, 109, .34);
    cursor: pointer;
}
.option_menu .optionsBoxTypo {
    font-weight: 600 !important;
    overflow: hidden;
    white-space:nowrap;
}
.membersParentContainer .transparentBg {
    background-color: transparent;
}
.membersParentContainer .noDataBox {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.option_menu .btnContainer {
    display: flex !important;
    flex-direction: row !important;
    justify-content: start !important;
    margin-top: 20px !important;
    align-items: center !important;
}
.option_menu .okButton {
    border-radius: 5px !important;
    max-width: 110px !important;
    text-transform: capitalize !important;
    margin-right: 12px !important;
}
.filter_box .cancelButton {
    border-radius: 5px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    margin-left: 5px !important;
    border-style: solid !important;
    border-width: 0.5px !important;
    max-width: 110px !important;
}
.filter_box .applyBtn {
    border-radius: 5px !important;
    max-width: 110px !important;
    text-transform:capitalize !important;
    margin-right: 12px !important;
}
.filter_box .pt15 {
    padding-top: 15px !important;
}
.membersParentContainer .pt15 {
    padding-top: 15px !important;
}
.membersParentContainer .searchBox {
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 15px;
    padding: 1% 1.2% 1% 1.7% !important;
}
.membersParentContainer .inputBox {
    border: none;
    outline: none;
    color: #484848;
    opacity: 0.45;
    font-size: 1rem;
    font-weight: 600;
    width: 100% !important;
    padding-right: 1% !important;
    padding-left: 1% !important;
}
.membersParentContainer .filterBox2 {
    padding-bottom: 14px;
    align-items: center;
}
.membersParentContainer .arrowImgBox {
    height: 25px;
    width: 25px;
    margin-top: 22px;
    padding-right: 6px;
}
.membersParentContainer .rightArrow {
    height: 25px;
    width: 25px;
    margin-top: 3px;
    position: relative;
    top: -2px;
    padding-right: 6px;
}
.membersParentContainer .headingTitle {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.membersParentContainer .w650 {
    min-width: 650px;
}
.membersParentContainer .bgColor {
    background-color: #f3f1ff;
}
.membersParentContainer .w15 {
    width: 15px;
}
.membersParentContainer .checkboxColor {
    color: #7f6cfc;
}
.membersParentContainer .avatarImage {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: 3px solid #907dfc;
}
.membersParentContainer .nameAttr {
    text-transform: none;
    font-size: 16px;
    margin-left: 10px;
    color: #3f526d;
    font-weight: 600;
    font-family: 'Open sans';
}
.membersParentContainer .roleAttr {
    text-transform: none;
    font-size: 14px;
    font-family: 'Open sans';
    color: #907dfc;
    font-weight: 600;
}
.membersParentContainer .genderAttr {
    text-transform: none;
    font-size: 14px;
    font-family: 'Open sans';
    color: black;
}