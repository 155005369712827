.studentAssessmentSummary_ExamTxt {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-top: 20px !important;
    text-transform: capitalize !important
}

.studentAssessmentSummary_backIcon {
    height: 14px !important;
    width: 18px !important;
    object-fit: contain;
    margin-right: 10px !important;
    cursor: pointer!important;
}

.studentAssessmentSummary_titleDiv {
  padding: 24px 24px 30px 24px;
  height: 100%;
  min-height: 420px;
  margin: 20px 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
}

.studentAssessmentSummary_titleTxt {
    font-size: 20px !important;
    font-weight: 600 !important;
}

.studentAssessmentSummary_subtitleDiv {
  margin-top: 10px;
}

.studentAssessmentSummary_subtitleTxt {
  margin: 10px 0px;
  font-weight: 600 !important;
}

.studentAssessmentSummary_assesmentNameDiv {
  margin: 7px 0px;
}

.studentAssessmentSummary_assesmentNameTxt {
  font-weight: 600 !important;
  margin: 10px 0px;
}

.studentAssessmentSummary_assesmentNameTopDiv {
  margin-top: 7px;
}

.studentAssessmentSummary_checkboxTxt {
    font-size: 15px !important;
    margin-left: 30px !important;
}

.studentAssessmentSummary_questionPaperDesc {
    margin-top: 10px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.studentAssessmentSummary_txtDiv {
  display: flex;
  margin: 10px 0px 20px;
  font-weight: 600 !important;
}

.studentAssessmentSummary_txtDivImg {
    height: 22px;
    width: 22px
}

.studentAssessmentSummary_txtDivTxt {
  font-weight: 600 !important;
  margin-left: 20px !important;
}

.studentAssessmentSummary_textField {
  width: 40%;
  text-transform: capitalize;
}

.studentAssessmentSummary_assesmentNameSubDiv {
  display: flex;
  margin-top: 10px;
}

.studentAssessmentSummary_assesmentBtnDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.studentAssessmentSummary_activeBtn {
  border-radius: 8px;
  width: 150px;
  opacity: 1;
}

.studentAssessmentSummary_disabledBtn {
  border-radius: 8px;
  width: 150px;
  opacity: 0.5;
}

.studentAssessmentSummary_assesmentBtnTxt {
    font-family: 'Open sans';
    font-size: 15px !important;
    font-weight: 600 !important;
    text-align: 'center' !important;
    color: #fff;
    text-transform: none !important;
    padding: 6px 24px !important
}
