.ViewClassPeriodDialogBox {
    border-radius: 8px;
}
.ViewClassPeriodDialogBox .InnerBox {
    padding: 5%;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.ViewClassPeriodDialogBox .FlexBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ViewClassPeriodDialogBox .DataTypo {
    color: #3f526d;
    font-size: 1.5rem;
    font-weight: 600;
}
.ViewClassPeriodDialogBox .CloseIcon {
    cursor: pointer;
    font-size: 38px;
    color: lightgray;
}
.ViewClassPeriodDialogBox .ContentBox {
    height: fit-content;
    width: 100%;
    border-radius: 8px;
    background: #7f6cfc;
    display: flex;
    margin: 20px 0;
    padding: 2% 0;
}
.ViewClassPeriodDialogBox .ContentInnerBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid white;
    padding-right: 20px;
}
.ViewClassPeriodDialogBox .ContentTypo {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-left: 20px;
}
.ViewClassPeriodDialogBox .ContentTypo2 {
    font-size: 18px;
    color: #fff;
    margin-left: 20px;
}
.ViewClassPeriodDialogBox .DateTimeContentTypo {
    font-size: 18px;
    color: #3f526d;
    margin-left: 10px;
}
.ViewClassPeriodDialogBox .DateTimeContent {
    display: flex;
    flex-direction: row;
    padding: 12px 0;
}
.ViewClassPeriodDialogBox .Img {
    height: 24px;
    width: 24px;
}
.ViewClassPeriodDialogBox .FlexCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ViewClassPeriodDialogBox .ButtonContainer { 
    margin-top: 2rem;
}
.ViewClassPeriodDialogBox .ButtonContainer .BtnStyle {
    padding: 8px;
    border-radius: 8px;
    border-radius: 1px solid #7f6cfc;
    color: #7f6cfc;
    background: #fff;
    width: 120px;
}
.ViewClassPeriodDialogBox .BtnTypo {
    text-transform: none;
    font-size:1.125rem;
    font-weight: 600;
    color: #7f6cfc;
}
.ViewClassPeriodDialogBox .Ml2 {
    margin-left: 2%;
}
.ViewClassPeriodDialogBox .Fw600 {
    font-weight: 600;
}