.submitPaperModal_mainModal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.submitPaperModal_mainBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    padding: 4px 4px;
    width: 600px
}

.submitPaperModal_closeIconDiv {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
    margin-bottom: 0px;
    padding: 0px
}

.submitPaperModal_imgIconDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 8px;
    padding-right: 32px
}

.submitPaperModal_modalImg {
    height: 150px;
    width: 150px;
    z-index: 1
}

.submitPaperModal_examIcon {
    height: 150px;
    width: 180px;
    margin-bottom: 72px;
    position: absolute;
    z-index: 2;
    object-fit: contain
}

.submitPaperModal_msgDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 32px;
    padding-bottom: 8px;
    padding-right: 32px
}

.submitPaperModal_msgTitle {
    font-size: 24px !important;
    font-family: "Open sans";
    font-weight: 600 !important;
    color: #3f526d
}

.submitPaperModal_questionDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 32px;
    padding-bottom: 16px;
    padding-right: 32px
}

.submitPaperModal_questionTitle {

    font-size: 18px !important;
    font-family: "Open sans";
    font-weight: 600 !important;
    color: #3f526d
}

.submitPaperModal_btnDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 8px;
    padding-left: 32px;
    padding-bottom: 24px;
    padding-right: 32px
}

.submitPaperModal_subBtnDiv {
    margin-top: 4%;
    display: flex;
}

.submitPaperModal_subBtnTxt {
    text-transform: none !important;
}

.submitPaperModal_ReUploadBtn {
    margin-left: 15px !important;
    margin-right: 15px !important;
    padding: 13px 38.5px 13px 39.5px;
}