
.paperSubmittedModal_mainModal {
    display: flex;
    justify-content: center;
    align-items: center;
}
.paperSubmittedModal_subDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 4px;
    width: 700px;
}
.paperSubmittedModal_congratsDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 32px;
    margin-right: 32px;
    padding-top: 0px;
    margin-top: 16px;
}
.paperSubmittedModal_congratsTxt {
    font-size: 30px !important;
    font-family: 'Open sans';
    font-weight: bold !important;
    margin-top: 0px;
    padding-top: 0px;
    color: #3f526d;
}
.paperSubmittedModal_thumbImgDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 8px;
    padding-left: 32px;
    padding-right: 32px;
}
.paperSubmittedModal_thumbBackgroundImg {
    width: 150px;
    height: 150px;
    z-index: 1;
}
.paperSubmittedModal_thumbMainImg {
    width: 180px;
    height: 150px;
    object-fit: contain;
    position: absolute;
    z-index: 2;
    margin-bottom: 50px;
    margin-right: 50px;
}
.paperSubmittedModal_msgDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 8px;
    padding-left: 32px;
    padding-right: 32px;
}
.paperSubmittedModal_msgTxt {
    font-size: 24px !important;
    font-family: 'Open sans';
    font-weight: 600 !important;
    color: #3f526d;
}
.paperSubmittedModal_btnDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 24px;
    padding-left: 32px;
    padding-right: 32px;
}
.paperSubmittedModal_subBtnDiv {
    display: flex;
    margin-top: 4%;
}
.paperSubmittedModal_btnTxt {
    text-transform: none !important;
}
.paperSubmittedModal_okayBtn {
    margin-left: 15px !important;
    margin-right: 15px !important;
    padding: 13px 47.5px !important;
}