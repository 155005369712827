.Header {
  height: 75px;
  box-shadow: 0 2px 4px 0 rgba(216, 216, 216, 0.5);
  padding: 0 35px;
  align-items: center;
}
.gray-placeholder::placeholder {
  opacity: 0.45;
  color: #484848 !important;
}
.centerDiv {
  align-items: center;
  height: 100%;
}

.Avtar-Img {
  margin-left: 46px;
  margin-right: 27px;
}

.centerDiv {
  align-items: center;
  height: 100%;
}

.MuiSvgIcon-root {
  position: relative;
  right: 0;
}

.Radio-Button .MuiSvgIcon-root {
  right: 0px;
  width: 1.8em !important;
  height: 1.8em !important;
}

.Emai-Row {
  align-items: center;
  max-width: 623px;
  margin-bottom: 15px !important;
}

.blockText {
  width: 51%;
  text-align: start;
  font-family: 'Open sans';
  font-size: 24.1px;
  font-weight: bold;
  /* font-stretch: normal; */
  /* font-style: normal; */
  /* line-height: normal; */
  /* letter-spacing: normal; */
  color: rgb(255, 255, 255);
  position: relative;
  vertical-align: middle;
}

.informationTextLabel {
  font-size: 18px;
  color: rgb(255, 255, 255);
  font-family: 'Open sans';
  font-weight: normal;
  line-height: 1.17;
  margin-top: 10px;
}

.arrowStyleButtonLeft {
  border-radius: 8.8px !important;
  /* padding: 6px !important;
  margin: 10px !important; */
  background-color: #374c88 !important;
  color: #fff !important;
  height: fit-content;
  /* position: absolute !important; */
  /* top: 50%; */
  /* left: 25px !important; */
  font-size: 13.1px !important;
  font-family: Open sans !important;
}

.arrowStyleButtonRight {
  border-radius: 8.8px !important;
  /* padding: 6px !important;
  margin: 10px !important; */
  background-color: #374c88 !important;
  color: #fff !important;
  font-size: 13.1px !important;
  height: fit-content;
  /* position: absolute !important; */
  /* top: 50%; */
  /* right: 50px !important; */
  font-family: Open sans !important;
}

.arrowStyledisableButton {
  border-radius: 8.8px !important;
  padding: 6px !important;
  margin: 10px !important;
  background-color: #374c88 !important;
  color: #fff !important;
  font-size: 13.1px !important;
  height: fit-content;
  position: absolute !important;
  top: 50%;
  right: 50px !important;
  font-family: Open sans !important;
}

.input_date_css_error_format_registration > input {
  width: 90%;
  padding: 17.5px;
  position: relative;
  font-size: 15.8px;
  border-radius: 8.8px;
  border: 1px solid red;
  color: #3f526d;
  background-color: #f6f6f6;
  height: 33px;
}

.input_date_cal_css_format_registration > input {
  width: 90%;
  padding: 17.5px;
  font-size: 15.8px;
  border-radius: 8.8px;
  border: 1px solid lightgray;
  position: relative;
  color: #3f526d;
  background-color: #f6f6f6;
  height: 33px;
}
/* .rdtPicker {
  width: 100% !important;
  padding: 0 !important;
} */

.Date_of_birth_calendar_Icon_register:focus {
  outline: none;
}

.Date_of_birth_calendar_Icon_register {
  background-repeat: no-repeat;
  background-image: url('../assets/addCalendar.png');
  background-size: 19px 19px;
  background-position: top 16px right 28.3px;
}

.typesofAccount_div {
  width: 60% !important;
  justify-content: center !important;
  margin: auto !important;
  margin-top: 18px !important;
  text-align: center !important;
}

.classSubjectDeleteIcon {
  object-fit: contain;
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  right: -50px;
  top: 20px;
}

.classSubjectAddIcon {
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: -26px;
  top: 20px;
  width: 30px;
  height: 30px;
  margin-left: 5px;
}

.uploadimage_btn {
  border-radius: 22.5px !important;
  background-color: rgb(237, 158, 49) !important;
  font-size: 13.1px !important;
  padding: 13px 12px 14px 13px !important;
  text-align: center !important;
  text-transform: none !important;
}

.schoolSelect {
  width: 98% !important;
  border-radius: 8.8px !important;
  text-align: start !important;
  font-size: 15.8px !important;
  font-family: Open sans !important;
}

.requestDemoBtn {
  font-family: 'Open sans' !important;
  color: rgb(255, 255, 255) !important;
  margin-top: 20px !important;
  width: 100% !important;
  background: -webkit-linear-gradient(left, #776be8 0%, #aca2ff 99%);
  height: 70px;
  border-radius: 8.8px !important;
  font-size: 19.8px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.selectType {
  width: 98% !important;
  border-radius: 8.8px !important;
  margin-bottom: 5px !important;
  text-align: start !important;
}

.react-tel-input .form-control::-webkit-input-placeholder,
.Date_of_birth_calendar_Icon::-webkit-input-placeholder {
  color: #a6a5c0;
}

.react-tel-input .form-control::-moz-placeholder,
.Date_of_birth_calendar_Icon::-moz-placeholder {
  color: #a6a5c0;
}

.react-tel-input .form-control:-ms-input-placeholder,
.Date_of_birth_calendar_Icon:-ms-input-placeholder {
  color: #a6a5c0;
}

.react-tel-input .form-control:-moz-placeholder,
.Date_of_birth_calendar_Icon:-moz-placeholder {
  color: #a6a5c0;
}

@media (max-width: 350px) {
  /* .informationTextLabel{
    font-size: 13px;
  } */

  .input_date_css_error_format_registration > input {
    width: 86% !important;
  }

  .input_date_cal_css_format_registration > input {
    width: 86% !important;
  }
}

@media (max-width: 599px) {
  .arrowStyleButtonLeft {
    top: 75px !important;
    left: 0 !important;
  }

  .arrowStyleButtonRight {
    top: 75px !important;
    right: 0 !important;
  }

  .arrowStyledisableButton {
    top: 75px !important;
    right: 0 !important;
  }
}

@media (max-width: 767px) {
  .Header {
    padding: 0 15px;
  }

  .blockText {
    font-size: 14.1px;
    margin-top: 0px !important;
    margin-left: 10px !important;
    width: 64%;
  }

  .informationTextLabel {
    font-size: 13px;
    margin-top: 5px;
  }

  .arrowStyleButtonLeft {
    top: 85px !important;
    padding: 6px !important;
    left: 0px;
  }

  .uploadimage_btn {
    padding: 0 !important;
  }

  .arrowStyleButtonRight {
    top: 85px !important;
    padding: 6px !important;
    right: 0px !important;
  }

  .arrowStyleButtonLeft {
    top: 10%;
    padding: 6px !important;
    left: 0px;
  }

  .arrowStyledisableButton {
    top: 85px !important;
    padding: 6px !important;
    right: 0px !important;
  }

  .chooseAccount {
    margin-top: 40px !important;
    font-size: 25px !important;
  }

  .Avtar-Img {
    margin-left: 15px;
    margin-right: 0px;
  }

  .Avtar-Img img {
    width: 50px !important;
    height: 50px !important;
  }

  .Radio-Button .MuiSvgIcon-root {
    right: 0px;
    width: 1em !important;
    height: 1em !important;
  }

  .Emai-Row {
    height: auto !important;
    min-height: 95px;
  }

  .typesofAccount_div {
    width: 100% !important;
    margin-top: 15% !important;
  }

  .input_date_css_error_format_registration > input {
    width: 93% !important;
  }

  .input_date_cal_css_format_registration > input {
    width: 93% !important;
  }

  .schoolSelect {
    width: 95% !important;
  }

  .requestDemoBtn {
    width: 95% !important;
  }

  .selectType {
    width: 95% !important;
  }

  .classSubjectDeleteIcon {
    right: -24px;
    top: 30px;
  }

  .classSubjectAddIcon {
    top: 5px;
    width: 25px;
    height: 25px;
  }

  .error_logo_div {
    height: 80px !important;
    width: 80px !important;
  }

  .success_logo_div {
    height: 60px !important;
    width: 60px !important;
  }

  .verification_link_text {
    font-size: 18px !important;
  }
  .pl-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 959px) {
  .uploadimage_btn {
    padding: 2px 2px 2px 5px !important;
  }

  .loginHeight {
    height: auto !important;
  }

  .centerDiv {
    margin-bottom: 25px;
    margin-top: 15px;
  }

  .input_date_css_error_format_registration > input {
    width: 89%;
    padding: 17.5px !important;
  }

  .input_date_cal_css_format_registration > input {
    width: 89%;
    padding: 17.5px !important;
  }

  .arrowStyleButtonLeft {
    left: 0px !important;
  }

  .arrowStyleButtonRight {
    right: 0px !important;
  }

  .arrowStyledisableButton {
    right: 0px !important;
  }
}

@media (min-width: 1023px) {
  .input_date_css_error_format_registration > input {
    padding: 17.5px 6px 17.5px 17.5px !important;
  }

  .input_date_cal_css_format_registration > input {
    padding: 17.5px 6px 17.5px 17.5px !important;
  }
}

@media (min-width: 1279px) {
  .input_date_css_error_format_registration > input {
    padding: 17.5px !important;
  }

  .input_date_cal_css_format_registration > input {
    padding: 17.5px !important;
  }

  .arrowStyleButtonLeft {
    left: 10px;
  }

  .arrowStyleButtonRight {
    right: 10px;
  }

  .arrowStyledisableButton {
    right: 10px;
  }
}

@media (max-width: 1279px) {
  .md-mb-0 {
    margin-bottom: 0px !important;
  }

  .success_logo_div {
    margin-top: 15px !important;
  }
}
@media (max-width: 415px) {
  .input_date_css_error_format_registration > input {
    width: 88% !important;
  }

  .input_date_cal_css_format_registration > input {
    width: 88% !important;
  }
}

.accountRegistration_div {
  justify-content: space-between;
  display: flex;
  box-shadow: 0 2px 4px 0 hsla(0,0%,84.7%,.5) !important;
}
.accountRegistration_helpIcon {
  color: #f57b42;
  padding: 0px;
}
.logoImage {
  width: 151px;
  position: relative;
  object-fit: cover;
}
.typesofAccount_div {
  justify-content: center;
  margin: auto;
  text-align: center;
}
.typesofAccount_textLabel {
  font-size: 35.1px;
  font-weight: bold;
  margin-top: 35px;
  margin-bottom: 32.6px;
}
.studentAccBackgroundImg {
  width: 100%;
  justify-content: space-between;
  height: 161px;
  border-radius: 15px;
  display: flex;
  margin-bottom: 10px;
  background-position: right;
  background-repeat: no-repeat;
}
.teacherAccBackgroundImg {
  width: 100%;
  justify-content: space-between;
  height: 161px;
  border-radius: 15px;
  display: flex;
  background-position: right;
  background-repeat: no-repeat;
}
.parentAccBackgroundImg {
  width: 100%;
  justify-content: space-between;
  height: 161px;
  border-radius: 15px;
  display: flex;
  background-position: right;
  background-repeat: no-repeat;
}
.avatar {
  vertical-align: middle;
  width: 81px;
  height: 81px;
  border-radius: 50%;
}
.avtar_div {
  position: relative;
}
.radioDiv {
  width: 20%;
  margin: auto;
  vertical-align: middle;
}
.accountRegistration_footer {
  justify-content: space-between;
  display: flex;
  margin-top: 15px;
}
.FlexCenter {
  display: flex !important;
  align-items: center !important;
}
.WhiteText {
  color: #fff !important;
}
.EmailAccount_MarginB10 {
  margin-bottom: 10px !important;
}